import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  _getPolicy,
  _loadPolicyClaims,
  _loadPolicyDocuments
} from "../../store/policy/service";
import formatCurrency from "../../utils/format-currency";
import { _getLang, _getPolicyToken } from "../../store/system/service";
import ClaimStatus from "../custom/policy-detail/claim-status"
import useConfig from "../qnect-ui/qnect-config";

const MContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const MRow = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const MKey = styled.div`
  margin-bottom: 4px;
  color: #8a94a6;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

const MValue = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const MClaimValue = styled(MValue)`
  color: #003da5;
`;


/* ============================ */

const Word = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Empty = styled.div`
  padding: 40px 0;
  height: 100px;
  width: 100%;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const StyledTableContainer = withStyles({
  root: {
    padding: "0 20px",
    borderRadius: "20px",
    boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)"
  }
})(TableContainer);

const StyledTableCell = withStyles({
  root: {
    padding: "20px"
  },
  head: {
    width: "25%",
    color: "#8A94A6",
    fontFamily: "Inter",
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "20px"
  }
})(TableCell);

const StyledTableBody = withStyles({
  root: {
    "& .MuiTableRow-root:last-child .MuiTableCell-root": {
      border: "none"
    }
  }
})(TableBody);

const PolicyClaims = props => {
  const [claims, setClaims] = useState();
  const [currentClaims, setCurrentClaims] = useState([]);
  const [claimLoaded, setClaimLoaded] = useState(false);

  const classes = useStyles();

  const columns = [
    { title: "policy.heading.claimNo", key: "claimNum" },
    { title: "policy.heading.submissionDate", key: "date" },
    { title: "policy.heading.amountPaid", key: "paid" },
    { title: "policy.heading.status", key: "status" }
  ];

  function IntlNumberFormat(n) {
    return new Intl.NumberFormat("ja-JP", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(n);
  }

  useEffect(() => {
    if (props.policy?.policyNumber) {
      props.loadPolicyClaims().then(resp => {
        setClaimLoaded(true);
        setClaims(resp);
        if (resp) {
          let currClaims = resp
              ?.sort((c1, c2) => (c1.dateOfLoss = c2.dateOfLoss));
          setCurrentClaims(currClaims);
        }
      });
    } else {
      setCurrentClaims([]);
    }
  }, [props.policy]);

  return (
      <>
        <Hidden mdUp>
          {claimLoaded && currentClaims?.length > 0 && currentClaims?.map((row, index) => (
              <MContainer key={row.claimNumber}>
                <MRow>
                  <MKey>{props.cfg(columns[0]?.title)}</MKey>
                  <MClaimValue>{row.claimNumber ? row.claimNumber : row.claimNotificationNumber }</MClaimValue>
                </MRow>
                <MRow>
                  <MKey>{props.cfg(columns[1]?.title)}</MKey>
                  <MValue>{row.dateReported ? moment(row.dateReported).format("DD/MM/YYYY") : null}</MValue>
                </MRow>
                <MRow>
                  <MKey>{props.cfg(columns[3]?.title)}</MKey>
                  <MValue>
                    {(row.paidAmount && row.paidAmount !== "N/A")
                        ? row.paidAmount
                        : null}
                  </MValue>
                </MRow>
                <ClaimStatus status={row.qnectStatus} cfg={props.cfg} />
              </MContainer>
          ))}
          { claimLoaded && currentClaims && currentClaims.length === 0 &&
            <MContainer key='noClaim'>
              <MRow>
                <Empty>{props.cfg("policy.noClaimFound")}</Empty>
              </MRow>
            </MContainer>
          }
          { !claimLoaded &&
            <MContainer key='noClaim'>
              <MRow>
                <Empty>{props.cfg("policy.loading")}</Empty>
              </MRow>
            </MContainer>
          }
        </Hidden>

        <Hidden smDown>
          <StyledTableContainer component={Paper}>
            <Table className={classes.table}>
              {currentClaims && currentClaims.length > 0 && (
                  <TableHead>
                    <TableRow>
                      {columns.map(({ title, key }) => (
                          <StyledTableCell key={key}>
                            {props.cfg(title)}
                          </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
              )}
              <StyledTableBody>
                {claimLoaded && currentClaims?.length > 0 && currentClaims?.map((row, index) => (
                    <TableRow key={row.claimNumber}>
                      <StyledTableCell component="th" scope="row">
                        <Word>{row.claimNumber ? row.claimNumber : row.claimNotificationNumber}</Word>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Word>{row.dateReported ? moment(row.dateReported).format("DD/MM/YYYY") : null}</Word>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Word>
                          {(row.paidAmount && row.paidAmount !== "N/A")
                              ? row.paidAmount
                              : null}
                        </Word>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ClaimStatus status={row.qnectStatus} cfg={props.cfg} />
                      </StyledTableCell>
                    </TableRow>
                ))}
                {claimLoaded && currentClaims && currentClaims.length === 0 &&
                  <TableRow>
                    <StyledTableCell>
                      <Empty>{props.cfg("policy.noClaimFound")}</Empty>
                    </StyledTableCell>
                  </TableRow>
                }
                {!claimLoaded &&
                  <TableRow>
                    <StyledTableCell>
                      <Empty>{props.cfg("policy.loading")}</Empty>
                    </StyledTableCell>
                  </TableRow>
                }
              </StyledTableBody>
            </Table>
          </StyledTableContainer>
        </Hidden>
      </>
  );
};

const mapStateToProps = state => {
  return {
    lang: _getLang(state),
    policy: _getPolicy(state),
    policyToken: _getPolicyToken(state)
  };
};

const mapDispatchToProps = {
  loadPolicyClaims: _loadPolicyClaims
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PolicyClaims)
);
