import React from "react";
import {ReactComponent as UnhappyError} from "../../assets/unhappyerror.svg";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    TopErrorContainer: {
        minHeight: "100px",
        color: "#ffffff",
        fontSize: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000000",
        zIndex: "10"
    },
    ErrorText: {
        maxWidth: "420px"
    },
    ErrorImg: {
        marginRight: "25px",
        height: "43px",
        flexShrink: "0"
    }
}));

export default function SystemUnavailableBanner() {
    const classes = useStyles();
    return (
        <>
            <div className={classes.TopErrorContainer}>
                <UnhappyError className={classes.ErrorImg}  alt=""/>
                <span className={classes.ErrorText}>Sorry, our system is currently unavailable. Please try again later.</span>
            </div>
        </>
    );
}
