import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import {ReactComponent as Youtube} from "../../assets/login/icon_youtube.svg";
import {ReactComponent as Link} from "../../assets/login/icon_linkedin.svg";
import {ReactComponent as Facebook} from "../../assets/login/facebook.svg";
import {ReactComponent as Twitter} from "../../assets/login/icon_twitter_wht.svg";
import useConfig from "../qnect-ui/qnect-config";
import {useSelector} from "react-redux";
import {_getAdminLogin, _getCountry, _getLang} from "../../store/system/service";
import BuildNumber from '../../assets/build.number';
import "./footer-custom-style.css";

const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: "border-box",
        position: "absolute",
        bottom: 0,
        width: "100%",
        fontFamily: "Inter",
        fontSize: "16px",
        lineHeight: "20px"
    },
    flexbox: {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    topic: {
        cursor: "default",
        "&:first-child": {
            marginLeft: 0
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: "8px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginLeft: "20px"
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "30px"
        },
        "& a": {
            "&:visited, &:link": {
                color: "#fff"
            }
        }
    },
    version: {
        fontSize: "8px",
        color: "#8a94a6",
        marginTop: "16px",
        "&:first-child": {
            marginLeft: 0
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        [theme.breakpoints.up("md", "lg")]: {
            marginLeft: "10px"
        }
    },
    right: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "88px"
        },
        [theme.breakpoints.up("md")]: {
            width: "136px"
        }
    },
    iconWrapper: {
        borderRadius: "50%",
        background: "rgba(255, 255, 255, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up("md")]: {
            width: "32px",
            height: "32px"
        }
    },
    iconWrapperBorder:{
        background: "rgba(25, 25, 25, 1)",
        borderRadius: "50%",
        border: "1px solid #00AFF4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up("md")]: {
            width: "34px",
            height: "34px"
        }
    },
    facebookIcon: {
        [theme.breakpoints.down("xs")]: {
            width: "12px",
            height: "12px"
        },
        [theme.breakpoints.up("md")]: {
            width: "16px",
            height: "16px"
        }
    },
    iconStyle: {
        [theme.breakpoints.down("xs")]: {
            width: "12px",
            height: "12px"
        },
        [theme.breakpoints.up("md")]: {
            width: "16px",
            height: "16px"
        }
    },
    iconBorderStyle: {
        [theme.breakpoints.down("xs")]: {
            width: "12px",
            height: "12px"
        },
        [theme.breakpoints.up("md")]: {
            width: "14px",
            height: "14px"
        }
    },
    footerMain: {
        background: "#191919",
        color: "#fff",
        height: "70px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            padding: "10px 46px 25px",
            height: "87px",
            width: "100%",
            fontSize: "14px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: "0 24px"
        },
        [theme.breakpoints.up("lg")]: {
            padding: "0 165px"
        },
        ['@media screen and (max-width: 350px)']: {
            padding: "16px 20px 20px!important",
        },
    }
}));

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = (props) => {
    const classes = useStyles();
    const cfg = useConfig(props.lang);

    const [versionNumber, setVersionNumber] = useState("");
    useEffect(() => {
        fetch(BuildNumber)
            .then(response => response.text())
            .then(data => {
                setVersionNumber(data);
            });
    },[])

    const privacyPolicyLink = () => {
        if (props.country === 'SGP') {
            return 'https://www.qbe.com/sg/privacy-policy';
        }
        if (props.country === 'MYS'){
            return 'https://www.qbe.com/my/privacy-policy';
        } 
        else {
            if (props.lang === 'en') {
                return 'https://www.qbe.com/hk/en/privacy-policy';
            }
            else {
                return 'https://www.qbe.com/hk/zh-hk/privacy-policy';
            }
        }
    }

    const termsOfUseLink = () => {
        if (props.country === 'SGP') {
            return 'https://www.qbe.com/sg/terms-of-use';
        }
        if (props.country === 'MYS'){
            return 'https://www.qbe.com/my/terms-of-use';
        }
        else {
            if (props.lang === 'en') {
                return 'https://www.qbe.com/hk/en/terms-of-use';
            } 
            else {
                return 'https://www.qbe.com/hk/zh-hk/terms-of-use';
            }
        }
    }

    const respDisclosureLink = () => {
        if (props.country === 'SGP') {
            return 'https://www.qbe.com/sg/responsible-disclosure-program';
        }
        else if (props.country === 'MYS'){
            return 'https://www.qbe.com/my/responsible-disclosure-program';
        } else {
            return 'https://www.qbe.com/responsible-disclosure-program';
        }
    }

    return (
        <div className={classes.root}>
            <div id="footer_container" className={classes.footerMain}>
                <Hidden mdUp>
                    <div className={classes.topic}>
                        {props.lang === 'en'
                            ?
                            <div>Copyright QBE {new Date().getFullYear()}. All Rights Reserved.</div>
                            :
                            cfg('footer.copyright')
                        }
                    </div>
                </Hidden>
                <div id="footer_links_container" className={classes.flexbox}>
                    <Left className="left_side">
                        <Hidden xsDown>
                            <div className={classes.topic}>
                                {props.lang === 'en'
                                    ?
                                    <div>Copyright QBE {new Date().getFullYear()}. All Rights Reserved.</div>
                                    :
                                    cfg('footer.copyright')
                                }
                            </div>
                        </Hidden>
                        <div className={classes.topic}>
                            <a href={ privacyPolicyLink() }
                               target='_blank'>
                                {cfg('footer.privacyPolicy')}
                            </a>
                        </div>
                        <div className={classes.topic}>
                            <a href={ termsOfUseLink() }
                               target='_blank'>
                                {cfg('footer.termsOfUse')}
                            </a>
                        </div>
                        <div className={classes.topic}>
                            <a href={respDisclosureLink()}
                               target='_blank' style={props.country === 'SGP' || props.country === 'MYS' ? {} : {display: 'none'}}>
                                {cfg('footer.respDisclosure')}
                            </a>
                        </div>
                    </Left>
                    <div className={classes.right}>
                        {
                            props.country === 'HKG' &&
                            <a href='https://www.facebook.com/QBEHongKong/'
                               target='_blank' className={props.country === 'MYS' ? classes.iconWrapperBorder : classes.iconWrapper}
                               style={props.country === 'SGP' ? {display: 'none'} : {}}>
                                <Facebook className={props.country === 'MYS' ? classes.iconBorderStyle : classes.iconStyle}/>
                            </a>
                        }
                        <a
                            href={props.country === 'HKG' ? 'https://hk.linkedin.com/company/qbe-asia'
                                : 'https://www.linkedin.com/company/qbe-asia'}
                            target='_blank'
                            className={props.country === 'MYS' ? classes.iconWrapperBorder : classes.iconWrapper}>
                            <Link className={props.country === 'MYS' ? classes.iconBorderStyle : classes.iconStyle}/>
                        </a>
                        <a
                            href='https://www.youtube.com/channel/UCDXN3KNTtz5eQ2uX76Xk0Mg'
                            target='_blank'
                            className={props.country === 'MYS' ? classes.iconWrapperBorder : classes.iconWrapper}>
                            <Youtube className={props.country === 'MYS' ? classes.iconBorderStyle : classes.iconStyle}/>
                        </a>
                        {
                            props.country === 'MYS' &&(
                                <a 
                                    href='https://twitter.com/qbe'
                                    target='_blank' 
                                    className={props.country === 'MYS' ? classes.iconWrapperBorder : classes.iconWrapper}
                                    style={props.country === 'SGP' || props.country === 'HKG' ? {display: 'none'} : {}}
                                >
                                    <Twitter className={props.country === 'MYS' ? classes.iconBorderStyle : classes.iconStyle}/>
                                </a>
                            )
                        }
                    </div>
                    <div className={classes.version}>
                        {props.adminLogin &&
                            <span>{ props.adminLogin } - </span>
                        }
                        {versionNumber}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state),
        adminLogin: _getAdminLogin(state),
    }
}

export default connect(mapStateToProps)(Footer);
