import React, {useRef} from "react";
import styled from "styled-components";
import {withStyles} from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import OtpInput from "../opt-input";
import QnectButton from "../../qnect-ui/qnect-button";
import {ReactComponent as Back} from "../../../assets/login/back_sharp.svg";
import ButtonWrapper from "../button-wrapper";
import withWidth from "@material-ui/core/withWidth";
import {smDown} from '../../../ui/common/breakpoints';
import email from "../../../ui/components/icons/legacy/email";

const StyledOtpInput = styled(OtpInput)`
  margin-top: 49px;
`;

const StyledMessage = styled.div`
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: "Inter";
  ${smDown`
    font-size: 16px;
    line-height: 20px;
  `}
  
  span {
     letter-spacing: 0.1px;
  }
`;

const StyledResendMessage = styled(StyledMessage)`
  margin-top: 42px;
`;

const StyledResend = styled(StyledMessage)`
  display: inline-block;
  margin-top: 8px;
  margin-left: 8px;
  color: #003da5;
  border-bottom: 1px solid #003da5;
  cursor: pointer;
`;

const StyledResendDisabled = styled(StyledResend)`
  color: #b0b7c3;
  border-bottom: 1px solid #b0b7c3;
  cursor: auto;
`;

const StyledActionText = styled.div`
  font-family: "Inter";
  line-height: 24px;
  color: #003da5;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 22px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  align-items: center;
`;

const StyledIconDescription = styled.div`
  font-weight: 500;
  color: #0a1f44;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  ${smDown`
    font-size: 16px;
    line-height: 20px;
  `}
  & > :first-child {
    padding-bottom: 30px;
    ${smDown`
      padding-bottom: 20px;
    `}
  }
`;

const StyledIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > :first-child {
    padding-right: 4px;
  }
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;

`;

const StyledBack = styled(Back)`
  width: 28px;
  height: 28px;
  margin-right: 16px;
  vertical-align: sub;
  cursor: pointer;
  position: relative;
  top: 50px;
  left: 58px;
  ${smDown`
    padding-top: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-left: -24px;
    position: absolute;
    top: 20px;
    left: 30px;
  `}
`

const StyledDialogActions = withStyles((theme) => ({
    root: {
        marginTop: "78px",
        marginBottom: "56px",
        display: "flex",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down("md")]: {margin: "78px 45px 52px"},
        [theme.breakpoints.down("sm")]: {margin: "45px 0 0 0"},
    },
}))(DialogActions);

const StyledDialog = withStyles({
    paper: {
        width: "630px",
        padding: "14px 29px 0 30px",
    },
    paperWidthSm: {
        maxWidth: "none",
    },
})(Dialog);

const StyledDialogContent = withStyles((theme) => ({
    root: {
        width: "429px",
        margin: "40px auto auto auto",
        padding: 0,
        overflowY: "unset",
        [theme.breakpoints.down("md")]: {
            marginTop: '14px !important',
            marginBottom: '0 !important'
        },
        [theme.breakpoints.down("sm")]: {
            width: "auto",
            margin: '4px 0 0 0'
        },
    },
}))(DialogContent);

const StyledDialogContentNext = withStyles((theme) => ({
    root: {
        marginTop: "30px",
        marginBottom: "60px",
        [theme.breakpoints.down("md")]: {
            marginBottom: "60px !important",
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "20px !important",
            marginTop: "4px !important",
        },
    },
}))(StyledDialogContent);

const AuthStepEmailMobileOtpDialog = ({open, onClose, cfg, width, handleResend, resendTimer, resendEmailTimer,
                                          maskedPhone, maskedEmail, isForEmail, errorMessage, setErrorMessage,
                                          onConfirm, lang}) => {
    const [value, setValue] = React.useState("");
    const [showHelp, setShowHelp] = React.useState(false);

    function handleConfirm() {
        onConfirm(value);
    }

    function convertSecsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_mins = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_mins / 60);

        let divisor_for_secs = divisor_for_mins % 60;
        let seconds = Math.ceil(divisor_for_secs);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds < 10 ? `0`+seconds : seconds
        };
        let timeValue = obj.m + ":" + obj.s;
        return timeValue;
    }

    React.useEffect(() => setValue(''),[open]);

    return (
        <StyledDialog open={open} onClose={onClose}>
            {!showHelp ? (
                <>
                    <StyledBack onClick={onClose}></StyledBack>
                    <DialogTitle title={cfg('authStepChooseEmailMobile.title')} onClose={onClose}
                                 closable={width === "lg" || width === "xl" ? true : false}
                                 lang={lang}
                    />
                    <StyledDialogContent>
                        { isForEmail ?
                            <StyledMessage>
                                <span>{cfg('authStepChooseEmailMobile.emailWith6digit') + maskedEmail}</span>
                            </StyledMessage> :
                            <StyledMessage>
                                <span>{cfg('authStepChooseEmailMobile.smsWith6digit') }{ typeof(maskedPhone) ==='string'?maskedPhone.split('').map(x=><span>{x}</span>):maskedPhone}</span>
                            </StyledMessage>
                        }
                        <StyledOtpInput value={value}
                                        onChange={(v) => {
                                            setErrorMessage('');
                                            setValue(v);
                                        }}
                                        errorMessage={value !== "" && errorMessage}/>
                        <StyledResendMessage>
                            <div>{cfg('authStepChooseEmailMobile.haventReceived')}</div>
                            { isForEmail && (resendEmailTimer === 0 ?
                                <StyledResend onClick={handleResend}>{ cfg('verification.resend')}</StyledResend> :
                                <StyledResendDisabled>{ cfg('verification.resend')}({convertSecsToTime(resendEmailTimer)})</StyledResendDisabled>)}
                            { !isForEmail && (resendTimer === 0 ?
                                <StyledResend onClick={handleResend}>{ cfg('verification.resend')}</StyledResend> :
                                <StyledResendDisabled>{ cfg('verification.resend')}({resendTimer})</StyledResendDisabled>)}
                        </StyledResendMessage>
                    </StyledDialogContent>
                    <StyledDialogActions>
                        <ButtonWrapper>
                            <StyledQnectButton
                                onClick={handleConfirm}
                                disabled={value.length !== 6}
                            >
                                {cfg("labels.submit")}
                            </StyledQnectButton>
                        </ButtonWrapper>
                    </StyledDialogActions>
                </>
            ) : (
                <>
                    <DialogTitle
                        title={
                            <>
                                <StyledBack onClick={() => setShowHelp(false)}/>
                                Need Help?
                            </>
                        }
                        onClose={onClose}
                        closable={width === "lg" || width === "xl" ? true : false}
                    />
                    <StyledDialogContentNext>
                        <StyledMessage>Are you having trouble finding your policy?<br/>Please contact QBE Hong Kong's
                            Customer Service.</StyledMessage>
                        <StyledIconContainer>
                            <StyledIconDescription>
                                <StyledIconRow>
                                    <PhoneIcon/> +852 2828 3138
                                </StyledIconRow>
                                <StyledIconRow>
                                    <MailOutlineIcon/> customerservice@qbe.com
                                </StyledIconRow>
                            </StyledIconDescription>
                        </StyledIconContainer>
                    </StyledDialogContentNext>
                </>
            )}
        </StyledDialog>
    );
}


export default withWidth()(AuthStepEmailMobileOtpDialog);
