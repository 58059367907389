import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _getPolicyToken,
    _getPortfolioToken, _hideModal, _hideSpinner,
    _setLang, _showError,
    _showModal, _showSpinner
} from "../../store/system/service";
import '../../assets/styles/auto-renewal.css';
import {_manageRenewal} from "../../store/user/service";
import {_sendLog} from "../../utils/utility";
import {_getPolicy} from "../../store/policy/service";
import AutoRenewalSectionToggleTOS from "./auto-renewal-section-toggle-tos";
import {EXPIRED, IS_ACTIVE, NA, NO_RECORD, OPTIN, OPTOUT} from "./auto-renewal-constants";
import AutoRenewalHeader from "./auto-renewal-header";
import QnectButton from "../qnect-ui/qnect-button";

const AutoRenewalManage = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);

    const [policyNumber, setPolicyNumber] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [expiry, setExpiry] = useState("");
    const [isActive, setIsActive] = useState("");
    const [status, setStatus] = useState("");
    const [isAllowedCancel, setIsAllowedCancel] = useState(false);

    useEffect(() => {
        // needed condition when opening manage auto-renewal from link or manual edit url,
        // otherwise, the same condition is already in placed from policy header (policy-header.js)
        // to keep policy that should not be allowed to manage auto-renewal.
        if (!(props.policy.statusCode === "IF" || props.policy.statusCode === "AR")) {
            history.push("/portfolio");
        } else {
            props.showSpinner();
            props.manageRenewal(props.policy.policyNumber, props.policy.country).then((resp) => {
                props.hideSpinner();
                setPolicyNumber(resp.manageRenewal.policyNumber);
                setCardNumber(resp.manageRenewal.maskedCardNumber);
                setExpiry(resp.manageRenewal.expiry);
                setIsActive(resp.manageRenewal.isActive);
                setStatus(resp.manageRenewal.status);
                setIsAllowedCancel((resp.manageRenewal.isActive !== NO_RECORD && resp.manageRenewal.isActive === IS_ACTIVE)
                    && resp.manageRenewal.status === OPTIN && props.policy.statusCode === 'AR');
                props.policy.autoRenewalStatus = resp.manageRenewal.status;
            }, (error) => {
                _sendLog(' auto-renewal-manage.js - useEffect(): exception:\n\n' + JSON.stringify(error));
                props.showError(['Error loading Auto Renewal details']);
            })
        }
    }, [])

    const generateContentsByRenewalStatus = () => {
        if(status === OPTIN){
            return (<>
                <div className="status-row">
                    <div className="status-display-title">
                        <span className="">{cfg('labels.autoRenewal.statusTitle')}</span>
                    </div>
                    <div id="autoRenewalStatusBox" className="status-container active-alert">
                        <span className="qbeTranslatable">{cfg('labels.autoRenewal.statusOn')}</span>
                    </div>
                    { isAllowedCancel ? (
                        <QnectButton color="secondary" className="btn btn-small btn-round btn-secondary" onClick={cancelAutoRenewal}>
                            <span className="qbeTranslatable">{ cfg('labels.autoRenewal.buttonLabelCancelAutoRenewal') }</span>
                        </QnectButton>) : ""
                    }
                </div>
                <div className="info-sub-container card-details">
                    {
                        <div className="info-sub-list">
                            <div className="main-list"
                                 dangerouslySetInnerHTML={{__html: cfg("labels.autoRenewal.statusOnList")}}/>
                            <AutoRenewalSectionToggleTOS/>
                        </div>
                    }
                </div>
            </>)
        } else if(status === OPTOUT) {
            return (<>
                <div className="status-row">
                    <div className="status-display-title">
                        <span className="">{cfg('labels.autoRenewal.statusTitle')}</span>
                    </div>
                    <div id="autoRenewalStatusBox" className="status-container inactive-alert">
                        <span className="qbeTranslatable">{cfg('labels.autoRenewal.statusOff')}</span>
                    </div>
                    {
                        isActive !== NO_RECORD &&
                        <QnectButton color="secondary" className="btn btn-small btn-round btn-secondary" onClick={activateCreditCard}>
                            <span className="qbeTranslatable">
                                { cfg('labels.autoRenewal.buttonLabelActivateAutoRenewal') }
                            </span>
                        </QnectButton>
                    }
                </div>
                <div className="info-sub-container card-details">
                    {
                        <div className="info-sub-list">
                            <div className="main-list"
                                 dangerouslySetInnerHTML={{__html: cfg("labels.autoRenewal.statusOffList")}}/>
                        </div>
                    }
                </div>
            </>);
        } else if(status === NA) {
            return (<>
                <div className="status-row">
                    <div className="status-display-title">
                        <span className="">{cfg('labels.autoRenewal.statusTitle')}</span>
                    </div>
                    <div id="autoRenewalStatusBox" className="status-container inactive-alert">
                        <span className="qbeTranslatable hidden">{cfg('labels.autoRenewal.statusNA')}</span>
                    </div>
                    <QnectButton color="secondary" className="btn btn-small btn-round btn-secondary" onClick={activateCreditCard}>
                        <span className="qbeTranslatable">
                            { cfg('labels.autoRenewal.buttonLabelActivateAutoRenewal') }
                        </span>
                    </QnectButton>
                </div>
                <div className="info-sub-container card-details">
                    {
                        <div className="info-sub-list">
                            <div className="main-list"
                                 dangerouslySetInnerHTML={{__html: cfg("labels.autoRenewal.statusOffList")}}/>
                        </div>
                    }
                </div>
            </>);
        }
    }

    const generateCreditCardStatusBox = () => {

        if(isActive === NO_RECORD) {
            return (<>
                <section className="creditCard-section main-info separated">
                    <div className="status-row">
                        <div className="status-display-title">
                            <span className="">{cfg('labels.autoRenewal.statusTitleCreditCardStatus')}</span>
                        </div>
                        <div id="creditCardStatusBox" className="status-container error-alert">
                            <span className="">{cfg('labels.autoRenewal.statusLabelCreditCardNoRecord')}</span>
                        </div>
                        <QnectButton color="secondary" className="btn btn-small btn-round btn-secondary" onClick={updateCreditCardDetails}>
                            <span>{cfg('labels.autoRenewal.buttonLabelUpdateCreditCardDetails')}</span>
                        </QnectButton>
                    </div>
                    <div className="info-sub-container card-details">
                        <div className="data-row">
                            <span className="">{cfg('labels.autoRenewal.dataLabelCardNumber')}</span>
                            <span className="data-value">-</span>
                        </div>
                        <div className="data-row">
                            <span className="">{cfg('labels.autoRenewal.dataLabelCardExpiryDate')}</span>
                            <span className="data-value">-</span>
                        </div>
                    </div>
                </section>
            </>);
        }
        if(isActive === EXPIRED){
            return (<>
                <section className="creditCard-section main-info separated">
                    <div className="status-row">
                        <div className="status-display-title">
                            <span className="">{cfg('labels.autoRenewal.statusTitleCreditCardStatus')}</span>
                        </div>
                        <div id="creditCardStatusBox" className="status-container error-alert">
                            <span className="">{cfg('labels.autoRenewal.statusLabelCreditCardExpired')}</span>
                        </div>
                        <QnectButton color="secondary" className="btn btn-small btn-round btn-secondary" onClick={updateCreditCardDetails}>
                            <span>{cfg('labels.autoRenewal.buttonLabelUpdateCreditCardDetails')}</span>
                        </QnectButton>
                    </div>
                    <div className="info-sub-container card-details">
                        <div className="data-row">
                            <span className="">{cfg('labels.autoRenewal.dataLabelCardNumber')}</span>
                            <div className="data-value"><span className="hiddenVal"/> <span className="hiddenVal"/> <span className="hiddenVal"/> { cardNumber }</div>
                        </div>
                        <div className="data-row">
                            <span className="">{cfg('labels.autoRenewal.dataLabelCardExpiryDate')}</span>
                            <span className="data-value">{ expiry }</span>
                        </div>
                    </div>
                </section>
            </>);
        }
        if(isActive === IS_ACTIVE){
            return (<>
                <section className="creditCard-section main-info separated">
                    <div className="status-row">
                        <div className="status-display-title">
                            <span className="">{cfg('labels.autoRenewal.statusTitleCreditCardStatus')}</span>
                        </div>
                        <div id="creditCardStatusBox" className="status-container active-alert">
                            <span className="">{cfg('labels.autoRenewal.statusLabelCreditCardActive')}</span>
                        </div>
                        <QnectButton color="secondary" className="btn btn-small btn-round btn-secondary" onClick={updateCreditCardDetails}>
                            <span className="">{cfg('labels.autoRenewal.buttonLabelUpdateCreditCardDetails')}</span>
                        </QnectButton>
                    </div>
                    <div className="info-sub-container card-details">
                        <div className="data-row">
                            <span className="">{cfg('labels.autoRenewal.dataLabelCardNumber')}</span>
                            <div className="data-value"><span className="hiddenVal"/> <span className="hiddenVal"/> <span className="hiddenVal"/> { cardNumber }</div>
                        </div>
                        <div className="data-row">
                            <span className="">{cfg('labels.autoRenewal.dataLabelCardExpiryDate')}</span>
                            <span className="data-value">{ expiry }</span>
                        </div>
                    </div>
                </section>
            </>);
        }
    }

    const updateCreditCardDetails = () => {
        props.showSpinner();
        history.push("/auto-renewal/update-credit-card");
    }

    const cancelAutoRenewal = () => {
        props.showSpinner();
        history.push("/auto-renewal/cancel");
    }

    const activateCreditCard = () => {
        props.showSpinner();
        history.push("/auto-renewal/activate");
    }

    const getTopAlert = () => {
      if(props.match.params.operation) {
          if(props.match.params.operation === 'success-update') {
              return (
                  <section id="topAlert" className="alert-container info-alert">
                      <span className="">{cfg('labels.autoRenewal.alertCreditCardUpdated') }</span>
                  </section>
              );

          } else if (props.match.params.operation === 'error-update') {
              return (
                  <section id="topAlert" className="alert-container error-alert">
                      <span className="">{cfg('labels.autoRenewal.alertSubmissionUnsuccessful') }</span>
                  </section>
              );
          } else if (props.match.params.operation === 'error-cancel') {
              return (
                  <section id="topAlert" className="alert-container error-alert">
                      <span className="">{cfg('labels.autoRenewal.alertSubmissionUnsuccessful') }</span>
                  </section>
              );
          } else if (props.match.params.operation === 'success-cancel'){
              return (
                  <section id="topAlert" className="alert-container info-alert">
                      <span className="">{cfg('labels.autoRenewal.alertAutoRenewalDisabled') }</span>
                  </section>
              );
          } else if (props.match.params.operation === 'error-activate') {
              return (
                  <section id="topAlert" className="alert-container error-alert">
                      <span className="">{cfg('labels.autoRenewal.alertSubmissionUnsuccessful') }</span>
                  </section>
              );
          } else if (props.match.params.operation === 'success-activate'){
              return (
                  <section id="topAlert" className="alert-container info-alert">
                      <span className="">{cfg('labels.autoRenewal.alertAutoRenewalEnabled') }</span>
                  </section>
              );
          }
      }
    }

    return (
        <>
        <div className="pageContainer auto-renewal detail-page">

            <AutoRenewalHeader titleHeader="labels.autoRenewal.titleManageAutoRenewal"
                               pathname={props.history.location.pathname}
               onBackCallBack={ () => {
                   history.push("/policy");
               }}/>

            <div className="main-content-container-boxed">
                <div className="main-content-container-box">
                    { getTopAlert() }
                    <div className="main-content-box">
                        <div className="content-boxed-block">
                            <div className="lined-title linked-title">
                                <span>{cfg('labels.autoRenewal.renewalDetails.policyNumber')}</span> - <span>{policyNumber}</span>
                            </div>
                            { generateCreditCardStatusBox() }
                            <section className="manageAutoRenewalDetails-section main-info">
                                { generateContentsByRenewalStatus() }
                            </section>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    setLang: _setLang,
    manageRenewal: _manageRenewal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoRenewalManage));

