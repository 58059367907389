import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";

import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import StyledTextFieldWrapper from "../style-text-field-wrapper";
import QnectTextField from "../../qnect-ui/qnect-text-field";

import { connect } from "react-redux";
import {_getPolicy, _updateEmailAndMobile} from "../../../store/policy/service";
import {_isNotDecimalChar, _validateForm} from "../../../utils/utility";
import {_getCountry, _hideModal, _hideSpinner, _setLang, _showModal, _showSpinner} from "../../../store/system/service";
import ForgotPasswordDialog from "../login/forgot-password-dialog";
import LabelledCheckbox from "../../../ui/components/form-controls/labelled-checkbox";
import styled from "styled-components";
import {smDown} from "../../../ui/common";
import {_registerEmailAndMobile, _storeUser} from "../../../store/user/service";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    dialog: {
        "& .MuiDialog-paper": {
            [theme.breakpoints.between("sm", "md")]: {
                padding: "14px 44px 52px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "14px 76px 52px",
                width: "630px"
            }
        }
    },
    inputBox: {
        overflowX: "hidden"
    },
    message: {
        textAlign: "center",
        color: "#0a1f44",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "20px",
            fontSize: "18px",
            lineHeight: "25px"
        }
    },
    input_wrapper: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "20px"
        }
    },
    country: {
        [theme.breakpoints.down("sm")]: {
            marginRight: "8px",
            width: "112px",
            flexShrink: 0
        },
        [theme.breakpoints.up("sm")]: {
            marginRight: "20px",
            width: "160px"
        }
    },
    phone_number: {
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("sm")]: {
            flex: 1
        }
    },
    error_message: {
        margin: "8px 0",
        fontSize: "16px",
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "#d54040",
        wordBreak: "keep-all",
        whiteSpace: "pre-wrap",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("sm")]: {}
    },
    actions: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            marginTop: "44px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "60px",
            padding: "0 24px"
        }
    }
}));

const StyledCheckBoxContent = styled.div`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  & > span {
    color: #003da5;
    cursor: pointer;
  }
  
   ${smDown`
        height: 95px;
        width: 265px;
  `}
   
`;

const AdditionalMargin = styled.div`
  margin-bottom: 20px;
`;

const UpdateEmailMobileDialog = ({
    open,
    onClose,
    policyNumber,
    cfg,
    ...props
}) => {
    const classes = useStyles();
    const formId = "find-my-policy-update-email-mobile";
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [isCheckedOne, setIsCheckedOne] = useState(false);
    const [isCheckedTwo, setIsCheckedTwo] = useState(false);

    useEffect(() => {
        setCountryCode(props.country === 'SGP' ? '65' : '852');
    }, [props.country]);

    const openPICSPage = () => {
        if (props.lang === 'zh') {
            window.open(process.env.REACT_APP_PICS_ZH_PAGE, '_blank');
        } else {
            window.open(process.env.REACT_APP_PICS_EN_PAGE, '_blank');
        }
    }
    
    const updateEmailAndMobile = () => {
        if (_validateForm(formId)) {
            props.showSpinner();
            let clientCredentials = {
                policyNumber: policyNumber,
                country: props.country,
                policyHolderType: 'I',
                clientPhone: phoneNumber,
                clientEmail: email
            }
            props.updateEmailAndMobile(clientCredentials).then((response) => {
                if (response.success) {
                    props.hideSpinner();
                    onClose();
                } else {
                    props.hideSpinner();
                }
            }, (error) => {
                props.hideSpinner();
                console.error('updateEmailAndMobile has failed', error);
            });
        }
    }

    return (
        <div>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                <DialogTitle title={cfg("updateEmailAndMobile.title")} onClose={onClose} />
                <DialogContent className={classes.inputBox}>
                    <div className={classes.message}>
                        {cfg("updateEmailAndMobile.message")}
                    </div>
                    <AdditionalMargin></AdditionalMargin>
                    <div>
                        <StyledTextFieldWrapper>
                            <QnectTextField field="updateDetailsEmailAddress"
                                            formId={formId}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            maxLength={60}
                            />
                        </StyledTextFieldWrapper>
                    </div>
                    <div className={classes.input_wrapper}>
                        <div className={classes.country}>
                            <StyledTextFieldWrapper>
                                <QnectTextField
                                    field="updateDetailsCountryCode"
                                    value={countryCode}
                                    onChange={(evt) => setCountryCode(evt.target.value)}
                                    onKeyPress={(event) => {
                                        if (_isNotDecimalChar(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    maxLength={5}
                                />
                            </StyledTextFieldWrapper>
                        </div>
                        <div className={classes.phone_number}>
                            <StyledTextFieldWrapper>
                                <QnectTextField field="updateDetailsPhoneNumber"
                                                value={phoneNumber}
                                                onChange={(evt) => setPhoneNumber(evt.target.value)}
                                                onKeyPress={(event) => {
                                                    if (_isNotDecimalChar(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                maxLength={12}
                                                formId={formId}
                                />
                            </StyledTextFieldWrapper>
                        </div>
                    </div>
                    <AdditionalMargin></AdditionalMargin>
                    <LabelledCheckbox checked={isCheckedOne} onChange={(event) => setIsCheckedOne(event.target.checked)}>
                        <StyledCheckBoxContent>
                            By proceeding, I confirm that I have read, understood, and accepted the
                            <span onClick={openPICSPage}>
                                Personal information Collection Statement("PICS")
                            </span> and agree with the digital service.
                        </StyledCheckBoxContent>
                    </LabelledCheckbox>
                    <AdditionalMargin></AdditionalMargin>
                    <LabelledCheckbox checked={isCheckedTwo} onChange={(event) => setIsCheckedTwo(event.target.checked)}>
                        <StyledCheckBoxContent>
                            I/We agree to receive marketing offers and promotions as described in the <span
                            onClick={openPICSPage}>Personal Information Collection Statement</span>.
                        </StyledCheckBoxContent>
                    </LabelledCheckbox>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <ButtonWrapper color="secondary" className={classes.button}>
                        <QnectButton color="secondary" onClick={onClose}>Skip</QnectButton>
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <QnectButton
                            onClick={() => {
                                updateEmailAndMobile();
                            }}
                            disabled={email === "" || phoneNumber === "" || countryCode === "" || !isCheckedOne}
                        >
                            Confirm
                        </QnectButton>
                    </ButtonWrapper>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        policy: _getPolicy(state),
        country: _getCountry(state)
    };
};

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    updateEmailAndMobile: _updateEmailAndMobile,
    setLang: _setLang
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmailMobileDialog);
