import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import '../../assets/styles/payment.css';
import LinkedIn from '../../assets/icon_linkedin.svg';
import YouTube from '../../assets/icon_youtube.svg';
import Tick from '../../assets/Tick.svg';
import useConfig from "../qnect-ui/qnect-config";
import QbeLogo from "../../assets/QBE-logo-on-light.png";
import {_getLang, _getPolicyToken, _getPortfolioToken, _setLang} from "../../store/system/service";
import {_getPolicyPayment} from "../../store/policy-payment/service";
import Header from "../custom/header";
import QnectButton from "../qnect-ui/qnect-button";
import styled from "styled-components";
import {_resetPolicyList} from "../../store/user/service";
import {_resetPolicy} from "../../store/policy/service";
import * as TagManager from "react-gtm-module";
import {_getPolPaymentCurrency} from "../../utils/utility";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const AdhocFinish = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);

    const [lang, setLang] = useState( props.lang);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [receiptNumber] = useState( props.policyPayment.payment.receiptNumber);
    const [emailAddress] = useState (props.policyPayment.email ? props.policyPayment.email : '');
    const [isBulkPayment] = useState (props.policyPayment.bulkPayment);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Payment Finish – Payment - ' + props.policyPayment.productType,
                vPath: '/payment-finish',
                vProduct: props.policyPayment.productType,
                vPolicyNo: props.policyPayment.policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                vPaymentAmount: props.policyPayment.amount,
                vCountry: props.policyPayment.country,
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: props.policyPayment.policyNumber,
                            affiliation: props.policyPayment?.agentNumber,
                            revenue: props.policyPayment.amount
                        },
                        products: [{
                            name: props.policyPayment.productType,
                            price: props.policyPayment.amount,
                            dimension1: 'credit-card',
                            dimension2: props.policyPayment.country,
                            category: 'Adhoc Payment',
                            quantity: 1
                        }]
                    }
                }
            }
        });
    },[])


    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as '+newLang);
    }

    const close = () => {
        if (props.policyPayment.country === 'SGP') {
            window.open(process.env.REACT_APP_QBE_ESERV_SGP,'_self');
        } else {
            window.open(process.env.REACT_APP_QBE,'_self');
        }
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }

    return (

        <div className="pageContainer adhoc-payment-finish-page">
            <div className="container">
                {canGoBack() ?
                  <Header/>
                  :
                  <div className="topRow">
                      <div className="left">
                          <a href="https://www.qbe.com/hk/en/about-qbe">
                              <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                          </a>
                      </div>
                      <div className="right">
                          <div className="languageSelect" onClick={() => switchLanguage()}
                               style={{ visibility: (props.policyPayment.country === 'HKG' ||
                                   props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                              {(lang === 'en') ?
                                cfg("labels.lang_zh")
                                :
                                cfg("labels.lang_en")
                              }
                          </div>

                      </div>
                  </div>
                }

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        {
                            props.policyPayment.country === 'HGI' &&
                            <section id="topAlert" className="hgi-alert-container info-alert">
                                <span className="">{ cfg('labels.qgiPolicyDisclaimer') }</span>
                            </section>
                        }
                        <div className="main-content-box no-topTitle">
                            <div className="thankyou-message">
                                <img src={Tick} width="80" height="80" alt="Info"/>
                                <div className="main-content-title alignCenter">{ cfg('labels.paymentSuccessTitle')}</div>
                                <div className="main-description-text alignCenter">{ cfg('labels.paymentSuccessMsg') } <b>{ emailAddress } </b></div>
                            </div>
                            <div className="table-details policy-details">
                                { isBulkPayment ?
                                (<table>
                                    <tbody>
                                    <tr>
                                        <td>{ cfg('labels.receiptNumber')}</td>
                                        <td>{ receiptNumber }</td>
                                    </tr>
                                    </tbody>
                                </table>)
                            : (<table>
                                    <thead>
                                    <tr>
                                        <th colSpan="2">{ cfg('labels.policyDetail')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{ cfg('labels.policyNumber')}</td>
                                        <td>{ policyNumber }</td>
                                    </tr>
                                    <tr>
                                        <td>{ cfg('labels.receiptNumber')}</td>
                                        <td>{ receiptNumber }</td>
                                    </tr>
                                    </tbody>
                                </table> )
                                }
                            </div>
                            <div className="form-field-container button-container button-size1">
                                <InputGroupStyle>
                                    <QnectButton field="close" onClick={close}>
                                    </QnectButton>
                                </InputGroupStyle>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    resetPolicyList: _resetPolicyList,
    resetPolicy: _resetPolicy
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdhocFinish));

