import React, {useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import typographyStyles from '../../common/typography-styles';
import useParentBlur from '../../common/use-parent-blur';
import SupplierCard from './supplier-card';

const Options = styled.div`
    margin: 0 auto;
`;

const SupplierTypeHeading = styled.div`
    padding: 16px;
    text-align: center;
    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'semiBold',
        defaultColor: 'black',
    })}
`;

const StyledSupplierCard = styled(SupplierCard)`
    margin: 16px 0 24px;
`;

const SupplierCardsField = React.forwardRef((
    {
        className,
        type,
        value: currentValue,
        onChange = noop,
        onBlur = noop,
        options,
        disabled,
        viewMoreText,
        viewLessText,
    },
    ref
) => {
    // Fallback ref so we can get onBlur events without an externally provided ref
    const internalRef = useRef(null);

    // Detect focus outside the group of radios
    useParentBlur(ref || internalRef, onBlur);

    return (
        <div>
            <Options
                ref={ref || internalRef}
                className={classnames('SupplierCardsField', className)}
            >
                <SupplierTypeHeading>
                    {type}
                </SupplierTypeHeading>

                {(options || []).map(({id, name, details}) => (
                    <StyledSupplierCard
                        key={id}
                        checked={id === currentValue}
                        disabled={disabled}
                        details={details}
                        name={name}
                        onChange={() => void onChange(id)}
                        value={id}
                        viewMoreText={viewMoreText}
                        viewLessText={viewLessText}
                    />
                ))}
            </Options>
        </div>
    );
});

SupplierCardsField.propTypes = {
    className: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onBlur: propTypes.func,
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string,
        name: propTypes.string,
        details: propTypes.shape({
            message: propTypes.string,
            contactDetails: propTypes.arrayOf(propTypes.string),
            combinationClaimInstructions: propTypes.string,
            specialInstructions: propTypes.string,
        }),
    })),
    type: propTypes.string,
    value: propTypes.string,
    viewMoreText: propTypes.string,
    viewLessText: propTypes.string,
};

SupplierCardsField.displayName = 'SupplierCardsField';

export default SupplierCardsField;
