import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import '../../assets/styles/payment.css';
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import VisaImg from '../../assets/Visa_logo.svg';
import MastercardImg from '../../assets/Mastercard_logo.svg';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import BalloonTip from "../ui/balloon-tip/balloon-tip";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry, _getLang, _getPaymentToken,
    _hideSpinner, _setLang,
    _showError,
    _showSpinner
} from "../../store/system/service";
import formatCurrency from "../../utils/format-currency";
import {
    _onlineAcceptPayment,
    _onlineFailPayment,
    _onlineInitiatePayment,
    _getPolicyPayment,
    _storePolicyPayment
} from "../../store/policy-payment/service";
import {_formatPaymentAmount, _getPolPaymentCurrency, _isNotDecimalChar, _sendLog} from "../../utils/utility";
import {_resetPolicy} from "../../store/policy/service";
import * as TagManager from "react-gtm-module";
import {OPTIN} from "../auto-renewal/auto-renewal-constants";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;


const OnlinePaymentProcess = (props) => {
    const cfg = useConfig(props.lang);

    const [paymentSignature, setPaymentSignature] = useState(null);
    const formRef = useRef(null);
    const [lang, setLang] = useState( props.lang);
    const [productName] = useState (props.policyPayment.productName);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [country] = useState (props.policyPayment.country);
    const [totalAmount] = useState(props.policyPayment.amount);
    const [cardHolderName, setCardHolderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [eligibleForAutoRenewal] = useState (props.policyPayment.eligibleForAutoRenewal);
    const [autoRenewalStatus] = useState (props.policyPayment.autoRenewalStatus);

    const originalConsoleError = console.error;
    console.error = function () {
        const errorArguments = Array.from(arguments);
        const errorMessage = errorArguments.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(" ");
        _sendLog("online-payment-process.js: " + errorMessage, "error")
        originalConsoleError.apply(console, arguments);
    }

    useEffect(() => {
        props.setLang('en');

        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Online Payment Process – Payment - ' + productName,
                vPath: '/online-payment-process',
                vProduct: productName,
                vPolicyNo: policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(country),
                vPaymentAmount: totalAmount,
                vCountry: country,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1
                        }
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        window.paymentSuccess = paymentSuccess.bind(this);
        window.paymentError = paymentError.bind(this);

        //cleanup
        return () => {
            delete window.paymentSuccess;
            delete window.paymentError;
        }
    }, [props.policyPayment]);

    const onChangeCardNumberFormatHandler = (event) => {
        let cardNoRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{4}$", "g");
        let newCardNo = '';
        let preventDefault = false;
        if (!cardNoRegex.test(event.target.value)) {
            let cardNo = event.target.value.replace(/-/g, '');
            if (cardNo.length > 16) {
                cardNo = cardNo.substring(0, 16);
            }

            if (!(/\D/g.test(cardNo))) {
                do {
                    if (cardNo.length > 4) {
                        newCardNo = newCardNo + cardNo.substring(0, 4) + '-';
                        cardNo = cardNo.substring(4, cardNo.length);
                    } else {
                        newCardNo = newCardNo + cardNo;
                        cardNo = '';
                    }
                } while (cardNo.length > 0);
            } else {
                preventDefault = true;
            }
        } else {
            newCardNo = event.target.value;
        }

        if (!preventDefault) {
            setCardNumber(newCardNo);
        } else {
            event.preventDefault();
        }
    };

    const onChangeExpiryDateHandler = (event) => {
        let newExpiryDate = event.target.value.trim();
        if (newExpiryDate) {
            if (newExpiryDate.length === 4 && newExpiryDate.indexOf("-") === -1) {
                newExpiryDate = newExpiryDate.substring(0, 2) + '-' + newExpiryDate.substring(2);
            }
        }

        setExpiryDate(newExpiryDate);
    };

    const processPayment = () => {
        _sendLog(' online-payment-process.js - processPayment() checkpoint', 'info');

        props.policyPayment.cardHolder = cardHolderName;
        props.policyPayment.expiryDate = expiryDate;
        props.policyPayment.cvv = cvv;
        props.storePolicyPayment(props.policyPayment);
        props.showSpinner();

        _sendLog(' online-payment-process.js - processPayment(): calling initiatePayment()...', 'info');
        props.initiatePayment(props.policyPayment, props.paymentToken).then(resp => {
            let cardType = '001';
            let ccExpiryDate = expiryDate.substring(0, 2) + "-20" + expiryDate.substring(3);
            let creditCardNumber = '';

            if (cardNumber) {
                creditCardNumber = cardNumber.replace(/\s/g, '').replace(/-/g, '');
                if (new RegExp('^5[1-5]*').test(cardNumber)) {
                    cardType = '002';
                }
            }

            resp.cardType = cardType;
            resp.cardNumber = creditCardNumber;
            resp.expiryDate = ccExpiryDate;

            setPaymentSignature(resp);
            formRef.current.submit();
        }, (error) => {
            props.hideSpinner();
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
            _sendLog(' online-payment-process.js - processPayment() - initiatePayment(): exception:\n\n' + JSON.stringify(error));
        });
    }

    const paymentSuccess = (referenceNumberToken, referenceNumber, auth_code, receiptNumber,
                            email, paymentToken, cardExpiryDate, cardNumber, forename, surname, transactionType) => {
        console.log('Payment success, refNumber ' + referenceNumber + ' receiptNumber:' + receiptNumber);
        _sendLog(' online-payment-process.js - paymentSuccess(): referenceNumber = '+ referenceNumber + ', auth_code = '+ auth_code + ', receiptNumber = ' + receiptNumber, 'info');

        props.policyPayment.payment = {
            paymentReferenceNumber: referenceNumber,
            paymentReferenceNumberToken: referenceNumberToken,
            auth_code: auth_code,
            receiptNumber: receiptNumber,
            email: email,
            paymentToken: paymentToken,
            cardExpiryDate: cardExpiryDate,
            cardNumber: cardNumber,
            forename: forename,
            surname: surname,
            transactionType: transactionType
        };

        Object.keys(props.policyPayment.payment).forEach(function (key) {
            if(typeof props.policyPayment.payment[key] === 'undefined'){
                delete props.policyPayment.payment[key];
            }
        });

        _sendLog(' online-payment-process.js - paymentSuccess(): calling acceptPayment()...', 'info');
        props.acceptPayment(props.policyPayment, props.paymentToken).then(resp => {
            console.log(resp);
            _sendLog(' online-payment-process.js - paymentSuccess() - acceptPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
            props.hideSpinner();
            props.storePolicyPayment(resp);
            props.resetPolicy();
            props.history.push('/payment-finish');
        }, (error) => {
            props.hideSpinner();
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
            _sendLog(' online-payment-process.js - paymentSuccess() - acceptPayment(): exception:\n\n' + JSON.stringify(error));
        });
    }

    const paymentError = (message, invalidFields, reasonCode, referenceNumber) => {
        console.log('Payment error message ' + message + ' invalidFields:' + invalidFields + ' reasonCode ' + reasonCode);
        _sendLog(' online-payment-process.js - paymentError(): message = ' + message + ', invalidFields = ' + invalidFields + ', reasonCode = ' + reasonCode);
        let errorMessage = message + '&lt;br&gt;' + 'Transaction number: ' + referenceNumber;

        props.hideSpinner();
        props.showError([errorMessage]);
        _sendLog(' online-payment-process.js - paymentError(): errorMessage = ' + errorMessage, 'info');

        props.failPayment(props.policyPayment, props.paymentToken).then(resp => {
            console.log(resp);
            _sendLog(' online-payment-process.js - paymentError() - failPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
        }, errorMessage => {
            console.error(errorMessage);
            _sendLog(' online-payment-process.js - paymentError() - failPayment(): exception = ' + errorMessage);
        })
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }
    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
    }

    return (
        <div id="online-payment-credit-card-details-page" className="pageContainer online-payment-payment-page">
            <div className="container">

                  <div className="topRow">
                      <div className="left">
                          <a href="https://www.qbe.com/hk/en/about-qbe">
                              <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                          </a>
                      </div>
                      <div className="right">
                          <div className="languageSelect" onClick={() => switchLanguage()}
                               style={{ visibility: (props.policyPayment.country === 'HKG' ||
                                   props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                              {(lang === 'en') ?
                                  cfg("labels.lang_zh")
                                  :
                                  cfg("labels.lang_en")
                              }
                          </div>
                      </div>
                  </div>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        <div className="main-content-title">{ cfg('labels.payment') }</div>
                        <div className="main-content-box">

                            <div className="pricing-row no-topMargin">
                                <div className="pricing-block">
                                    <div className="highlight-text alignCenter">{ cfg('labels.totalAmount') }</div>
                                    <div className="main-highlight-text alignCenter">
                                        <div>{ _formatPaymentAmount(props.policyPayment.country, totalAmount)}</div>
                                        { props.policyPayment.country === 'SGP' &&
                                            <div style={{fontSize: '15px'}}>
                                                { props.policyPayment.hasGST === 'Y' ? ' w/GST' : ' GST Not Applicable' }
                                            </div>
                                        }
                                    </div>
                                    <div className="credit-cards">
                                        <button className="c-card">
                                            <img src={VisaImg} width="80" height="46" alt="Visa"/>
                                        </button>
                                        <button className="c-card">
                                            <img src={MastercardImg} width="80" height="46" alt="MasterCard"/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="form-container">
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField field="cardholderName" value={cardHolderName}
                                                        onChange={(event) => {
                                                            setCardHolderName(event.target.value.toUpperCase());
                                                        }}
                                        />
                                    </InputGroupStyle>
                                    <div className="form-caption">
                                        <span>
                                            { (props.policyPayment.country === 'HKG' || props.policyPayment.country === 'HGI') ?
                                                cfg('labels.cardHolderNameHelperText') : cfg('labels.cardHolderNameHelperTextSGP')}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField field="cardNumber" value={cardNumber}
                                                        onChange={(event) => {
                                                            onChangeCardNumberFormatHandler(event)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (_isNotDecimalChar(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={19}
                                        />
                                    </InputGroupStyle>
                                </div>
                                <div className="two-column">
                                    <div className="form-field-container">
                                        <InputGroupStyle>
                                            <QnectTextField field="expiryDate" value={expiryDate}
                                                            onChange={(event) => onChangeExpiryDateHandler(event)}
                                                            onKeyPress={(event) => {
                                                                if (_isNotDecimalChar(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            maxLength={5}
                                            />
                                        </InputGroupStyle>
                                    </div>
                                    <div className={"form-field-container icon-info-container with-ccv-icon " + (lang === 'en' ? 'eng':'chi')}>
                                        <InputGroupStyle>
                                            <QnectTextField field="cvv" value={cvv}
                                                            onChange={(event) => setCvv(event.target.value)}
                                                            onKeyPress={(event) => {
                                                                if (_isNotDecimalChar(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                            />
                                        </InputGroupStyle>
                                        <BalloonTip>
                                            { cfg('labels.cvvTip')}
                                        </BalloonTip>
                                    </div>
                                </div>
                                { (eligibleForAutoRenewal && autoRenewalStatus === OPTIN) &&
                                    <div className="form-field-container">
                                        <div className="form-caption cc-note">
                                            <span>{cfg('labels.autoRenewal.creditCardNote')}</span>
                                        </div>
                                    </div>
                                }
                                <div className="form-field-container button-container button-size1">
                                    <InputGroupStyle>
                                        <QnectButton field="confirmPayNow" onClick={processPayment} disabled={!(cardHolderName && cardNumber && expiryDate && cvv)}>
                                        </QnectButton>
                                    </InputGroupStyle>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div id="pay_confirm_section">
                <form ref={formRef} action={paymentSignature?.paymentUrl} method="post"
                      target="payment_iframe">
                    <input type="hidden" id="access_key" name="access_key"
                           value={paymentSignature?.access_key || ''}/>
                    <input type="hidden" id="profile_id" name="profile_id"
                           value={paymentSignature?.profile_id || ''}/>
                    <input type="hidden" id="transaction_uuid"
                           name="transaction_uuid" value={paymentSignature?.transaction_uuid || ''}/>
                    <input type="hidden" id="signed_field_names" name="signed_field_names"
                           value={paymentSignature?.signed_field_names || ''}/>
                    <input type="hidden" id="unsigned_field_names" name="unsigned_field_names"
                           value={paymentSignature?.unsigned_field_names || ''}/>
                    <input type="hidden" id="signed_date_time" name="signed_date_time"
                           value={paymentSignature?.signed_date_time || ''}/>
                    <input type="hidden" id="locale" name="locale" value={paymentSignature?.locale || ''}/>
                    <input type="hidden" id="transaction_type" name="transaction_type"
                           value={paymentSignature?.transaction_type || ''}/>
                    <input type="hidden" id="reference_number" name="reference_number"
                           value={paymentSignature?.reference_number || ''}/>
                    <input type="hidden" id="amount" name="amount"
                           value={paymentSignature?.amount || ''}/>
                    <input type="hidden" id="currency" name="currency"
                           value={paymentSignature?.currency || ''}/>
                    <input type="hidden" id="payment_method" name="payment_method"
                           value={paymentSignature?.payment_method || ''}/>
                    <input type="hidden" id="bill_to_forename" name="bill_to_forename"
                           value={paymentSignature?.bill_to_forename || ''}/>
                    <input type="hidden" id="bill_to_surname" name="bill_to_surname"
                           value={paymentSignature?.bill_to_surname || ''}/>
                    <input type="hidden" id="bill_to_email" name="bill_to_email"
                           value={paymentSignature?.bill_to_email || ''}/>
                    <input type="hidden" id="bill_to_phone" name="bill_to_phone"
                           value={paymentSignature?.bill_to_phone || ''}/>
                    <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1"
                           value={paymentSignature?.bill_to_address_line1 || ''}/>
                    <input type="hidden" id="bill_to_address_city" name="bill_to_address_city"
                           value={paymentSignature?.bill_to_address_city || ''}/>
                    <input type="hidden" id="bill_to_address_state"
                           name="bill_to_address_state"
                           value={paymentSignature?.bill_to_address_state || ''}/>
                    <input type="hidden" id="bill_to_address_country" name="bill_to_address_country"
                           value={paymentSignature?.bill_to_address_country || ''}/>
                    <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code"
                           value={paymentSignature?.bill_to_address_postal_code || ''}/>
                    <input type="hidden" id="override_custom_receipt_page" name="override_custom_receipt_page"
                           value={paymentSignature?.override_custom_receipt_page || ''}/>
                    <input type="hidden" id="signature" name="signature"
                           value={paymentSignature?.paymentSignatureKey || ''}/>
                    <input type="hidden" id="card_type" name="card_type"
                           value={paymentSignature?.cardType || ''}/>
                    <input type="hidden" id="card_number" name="card_number"
                           value={paymentSignature?.cardNumber || ''}/>
                    <input type="hidden" id="card_expiry_date" name="card_expiry_date"
                           value={paymentSignature?.expiryDate || ''}/>
                    <input type="hidden" id="card_cvn" name="card_cvn" value={cvv || ''}/>
                    { (paymentSignature?.payment_token) &&
                        <input type="hidden" id="payment_token" name="payment_token"
                               value={paymentSignature?.payment_token || ''}/>
                    }
                </form>

                <iframe frameBorder="0" height="0" name="payment_iframe" src="" width="0"></iframe>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        country: _getCountry(state),
        lang: _getLang(state),
        paymentToken: _getPaymentToken(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    resetPolicy: _resetPolicy,
    storePolicyPayment: _storePolicyPayment,
    initiatePayment: _onlineInitiatePayment,
    failPayment: _onlineFailPayment,
    acceptPayment: _onlineAcceptPayment
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlinePaymentProcess));

