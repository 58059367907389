import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/custom/header.js";
import Hidden from "@material-ui/core/Hidden";
import {useHistory, withRouter} from "react-router-dom";
import { ReactComponent as Back } from "../../assets/login/back_sharp.svg";
import BlankImage from "../../assets/profile/avatar_image_placeholder.svg";
import LogoutDialog from "../../components/custom/profile/logout-dialog";
import BeforeUpdatePhoneDialog from "../custom/profile/before-update-phone-dialog";
import BeforeUpdateEmailDialog from "../custom/profile/before-update-email-dialog";
import VerificationCodeDialog from "../custom/profile/verification-dialog";
import SuccessDialog from "../custom/profile/success-dialog";
import UnsaveDialog from "../custom/profile/unsave-dialog";
import NoChangesDialog from "../custom/profile/no-changes-dialog";
import VerifyEmailDialog from "../custom/profile/verify-email-dialog";
import NeedHelpDialog from "../custom/profile/need-help-dialog";
import ProfileBase from "./profile-base";
import {connect} from "react-redux";
import {
    _getUser,
    _getUserDetailsFromToken
} from "../../store/user/service";
import {_clearState, _getCountry, _getLang, _isNoLogin} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";
import LearnMoreDialog from "../custom/portfolio/learn-more-dialog";
import {_getPolicy, _storePolicy} from "../../store/policy/service";
import * as TagManager from "react-gtm-module";
import AreYouStillThereDialog from "../custom/portfolio/are-you-still-there-dialog";

const BackIcon = styled(Back)`
  margin-right: 8px;
`;

const Container = styled.div`
  background: #f6f8fa;
  min-height: calc(100vh - 64px);
  padding-bottom: 64px;
`;

const useStyles = makeStyles(theme => ({
  box: {
    width: "100%",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("lg")]: {
      height: "432px"
    }
  },
  message: {
    textAlign: "center",
    fontFamily: "Inter-Regular",
    fontSize: "16px",
    cursor: "pointer",
    color: "#8A94A6",
    width: "480px",
    margin: "40px auto 0",
    lineHeight: "24px",
    ['@media screen and (max-width: 350px)']: {
        maxWidth: 'calc(100vw - 50px)',
        paddingBottom: '30px',
    },
    [theme.breakpoints.between("sm","md")]: {
        margin: "8px auto 0",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: '14px',
        maxWidth: 'calc(100vw - 77px)',
        marginTop: '21px',
    }
  },
 contact_us: {
    color: "#003DA5",
    cursor: "pointer",
    fontWeight: "600"
  }

}));

const TopWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
`;

const BackWrapper = styled.div`
  margin-top: 40px;
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const Title = styled.div`
  margin-left: 20px;
  margin-top: 40px;
  font-size: 40px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
`;

const Profile = props => {
  const history = useHistory();
  const classes = useStyles();
  const cfg = useConfig(props.lang);

  const [logoutOpen, setLogoutOpen] = useState(false);
  const [openLearnMoreDialog, setLearnMoreDialog] = useState(false);
  const [beforePhoneOpen, setBeforePhoneOpen] = useState(false);
  const [beforeEmailOpen, setBeforeEmailOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [unsaveOpen, setUnsaveOpen] = useState(false);
  const [noChangesOpen, setNoChangesOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [needHelpOpen, setNeedHelpOpen] = useState(false);
  const [openAreYouStillThereDialogState, setOpenAreYouStillThereDialogState] = React.useState(false);
  const SESSION_TIMEOUT = 60 * 15;
  const [sessionTimer, setSessionTimer] = useState(SESSION_TIMEOUT);
  const [sessionTimerTimeout, setSessionTimerTimeout] = useState(null);

  useEffect(() => {
      TagManager.dataLayer({
          dataLayer: {
              event: 'vpageview',
              pageStep: 'My Profile',
              vPath: '/profile'
          }
      });
  }, []);

  const handleLogout = () => {
    props.clearState();
    props.history.push('/login');
  }

  const resetSessionTimer = () => {
    clearTimeout(sessionTimerTimeout);
    setSessionTimer(SESSION_TIMEOUT);
  }

  useEffect(() => {
    if(props.country == 'MYS'){
        clearTimeout(sessionTimerTimeout);
        if (sessionTimer > 0) {
            let timeoutId = setTimeout(() => {
                setSessionTimer(sessionTimer - 1);
            }, 1000);
            setSessionTimerTimeout(timeoutId);
        } else {
            setOpenAreYouStillThereDialogState(true);
        }
    }
  }, [sessionTimer]);

  useEffect(() => {
    document.addEventListener('mousemove', () => {
        resetSessionTimer();
    });
    document.addEventListener('keypress', () => {
        resetSessionTimer();
    });
  }, []);

  return (
    <>
      <Container>
        <div className={classes.box}>
          <TopWrapper>
            <Header pathname={props.history.location.pathname}/>
            <Hidden mdDown>
              <BackWrapper onClick={() => {
                  history.push("/portfolio");
                  TagManager.dataLayer({
                      dataLayer: {
                          event: 'Icon-Click',
                          eventCategory: 'Back-Arrow'
                      }
                  })
              }}>
                <BackIcon />
                  {cfg("profilePage.back")}
              </BackWrapper>
              <Title>{cfg("profilePage.myProfile")}</Title>
            </Hidden>
          </TopWrapper>
        </div>

        {/* design flow one */}
        <ProfileBase props={props} imgSrc={BlankImage}
                     onLogout={() => setLogoutOpen(true)} />

        {/* design flow two */}
          {/*<ProfileForm
          onLogout={() => setLogoutOpen(true)}
          onUpdate={handleUpdate}
          {...mockData}
        />*/}
          {
              props.country === 'SGP' &&
              <div className={classes.message}>
                  {cfg("profilePage.contactUs")}
                  <div className={classes.contact_us} onClick={() => {window.open(process.env.REACT_APP_ONLINE_ENQUIRY_SG, '_blank');}}>
                    {cfg("profilePage.contactUsForm")}
                  </div>
              </div>
          }
      </Container>

      <LogoutDialog
            open={logoutOpen}
            onClose={() => () => setLogoutOpen(false)}
      />

        <LearnMoreDialog
            open={openLearnMoreDialog}
            onClose={() =>
                () => setLearnMoreDialog(false)
            }
            onConfirm={() => {
                props.lang === "en"
                    ? window.open("https://www.qbe.com/hk/zh-hk/eclaims")
                    : window.open("https://www.qbe.com/hk/en/eclaims")
            }}
            cfg={cfg}
        />

      <BeforeUpdatePhoneDialog
        open={beforePhoneOpen}
        onClose={() => setBeforePhoneOpen(false)}
        onConfirm={() => {
          setBeforePhoneOpen(false);
          setOtpOpen(true);
        }}
      />

      <BeforeUpdateEmailDialog
        open={beforeEmailOpen}
        onClose={() => setBeforeEmailOpen(false)}
        onConfirm={() => {
          setBeforeEmailOpen(false);
          setVerifyOpen(true);
        }}
      />

      <VerificationCodeDialog
        open={otpOpen}
        onClose={() => setOtpOpen(false)}
        onConfirm={() => {
          setOtpOpen(false);
          setSuccessOpen(true);
        }}
      />

      <SuccessDialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        onConfirm={() => setSuccessOpen(false)}
      />

      <UnsaveDialog
        open={unsaveOpen}
        onClose={() => setUnsaveOpen(false)}
        onConfirm={() => {
          setUnsaveOpen(false);
          history.push("/portfolio");
        }}
      />

      <NoChangesDialog
        open={noChangesOpen}
        onClose={() => setNoChangesOpen(false)}
      />

      <VerifyEmailDialog
        email="alicechan@gmail.com"
        open={verifyOpen}
        onClose={() => setVerifyOpen(false)}
        onResend={() => {}}
        onHelp={() => {
          setVerifyOpen(false);
          setNeedHelpOpen(true);
        }}
        onConfirm={() => setVerifyOpen(false)}
      />

      <NeedHelpDialog
        open={needHelpOpen}
        onClose={() => setNeedHelpOpen(false)}
        onBack={() => {
          setNeedHelpOpen(false);
          setVerifyOpen(true);
        }}
      />
      <AreYouStillThereDialog
        open={openAreYouStillThereDialogState}
        onLogout={() => {
            handleLogout();
        }}
        onClose={() => {
            setOpenAreYouStillThereDialogState(false);
        }}
        cfg={cfg}
        lang={props.lang}
        country={props.country}
      />
    </>
  );
};

const mapStateToProps = (state) => {
    return {
        noLogin: _isNoLogin(state),
        lang: _getLang(state),
        country: _getCountry(state),
        user: _getUser(state)
    };
}

const mapDispatchToProps = {
    getUserDetailsFromToken: _getUserDetailsFromToken,
    clearState: _clearState
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));

