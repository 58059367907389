import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import typographyStyles from '../../../common/typography-styles';

const List = styled.ul`
    margin: 16px 0 0;
    padding: 0;
    list-style-type: none;
`;

const Item = styled.li`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'xSmall',
    })}
`;

export default function IncidentCoverLabel({
    coverType,
    details,
}) {
    const hasDetails = get(details, 'length') > 0;

    return (
        <>
            {coverType}
            {hasDetails && (
                <List>
                    {details.map((item, index) => (
                        <Item key={index}>
                            {item}
                        </Item>
                    ))}
                </List>
            )}
        </>
    );
}

IncidentCoverLabel.propTypes = {
    coverType: propTypes.node.isRequired,
    details: propTypes.arrayOf(propTypes.node),
};
