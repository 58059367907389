import React from 'react';
import ActivateAutoRenewal from "../components/auto-renewal/auto-renewal-activate";

const AutoRenewalActivatePage = () => {
    return (
        <div>
            <ActivateAutoRenewal/>
        </div>
    )
}

export default AutoRenewalActivatePage;
