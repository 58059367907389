import React from 'react';

import BaseIcon from '../common/base-icon';

export default function IconClose(props) {
    return (
        <BaseIcon {...props}>
            <path
                d="M17.657 7.757L13.414 12l4.243 4.243-1.414 1.414L12 13.414l-4.243 4.243-1.414-1.414L10.586 12 6.343 7.757l1.414-1.414L12 10.586l4.243-4.243 1.414 1.414z"
                fillRule="evenodd"
            />
        </BaseIcon>
    );
}
