import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import visuallyHidden from '../../../common/visually-hidden';
import CheckCard from '../../form-controls/check-card';
import ActionIconInfoOutline from '../../icons/functional/action/info-outline';
import IncidentCoverLabel from './incident-cover-label';

const HelpText = styled.span`
    ${visuallyHidden}
`;

export default function IncidentCoverCard({
    className,
    coverType,
    details,
    helpText,
    onClickHelp,
    disabled,
    checked,
    onChange,
}) {
    return (
        <CheckCard
            className={classnames('IncidentCoverCard', className)}
            label={(
                <IncidentCoverLabel
                    details={details}
                    coverType={coverType}
                />
            )}
            help={!!onClickHelp && (
                <>
                    <ActionIconInfoOutline
                        size={16}
                        color="grey"
                        title={helpText}
                    />
                    {!!helpText && (
                        <HelpText>
                            {helpText}
                        </HelpText>
                    )}
                </>
            )}
            {...{onClickHelp, disabled, checked, onChange}}
        />
    );
}

IncidentCoverCard.propTypes = {
    className: propTypes.string,
    coverType: propTypes.node.isRequired,
    details: propTypes.arrayOf(propTypes.node),
    helpText: propTypes.string,
    onClickHelp: propTypes.func,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func.isRequired,
};
