import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {smUp} from '../../common/breakpoints';
import typographyStyles from '../../common/typography-styles';

const Container = styled.div`
    display: grid;
    grid-row-gap: 40px;
`;

const Table = styled.table`
    border: none;
    width: 100%;
    border-collapse: collapse;

    ${typographyStyles({
        type: 'text',
    })};
`;

const Thead = styled.thead`
    ${smUp`
        border-bottom: 2px solid ${property('theme.color.tertiary80')};
    `};
`;

const Th = styled.th`
    box-sizing: border-box;
    padding: 12px 0;
    text-align: left;

    ${typographyStyles({
        defaultColor: 'primary',
        defaultWeight: 'semiBold',
        type: 'subheading',
    })};

    &:first-of-type {
        padding-right: 21px;
        width: 55%;
        min-width: 55%;

        ${smUp`
            width: 65%;
            min-width: 65%;
        `}
    }
`;

const TbodyTr = styled.tr`
    ${smUp`
        border-bottom: 1px solid ${property('theme.color.grey40')};

        &:last-of-type {
            border-bottom: none;
        }
    `};
`;

const TbodyTd = styled.td`
    padding: 8px 0;

    ${typographyStyles({
        defaultColor: 'tertiary90',
        defaultWeight: 'semiBold',
        type: 'subheading',
    })};

    &:first-of-type {
        padding-right: 21px;
    }
`;

const Tfoot = styled.tfoot`
    ${smUp`
        border-top: 1px solid ${property('theme.color.grey40')};
    `};
`;

const TfootTd = styled.td`
    padding: 8px 0;

    ${typographyStyles({
        defaultColor: 'tertiary90',
        defaultWeight: 'semiBold',
        type: 'subheading',
    })};
`;

export default function ClaimSummaryCovers({
    className,
    covers = [],
    coversLabel,
    coversTotal,
    coversTotalLabel,
    excesses = [],
    excessAmountLabel,
    excessCollectorLabel,
    reserveAmountsLabel,
}) {
    return (
        <Container className={classnames('ClaimSummaryCovers', className)}>
            <Table>
                <Thead>
                    <tr>
                        <Th>
                            {coversLabel}
                        </Th>
                        <Th>
                            {reserveAmountsLabel}
                        </Th>
                    </tr>
                </Thead>
                <tbody>
                    {covers.map(({label, value}) => (
                        <TbodyTr key={label}>
                            <TbodyTd>
                                {label}
                            </TbodyTd>
                            <TbodyTd>
                                {value}
                            </TbodyTd>
                        </TbodyTr>
                    ))}
                </tbody>
                <Tfoot>
                    <tr>
                        <TfootTd>
                            {coversTotalLabel}
                        </TfootTd>
                        <TfootTd>
                            {coversTotal}
                        </TfootTd>
                    </tr>
                </Tfoot>
            </Table>
            <Table>
                <Thead>
                    <tr>
                        <Th>
                            {excessAmountLabel}
                        </Th>
                        <Th>
                            {excessCollectorLabel}
                        </Th>
                    </tr>
                </Thead>
                <tbody>
                    {excesses.map(({label, value}) => (
                        <TbodyTr key={label}>
                            <TbodyTd>
                                {label}
                            </TbodyTd>
                            <TbodyTd>
                                {value}
                            </TbodyTd>
                        </TbodyTr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

ClaimSummaryCovers.propTypes = {
    className: propTypes.string,
    covers: propTypes.arrayOf(propTypes.exact({label: propTypes.string.isRequired, value: propTypes.string.isRequired})).isRequired,
    coversLabel: propTypes.string.isRequired,
    coversTotal: propTypes.string.isRequired,
    coversTotalLabel: propTypes.string.isRequired,
    excesses: propTypes.arrayOf(propTypes.exact({label: propTypes.string.isRequired, value: propTypes.string.isRequired})).isRequired,
    excessAmountLabel: propTypes.string.isRequired,
    excessCollectorLabel: propTypes.string.isRequired,
    reserveAmountsLabel: propTypes.string.isRequired,
};
