import React from 'react';

import BaseIcon from '../common/base-icon';

export default function IconInfoCircle(props) {
    return (
        <BaseIcon {...props}>
            <path
                d="M12 3a9 9 0 110 18 9 9 0 010-18zm1 7h-2v6h2v-6zm-1-3a1 1 0 100 2 1 1 0 000-2z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </BaseIcon>
    );
}
