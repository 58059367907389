import React from 'react';
import {connect} from 'react-redux';

import {
    _getErrors,
    _getShowError,
    _clearError
} from '../store/system/service';
import styled from "styled-components";
import typographyStyles from "../ui/common/typography-styles";
import AlertModal from "../ui/components/modals/alert-modal";

const List = styled.dl`
    margin: 0;
    padding: 24px 0;
    text-align: center;
`;

const Message = styled.dd`
    margin: 0;
    padding: 0;

    ${typographyStyles({
    type: 'text',
    defaultSize: 'normal',
    defaultWeight: 'regular',
    defaultColor: 'tertiary90',
})}

    &:last-of-type) {
        padding-bottom: 24px;
    }
`;

function ErrorModal({
    errors,
    showError,
    onClose
}) {
    return (
        <AlertModal
            isOpen={showError}
            onClose={onClose}
            alertLevel='error'
            title='Error'
            primaryAction={{label: 'Close', onClick: onClose}}>
            {errors && errors.length ?
                (
                    <List>
                        {errors.map((message) => (
                            (message + "").split('&lt;br&gt;').map(m =>
                                <Message>
                                    {m}
                                </Message>)
                            ))}
                    </List>
                ) : ''
            }
        </AlertModal>
    );
}

function mapStateToProps(state) {
    return {
        errors: _getErrors(state),
        showError: _getShowError(state),
    };
}

const mapDispatchToProps = {
    onClose: _clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
