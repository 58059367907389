import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import typographyStyles from '../../common/typography-styles';

const List = styled.dl`
    margin: 0;
    padding: 24px 0;
    text-align: center;
`;

const Codes = styled.dt`
    margin: 0;
    padding: 0;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultWeight: 'regular',
        defaultColor: 'grey90',
    })}
`;

const Message = styled.dd`
    margin: 0;
    padding: 0;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'regular',
        defaultColor: 'tertiary90',
    })}

    &:not(:last-of-type) {
        padding-bottom: 24px;
    }
`;

export default function ErrorCodesAndMessages({errors = []}) {
    return !!errors.length && (
        <List>
            {errors.map(({codes, message}) => (
                <React.Fragment key={codes}>
                    <Codes>
                        {codes.length === 1 ? 'Error' : 'Errors'}: {codes.join(', ')}
                    </Codes>
                    <Message>
                        {message}
                    </Message>
                </React.Fragment>
            ))}
        </List>
    );
}

ErrorCodesAndMessages.propTypes = {
    errors: propTypes.arrayOf(propTypes.shape({
        codes: propTypes.arrayOf(propTypes.string),
        message: propTypes.string,
    })),
};
