import React, {Component, useState} from 'react';
import PayLaterDetail from "../components/pay-later-detail/pay-later-detail";
import {_setCountry, _setLang, _storePaymentToken} from "../store/system/service";
import {_renewLoadPolicy, _storePolicyPayment} from "../store/policy-payment/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import QBESpinner from "../assets/QBE-spinner.gif";

const PayLaterPage = (props) => {
  console.debug('processing renewal payment');
  const [loading, setLoading] = useState( false );

  const token = props.match.params.token;
  const iv = props.match.params.iv;
  const lang = props.match.params.lang;

  if (!loading && token && iv && lang) {
    setLoading(true);
    console.debug('token = '+token+', iv=' + iv);
    props.renewLoadPolicy(token, iv, lang).then((data) => {
      if (lang === 'E') {
        props.setLang('en');
      }
      else {
        props.setLang(lang);
      }
      if (!(data.policy?.country === 'HKG' || data.policy?.country === 'HGI')) {
        props.setLang('en')
      }
      if (data.policy?.country === 'SGP') {
        props.setCountry('SGP');
      }
      props.storePaymentToken(data.token, data.iv);
      props.storePolicyPayment(data.policy);
      props.history.push("/renew/pay-later-detail");
    });
  };

  return (
    <>
      <div id="splash-screen">
          <img src={QBESpinner} alt="QBE spinner" />
      </div>
    </>
  );

}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  setLang: _setLang,
  setCountry: _setCountry,
  renewLoadPolicy: _renewLoadPolicy,
  storePolicyPayment: _storePolicyPayment,
  storePaymentToken: _storePaymentToken
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayLaterPage));
