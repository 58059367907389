import React from 'react';
import propTypes from 'prop-types';
import {MenuItem} from '@material-ui/core';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import DropDown from '../dropdowns/drop-down';
import Actions from '../buttons/actions';
import Paragraph from '../typography/paragraph';

const Container = styled.div`
    position: relative;
`;

const StyledActions = styled(Actions)`
    padding-top: 32px;
`;

const ContentBody = styled.div`
    text-align: center;
`;

const StyledMenuItem = styled(MenuItem)`
    &.MuiListItem-button:hover {
        color: ${property('theme.color.secondary60')};
    }
`;

const StyledDropDown = styled(DropDown)`
    margin: 0 auto;
    max-width: 346px;
`;

export default function ChangeProfileContent({
    className,
    message,
    okLabel,
    isOkDisabled,
    okHref,
    onClickOk,
    cancelLabel,
    isCancelDisabled,
    cancelHref,
    onClickCancel,
    dropDownComponent,
}) {
    return (
        <Container className={classnames('ChangeProfileContent', className)}>
            <ContentBody>
                <Paragraph color="tertiary" size="big" weight="regular">
                    {message}
                </Paragraph>
                {!!dropDownComponent && (
                    <StyledDropDown
                        label={dropDownComponent.label}
                        value={dropDownComponent.value || ''}
                        isDisabled={dropDownComponent.isDisabled}
                        onChange={dropDownComponent.onChange}
                    >
                        <StyledMenuItem value="" disabled>
                            {dropDownComponent.placeholder}
                        </StyledMenuItem>
                        {(dropDownComponent.options || []).map((value) => (
                            <StyledMenuItem key={value} value={value}>
                                {value}
                            </StyledMenuItem>
                        ))}
                    </StyledDropDown>
                )}
            </ContentBody>

            <StyledActions
                primaryAction={{
                    label: okLabel,
                    href: okHref,
                    onClick: onClickOk,
                    isDisabled: isOkDisabled,
                }}
                secondaryAction={{
                    label: cancelLabel,
                    href: cancelHref,
                    onClick: onClickCancel,
                    isDisabled: isCancelDisabled,
                }}
            />
        </Container>
    );
}

ChangeProfileContent.propTypes = {
    className: propTypes.string,
    message: propTypes.node,
    okLabel: propTypes.string,
    isOkDisabled: propTypes.bool,
    okHref: propTypes.string,
    onClickOk: propTypes.func,
    cancelLabel: propTypes.string,
    isCancelDisabled: propTypes.bool,
    cancelHref: propTypes.string,
    onClickCancel: propTypes.func,
    dropDownComponent: propTypes.shape({
        label: propTypes.string,
        value: propTypes.string,
        options: propTypes.arrayOf(propTypes.string),
        onChange: propTypes.func,
        placeholder: propTypes.string,
        isDisabled: propTypes.bool,
    }),
};
