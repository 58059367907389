import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    dialog: {
        "& .MuiDialog-paper": {
            [theme.breakpoints.between("sm", "md")]: {
                padding: "14px 60px 52px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "14px 76px 52px",
                width: "630px"
            }
        }
    },
    message: {
        textAlign: "center",
        color: "#0a1f44",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "20px",
            fontSize: "18px",
            lineHeight: "25px"
        }
    },
    actions: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "40px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "28px"
        }
    }
}));

export default function RegisterOrPolicyDialog({
    open = false,
    onClose,
    onGoToRegister,
    onGoToPolicy,
    cfg
}) {
    const classes = useStyles();

    return (
        <div>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                <DialogTitle title="Are you sure?" onClose={onClose} />
                <DialogContent>
                    <div className={classes.message}>
                        {cfg('findMyPolicy.registerOrPolicy')}
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <ButtonWrapper color="secondary" className={classes.button}>
                        <QnectButton color="secondary" onClick={onGoToPolicy}>{cfg('findMyPolicy.goToPolicy')}</QnectButton>
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <QnectButton onClick={onGoToRegister}>{cfg('findMyPolicy.goToRegister')}</QnectButton>
                    </ButtonWrapper>
                </DialogActions>
            </Dialog>
        </div>
    );
}

RegisterOrPolicyDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};
