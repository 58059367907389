import property from 'lodash/property';
import * as type from "./types";
import axios from "axios";
import {
    _getCountry,
    _getDocumentToken,
    _getCpToken
} from "../system/service";
import {_sendLog} from "../../utils/utility";

const ORIGINAL_POLICY_PERIOD = 'origPolicyPeriod';
const ORIGINAL_STATUS = 'origStatus';
const POLICY_CARD_PORTFOLIO_STATUS = 'portfolioStatus';

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}


const authDocumentHeader = (state) => {
    const cpToken = _getCpToken(state);
    const documentToken = _getDocumentToken(state);
    if (documentToken) {
        return {
            'Authorization': 'Bearer ' + cpToken,
            'policyDocumentToken': documentToken
        };
    } else {
        return {};
    }
}

export const _resetPolicy = () => {
    return (dispatch) => {
        dispatch({
            type: type.RESET_STATE
        })
    };
}

export const _verifyCredentials = (credentials) => {
    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            let cn = _getCountry(getState());
            credentials.country = cn;

            let config = {
                headers: authCpHeader(getState())
            }

            axios.post(process.env.REACT_APP_VERIFY_CREDENTIALS, credentials, config)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(error => {
                    _sendLog(['Customer Portal React - Policy Service - _verifyCredentials', JSON.stringify(error)],
                        'error');
                    reject(error)
                })
        });
    }
}

export const _storePolicy = (policy) => {
    return (dispatch) => {
        dispatch({
            type: type.POLICY_STORE,
            data: policy
        })
    };
}

export const _verifyPolicy = (policyNumber, isRegistration = 'false') => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let cn = _getCountry(getState());
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    country: cn,
                    isRegistration: isRegistration
                }
            }

            axios.get(process.env.REACT_APP_SEARCH_POLICY + '/' + policyNumber, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                    _sendLog(['Customer Portal React - Policy Service - _verifyPolicy: ' + policyNumber + ', country: '+cn, JSON.stringify(error)], 'error');
                    reject(error);
            });
        })
    }
}

export const _loadPolicyDetail = (fromDate) => {
    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    from: fromDate
                },
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_POLICY_DETAIL, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                    _sendLog(['Customer Portal React - Policy Service - _loadPolicyDetail', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _loadPolicyDocuments = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let policyObject = _getPolicy(getState());

            let config = {
                headers: authCpHeader(getState()),
                params: {
                    catalogueCode: policyObject.catalogueCode
                }
            }
            axios.get(process.env.REACT_APP_POLICY_DOCUMENTS, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                    _sendLog(['Customer Portal React - Policy Service - _loadPolicyDocuments', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _loadDocument = (docName) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState())
            }

            axios.get(process.env.REACT_APP_DOCUMENT + docName, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                reject(error);
            });
        })
    }
}

export const _loadPolicyClaims = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000,
                params: {
                    shouldIntercept: 'false'
                }
            }

            axios.get(process.env.REACT_APP_POLICY_CLAIMS, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                reject(error);
            });
        })
    }
}

export const _sendDocumentEmail = (emailObj) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_CUSTOMER_DOCUMENT_EMAIL, emailObj, {
                headers: authDocumentHeader(getState()),
                timeout: 60000
            })
                .then(resp => {
                    resolve();
                }).catch(error => {
                    _sendLog(['Customer Portal React - Policy Service - _loadPolicyDocuments', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _getFileClaimsUrl = (policyNumber, status, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    status: status,
                    country: country
                }
            }

            axios.get(process.env.REACT_APP_RETRIEVE_FILE_CLAIM_URL, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }
}

export const _downloadZip = (docNames) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authDocumentHeader(getState())
            }

            axios.post(process.env.REACT_APP_CUSTOMER_DOCUMENT_DOWNLOAD_ZIP, docNames, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                    _sendLog(['Customer Portal React - Policy Service - _loadPolicyDocuments', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _updateEmailAndMobile = (credentials) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let cn = _getCountry(getState());
            credentials.country = cn;

            axios.post(process.env.REACT_APP_UPDATE_EMAIL_AND_MOBILE, credentials)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(error => {
                    reject(error)
                })
        });
    }
}
/** end adhoc payment methods */

export const _retrieveRenewalNoticeAndAgentDetails = (policyNumber, policyCountry, fromDate) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000,
                params: {
                    policyNumber: policyNumber,
                    country: policyCountry,
                    fromDate: fromDate
                }
            }

            axios.get(process.env.REACT_APP_RETRIEVE_RENEWAL_NOTICE_AND_AGENT_DETAILS, config)
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}


export const _setOrigPolicyPeriodToSession = (policyPeriod, origStatus, policyCardPortfolioStatus) => {
    window.sessionStorage.setItem(ORIGINAL_POLICY_PERIOD, policyPeriod);
    window.sessionStorage.setItem(ORIGINAL_STATUS, origStatus);
    window.sessionStorage.setItem(POLICY_CARD_PORTFOLIO_STATUS, policyCardPortfolioStatus);
}

export const _getOrigPolicyPeriodToSession = () => {
    let origPolicyPeriod = window.sessionStorage.getItem(ORIGINAL_POLICY_PERIOD);
    if (origPolicyPeriod) {
        return origPolicyPeriod;
    }
}

export const _getOrigStatusFromSession = () => {
    let origStatus = window.sessionStorage.getItem(ORIGINAL_STATUS);
    if (origStatus) {
        return origStatus;
    }
}

export const _getPolicyCardPortfolioStatusFromSession = () => {
    let policyPortfolioStatus = window.sessionStorage.getItem(POLICY_CARD_PORTFOLIO_STATUS);
    if (policyPortfolioStatus) {
        return policyPortfolioStatus;
    }
}

export const _clearSessionItems = () => {
    window.sessionStorage.removeItem(ORIGINAL_POLICY_PERIOD);
    window.sessionStorage.removeItem(ORIGINAL_STATUS);
    window.sessionStorage.removeItem(POLICY_CARD_PORTFOLIO_STATUS);
}

export const _getPolicy = property('pl');
