import React, { memo } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Border = styled.div`
  border-bottom: 1px solid #14284b;
  width: 100%;
`;

const Content = styled.span`
  padding: 0 20px 0;
  color: #0a1f44;
  font-family: "InterSemiBold", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
`;

const Divider = ({ children }) => {
  return (
    <Container>
      <Border />
      <Content>{children}</Content>
      <Border />
    </Container>
  );
};

export default memo(Divider);
