import React, {useEffect, useState} from 'react';
import useConfig from "../qnect-ui/qnect-config";
import '../../assets/styles/auto-renewal.css';
import {_getLang, _hideModal, _setLang, _showError, _showModal} from "../../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import QnectButton from "../qnect-ui/qnect-button";
import AutoRenewalSectionToggleTOS from "./auto-renewal-section-toggle-tos";
import {OPTIN, OPTOUT} from "./auto-renewal-constants";

const AutoRenewalSectionRenewalStatus = (props) => {

    useEffect(() => {
        if (props.callFunction === "showAutoRenewalTurnOnModal") {
            autoRenewalTurnOnModal();
        }
    }, [props.callFunction]);

    const cfg = useConfig(props.lang);

    const [autoRenewalStatus, setAutoRenewalStatus] = useState (props.autoRenewalStatus);

    useEffect(() => {
        setAutoRenewalStatus(props.autoRenewalStatus);
    }, [props.autoRenewalStatus]);

    const getAutoRenewalStatus = () => {
        switch(autoRenewalStatus) {
            case OPTIN: return "on";
            case OPTOUT: return "off";
            default: return "off";
        }
    }
    const autoRenewalTurnOffModal = () => {

        props.showModal({
            disableBackDropClick: true,
            flat: true,
            content:
                <div className="auto-renewal-modal_turn-off">
                    <div className="modal-block">
                        <div className="modal-top">
                            <span className="modal-title">{ cfg('labels.autoRenewal.autoRenewalService') }</span>
                        </div>
                        <div className="modal-body">
                            <div className="note-top">{ cfg('labels.autoRenewal.disableNote') }</div>
                            <div className="list-text" dangerouslySetInnerHTML={{__html:  cfg("labels.autoRenewal.statusOffList")}}></div>
                        </div>
                        <div className="modal-bottom">
                            <div className="button-container">
                                <QnectButton className="btn-big" field="cancel" onClick={() => props.hideModal()}/>
                                <QnectButton color="primary" className="btn-big" field="disable" onClick={() => {
                                    props.updateAutoRenewalStatusCallBack(OPTOUT);
                                    props.hideModal();
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
        });
    }

    const autoRenewalTurnOnModal = () => {

        props.showModal({
            disableBackDropClick: true,
            flat: true,
            content:
                <div className="auto-renewal-modal_turn-on">
                    <div className="modal-block">
                        <div className="modal-top">
                            <span className="modal-title">{ cfg('labels.autoRenewal.autoRenewalService') }</span>
                        </div>
                        <div className="modal-body">
                            <div className="note-top">{ cfg('labels.autoRenewal.enableNote') }</div>
                            <div className="list-text" dangerouslySetInnerHTML={{__html:  cfg("labels.autoRenewal.statusOnList")}}></div>
                            <AutoRenewalSectionToggleTOS />
                        </div>
                        <div className="modal-bottom">
                            <div className="button-container">
                                <QnectButton className="btn-big" field="cancel" onClick={() => props.hideModal()}/>
                                <QnectButton color="primary" className="btn-big" field="enable" onClick={() => {
                                    props.updateAutoRenewalStatusCallBack(OPTIN);
                                    props.hideModal();
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
        });
    }

    const generateContentsByRenewalStatus = (optnInCall, optOutCall, naCall) => {
        switch(autoRenewalStatus) {
            case OPTIN: return optnInCall();
            case OPTOUT: return optOutCall();
            default: {
                return (typeof(naCall) !== 'undefined')? naCall() : "";
            }
        }
    }

    return (<>
        <div className="content-boxed-block status-block">
            <div className={'banner-box '+ getAutoRenewalStatus() }>
                <div className="banner-box-details">
                    {
                        autoRenewalStatus === OPTIN?
                        <div className="status-title">{ cfg('labels.autoRenewal.infoTextTitleOn') }</div>:
                        <div className="status-title">{ cfg('labels.autoRenewal.infoTextTitleOff') }</div>
                    }
                    <div className="details-text">{ cfg('labels.autoRenewal.infoText') }</div>
                </div>
            </div>
            <div className="details-box">
                <div className="status-row">
                    {
                        generateContentsByRenewalStatus(
                            () => { return <h1>{ cfg('labels.autoRenewal.statusTitle') }&nbsp;{ cfg('labels.autoRenewal.statusOn') }</h1> },
                            () => { return <h1>{ cfg('labels.autoRenewal.statusTitle') }&nbsp;{ cfg('labels.autoRenewal.statusOff') }</h1> }
                        )
                    }
                    {
                        generateContentsByRenewalStatus(
                            () => { return <button onClick={autoRenewalTurnOffModal} className="btn btn-small btn-round btn-secondary">{ cfg('labels.autoRenewal.turnOffAutoRenewal') }</button> },
                            () => { return <button onClick={autoRenewalTurnOnModal} className="btn btn-small btn-round btn-secondary">{ cfg('labels.autoRenewal.turnOnAutoRenewal') }</button> }
                        )
                    }
                </div>
                {
                    generateContentsByRenewalStatus(
                        () => { return <div className="main-list optIN" dangerouslySetInnerHTML={{__html:  cfg("labels.autoRenewal.statusOnList")}}></div> },
                        () => { return <div className="main-list optOUT" dangerouslySetInnerHTML={{__html:  cfg("labels.autoRenewal.statusOffList")}}></div> }
                    )
                }
                {
                    (autoRenewalStatus === OPTIN && <AutoRenewalSectionToggleTOS />)
                }
            </div>
        </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
    }
};

const mapDispatchToProps = {
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    setLang: _setLang,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoRenewalSectionRenewalStatus));
