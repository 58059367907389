import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import DoneIcon from "@material-ui/icons/Done";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectDateField from "../../components/qnect-ui/qnect-date-field";
import AuthTipsDialog from "../../components/custom/registration/auth-tips-dialog";
import AuthTipsDialogMore from "../../components/custom/registration/auth-tips-dialog-more";
import BalloonTip from "../../components/ui/balloon-tip/balloon-tip";
import {ReactComponent as ShowIcon} from "../../assets/registration/icon_show.svg";
import {ReactComponent as HideIcon} from "../../assets/registration/icon_hide.svg";
import Registration from "./registration";
import {_scrollToTop, _validateForm, customReplaceAll} from "../../utils/utility";
import {connect} from "react-redux";
import {
    _verifyCredentials
} from "../../store/policy/service";
import moment from "moment";
import {
    _generatePolicyTokenFromPortfolioScreen,
    _generatePortfolioToken,
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner, _setCpToken,
    _setLang,
    _showModal,
    _showSpinner,
    _verifyOtp
} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";
import {LanguageSelectDiv, StyledModalDiv} from "../login-style";
import Hidden from "@material-ui/core/Hidden";
import MobileHeader from "../custom/registration/mobile-header";
import ButtonWrapper from "../../components/custom/button-wrapper";
import {smDown, lgDown} from '../../ui/common/breakpoints';
import {
    _getUser,
    _getUserDetailsFromToken,
    _storeUser,
    _addEmailAddressToOTPLockedCache,
    _checkEmailIfRegistered,
    _checkIfPolicyNumberIsRegistered, _sendOTPToEmailOrMobile
} from "../../store/user/service";
import QnectError from "../qnect-ui/qnect-erorr";
import AuthStepChooseEmailMobile from "../custom/registration/auth-step-choose-email-mobile";
import AuthStepEmailMobileOtpDialog from "../custom/registration/auth-step-email-mobile-otp-dialog";
import LabelledCheckbox from "../../ui/components/form-controls/labelled-checkbox";
import {ReactComponent as BlueDone} from "../../assets/BlueDone.svg";
import PopupDialogue from "../custom/popup-dialogue";
import * as TagManager from "react-gtm-module";
import NoEmailOrMobileDialog from "../custom/registration/no-email-mobile";

const Title = styled.div`
  font-family: "Stag";
  font-size: 40px;
  line-height: 48px;
  color: #003da5;
  text-align: left;
  margin-left: -70px;
  display: flex;
  ${lgDown`
    font-size: 32px;
    line-height: 40px;
  `}
`;

const SubTitle = styled.div`
  font-family: "Inter";
  font-size: 18px;
  line-height: 24px;
  color: "#0A1F44";
  ${lgDown`
    font-size: 16px;
  `}
`;

const SubText = styled.div`
  height: 48px;
  color: #8a94a6;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 8px;
  padding-top: ${({isError}) => (isError ? "43px" : "8px")};
  padding-bottom: 47px;
  ${lgDown`
    padding-top: 0;
    font-size: 14px;
    line-height: 20px;
  `}
`;

const ButtomRow = styled.div`
    margin-top: 20px;
    display: flex;
    margin-bottom: 70px;
    justify-content: space-between;
    & > :first-child {
        font-family: "Inter";
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #003da5;
        margin-top: 30px;
        cursor: pointer;
    }
    ${smDown`
      & > :first-child {
        margin-top: 22px;
      }
      & button {
        width: 160px;
      }
    `}
`;

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  margin-top: 20px;
  position: relative;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  > .BalloonTip {
    top: -35px;
    left: 190px;
    position: absolute;
    left: ${({lang}) => lang === 'en' ? "190px" : "130px"};
  }
  > .BalloonTip .contentDiv {
    top: -53px !important;
    width: 379px;
    // height: 48px !important;
    border-radius: 2px;
    margin-left: 2px;
    padding: 4px 12px 4px;
    background: rgba(78, 93, 120, 0.9) !important;
  }
  > div:not(.form-caption) {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 30px;
    right: 20px;
  }
  
   div.MuiFormControl-root > p.MuiFormHelperText-root.Mui-error {
       height: auto;
       padding: 10px 0 0 0;
   }

  ${lgDown`
    & .MuiInputLabel-formControl {
      font-size: 16px !important;
      line-height: 24px !important;
      top: -30px !important;
    }
  `}
  
  .Mui-disabled {
        opacity: 0.5;
  }
`;

const StyledPolicyholderField = styled(StyledTextFieldWrapper)`
  > .BalloonTip.contentDiv {
    padding: 4px 12px 4px !important;
  }
  
    & > .BalloonTip {
        left: ${({lang}) => lang === 'en' ? "201px !important" : "149px !important"};
        
        .contentDiv {
            width: 360px;
        }
    }
  
  
  ${lgDown`
    & > .BalloonTip {
      top: -30px !important;
      left: ${({lang}) => lang === 'en' ? "178px !important" : "132px !important"};
      & .contentDiv {
        margin-left: -170px !important;
      }
    }
  `}
  ${smDown`
    & > .BalloonTip {
      & .contentDiv {
        margin-left: -185px !important;
      }
    }
  `}
  
  .Mui-disabled > .BalloonTip {
        opacity: 0.5;
  }
`;

const ShortTextInputWrapper = styled(StyledTextFieldWrapper)`
  margin-bottom: 32px;
  flex: 1;
  
  input {
    text-transform: uppercase;
  } 

  & > .BalloonTip {
    left: ${({lang,country}) => lang === 'en' ? (country === 'MYS' ? "160px !important" : "122px !important") : "168px !important"};
    white-space: pre-wrap;
  }

  ${lgDown`
    & .MuiInputAdornment-positionEnd {
      right: 10px !important;
    }
    & > .BalloonTip {
      top: -30px !important;
      left: ${({lang}) => lang === 'en' ? "108px !important" : "149px !important"};
      & .contentDiv {
        top: -55px !important;
        margin-left: -220px !important;
      }
    }
  `}
  ${smDown`
    width: auto;
    font-size: 16px;
    line-height: 24px;
    & > .BalloonTip {
      & .contentDiv {
        top: -55px !important;
        margin-left: -250px !important;
      }
    }
  `}
`;

const StyledWrapper = styled.div`
  ${smDown`
    padding: 21px 14px 20px 16px;
  `}
  
  & div.errorMessage {
    margin-top: 30px;
  }
`;

const StyledFirstRowWrapper = styled(StyledTextFieldWrapper)`
  padding-top: 40px;
  display: flex;
  ${lgDown`
    padding-top: 32px;
  `}
  ${smDown`
    padding-top: 0;
    margin-top: 10px;
  `}
`;

const SelectWrapper = styled(StyledTextFieldWrapper)`
  width: 161px;
  margin-bottom: 32px;
  & .MuiFormLabel-root {
    padding: 0 !important;
    transform: none !important;
  }
  & .MuiInputBase-root .MuiSelect-root {
    padding: 12px 16px 10px 18px !important;
  }
  ${lgDown`
    width: 126px;

  `}
  ${smDown`
    & .MuiFormControl-root.MuiFormLabel-root {
      padding: 0 !important;
      transform: none !important;
    }
  `}
  
  & div.MuiInputBase-root.MuiFilledInput-root.Mui-disabled {
        background: #FAFBFB;
        & input.Mui-disabled {
            color: #8A94A6 !important;
        }
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 20px !important;
  margin-bottom: 20px !important;
`;

const EnterOneFieldFail = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #0a1f44;
  font-family: "Inter";
  align-items: center;
  padding-bottom: 30px;
  ${lgDown`
    font-size: 16px;
    line-height: 24px;
  `}
  white-space: pre-wrap;
`;

const EnterOneFieldSuccess = styled(EnterOneFieldFail)`
  color: #009ae4;
  display: flex;
  & > :first-child {
    padding-left: 4px;
    padding-bottom: 2px;
  }
`;

const StyledDoneIcon = styled(DoneIcon)`
  font-size: 18px;
  color: #009ae4;
`;

const StyledArrowBackIcon = styled(ArrowBackIosIcon)`
  font-size: 18px;
  margin-top: 12px;
  cursor: pointer;
  margin-right: 32px;
  margin-left: 14px;
  ${lgDown`
    margin: 8px 12px 0 36px;
  `}
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #d54040;
  word-break: keep-all;
  white-space: pre-wrap;
  font-family: "Inter";
  margin-top: -8px;
`;

const StyledLanguageDiv = styled(LanguageSelectDiv)`
    font-weight: normal;
    top: 5%;
    right: 15%;
`;

const StyledCheckBoxContent = styled.div`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  & > span:first-child {
    color: #003da5;
    cursor: pointer;
  }
  ${lgDown`
    line-height: 20px;
  `}
`;

const CheckBoxDiv = styled.div`
    margin-top: 30px;
    margin-bottom: 40px;
`;

const SubtitleDiv = styled.div`
    color: #8A94A6;
    font: Inter-Regular;
    font-size: 16px;
    margin-top: 15px;
    white-space: pre-wrap;
`;

const AdditionalMargin = styled.div`
    margin-top: 20px;
`;

const StyledModalDiv2 = styled(StyledModalDiv)`
    width: 590px;
    white-space: pre-wrap;
    
    div.title {
        margin-top: 30px;
        ${({lang}) => {
            if (lang === 'zh') {
                return css` font-weight: bold` 
            } 
        }}
    }
    div.content {
        margin: 41px auto 40px;
        text-align: left;
        
        ol {
            padding: 0;
            margin-left: 20px;
            margin-top: 0;
            list-style-type: lower-alpha;
            
            li {
                margin-bottom: 10px;
                margin-left: 12px;
            }
            
            ol {
                margin-top: 10px;
                list-style-type: lower-roman;
            }
        }
       
    }
`;

const OrDiv = styled.div`
    display: flex;
    margin-bottom: 58px;
    position: relative;
    color: #8A94A6;
    div.lineSeparator {
        border-top: 2px solid rgba(0, 0, 0, 0.12);
        position: relative;
        top: 10px;
        width: 50%;
        margin: 0 5px;
    }
`;

const PICSDiv = styled.div`
    li {
        margin-bottom: 5px;
    }
    
    ol {
        margin-top: 5px;
    }
    
    .content {
        white-space: pre-wrap;
    }
`;

const AuthenticationSection = (props) => {
    const cfg = useConfig(props.lang);
    const formId = 'authentication-form';

    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [openOtpDialog, setOpenOtpDialog] = useState(false);
    const [openPicsDialog, setOpenPicsDialog] = useState(false);

    const [type, setType] = useState(props.user?.idType);
    const [idNumber, setIdNumber] = useState(props.user?.idNumber);
    const [nameOfPolicyholder, setNameOfPolicyholder] = useState(props.user?.policyHolder);
    const [dateOfBirth, setDateOfBirth] = useState(props.user?.dateOfBirth ?
        moment(props.user?.dateOfBirth, 'DD/MM/YYYY') : null);
    const [showIdNumber, setShowIdNumber] = useState(true);

    const [authenticationErrorMessage, setAuthenticationErrorMessage] = useState('');
    const [idErrorMsg, setIdErrorMsg] = useState('');
    const [dobErrorMsg, setDobErrorMsg] = useState('');

    const [maskedPhone, setMaskedPhone] = useState('');
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [failedOpen, setFailedOpen] = useState(false);

    const [authMethod, setAuthMethod] = useState(null);
    const [maskedEmail, setMaskedEmail] = useState();
    const [maskedPhoneNumber, setMaskedPhoneNumber] = useState();
    const [authStepEmailMobileDialog, setAuthStepEmailMobileDialog]  = useState(false);
    const [authStepEmailMobileOtpDialog, setAuthStepEmailMobileOtpDialog]  = useState(false);
    const [isForEmail, setIsForEmail] = useState(false);
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [resendTimer, setResendTimer] = useState(0);
    const [resendTimerIntervalId, setResendTimerIntervalId] = useState(null);
    const [resendEmailTimer, setResendEmailTimer] = useState(0);
    const [resendEmailTimerIntervalId, setResendEmailTimerIntervalId] = useState(null);

    const [isCheckedOne, setIsCheckedOne] = useState(false);
    const [isCheckedTwo, setIsCheckedTwo] = useState(false);

    const [showNoEmailOrMobileDialog, setShowNoEmailOrMobileDialog] = useState(false);

    const addEmailErrorToCache = () => {
        if (props.user.email) {
            let email = props.user.email;
            props.addEmailAddressToOTPLockedCache(email).then(
                response => {
                    if (response > 5) {
                        setOpenOtpDialog(false);
                        setFailedOpen(true);
                    }
                }
            )
        }
    }

    useEffect(() => {
        _scrollToTop();
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Authentication',
                vPath: '/registration/authentication'
            }
        });
    }, []);

    const validateHKId = (inputId) => {
      // basic check length
      if (!inputId || inputId.length < 8) {
        return false;
      }

      if (showIdNumber) {
        let pattern = /^[a-zA-Z]{1,2}\d{6}\([\d|A]\)$/;

        return pattern.test(inputId);
      }
      else {
        let pattern = /^[a-zA-Z]{1,2}\d{6}[\d|A]$/;

        return pattern.test(inputId);
      }

      // let strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      //
      // // basic check length
      // if (!inputId || inputId.length < 8) {
      //   return false;
      // }
      //
      // let hkId = inputId.toUpperCase().trim();
      // if (hkId) {
      //   hkId = hkId.replaceAll('(', '');
      //   hkId = hkId.replaceAll(')', '');
      // }
      //
      // if (hkId.charAt(hkId.length - 3) == '(' && hkId.charAt(hkId.length - 1) == ')') {
      //   hkId = hkId.substring(0, hkId.length - 3) + hkId.charAt(hkId.length - 2);
      // }
      //
      // // convert to upper case
      // hkId = hkId.toUpperCase();
      //
      // // regular expression to check pattern and split
      // let hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
      // let matchArray = hkId.match(hkidPat);
      //
      // // not match, return false
      // if (!matchArray) {
      //   return false;
      // }
      //
      // // the character part, numeric part and check digit part
      // let charPart = matchArray[1];
      // let numPart = matchArray[2];//
      // let checkDigit = matchArray[3];
      //
      // // calculate the checksum for character part
      // let checkSum = 0;
      //
      // if (charPart.length == 2) {
      //   checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
      //   checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      // } else {
      //   checkSum += 9 * 36;
      //   checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      // }
      //
      // // calculate the checksum for numeric part
      // for (let i = 0, j = 7; i < numPart.length; i++, j--) {
      //   // checkSum += j * numPart.charAt(i);
      //   checkSum += j * Number.parseInt(numPart.charAt(i));
      // }
      //
      // // verify the check digit
      // let remaining = checkSum % 11;
      // let verify = remaining == 0 ? 0 : 11 - remaining;
      //
      // return verify === Number.parseInt(checkDigit) || (verify == 10 && checkDigit == 'A');
    }

    const validateMyKadID = (inputId) => {
        if (!inputId || inputId.length !== 14) {
            return false;
        }

        if (showIdNumber) {
            let pattern = /^[0-9]{6}-[0-9]{2}-[0-9]{4}$/;
            return pattern.test(inputId);
        }
    }

    const handleAuthenticate = () => {
      setIdErrorMsg(null);
      setAuthenticationErrorMessage(null);
      if (props.user.policyNumber && props.country && _validateForm(formId)) {

            if (props.country === 'HKG' && !validateHKId(idNumber)) {
              setIdErrorMsg('registrationAuthentication.errorMessage.invalidFormat');
              props.hideSpinner();
              return;
            }else if (props.country === 'MYS' && !validateMyKadID(idNumber)) {
                setIdErrorMsg('registrationAuthentication.errorMessage.invalidFormatMY');
                props.hideSpinner();
                return;
            }

            let clientCredentials = {
                policyNumber: props.user.policyNumber,
                country: props.country,
                policyHolderType: 'I',
                isRegistration: true,
                clientID: idNumber.replaceAll(/[^a-zA-Z\d]/g, ''),
                clientName: nameOfPolicyholder,
                clientDOB: dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : ''
            }

            props.showSpinner();
            props.verifyCredentials(clientCredentials).then((rsp) => {
                if (rsp.success && rsp.policy.policyNumber && rsp.token) {
                    props.user.idType = type;
                    props.user.idNumber = idNumber;
                    props.user.policyHolder = nameOfPolicyholder;
                    props.user.dateOfBirth = dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : '';
                    props.user.isHGI = rsp.policy.country === 'HGI' ? 'Y' : 'N';
                    props.storeUser(props.user);
                    props.setCpToken(rsp.token);

                    let invalidCatalogueCodes = cfg('invalidCatalogueCodes.' + rsp.policy.country);
                    if (invalidCatalogueCodes.includes(rsp.policy.catalogueCode)){
                        setAuthenticationErrorMessage(cfg('invalidCatalogueCodes.errorMessage'));
                    } else {
                        if (rsp.policy.email) {
                            let val = rsp.policy.email.split('@')[0];
                            let regexToUse = val.length > 4 ? /^.*(?=....@)/g : /^.*(?=@)/g;
                            let emailMasked = customReplaceAll(regexToUse, rsp.policy.email, '•');
                            setEmail(rsp.policy.email);
                            setMaskedEmail(emailMasked);
                        }
                        if (rsp.policy.clientPhoneNumber) {
                            setMaskedPhoneNumber(customReplaceAll(/^\d*(?=\d{4}$)/g, rsp.policy.clientPhoneNumber, '•'));
                            setPhoneNumber(rsp.policy.clientPhoneNumber);
                        }

                        resetResendTimer();
                        resetResendEmailTimer();
                        setAuthStepEmailMobileDialog(true);
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'popup',
                                popupPath: '/registration/authentication',
                                popupTitle: 'Complete Authentication'
                            }
                        });
                    }
                    props.hideSpinner();
                } else if (rsp.errorCode) {
                    if (rsp.errorCode === 'no-email-mobile' && props.country === 'HKG') {
                        setShowNoEmailOrMobileDialog(true);
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'popup',
                                popupPath: '/authentication',
                                popupTitle: 'Oops!'
                            }
                        });
                    } else {
                        setAuthenticationErrorMessage(cfg(rsp.errorCode));
                    }
                    props.hideSpinner();
                } else {
                    setAuthenticationErrorMessage(cfg('registrationAuthentication.errorMessage.recordNotFound'));
                    props.hideSpinner();
                }
            }, error => {
                setAuthenticationErrorMessage(error.response.statusText ? error.response.statusText : 'Internal Server Error');
                props.hideSpinner();
            });
        }
    }

    const switchLanguage = () => {
        let newLang = props.lang === 'en' ? 'zh' : 'en';
        props.setLang(newLang);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Change-Language'
            }
        });
    }

    const onChangeIdNumber = (event) => {
        let idNumber = event.target.value?.toUpperCase().trim();

        if (idNumber) {
            if(props.country === 'HKG') {
                idNumber = idNumber.replaceAll('(', '');
                idNumber = idNumber.replaceAll(')', '');
                idNumber = idNumber.replaceAll('_', '');

                if (showIdNumber) {
                    if (idNumber.length > 9) {
                        idNumber = idNumber.substring(0, 8);
                    }

                    if (idNumber.length > 7) {
                        idNumber = idNumber.substring(0, idNumber.length - 1) + '(' + idNumber.charAt(idNumber.length - 1) + ')';
                    }
                }
            }else if(props.country === 'MYS'){
                idNumber = formatMyKADId(idNumber)
            }
        }

        // console.log('idNumber', idNumber);
        setIdNumber(idNumber);
    }

    const formatMyKADId = (value) => {
        let newVal = value.replaceAll(/[a-zA-Z-]/g,'')
        if(newVal.length > 6){
            let leftHand = newVal.slice(0,6)
            let rightHand = newVal.slice(6)
            newVal = leftHand + '-'+rightHand
        }
        if(newVal.length > 9){
            let leftHand = newVal.slice(0,9)
            let rightHand = newVal.slice(9)
            newVal = leftHand + '-'+rightHand
        }
        return newVal
    }

    const refreshIdNumber = () => {
      let newIdNumber = idNumber;

      newIdNumber = newIdNumber.replaceAll('(', '');
      newIdNumber = newIdNumber.replaceAll(')', '');
      newIdNumber = newIdNumber.replaceAll('_', '');

      if (!showIdNumber) {
        if (newIdNumber.length > 9) {
          newIdNumber = newIdNumber.substring(0, 8);
        }

        if (newIdNumber.length > 7) {
          newIdNumber = newIdNumber.substring(0, idNumber.length - 1) + '(' + newIdNumber.charAt(7) + ')';
        }
      }

      setIdNumber(newIdNumber);
  }

    const handleSelectedOption = () => {
        props.showSpinner();
        if (authMethod === 'email') {
            props.sendOTPToEmailOrMobile(email, null, null)
                .then((response) => {
                    props.setCpToken(response.token);
                    setIsForEmail(true);
                    resetResendTimer();
                    resetResendEmailTimer();
                    setAuthStepEmailMobileOtpDialog(true);
                    props.hideSpinner();
                });
        } else if (authMethod === 'sms') {
            props.sendOTPToEmailOrMobile(null, null, phoneNumber)
                .then((response) => {
                    props.setCpToken(response.token);
                    setIsForEmail(false);
                    resetResendTimer();
                    resetResendEmailTimer();
                    setAuthStepEmailMobileOtpDialog(true);
                    props.hideSpinner();
                });
        }
    }

    const handleResend = () => {
        props.showSpinner();
        if (resendTimer === 0) {
            if (isForEmail) {
                props.sendOTPToEmailOrMobile(email, null, null)
                    .then((response) => {
                        props.setCpToken(response.token);
                        props.hideSpinner();
                    }, () => props.hideSpinner());
            } else {
                props.sendOTPToEmailOrMobile(null, null, phoneNumber)
                    .then((response) => {
                        props.setCpToken(response.token);
                        props.hideSpinner();
                    }, () => props.hideSpinner());
            }
            resetResendTimer();
            resetResendEmailTimer();
        }
    }

    const handleConfirm
      = (otpValue) => {
        props.showSpinner();
        props.verifyOtp(otpValue).then((response) => {
            if (response.success) {
                props.setCpToken(response.token);
                setAuthStepEmailMobileOtpDialog(false);
                if (email) {
                    props.user.email = email;
                }
                if (phoneNumber) {
                    props.user.phoneNumber = phoneNumber;
                }
                props.storeUser(props.user);
                setTimeout(() => {
                    props.showModal({
                        flat: true,
                        content: <StyledModalDiv>
                            <div className="iconDiv">
                                <BlueDone/>
                            </div>
                            <div className="title"
                                 style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}
                            >
                                { cfg('authStepChooseEmailMobile.otpSuccessTitle') }
                            </div>
                            <div className="content auth-success">{cfg('authStepChooseEmailMobile.otpSuccessMsg')}</div>
                            <div className="buttonDiv auth-btn">
                                <StyledQnectButton
                                    field="continue"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: "/registration/personalDetail",
                                            state: { passwordMinLength: response.passwordMinLength}
                                        });
                                        props.hideModal();
                                    }}></StyledQnectButton>
                            </div>
                        </StyledModalDiv>
                    });
                }, 500);
                props.hideSpinner();

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'popup',
                        popupPath: '/registration/authentication',
                        popupTitle: 'Authentication Success'
                    }
                });
            } else {
                setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
                props.hideSpinner();
            }
        }, (error) => {
            setOtpErrorMessage(error.response.data);
            props.hideSpinner();
        })
    }

    const resetResendTimer = () => {
        if (resendTimerIntervalId) {
            clearInterval(resendTimerIntervalId);
        }
        setResendTimer(60);
        let setTimerInterval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(setTimerInterval);
                    return 0;
                }
            });
        }, 1000);
        setResendTimerIntervalId(setTimerInterval);
    }

    const resetResendEmailTimer = () => {
        if (resendEmailTimerIntervalId) {
            clearInterval(resendEmailTimerIntervalId);
        }
        setResendEmailTimer(600);
        let setEmailTimerInterval = setInterval(() => {
            setResendEmailTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(setEmailTimerInterval);
                    return 0;
                }
            });
        }, 1000);
        setResendEmailTimerIntervalId(setEmailTimerInterval);
    }

    const getIDBalloonTooltipCfg = () => {
        if(props.country === 'HKG'){
            return 'labels.lettersAnd6Digits'
        }else if(props.country === 'MYS'){
            return 'labels.lettersAnd6DigitsMy'
        }else {
            return 'labels.lettersAnd6DigitsSg'
        }
    }

    return (
        <Registration currentStep={0}>
            <Hidden xsDown>
                <Title>
                    <StyledArrowBackIcon
                        onClick={() => {
                            props.history.push("/login");
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'Icon-Click',
                                    eventCategory: 'Back-Arrow'
                                }
                            })
                        }}
                    />
                    <div 
                        style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>{cfg('findMyPolicy.authentication')}</div>
                    <StyledLanguageDiv onClick={switchLanguage}
                                       style={{visibility: (props.country === 'SGP' || props.country === "MYS" ? 'hidden' : 'visible')}}>
                        {(props.lang === 'en') ?
                            cfg("labels.lang_zh")
                            :
                            cfg("labels.lang_en")
                        }
                    </StyledLanguageDiv>
                </Title>
            </Hidden>
            <Hidden smUp>
                <MobileHeader
                    title={cfg('findMyPolicy.authentication')}
                    lang={(props.lang === 'en') ? cfg("labels.lang_zh") : cfg("labels.lang_en")}
                    onBack={() => props.history.go(-1)}
                    onToggleLang={() => switchLanguage()}
                    onClickTitle={() => {
                    }}
                    isHidden={props.country === 'SGP' || props.country === "MYS"}
                />
            </Hidden>

            <StyledWrapper>
                <StyledFirstRowWrapper>
                    <ShortTextInputWrapper lang={props.lang} country={props.country}>
                      {
                          props.country === 'HKG' || props.country === 'MYS'
                          ?
                          <QnectTextField
                            formId={formId}
                            field={"registrationIDNumber" + props.country}
                            value={idNumber}
                            onBlur={(evt) => {
                              setAuthenticationErrorMessage(null);
                              onChangeIdNumber(evt);
                            }}
                            onChange={(evt) => {
                              setAuthenticationErrorMessage(null);
                              onChangeIdNumber(evt);
                            }}
                            onKeyPress={(event) => {
                                let regex = /[\(\)]/g;
                                if (event.key.match(regex)){
                                    event.preventDefault();
                                }
                            }}

                            placeholder={cfg(`registrationIDNumber${props.country}.placeholder`)}
                            errorMessage={idErrorMsg}
                            maxLength={cfg(`registrationIDNumber${props.country}.maxLength`)}
                          />
                          :
                          <QnectTextField
                            formId={formId}
                            field={"registrationIDNumber" + props.country}
                            value={idNumber}
                            onBlur={(evt) => {
                              setAuthenticationErrorMessage(null);
                              setIdNumber(evt.target.value.toUpperCase());
                            }}
                            onChange={(evt) => {
                              setAuthenticationErrorMessage(null);
                              setIdNumber(evt.target.value.toUpperCase());
                            }}
                          />
                      }
                        <BalloonTip>
                            {cfg(getIDBalloonTooltipCfg())}
                        </BalloonTip>
                        <SubtitleDiv>{cfg(`authentication.${props.country}.idTip`)}</SubtitleDiv>
                    </ShortTextInputWrapper>
                </StyledFirstRowWrapper>
                <StyledDivider/>
                <EnterOneFieldFail>{cfg('registration.enterfullNameOrDOB')}</EnterOneFieldFail>

                <StyledPolicyholderField lang={props.lang}>
                    <QnectTextField
                        type={"text"}
                        field="registrationPolicyHolderName"
                        value={nameOfPolicyholder}
                        onChange={(evt) => {
                            setNameOfPolicyholder(evt.target.value?.toUpperCase());
                            setAuthenticationErrorMessage(null);
                            setIdErrorMsg(null);
                        }}
                        onBlur={(evt) => {
                            setNameOfPolicyholder(evt.target.value?.toUpperCase());
                            setAuthenticationErrorMessage(null);
                            setIdErrorMsg(null);
                        }}
                        disabled={dateOfBirth ? true : false}
                    />
                    <BalloonTip>{cfg('labels.jointPolicyHolders')}</BalloonTip>
                </StyledPolicyholderField>
                <SubText>{cfg('labels.pleaseReferToFullName')}</SubText>

                <OrDiv>
                    <div class="lineSeparator"></div>
                    <div>{cfg("labels.or")}</div>
                    <div class="lineSeparator"></div>
                </OrDiv>

                <StyledTextFieldWrapper dobErrorMsg={dobErrorMsg}>
                    <QnectDateField
                        formId={formId}
                        field="registrationDOB"
                        value={dateOfBirth}
                        onChange={(value) => {
                            setDateOfBirth(value);
                            setAuthenticationErrorMessage(null);
                        }}
                        placeholder={cfg("registrationDOB.placeholder")}
                        disabled={nameOfPolicyholder ? true : false}
                    />
                </StyledTextFieldWrapper>

                { authenticationErrorMessage &&
                    <div className="errorMessage">
                        <QnectError errorTitle={"Error"} errorMessage={authenticationErrorMessage}/>
                    </div>
                }

                <CheckBoxDiv>
                    <LabelledCheckbox checked={isCheckedOne} onChange={(event) => setIsCheckedOne(event.target.checked)}>
                        <StyledCheckBoxContent>
                            {cfg('registration.checkboxMessage')}
                        </StyledCheckBoxContent>
                    </LabelledCheckbox>
                    <AdditionalMargin></AdditionalMargin>
                    <LabelledCheckbox checked={isCheckedTwo} onChange={(event) => setIsCheckedTwo(event.target.checked)}>
                        <StyledCheckBoxContent>
                            {cfg('registration.checkboxMessage2')}
                            {props.country === 'MYS' ? <span onClick={() => {
                                window.open("https://www.qbe.com/my/privacy-policy","_blank")
                            }}>
                                Privacy Policy
                            </span> :
                                <span onClick={() => {
                                setOpenPicsDialog(true);
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'popup',
                                        popupPath: '/registration/authentication',
                                        popupTitle: 'Personal Information Collection Statement ("PICS")'
                                    }
                                });
                            }}>
                                {cfg('labels.personalDetails.checkBoxMessage1PICS')}
                            </span>}
                            {(props.lang !== 'zh' && !props.country === 'MYS') && <span>{" (\"PICS\")."}</span>}
                        </StyledCheckBoxContent>
                    </LabelledCheckbox>
                </CheckBoxDiv>

                <ButtomRow>
                    <div onClick={() => {
                        setOpenDialog(true);
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'popup',
                                popupPath: '/registration/authentication',
                                popupTitle: 'Authentication Tips'
                            }
                        });
                    }}>
                        {cfg('registration.details')}
                    </div>
                    <ButtonWrapper>
                        <StyledQnectButton
                            field="continue"
                            onClick={handleAuthenticate}
                            disabled={idNumber === "" || (nameOfPolicyholder === "" && dateOfBirth === null) ||
                                !isCheckedOne || !isCheckedTwo}
                        >
                        </StyledQnectButton>
                    </ButtonWrapper>
                </ButtomRow>

            </StyledWrapper>

            <AuthTipsDialog open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'Icon-Click',
                                        eventCategory: 'Close'
                                    }
                                })
                            }}
                            onOpenAnother={() => {
                                setOpenDialog2(true);
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'popup',
                                        popupPath: '/registration/authentication',
                                        popupTitle: 'Need Help? Contact Us'
                                    }
                                });
                            }} cfg={cfg} lang={props.lang} country={props.country}/>
            <AuthTipsDialogMore open={openDialog2}
                                onClose={() => {
                                    setOpenDialog2(false);
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: 'Icon-Click',
                                            eventCategory: 'Close'
                                        }
                                    })

                                }}
                                onOpenAnother={() => {
                                    setOpenDialog(true);
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: 'popup',
                                            popupPath: '/registration/authentication',
                                            popupTitle: 'Authentication Tips'
                                        }
                                    });
                                }} cfg={cfg} 
                                country={props.country}
                                lang={props.lang}/>

            <AuthStepChooseEmailMobile open={authStepEmailMobileDialog}
                                       onClose={() => {
                                           setAuthStepEmailMobileDialog(false);
                                           TagManager.dataLayer({
                                               dataLayer: {
                                                   event: 'Icon-Click',
                                                   eventCategory: 'Close'
                                               }
                                           })
                                       }}
                                       onConfirm={() => {
                                           handleSelectedOption();
                                           setAuthStepEmailMobileDialog(false);
                                       }}
                                       authMethod={authMethod}
                                       setAuthMethod={setAuthMethod}
                                       email={maskedEmail}
                                       sms={maskedPhoneNumber}
                                       cfg={cfg}
                                       lang={props.lang}
                                       country={props.country}/>

            <AuthStepEmailMobileOtpDialog open={authStepEmailMobileOtpDialog}
                                          onClose={() => {
                                              setAuthStepEmailMobileOtpDialog(false);
                                              TagManager.dataLayer({
                                                  dataLayer: {
                                                      event: 'Icon-Click',
                                                      eventCategory: 'Close'
                                                  }
                                              })
                                          }}
                                          maskedPhone={maskedPhoneNumber}
                                          maskedEmail={maskedEmail}
                                          isForEmail={isForEmail}
                                          handleResend={handleResend}
                                          onConfirm={handleConfirm}
                                          errorMessage={otpErrorMessage}
                                          setErrorMessage={setOtpErrorMessage}
                                          resendTimer={resendTimer}
                                          resendEmailTimer={resendEmailTimer}
                                          cfg={cfg}
                                          lang={props.lang}/>
            <PopupDialogue open={openPicsDialog}
                           onClose={() => {
                               setOpenPicsDialog(false);
                               TagManager.dataLayer({
                                   dataLayer: {
                                       event: 'Icon-Click',
                                       eventCategory: 'Close'
                                   }
                               })
                           }}
                           title='labels.PICS.title'
                           lang={props.lang}
                           cfg={cfg}>
              {props.lang === 'zh' ?
                  <PICSDiv>
                      <div className="content">
                          {cfg('labels.PICS.message')}
                          <ol type="a">
                              <li>
                                  {cfg('labels.PICS.message2')}
                              </li>
                              <li>
                                  {cfg('labels.PICS.message3')}
                                  <a style={{color: "#003DA5"}} href="https://hk-customer.qbe.com/portal" target="_blank">hk-customer.qbe.com/portal</a>
                                  {cfg('labels.PICS.message3-part2')}
                              </li>
                              <li>
                                  {cfg('labels.PICS.message4.title')}
                                  <ol type="i">
                                      <li>{cfg('labels.PICS.message4.msg-sub1')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub2')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub3')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub4')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub5')}</li>
                                  </ol>
                              </li>
                              <li>{cfg('labels.PICS.message5')}</li>
                              <li>{cfg('labels.PICS.message6')}</li>
                              <li>{cfg('labels.PICS.message7')}</li>
                          </ol>
                      </div>
                  </PICSDiv>
                :
                  <PICSDiv>
                      <div className="content">
                          {cfg('labels.PICS.message')}
                          <ol type="a">
                              <li>{cfg('labels.PICS.message2')}</li>
                              <li>
                                  {cfg('labels.PICS.message3')}
                                  <a style={{color: "#003DA5"}} href="https://hk-customer.qbe.com/portal" target="_blank">hk-customer.qbe.com/portal</a>
                                  {cfg('labels.PICS.message3-part2')}
                              </li>
                              <li>
                                  {cfg('labels.PICS.message4.title')}
                                  <ol type="i">
                                      <li>{cfg('labels.PICS.message4.msg-sub1')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub2')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub3')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub4')}</li>
                                      <li>{cfg('labels.PICS.message4.msg-sub5')}</li>
                                  </ol>
                              </li>
                              <li>{cfg('labels.PICS.message5')}</li>
                              <li>{cfg('labels.PICS.message6')}</li>
                              <li>{cfg('labels.PICS.message7')}</li>
                          </ol>
                      </div>
                  </PICSDiv>
              }

            </PopupDialogue>

            <NoEmailOrMobileDialog
                open={showNoEmailOrMobileDialog}
                cfg={cfg}
                lang={props.lang}
                onClose={() => {
                    setShowNoEmailOrMobileDialog(false);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'Icon-Click',
                            eventCategory: 'Close'
                        }
                    })
                }}
            />
        </Registration>
    );
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        country: _getCountry(state),
        lang: _getLang(state),
    }
}

const mapDispatchToProps = {
    verifyCredentials: _verifyCredentials,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storeUser: _storeUser,
    setLang: _setLang,
    checkIfPolicyNumberIsRegistered: _checkIfPolicyNumberIsRegistered,
    checkEmailIfRegistered: _checkEmailIfRegistered,
    setCpToken: _setCpToken,
    addEmailAddressToOTPLockedCache: _addEmailAddressToOTPLockedCache,
    showModal: _showModal,
    hideModal: _hideModal,
    verifyOtp: _verifyOtp,
    generatePortfolioToken: _generatePortfolioToken,
    getUserDetailsFromToken: _getUserDetailsFromToken,
    generatePolicyTokenFromPortfolioScreen: _generatePolicyTokenFromPortfolioScreen,
    sendOTPToEmailOrMobile: _sendOTPToEmailOrMobile,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationSection));
