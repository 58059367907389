import property from 'lodash/property';
import * as type from "./types";
import axios from "axios";
import {_getCpToken} from "../system/service";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export const _storePolicyPayment = (policy) => {
    return (dispatch) => {
        dispatch({
            type: type.POLICY_PAYMENT_STORE,
            data: policy
        })
    };
}

export const _searchPolicyPayment = (policyNumber) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let policyObj = _getPolicyPayment(getState());
            console.log('Policy Object', policyObj);
            policyObj.policyNumber = policyNumber;

            axios.get(process.env.REACT_APP_SEARCH_POLICY + '/' + policyNumber + '?country=HKG')
                .then(resp => {
                    if (resp.data.success) {
                        dispatch({
                            type: type.POLICY_PAYMENT_STORE,
                            data: policyObj
                        })
                        resolve();
                    } else {
                        reject(resp.data.errorCode);
                    }
                }).catch(error => {
                reject(error.message);
            });
        })
    }
}


/** Renewal Methods */
export const _renewLoadPolicy = (token, vector, lang) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.REACT_APP_RENEWAL_LOAD_POLICY + token + '/' + vector + '/' + lang)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    }
}

export const _renewLogPaymentLifeCycle = (policy) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_RENEWAL_LOG_PAYMENT_LIFE_CYCLE, policy)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    }
}

export const _renewInitiatePayment = (policy) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_RENEWAL_INITIATE_PAYMENT, policy, config)
                .then( (resp) => {
                    if (resp.data.errorMessage) {
                        reject(resp.data.errorMessage);
                    }
                    resolve(resp.data);
                })
                .catch(error => {
                    let message = error.response.data;
                    if (message) {
                        reject(message);
                    } else {
                        reject(error.message);
                    }
                });
        });
    }
}

export const _issuePolicyWithCreditTerms = (policy, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_RENEWAL_ISSUE_POLICY_WITH_CREDIT_TERMS + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policy)
                .then( (resp) => {
                    if (resp.data.errorMessage){
                        reject(resp.data.errorMessage);
                    } else {
                        resolve(resp);
                    }
                })
                .catch(error => {
                    reject(error.message);
                })
        })
    }
}

export const _issueAndReceiptPolicy = (policy) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_ISSUE_AND_RECEIPT_POLICY, policy, config)
                .then( (resp) => {
                    resolve(resp);
                })
                .catch(error => {
                    reject(error.message);
                })
        })
    }
}

export const _issueFailed = (policy) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_ISSUE_FAILED, policy, config)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error.message);
                })
        })
    }
}
/** end renewal methods*/


/** adhoc payment methods */
export const _adhocFindPaymentDetails = (token, vector) => {
    return () => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_ADHOC_FIND_PAYMENT_DETAILS + '?token=' + token + '&vector=' + vector;
            console.debug('Calling url ' + url);
            axios.get(url)
                .then( (resp) => {
                    resolve(resp.data);
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

export const _adhocFailPayment = (policyPaymentObject, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_ADHOC_FAIL_PAYMENT + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policyPaymentObject)
                .then( ( resp ) => {
                    resolve(resp.data);
                })
                .catch( error => {
                    reject(error.message);
                })
        })
    }
}

export const _adhocAcceptPayment = (policyPaymentObject, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_ADHOC_ACCEPT_PAYMENT + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policyPaymentObject)
                .then( (resp) => {
                    resolve(resp.data);
                })
                .catch( error => {
                    reject(error.message);
                })
        })
    }
}

export const _adhocInitiatePayment = (policyPaymentObject, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_ADHOC_INITIATE_PAYMENT + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policyPaymentObject)
                .then( (resp) => {
                    if (resp?.data?.errorMessage) {
                        reject(resp);
                    } else {
                        console.log("QR Code: " + resp.data);
                        resolve(resp.data);
                    }
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

/**
 * Generates QR Code payment for PayNow option.
 * @param policyPaymentObject the policy details
 * @returns {function(): Promise<unknown>}
 */
export const _adhocGenerateQrCode = (policyPaymentObject) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_ADHOC_GENERATE_QR_CODE, policyPaymentObject)
                .then( (resp) => {
                    if (resp?.data?.errorMessage) {
                        reject(resp);
                    } else {
                        resolve(resp.data);
                    }
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

/**
 * Calls api to simulate QR Code payment for PayNow option.
 * @param tnxRef transaction reference
 * @returns {function(): Promise<unknown>}
 */
export const _adhocSimulateQrCodePayment = (txnRef) => {
    return () => {
        return new Promise((resolve, reject) => {
            console.log("REF: " + txnRef);
            axios.post(process.env.REACT_APP_ADHOC_SIMULATE_QR_CODE_PAYMENT, {"txnRef": txnRef})
                .then( (resp) => {
                    if (resp?.data?.errorMessage) {
                        reject(resp);
                    } else {
                        resolve(resp.data);
                        // history.push()
                    }
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

export const _adhocCheckQrCodePaymentComplete = (txnRef) => {
    return () => {
        return new Promise((resolve, reject) => {
            console.log("REF complete pyament check: " + txnRef);
            axios.post(process.env.REACT_APP_ADHOC_CHECK_QR_CODE_PAYMENT_COMPLETE, {"txnRef": txnRef} )
                .then( (resp) => {
                    if (resp?.data?.errorMessage) {
                        reject(resp);
                    } else {
                        console.log("Payment Complete data... ");
                        console.log(resp.data);
                        resolve(resp.data);
                    }
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

export const _adhocPreparePayment = (policy) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_ADHOC_PREPARE_PAYMENT, policy)
              .then( ( resp ) => {
                  dispatch({
                      type: type.POLICY_PAYMENT_STORE,
                      data: resp.data
                  })
                  resolve();
              })
              .catch( error => {
                  reject(error.message);
              })
        })
    }
}

/** Online Payment */

export const _onlinePaymentLoadPolicy = (token, vector) => {
    return () => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_OP_LOAD_POLICY + '?token=' + token + '&vector=' + vector;
            console.debug('Calling url ' + url);
            axios.get(url)
                .then( (resp) => {
                    resolve(resp.data);
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

export const _onlineFailPayment = (policyPaymentObject, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_OP_FAIL_PAYMENT + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policyPaymentObject)
                .then( ( resp ) => {
                    resolve(resp.data);
                })
                .catch( error => {
                    reject(error.message);
                })
        })
    }
}

export const _onlineAcceptPayment = (policyPaymentObject, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_OP_ACCEPT_PAYMENT + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policyPaymentObject)
                .then( (resp) => {
                    if (resp?.data?.errorMessage) {
                        reject(resp);
                    } else {
                        resolve(resp.data);
                    }
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}

export const _onlineInitiatePayment = (policyPaymentObject, paymentToken) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_OP_INITIATE_PAYMENT + '?token=' + paymentToken.token + '&vector=' + paymentToken.vector, policyPaymentObject)
                .then( (resp) => {
                    if (resp?.data?.errorMessage) {
                        reject(resp);
                    } else {
                        resolve(resp.data);
                    }
                })
                .catch( error => {
                    reject(error);
                })
        })
    }
}


/** end adhoc payment methods */

export const _getPolicyPayment = property('pp');
