import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "../dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../dialog-title";
import QnectButton from "../../qnect-ui/qnect-button";
import ButtonWrapper from "../button-wrapper";
import { smDown } from "../../../ui/common/breakpoints";
import { convertSecsToMins} from "../../../utils/utility";

const StyledMessage = styled.div`
  margin: 20px auto 0;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 455px;
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    width: auto;
    margin-top: 28px;
  `}
`;

const StyledQnectButton = styled(QnectButton)`
  width: 251px !important;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  margin: 0;
  ${smDown`
    display: block;
    margin: 0 auto;
  `}
`;

const StyledCancelQnectButton = styled(StyledQnectButton)`
  box-shadow: none;
  width: 150px !important;
  ${smDown`
    margin-top: 20px;
    width: 251px !important;
  `}
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  ${smDown`
    display: block;
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "18px 66px 32px",
    [theme.breakpoints.down("md")]: {
      padding: "18px 49px 32px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "14px 28px 32px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: "44px auto 0",
    fontFamily: "Inter",
    display: "flex",
    justifyContent: "space-between",
    width: "451px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0 0",
      width: "auto",
    },
  },
}))(DialogActions);

export default function AreYouStillThereDialog({ open, onClose, onLogout, cfg, lang, country }) {
  const SESSION_TIMEOUT = () => {
      if(country == 'MYS') {
          return 60 * 5;
      } else {
          return 60;
      }
  }
  const [counter, setCounter] = React.useState(SESSION_TIMEOUT);

  const timer = React.useRef(null);

  React.useEffect(() => {
    timer.current = counter > 0 && open && setTimeout(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timer.current);
  }, [counter, open]);

  const getCounter = () => {
    if(country === "MYS") {
        return convertSecsToMins(counter);
    } else {
        return counter;
    }
  };

  const closePopup = () => {
      onClose();
      if(country === "MYS") {
          setCounter(SESSION_TIMEOUT);
      }
  }

  return (
    <div>
      <StyledDialog open={open} onClose={onClose} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <DialogTitle title={cfg('labels.portfolio.areYouStillThereTitle')} closable={false} lang={lang}/>
        <DialogContent>
          <StyledMessage>
            {cfg('labels.portfolio.areYouStillThereMessage')}
          </StyledMessage>
        </DialogContent>
        <StyledDialogActions>
          <StyledButtonWrapper>
            <Hidden smDown>
              <StyledCancelQnectButton field="logout" onClick={() => onLogout()} />
              <StyledQnectButton onClick={() => closePopup()} disabled={counter === 0 ? true : false}>{ counter > 0 ?
                  cfg('labels.portfolio.stayLoggedIn') + '(' + getCounter() + ')' : cfg('labels.portfolio.stayLoggedIn') }
              </StyledQnectButton>
            </Hidden>
            <Hidden smUp>
              <StyledQnectButton onClick={() => closePopup()} disabled={counter === 0 ? true : false}>Stay Logged in { counter > 0 ? (getCounter()) : '' }</StyledQnectButton>
              <StyledCancelQnectButton field="logout" onClick={() => onLogout()} />
            </Hidden>
          </StyledButtonWrapper>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
}
