import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import typographyStyles from '../../common/typography-styles';
import ActionHowToReg from '../icons/functional/action/how-to-reg';

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const RoleBlock = styled.div`
    padding: 8px 0 0 8px;
`;

const Role = styled.div`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'semiBold',
        defaultColor: 'black',
    })}
`;

export default function ClaimsOfficer({
    className,
    roleName,
}) {
    return (
        <Container
            className={classnames('ClaimsOfficer', className)}
        >
            <ActionHowToReg size={29} color="primary70" />

            <RoleBlock>
                {!!roleName && (
                    <Role>
                        {roleName}
                    </Role>
                )}
            </RoleBlock>
        </Container>
    );
}

ClaimsOfficer.propTypes = {
    className: propTypes.string,
    roleName: propTypes.string.isRequired,
};
