import React, {Component} from 'react';
import AdhocDetail from "../components/adhoc-detail/adhoc-detail";

const AdhocDetailPage = () => {
    return (
        <div>
            <AdhocDetail/>
        </div>
    )
}

export default AdhocDetailPage;
