import React from "react";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import {lgUp, smUp} from "../../ui/common/breakpoints";

const MWrapper = styled.div`
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.06);
  margin-top: 0;
`;

const MRow = styled.div`
  margin-bottom: 16px;
`;

const MKey = styled.div`
  margin-bottom: 4px;
  color: #8a94a6;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

const MValue = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

/* ================================== */

const Wrapper = styled.div`
  padding: 30px 40px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.06);
  display: flex;
`;

const Insured = styled.div`
`;

const Period = styled.div`
  flex-shrink: 0;
  ${smUp`
    margin-left: 64px;
`}
  ${lgUp`
    margin-left: 148px;
`}
`;

const Premium = styled.div`
  flex-shrink: 0;
  ${smUp`
    margin-left: 64px;
`}
  ${lgUp`
    margin-left: 96px;
`}
`;

const Name = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-family: Inter;
  color: #8a94a6;
`;

const Data = styled.div`
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: #0a1f44;
`;

const PolicyDetail = (props) => {

    const getPOI = () => {
        return props.policy.origPolicyPeriod;
    }

    return (
        <>
            {props.noLogin ? (
                <>
                    <Hidden smUp>
                        <MWrapper>
                            <MRow>
                                <MKey>{props.cfg("policy.heading.insured")}</MKey>
                                <MValue>
                                    {props.policy.insuredNames?.map(item => (
                                        <div>{item}</div>
                                    ))}
                                </MValue>
                            </MRow>
                            <MRow>
                                <MKey>{props.cfg("policy.heading.policyType")}</MKey>
                                <MValue>{props.policy.policyType}</MValue>
                            </MRow>
                            <MRow>
                                <MKey>{props.cfg("policy.heading.insuredPeriod")}</MKey>
                                <MValue>
                                    {getPOI()}
                                </MValue>
                            </MRow>
                        </MWrapper>
                    </Hidden>

                    <Hidden smDown>
                        <Wrapper>
                            <Insured>
                                <Name>{props.cfg("policy.heading.insured")}</Name>
                                <Data>
                                    {props.policy.insuredNames?.map(item => (
                                        <div>{item}</div>
                                    ))}
                                </Data>
                            </Insured>
                            <Period>
                                <Name>{props.cfg("policy.heading.policyType")}</Name>
                                <Data>{props.policy.policyType}</Data>
                            </Period>
                            <Premium>
                                <Name>{props.cfg("policy.heading.insuredPeriod")}</Name>
                                <Data>
                                    {getPOI()}
                                </Data>
                            </Premium>
                        </Wrapper>
                    </Hidden>
                </>
            ) : (
                <>
                    <Hidden smUp>
                        <MWrapper>
                            <MRow>
                                <MKey>{props.cfg("policy.heading.insured")}</MKey>
                                <MValue>
                                    {props.policy.insuredNames?.map((item, index) => (
                                        <div key={item + index}>{item}</div>
                                    ))}
                                </MValue>
                            </MRow>
                            <MRow>
                                <MKey>{props.cfg("policy.heading.insuredPeriod")}</MKey>
                                <MValue>
                                    {getPOI()}
                                </MValue>
                            </MRow>
                            <MRow>
                                <MKey>{props.cfg("policy.heading.totalPremium")}</MKey>
                                <MValue>{props.policy.country === 'MYS' ? props.policy.price.replace('$', 'MYR ') : props.policy.price}</MValue> 
                            </MRow>
                        </MWrapper>
                    </Hidden>

                    <Hidden smDown>
                        <Wrapper>
                            <Insured>
                                <Name>{props.cfg("policy.heading.insured")}</Name>
                                <Data>
                                    {props.policy.insuredNames?.map((item, index) => {
                                        return (
                                            <div key={item + index}>{item}</div>
                                        )
                                    })}
                                </Data>
                            </Insured>
                            <Period>
                                <Name>{props.cfg("policy.heading.insuredPeriod")}</Name>
                                <Data>
                                    {getPOI()}
                                </Data>
                            </Period>
                            <Premium>
                                <Name>{props.cfg("policy.heading.totalPremium")}</Name>
                                <Data>{props.policy.country === 'MYS' ? props.policy.price.replace('$', 'MYR ') : props.policy.price}</Data> 
                            </Premium>
                        </Wrapper>
                    </Hidden>
                </>
            )}
        </>
    );
};

export default PolicyDetail;
