// QBE default theme

import color from './color';
import typography from './typography';
import image from './image';

const qbeTheme = {
    color,
    typography,
    image,
};

export default qbeTheme;
