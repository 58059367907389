import React, {useState} from 'react';
import useConfig from "../qnect-ui/qnect-config";
import '../../assets/styles/auto-renewal.css';
import CollapseArrow from '../../assets/Arrow.svg';
import {
    _getLang,
} from "../../store/system/service";
import {connect} from "react-redux";

const AutoRenewalSectionToggleTOS = (props) => {
    const cfg = useConfig(props.lang);

    const [toggleArrow, setToggleArrow] = useState(Array(1).fill().map(() => Array(1).fill(false)));
    const toggledContent = useState(Array(1).fill().map(() => Array(1).fill(0)));

    const showToggledContent = (contentRow) => {
        toggleArrow[0][contentRow - 1] = !toggleArrow[0][contentRow - 1];
        setToggleArrow(state => [...state, toggleArrow])
        toggledContent[0][contentRow - 1] = (toggleArrow[0][contentRow - 1]) ? 1 : 0;
    }

    const toggleArrowAction = (rowNum, colNum) => {
        return (
            <div className="Toggler" onClick={() => showToggledContent(colNum + 1)}>
                <div>{ cfg('labels.autoRenewal.serviceTermsConditions') }</div>
                <div>
                    <img src={CollapseArrow}
                         className={toggleArrow[rowNum][colNum] && toggledContent[rowNum][colNum] === 1 ? 'FlipArrow' : ''}
                         alt=""/>
                </div>
            </div>);
    }

    return (
        <div className="TOS-list">
            { toggleArrowAction(0, 0) }
            {(toggleArrow[0][0] && toggledContent[0][0]) &&
            <div>
                <div dangerouslySetInnerHTML={{__html:  cfg("labels.autoRenewal.serviceTermsConditionsList")}}></div>
            </div>
            }

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps, null)(AutoRenewalSectionToggleTOS);