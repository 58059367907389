import React, {useState} from "react";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../../assets/login/icon_logo.svg";
import { ReactComponent as Back } from "../../assets/login/back_sharp.svg";
import PropTypes from "prop-types";
import {_clearToken, _getCountry, _getLang, _getPortfolioToken, _isNoLogin, _setLang} from "../../store/system/service";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import BlankImage from "../../assets/profile/avatar_image_placeholder.svg";
import {_getUser, _resetUser} from "../../store/user/service";
import useConfig from "../qnect-ui/qnect-config";
import {_getPolicy, _resetPolicy, _storePolicy} from "../../store/policy/service";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import LogoutDialog from "./profile/logout-dialog";
import * as TagManager from "react-gtm-module";


const StyledMenu = withStyles({
  paper: {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    width: "160px",
    marginTop: "10px",
    "& .MuiList-padding": {
      padding: 0
    }
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    height: "64px",
    borderRadius: "10px",
    "&:focus": {
      backgroundColor: "#fff"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            paddingRight: "12px",
            height: "44px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            paddingRight: "24px",
            height: "72px"
        },
        [theme.breakpoints.down("md")]: {
            backgroundColor: "#fff",
            borderBottom: "1px solid #e1e4e8"
        },
        [theme.breakpoints.up("lg")]: {
            height: "72px"
        }
    },
    back_wrapper: {
        cursor: "pointer",
        zIndex: 3,
        [theme.breakpoints.down("sm")]: {
            width: "51px",
            height: "42px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "76px",
            height: "72px"
        },
        [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        [theme.breakpoints.up("lg")]: {}
    },
    back_icon: {
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "36px",
            height: "36px"
        }
    },
    title: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            height: "43px",
            lineHeight: "43px",
            fontSize: "18px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: "71px",
            lineHeight: "71px",
            fontSize: "24px"
        },
        [theme.breakpoints.down("md")]: {
            color: "#003da5",
            fontFamily: "Stag",
            fontWeight: "500",
            letterSpacing: "1px"
        },
        [theme.breakpoints.up("lg")]: {}
    },
    right_part: {
        display: "flex",
        alignItems: "center",
        zIndex: 3
    },
    language_wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        color: "#1D4FBB",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.down("sm")]: {
            height: "24px",
            width: "24px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: "32px",
            width: "32px"
        }
    },
    profile: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "16px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginLeft: "30px"
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "40px"
        }
    },
    avatar: {
        display: "block",
        borderRadius: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up("sm")]: {
            width: "40px",
            height: "40px"
        }
    },
  name: {
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "6px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "50px"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "12px",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "24px"
    }
  },
  logo: {
    display: "flex",
    cursor: "pointer"
  }
}));

const Header = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const cfg = useConfig(props.lang);

  const [anchorEl, setAnchorEl] = useState(null);
  const [lang, setLang] = useState(props.lang);
  const [logoutOpen, setLogoutOpen] = useState(false);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as '+newLang);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Change-Language'
            }
        });
    }

    const handleBackToPortfolioPage = () => {
        if (!props.noLogin) {
            props.policy.policyNumber = '';
            props.storePolicy(props.policy);
            history.push('/portfolio');
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Icon-Click',
                    eventCategory: 'Back-Arrow'
                }
            })
        }
    }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleClose();

    TagManager.dataLayer({
      dataLayer: {
        event: 'User-Click',
        eventCategory: 'My-Profile'
      }
    });

    history.push("/profile");
  };

  const handleLogout = () => {
    handleClose();

    TagManager.dataLayer({
      dataLayer: {
        event: 'User-Click',
        eventCategory: 'Logout'
      }
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'popup',
        popupPath: props.pathname,
        popupTitle: 'Signing Out'
        }
    });

    setLogoutOpen(true);
  };

  const logout = () => {
    setLogoutOpen(false);
    props.clearToken();
    props.resetPolicy();
    props.resetUser();
    history.push("/login");
  }

  const mobileTabletHeader = () => {
      switch (props.pathname) {
          case '/policy':
              return 'Policy Details';
          case '/portfolio':
              return 'My Profile';
          case '/auto-renewal/manage-auto-renewal':
              return 'Manage Auto-renewal';
          case '/renewal-notice':
              return 'Renewal Details'
          case '/payment-detail':
              return 'Payment Details'
          default:
              return '';
      }
  }

  return (
    <>
      <div id="mainHeader" className={classes.wrapper + " main-header"}>
        <Hidden lgUp>
          <div
            className={classes.back_wrapper}
            onClick={() => {
              handleBackToPortfolioPage();
            }}
          >
            <Back className={classes.back_icon} />
          </div>
          <div className={classes.title}>{mobileTabletHeader()}</div>
        </Hidden>
        <Hidden mdDown>
          <a className={classes.logo}>
            <Logo onClick={() => {
                if (props.country === 'SGP') {
                    window.open('https://www.qbe.com/sg/about-qbe', '_blank');
                } else if (props.country === 'HKG'){
                    window.open('https://www.qbe.com/hk/en/about-qbe', '_blank');
                }else{
                    window.open('https://www.qbe.com/my/about-qbe', '_self');
                }
            }}/>
          </a>
        </Hidden>

        <div className={classes.right_part}>
          <div className={classes.language_wrapper} onClick={switchLanguage}
               style={{ visibility: ( (props.country === 'SGP' || props.country === 'MYS') ? 'hidden' : 'visible')}}>
            {props.lang === "en"
              ? cfg("labels.lang_zh")
              : cfg("labels.lang_en")}
          </div>
          {props.portfolioToken &&
            <div className={classes.profile} onClick={handleClick}>
                <img className={classes.avatar} src={BlankImage} alt="avatar" />
                <Hidden mdDown>
                    <div className={classes.name}>{props.user.userName}</div>
                </Hidden>
            </div>
          }
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={() => handleProfile()}>
              <ListItemText primary="My Profile" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleLogout()}>
              <ListItemText primary="Log Out" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </div>

      <LogoutDialog
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        onLogout={logout}
      />

    </>
  );
};

const mapStateToProps = (state) => {
    return {
        noLogin: _isNoLogin(state),
        policy: _getPolicy(state),
        lang: _getLang(state),
        user: _getUser(state),
        portfolioToken: _getPortfolioToken(state),
        country: _getCountry(state)
    }
}

const mapDispatchToProps = {
    storePolicy: _storePolicy,
    resetPolicy: _resetPolicy,
    clearToken: _clearToken,
    resetUser: _resetUser,
    setLang: _setLang
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
