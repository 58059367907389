import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    "& button": {
      fontWeight: "500",
      fontFamily: "Inter",
      boxShadow: props => props.color === 'secondary' ? "none" : "",

      [theme.breakpoints.down("sm")]: {
        width: "140px",
        height: "56px",
        fontSize: "18px",
        lineHeight: "24px",
        padding: 0
      },
      [theme.breakpoints.up("sm")]: {
        width: "180px",
        fontSize: "20px",
        lineHeight: "28px"
      }
    },
    "&.loginButton": {
        marginTop: "125px"
    }
  }
}));

export default function ButtonWrapper({ children, className, color }) {
  const classes = useStyles({color});

  return <div className={[classes.root, className].join(" ")}>{children}</div>;
}

ButtonWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string
};
