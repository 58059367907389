import React, {useEffect} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../dialog-title";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import ButtonWrapper from "../button-wrapper";
import RadioButton from "../../../ui/components/form-controls/radio-button";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import {ReactComponent as Back} from "../../../assets/login/back_sharp.svg";
import {smDown} from "../../../ui/common";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/core/SvgIcon/SvgIcon";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Divider from "@material-ui/core/Divider";
import WhatsAppMessage from "../whats-app-message";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "none",
        },
        [theme.breakpoints.up("md")]: {
            "& .MuiDialog-paper": {
                padding: "14px 14px 52px",
                width: "596px",
                [theme.breakpoints.up("lg")]: {
                    padding: "14px 38px 52px",
                    width: "720px",
                },
            },
        },
        whiteSpace: "pre-wrap"
    },
    message: {
        textAlign: "center",
        color: "#0a1f44",
        fontFamily: "Inter",
        fontSize: "18px",
        lineHeight: "25px",
        margin: "20px auto auto auto",
        whiteSpace: "pre-wrap",
        [theme.breakpoints.up('sm')]:{
            width: "520px",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
            fontSize: "16px",
            lineHeight: "20px",
            margin: "20px 4px auto",
            whiteSpace: "normal",
        }
    },
    actions: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            margin: "22px auto 0 !important",
        },
        [theme.breakpoints.down("md")]: {
            margin: "35px auto 0",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "8px 62px",
            margin: "26px auto auto",
        },
    },
    actionText: {
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "#003da5",
        fontWeight: 500,
        fontSize: "16px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("md")]: {
            marginTop: "12px",
        },
    },
    choices: {
        marginTop: "25px",
        marginLeft: "95px",
        "& div.optionDiv": {
            display: "flex",
            paddingTop: "19px",
            paddingBottom: "19px",
            fontFamily: "Inter-Medium",
            fontSize: "16px"
        },
        "& div.optionDiv :first-child": {
            marginRight: "14px"
        },
        [theme.breakpoints.down('sm')]:{
            marginLeft: "0px",
        }
    },
    selected: {
        color: "#003DA5"
    }
}));

const StyledBack = styled(Back)`
    width: 28px;
    height: 28px;
    margin-right: 16px;
    vertical-align: sub;
    cursor: pointer;
    ${smDown`
        padding-top: 0;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-left: -24px;
    `}
`;

const StyledDialogContent = withStyles((theme) => ({
    root: {
        width: "429px",
        margin: "40px auto auto auto",
        padding: 0,
        overflowY: "unset",
        [theme.breakpoints.down("md")]: {
            marginTop: '14px !important',
            marginBottom: '0 !important'
        },
        [theme.breakpoints.down("sm")]: {
            width: "auto",
            margin: '4px 0 0 0'
        },
    },
}))(DialogContent);

const StyledDialogContentNext = withStyles((theme) => ({
    root: {
        marginTop: "30px",
        marginBottom: "60px",
        [theme.breakpoints.down("md")]: {
            marginBottom: "60px !important",
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "20px !important",
            marginTop: "4px !important",
        },
    },
}))(StyledDialogContent);

const StyledMessage = styled.div`
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #0a1f44;
    font-family: "Inter";
    ${smDown`
        font-size: 16px;
        line-height: 20px;
    `}
    
    span {
        letter-spacing: 0.05px;
    }
`;

const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 44px;
    align-items: center;
`;

const StyledIconDescription = styled.div`
    font-weight: 500;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    ${smDown`
        font-size: 16px;
        line-height: 20px;
    `}
    & > :first-child {
        padding-bottom: 30px;
        ${smDown`
            padding-bottom: 20px;
        `}
    }
`;

const StyledIconRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    & > :first-child {
        padding-right: 4px;
    }
`;

const StyledDialog = withStyles({
    paper: {
        width: "630px",
        padding: "14px 29px 0 30px",
    },
    paperWidthSm: {
        maxWidth: "none",
    },
})(Dialog);

const StyledDivider = styled(Divider)`
    margin: 10px 62px 0 !important;
    @media (max-width:767.95px){
        margin: 10px 0 0 !important;
    }
`;

const StyledDivHelpMessage = styled.div`
    margin: 20px auto 0;
    font-family: Inter-Regular;
    font-size: 16px;
    color: #8A94A6;
    line-height: 24px;
    width: 430px;
    
    div.subMessage {
        margin-top: 30px;
        span.contactUs {
            color: #003DA5;
            cursor: pointer;
            font-weight: 600;
        }
    }
    @media (max-width:767.95px){
        width: auto;
        div.subMessage {
            margin-top: 8px;
            span.contactUs {
                display: block;
            }
        }
    }
`;

const AuthStepChooseEmailMobile = ({open, onClose, authMethod, setAuthMethod, onConfirm, email, sms, cfg, width, lang, country}) => {
    const classes = useStyles();
    const [showHelp, setShowHelp] = React.useState(false);

    useEffect(() => {
        if (open) {
            setAuthMethod(null);
        }
    }, [open])

    const configHelpMessage = () => {
        if (email && sms) {
            return cfg('authStepChooseEmailMobile.helpMessageEmailAndMobile'+country);
        } else if (email && !sms) {
            return cfg('authStepChooseEmailMobile.helpMessageEmailOnly'+country);
        } else if (!email && sms) {
            return cfg('authStepChooseEmailMobile.helpMessageSMSOnly'+country);
        }
    }

    return (
        <>
            { !showHelp ?
                <>
                    <Dialog className={classes.dialog} open={open} onClose={onClose} onRequestClose={() => false}>
                        <DialogTitle title={cfg('authStepChooseEmailMobile.title')} onClose={onClose} lang={lang}/>
                        <DialogContent className="auth-complete-registration">
                            <div className={classes.message}>{cfg('authStepChooseEmailMobile.message')}</div>
                            <div className={classes.choices}>
                                { email &&
                                <div className={["optionDiv"]}>
                                    <RadioButton
                                        onChange={(event) => setAuthMethod(event.target.value)}
                                        value="email"
                                        name="selectAuthMethod"
                                        id="item1"
                                        checked={authMethod === 'email'}
                                    />
                                    <label htmlFor="item1" className={ authMethod === 'email' ? classes.selected : null}>
                                        <div>
                                            { (lang === 'zh' ? "透過電郵 " : "Via Email ") + "(" + email + ")"}
                                        </div>
                                    </label>
                                </div>
                                }
                                {sms &&
                                <div className={["optionDiv"]}>
                                    <RadioButton
                                        onChange={(event) => setAuthMethod(event.target.value)}
                                        value="sms"
                                        name="selectAuthMethod"
                                        id="item2"
                                        checked={authMethod === 'sms'}
                                    />
                                    <label htmlFor="item2" className={ authMethod === 'sms' ? classes.selected : null}>
                                        <div>
                                            { (lang === 'zh' ? "透過短訊 " : "Via SMS ") + "(" + sms + ")"}
                                        </div>
                                    </label>
                                </div>
                                }
                            </div>
                        </DialogContent>
                        <StyledDivider/>
                        <StyledDivHelpMessage>
                            { configHelpMessage() }
                            <br/>
                            <WhatsAppMessage country={country}/>
                            <div className="subMessage">
                                {cfg('authStepChooseEmailMobile.alternatively')}
                                <span
                                    className="contactUs"
                                    onClick={() => {
                                        if(country === 'MYS') {
                                            window.open(process.env.REACT_APP_ONLINE_ENQUIRY_MY, '_blank');
                                        }else{
                                            if (lang === 'zh') {
                                                window.open(process.env.REACT_APP_ONLINE_ENQUIRY_ZH, '_blank');
                                            } else {
                                                window.open(process.env.REACT_APP_ONLINE_ENQUIRY, '_blank');
                                            }
                                        }
                                    }}>
                                    {cfg('authStepChooseEmailMobile.contactUs')}
                                </span>
                            </div>
                        </StyledDivHelpMessage>
                        <DialogActions className={classes.actions}>
                            <ButtonWrapper>
                                <QnectButton
                                    field="confirm"
                                    disabled={!authMethod ? true : false}
                                    onClick={onConfirm}></QnectButton>
                            </ButtonWrapper>
                        </DialogActions>
                    </Dialog>
                </> :
                <>
                    <StyledDialog open={open} onClose={onClose}>
                        <DialogTitle
                            title={
                                <>
                                    <StyledBack onClick={() => setShowHelp(false)}/>
                                    Need Help?
                                </>
                            }
                            onClose={onClose}
                            closable={width === "lg" || width === "xl" ? true : false}
                        />
                        <StyledDialogContentNext>
                            <StyledMessage>Are you having trouble finding your policy?<br/>Please contact QBE Hong Kong's
                                Customer Service.</StyledMessage>
                            <StyledIconContainer>
                                <StyledIconDescription>
                                    <StyledIconRow>
                                        <PhoneIcon/> +852 2828 3138
                                    </StyledIconRow>
                                    <StyledIconRow>
                                        <MailOutlineIcon/> customerservice@qbe.com
                                    </StyledIconRow>
                                </StyledIconDescription>
                            </StyledIconContainer>
                        </StyledDialogContentNext>
                    </StyledDialog>
                </>
            }
        </>
    );
}

export default withWidth()(AuthStepChooseEmailMobile);
