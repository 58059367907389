import React, {useEffect, useState, useRef} from "react";
import {useHistory, withRouter} from "react-router-dom";
import QnectTextField from "../qnect-ui/qnect-text-field";
import TabPanel from "../custom/tab-panel";
import VerificationCodeDialog from "../custom/login/verification-code-dialog";
import TooManyFailedAttemptsDialog from "../custom/login/too-many-failed-attempts-dialog";
import {ReactComponent as Logo} from "../../assets/login/icon_logo.svg";

import {
    _clearToken,
    _generatePortfolioToken,
    _getCountry,
    _getFindMyPolicyBusinessState,
    _getLang,
    _getShowOtpModal,
    _hideModal,
    _hideSpinner,
    _isNoLogin, _setAdminLogin, _setCpToken,
    _setLang,
    _setOtpToken,
    _setPortfolioToken,
    _setShowOtpModal,
    _showModal,
    _showSpinner,
    _verifyOtpAndGetUserDetail,
    _getSystemAvailable, _getScheduledMaintenance
} from "../../store/system/service";

import {connect} from "react-redux";
import {
    _getPolicy, _resetPolicy,
    _storePolicy,
    _verifyPolicy
} from "../../store/policy/service";
import useConfig from "../qnect-ui/qnect-config";
import useLoginStyles, {StyledModalDiv} from "../login-style";
import {_validateForm} from "../../utils/utility";

import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import StyledTextFieldWrapper from "../custom/style-text-field-wrapper";
import Hidden from "@material-ui/core/Hidden";
import QbeLogo from "../../assets/QBE logo on light.png";
import TopNotice from '../custom/top-notice';
import {
    _getUser,
    _storeUser,
    _addEmailAddressToOTPLockedCache,
    _checkEmailIfRegistered,
    _sendRegisteredEmailToSMS,
    _sendRegisteredMobileNumberToEmail,
    _resetUser,
    _sendResetPasswordLink,
    _verifyAdminUserCredentials
} from "../../store/user/service";
import ForgotPasswordDialog from "../custom/login/forgot-password-dialog";
import ForgetEmailContactDialog from "../custom/login/forget-email-contact-dialog";
import SystemUnavailableBanner from "../custom/system-unavailable-banner";
import ScheduledMaintenanceBanner from "../custom/scheduled-maintenance-banner";

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
    };
}

const tabEnum = {
    memberLogin: {
        name: 'login.memberLogin',
        value: "M",
    },
    findMyPolicy: {
        name: 'login.findMyPolicy',
        value: "F",
    },
    individual: {
        name: 'login.individual',
        value: "I",
    },
    business: {
        name: 'login.business',
        value: "B",
    },
};

const AdminLogin = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const formId = 'login-form';
    const classes = useLoginStyles(props);
    const ref = useRef(null);

    const [lang, setLang] = useState(props.lang);
    const [outerTab, setOuterTab] = useState(props.noLogin ? tabEnum.findMyPolicy.value : tabEnum.memberLogin.value);
    const [showHelp, setShowHelp] = useState(false);

    const [emailAddress, setEmailAddress] = useState("");
    const [adminEmailAddress, setAdminEmailAddress] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [adminEmailErrorMessage, setAdminEmailErrorMessage] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [resendTimer, setResendTimer] = useState(0);
    const [resendTimerIntervalId, setResendTimerIntervalId] = useState(null);

    const [verifyOpen, setVerifyOpen] = useState(false);
    const [failedOpen, setFailedOpen] = useState(false);

    const [password, setPassword] = useState();
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState("");


    useEffect(() => {
        props.clearToken();
        props.resetPolicy();
        props.resetUser();
        if (props.findMyPolicyBusinessState) {
            setOuterTab(tabEnum.findMyPolicy.value);
            props.setFindMyPolicyBusinessState(false);
        }
    }, []);

    const addEmailErrorToCache = () => {
        if (emailAddress || props.user?.email) {
            let email = emailAddress || props.user.email;
            props.addEmailAddressToOTPLockedCache(email).then(
                response => {
                    if (response > 5) {
                        setVerifyOpen(false);
                        setFailedOpen(true);
                    }
                }
            )
        }
    }

    const addEmailToPasswordLockCache = () => {
        if (emailAddress || props.user?.email) {
            let email = emailAddress || props.user.email;
            props.addEmailAddressToPasswordLockedCache(email).then(
                response => {
                    if (response > 5) {
                        setFailedOpen(true);
                    }
                }
            )
        }
    }

    const resetResendTimer = () => {
        if (resendTimerIntervalId) {
            clearInterval(resendTimerIntervalId);
        }
        setResendTimer(60);
        let setTimerInterval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(setTimerInterval);
                    return 0;
                }
            });
        }, 1000);
        setResendTimerIntervalId(setTimerInterval);
    }

    function handleLogin() {
        if (_validateForm(formId)) {
            props.showSpinner();
            setOtpValue("");
            setOtpErrorMessage('');
            setEmailErrorMessage("");
            setAdminEmailErrorMessage("");
            setPasswordErrorMessage('');
            props.verifyAdminUserCredentials(adminEmailAddress, password, emailAddress).then((response) => {
                if (response.success) {
                    if (!response.lockType) {
                        if (response.isExisting === 'true') {
                            props.setAdminLogin(adminEmailAddress);
                            props.user.email = emailAddress;
                            props.storeUser(props.user);
                            props.setCpToken(response.token);

                            resetResendTimer();
                            setVerifyOpen(true);
                        } else {
                            setAdminEmailErrorMessage(cfg("loginEmailAddress.errorMessages.emailNotFound"));
                            setEmailErrorMessage(cfg("loginEmailAddress.errorMessages.emailNotFound"));
                            setPasswordErrorMessage(cfg("loginPassword.errorMessages.passwordWrong"));
                        }
                        props.hideSpinner();
                    } else {
                        props.showModal({
                            flat: true,
                            content: <StyledModalDiv>
                                <div className="iconDiv">
                                </div>
                                <div className="title">Email Address is Locked</div>
                                <div className="content">Please try again
                                    after {response.lockType === 'otp' ? '10 minutes' : '1 hour'}</div>
                                <div className="buttonDiv">
                                    <QnectButton onClick={() => {
                                        props.hideModal();
                                    }}>Continue</QnectButton>
                                </div>
                            </StyledModalDiv>
                        });
                        props.hideSpinner();
                    }
                }
                else {
                    props.hideSpinner();
                    setAdminEmailErrorMessage(cfg('errorMessage.unknownError'));
                    setEmailErrorMessage(cfg('errorMessage.unknownError'));
                    setPasswordErrorMessage(cfg("errorMessage.unknownError"));
                }
            }, (error) => {
                if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                    setEmailErrorMessage(cfg('errorMessage.serverError'));
                    setPasswordErrorMessage(cfg("errorMessage.serverError"));
                } else {
                    setEmailErrorMessage(error.response.statusText);
                    setPasswordErrorMessage(error.response.statusText);
                }
                props.hideSpinner();
            });
        }
    }

    const handleResend = () => {
        if (resendTimer === 0) {
            props.showSpinner();
            setOtpValue("");
            setOtpErrorMessage('');
            props.checkEmailIfRegistered(emailAddress, props.country).then((response) => {
                if (response.isExisting === 'true') {
                    let otpToken = {
                        iv: response.iv,
                        token: response.token
                    }
                    props.setCpToken(response.token);
                    props.setOtpToken(otpToken);
                }
                props.hideSpinner();
            });
            resetResendTimer();
            addEmailErrorToCache();
        }
    }

    const handleVerifyOtp = () => {
        props.showSpinner();
        props.verifyOtpAndGetUserDetail(otpValue, props.user.email).then((response) => {
            if (response.success) {
                props.setCpToken(response.token);
                props.setPortfolioToken(response.token);
                console.log("redirecting to portfolio");
                history.push('/portfolio');
            } else {
                setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
                addEmailErrorToCache();
                props.hideSpinner(); // to fix missed commits or merge issues in UAT1 and PROD
            }
        }, (error) => {
            setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
            props.hideSpinner();
        })
    }

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as ' + newLang);
    }

    const handleCloseForgotPasswordDialog = () => {
        setOpenForgotPasswordDialog(false);
    }

    const handleForgotPasswordSubmit = (email) => {
        props.showSpinner();
        setEmailErrorMessage("");
        setAdminEmailErrorMessage("");
        setPasswordErrorMessage('');

        props.sendResetPasswordLink(email)
            .then(() => {
                TopNotice.show({
                    message: cfg("forgotPassword.submitSuccessMessage"),
                    duration: 3,
                });
                setOpenForgotPasswordDialog(false);
                props.hideSpinner();
            }, () => {
                TopNotice.show({
                    message: cfg("forgotPassword.submitSuccessMessage"),
                    duration: 3,
                });
                setOpenForgotPasswordDialog(false);
                props.hideSpinner();
            });
    }

    const hasError = !props.systemAvailable;
    const[scheduledMaintenance, setScheduledMaintenance] = useState(null);
    useEffect(() => {
        props.getScheduledMaintenance(props?.country).then(resp => {
            if (Object.keys(resp.data).length) {
                setScheduledMaintenance(resp.data);
            }
        });
    },[])
    return (
        <>
            <div className={classes.container}>
                { hasError && <SystemUnavailableBanner /> }
                { scheduledMaintenance && <ScheduledMaintenanceBanner value={scheduledMaintenance} country={props.country} /> }
                <div className={props.country === 'SGP' ? classes.bg_sgp : classes.bg}>
                    <Hidden mdDown>
                        <div className={props.country === 'SGP' ? classes.Cbg_img_sg : classes.Cbg_img}/>
                    </Hidden>

                    <Hidden smUp>
                        <div className={classes.Mtop}>
                            <div className={classes.Mheader}>
                                <a href="https://www.qbe.com/hk/en/about-qbe">
                                    <img src={QbeLogo} alt="QbeLogo" className={classes.Mlogo}/>
                                </a>
                                { props.country !== 'SGP' &&
                                    <span
                                        className={classes.Mlanguage}
                                        onClick={() => switchLanguage()}
                                    >
                                          {lang === "en"
                                              ? cfg("labels.lang_zh")
                                              : cfg("labels.lang_en")}
                                    </span>
                                }
                            </div>
                        </div>
                    </Hidden>

                    <div className={classes.right_content}>
                        <Hidden smDown>
                            <div className={classes.icon_wrapper}>
                                <a href="https://www.qbe.com/hk/en/about-qbe">
                                    <Logo/>
                                </a>
                                { props.country !== 'SGP' &&
                                    <div
                                        className={classes.language_select}
                                        onClick={() => switchLanguage()}>
                                        {lang === "en"
                                            ? cfg("labels.lang_zh")
                                            : cfg("labels.lang_en")}
                                    </div>
                                }
                            </div>
                        </Hidden>
                        <div className={classes.Mform} ref={ref}>
                            <div className={classes.subtitle} style={ props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                                {cfg('login.title')}
                            </div>

                            <TabPanel value={outerTab} index={tabEnum.memberLogin.value}>
                                <div className={classes.tab_panel}>
                                    <StyledTextFieldWrapper className={classes.admin_member_input}>
                                        <QnectTextField
                                            field="adminEmailAddress"
                                            value={adminEmailAddress}
                                            errorMessage={adminEmailErrorMessage}
                                            onChange={e => {
                                                setAdminEmailAddress(e.target.value);
                                                setAdminEmailErrorMessage("");
                                            }}
                                            placeholder={ cfg('login.adminEmailAddressPlaceHolder') }
                                        />
                                        <QnectTextField
                                            className="passwordField"
                                            field="loginPassword"
                                            type="password"
                                            value={password}
                                            formId={formId}
                                            errorMessage={passwordErrorMessage}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                                setPasswordErrorMessage('');
                                            }}
                                        />
                                        <QnectTextField
                                            className="passwordField"
                                            field="clientLoginEmailAddress"
                                            value={emailAddress}
                                            errorMessage={emailErrorMessage}
                                            onChange={e => {
                                                setEmailAddress(e.target.value);
                                                setEmailErrorMessage("");
                                            }}
                                            placeholder={ cfg('login.emailAddressPlaceHolder') }
                                        />
                                    </StyledTextFieldWrapper>
                                    <ButtonWrapper
                                        className={classes.button_wrapper + (passwordErrorMessage || emailErrorMessage ? " loginButton" : "")}>
                                        <QnectButton
                                            field="continue"
                                            disabled={!emailAddress}
                                            onClick={handleLogin}
                                        />
                                    </ButtonWrapper>
                                </div>
                            </TabPanel>


                            {/* <Hidden> */}
                                <div className={classes.forgotEmailPassword}>
                                    <span onClick={() => {
                                            setOpenForgotPasswordDialog(true);
                                            setShowHelp(false);
                                        }}>
                                        {cfg('login.forgotPassword')}
                                    </span>
                                </div>
                                <div className={[classes.corporateCustomers, classes.PreWrap].join(" ")}>
                                    {cfg('login.corporateCustomersMessage')}
                                </div>
                            {/* </Hidden> */}

                        </div>
                    </div>
                </div>
            </div>

            <VerificationCodeDialog
                open={verifyOpen}
                maskedPhone={adminEmailAddress}
                onClose={() => setVerifyOpen(false)}
                onConfirm={handleVerifyOtp}
                onOther={() => {
                    setVerifyOpen(false);
                }}
                value={otpValue}
                setValue={setOtpValue}
                errorMessage={otpErrorMessage}
                setOtpErrorMessage={setOtpErrorMessage}
                handleResend={handleResend}
                resendTimer={resendTimer}
                cfg={cfg}
                lang={props.lang}
            />

            <ForgotPasswordDialog
                open={openForgotPasswordDialog}
                onClose={handleCloseForgotPasswordDialog}
                showHelp={() => {
                    setOpenForgotPasswordDialog(false);
                    setShowHelp(true);
                }}
                errorMessage={forgotPasswordErrorMessage}
                setErrorMessage={setForgotPasswordErrorMessage}
                onSubmit={handleForgotPasswordSubmit}
                cfg={cfg}
                lang={props.lang}
            />

            <ForgetEmailContactDialog
                open={showHelp}
                onClose={() => setShowHelp(false)}
                onBack={() => {
                    setShowHelp(false);
                    setOpenForgotPasswordDialog(true)
                }}
                cfg={cfg}
                lang={props.lang}
            />

            <TooManyFailedAttemptsDialog
                open={failedOpen}
                onClose={() => setFailedOpen(false)}
                cfg={cfg}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        noLogin: _isNoLogin(state),
        showOtpModal: _getShowOtpModal(state),
        findMyPolicyBusinessState: _getFindMyPolicyBusinessState(state),
        user: _getUser(state),
        policy: _getPolicy(state),
        systemAvailable: _getSystemAvailable(state)
    }
}

const mapDispatchToProps = {
    setLang: _setLang,
    checkEmailIfRegistered: _checkEmailIfRegistered,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    clearToken: _clearToken,
    storePolicy: _storePolicy,
    verifyPolicy: _verifyPolicy,
    setOtpToken: _setOtpToken,
    setCpToken: _setCpToken,
    setAdminLogin: _setAdminLogin,
    verifyOtpAndGetUserDetail: _verifyOtpAndGetUserDetail,
    sendRegisteredEmailToSMS: _sendRegisteredEmailToSMS,
    sendRegisteredMobileNumberToEmail: _sendRegisteredMobileNumberToEmail,
    addEmailAddressToOTPLockedCache: _addEmailAddressToOTPLockedCache,
    storeUser: _storeUser,
    showModal: _showModal,
    hideModal: _hideModal,
    setShowOtpModal: _setShowOtpModal,
    generatePortfolioToken: _generatePortfolioToken,
    setPortfolioToken: _setPortfolioToken,
    resetPolicy: _resetPolicy,
    resetUser: _resetUser,
    verifyAdminUserCredentials: _verifyAdminUserCredentials,
    sendResetPasswordLink: _sendResetPasswordLink,
    getScheduledMaintenance: _getScheduledMaintenance
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLogin));
