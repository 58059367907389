import React, {Component} from 'react';
import AdhocPayment from "../components/adhoc-payment/adhoc-payment";

const AdhocPaymentPage = () => {
    return (
        <div>
            <AdhocPayment/>
        </div>
    )
}

export default AdhocPaymentPage;
