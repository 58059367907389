import React, {useState} from "react";
import styled from "styled-components";
import QbeLogo from "../../../assets/QBE logo on light.png";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import BlankImage from "../../../assets/profile/avatar_image_placeholder.svg"
import {_clearToken, _getCountry, _getLang, _setLang} from "../../../store/system/service";
import {_resetPolicy} from "../../../store/policy/service";
import {_resetUser} from "../../../store/user/service";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LogoutDialog from "../profile/logout-dialog";
import ListItemText from "@material-ui/core/ListItemText";
import * as TagManager from "react-gtm-module";

const Name = styled.div`
  margin-left: 12px;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

const StyledMenu = withStyles({
  paper: {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    width: "160px",
    marginTop: "10px",
    "& .MuiList-padding": {
      padding: 0
    }
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    height: "64px",
    borderRadius: "10px",
    "&:focus": {
      backgroundColor: "#fff"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "72px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "44px",
    },
  },
  left: {
    display: "flex",
    cursor: "pointer"
  },
  logo: {
    width: "122px",
    margin: "18.47px 0 18px",
    cursor: "pointer",
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: "24px",
    },
    [theme.breakpoints.down("md")]: {
      width: "70px",
      height: "20px",
      marginLeft: "12px"
    }
  },
  menu: {
    margin: "21px 20px 18px 16px",
    cursor: "pointer",
  },
  lang: {
    fontWeight: "bold",
    fontSize: "21px",
    color: "#ffffff",
    height: "32px",
    width: "32px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "24px",
      width: "12.82px",
      fontSize: "16px",
    },
  },
  profile: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "16px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: "30px"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "40px"
    }
  },
  avatar: {
    display: "block",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    cursor: "pointer",
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginLeft: "30px",
    // },
    [theme.breakpoints.down("sm")]: {
      margin: "0 12px 0 21.18px",
      width: "24px",
      height: "24px",
      borderRadius: "12px",
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "22px",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [lang, setLang] = useState(props.lang);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const homeIconlink = () => {
      if(props.country == "MYS") {
          window.open("https://www.qbe.com/my/about-qbe",'_self', "noopener");
      } else if(props.country == "SGP") {
          window.open("https://www.qbe.com/sg/about-qbe",'_blank', "noopener");
      } else {
          window.open("https://www.qbe.com/hk/about-qbe","_blank", "noopener");
      }
  }

  const switchLanguage = () => {
    let newLang = lang === 'en' ? 'zh' : 'en';
    setLang(newLang);
    props.setLang(newLang);
    console.log('setting language as '+newLang);

    TagManager.dataLayer({
      dataLayer: {
        event: 'Icon-Click',
        eventCategory: 'Change-Language'
      }
    });
  }

  const handleBackToPortfolioPage = () => {
      props.resetPolicy();
      history.push('/portfolio');
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleClose();
    history.push("/profile");
    TagManager.dataLayer({
      dataLayer: {
        event: 'User-Click',
        eventCategory: 'My-Profile'
      }
    });
  };

  const handleLogout = () => {
    handleClose();
    setLogoutOpen(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'User-Click',
        eventCategory: 'Logout'
      }
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'popup',
        popupPath: '/portfolio',
        popupTitle: 'Signing Out'
      }
    });
  };

  const logout = () => {
    setLogoutOpen(false);
    props.clearToken();
    props.resetPolicy();
    props.resetUser();
    history.push("/login");
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.left}>
          <a onClick={homeIconlink}>
          <img className={classes.logo} src={QbeLogo} alt="QbeLogo" />
          </a>
        </div>
        <div className={classes.right}>
          <div className={classes.lang} onClick={switchLanguage}
               style={{ visibility: (props.country === 'SGP' || props.country === "MYS" ? 'hidden' : 'visible')}}>
            {props.lang === "en"
                  ? props.cfg("labels.lang_zh")
                  : props.cfg("labels.lang_en")
            }
          </div>
          <div className={classes.profile} onClick={handleClick}>
            <img className={classes.avatar} src={BlankImage} alt="avatar" />
            <Hidden smDown>
              <Name>{props.name}</Name>
            </Hidden>
          </div>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={() => handleProfile()}>
              <ListItemText primary={props.cfg("profilePage.myProfile")} />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleLogout()}>
              <ListItemText primary={props.cfg("profilePage.logOut")} />
            </StyledMenuItem>
          </StyledMenu>
        </div>

      </div>

      <LogoutDialog
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        onLogout={logout}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: _getLang(state),
    country: _getCountry(state)
  }
}

const mapDispatchToProps = {
  resetPolicy: _resetPolicy,
  resetUser: _resetUser,
  clearToken: _clearToken,
  setLang: _setLang
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
