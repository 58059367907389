import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import Paragraph from '../../typography/paragraph';
import typographyStyles from '../../../common/typography-styles';
import {textFieldType} from '../../../common/common-prop-types';
import CheckCard from '../../form-controls/check-card';
import TextField from '../../form-fields/text-field';

const Heading = styled(Paragraph)`
    margin: 24px 0 16px;
    text-align: center;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultColor: 'tertiary90',
    })}
`;

const StyledCheckCard = styled(CheckCard)`
    margin-top: 28px;
`;

export default function AttendantDetails({
    officialType,
    officialDetails,
    detailsHeading,
}) {
    const isOfficialDetailsProvided = !!officialType.checked && !!officialDetails;

    return (
        <>
            <StyledCheckCard {...officialType} />

            {isOfficialDetailsProvided && (
                <>
                    <Heading>{detailsHeading}</Heading>

                    <TextField {...officialDetails} />
                </>
            )}
        </>

    );
}

AttendantDetails.propTypes = {
    officialType: propTypes.shape({
        className: propTypes.string,
        disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        label: propTypes.node,
        checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        onChange: propTypes.func,
    }).isRequired,
    officialDetails: textFieldType,
    detailsHeading: propTypes.string,
};
