import React, { useState } from 'react';
import {_hideSpinner, _showError} from '../../../store/system/service';
import {
    _adhocCheckQrCodePaymentComplete,
    _adhocFailPayment,
    _getPolicyPayment,
    _storePolicyPayment
} from '../../../store/policy-payment/service';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {_resetPolicy} from '../../../store/policy/service';

const CheckPaymentComplete = (props) => {
    const {txnRef}  = props;
    const [success, setSuccess] = useState(false);
    const [retryCount, setRetryCount] = useState(0); // flag to keep on checking with 10 secs interval

    const handleCheckQrCodePayment = () => {
        console.log("I am checking payment....");
        props.checkQrCodePayment(txnRef).then(resp => {
            if (resp.success) {
                console.log("trans: " + txnRef + " payment received...");
                props.hideSpinner();
                setSuccess(true);
            } else if (resp.error) {
                props.hideSpinner();
                props.showError([resp.message]);

                props.failPayment(props.policyPayment).then(resp => {
                    console.log(resp);
                }, errorMessage => {
                    console.error(errorMessage);
                })
            } else {
                console.log("PayNow QR Code Payment: policy is still not issued");
                setRetryCount(retryCount + 1);
            }
        }, (error) => {
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
        });
    }

    setTimeout(() => {
        if (!success) {
            handleCheckQrCodePayment();
        }
    }, 1000); // 1 second

    const showPaymentFinish = () => {
        props.policyPayment.payment = {
            paymentReferenceNumber: txnRef,
            receiptNumber: txnRef,
        };
        props.storePolicyPayment(props.policyPayment);
        props.resetPolicy();
        props.history.push('/payment-finish');
    }

    return <>{
        success && showPaymentFinish()
    }</>
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
    }
};

const mapDispatchToProps = {
    storePolicyPayment: _storePolicyPayment,
    resetPolicy: _resetPolicy,
    checkQrCodePayment: _adhocCheckQrCodePaymentComplete,
    showError: _showError,
    hideSpinner: _hideSpinner,
    failPayment: _adhocFailPayment,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckPaymentComplete));
