import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import typographyStyles from '../../common/typography-styles';

const Container = styled.div`
    background: ${property('theme.color.tertiary')};
    padding: 24px 24px 36px;
    text-align: center;
`;

const Paragraph = styled.p`
    margin: 0;
`;

const Preamble = styled.span`
    display: block;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultWeight: 'regular',
        defaultColor: 'white',
    })}
`;

const PolicyNumber = styled.span`
    display: block;
    margin: 8px 0;

    ${typographyStyles({
        type: 'heading',
        defaultSize: 'medium',
        defaultWeight: 'medium',
        defaultColor: 'white',
    })}
`;

const RiskAddress = styled.p`
    margin: 0;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'semiBold',
        defaultColor: 'white',
    })}
`;

export default function SelectReporterHeader({
    className,
    preamble,
    policyNumber,
    riskAddress,
}) {
    return (
        <Container className={classnames('SelectReporterHeader', className)}>
            <Paragraph>
                {!!preamble && (
                    <Preamble>
                        {preamble}
                    </Preamble>
                )}

                <PolicyNumber>
                    {policyNumber}
                </PolicyNumber>
            </Paragraph>

            {!!riskAddress && (
                <RiskAddress>
                    {riskAddress}
                </RiskAddress>
            )}
        </Container>
    );
}

SelectReporterHeader.propTypes = {
    className: propTypes.string,
    preamble: propTypes.string,
    policyNumber: propTypes.string.isRequired, // doesn't make any sense without a policy number
    riskAddress: propTypes.string,
};
