import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _getPolicyToken,
    _getPortfolioToken,
    _hideModal,
    _hideSpinner,
    _setLang,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import '../../assets/styles/auto-renewal.css';
import {_cancelAutoRenewal} from "../../store/user/service";
import {_getPolicy} from "../../store/policy/service";
import AutoRenewalHeader from "./auto-renewal-header";
import {_sendLog, getProductName} from "../../utils/utility";
import QnectButton from "../qnect-ui/qnect-button";


const AutoRenewalCancel = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);

    useEffect(() => {
        props.hideSpinner()
    }, [])


    const cancelAutoRenewal = () => {
        props.showSpinner();

        let autoRenewalDetails = {
            policyNumber: props.policy.policyNumber,
            country: props.policy.country,
            agentDetails: props.policy.agentDetails,
            customerName: props.policy.clientName,
            customerEmail: props.policy.email,
            productName: props.policy.policyType
        }

        props.cancelAutoRenewal(autoRenewalDetails).then((resp) => {
            history.push("/auto-renewal/manage-auto-renewal/success-cancel");
        }, (error) => {
            console.error(error);
            _sendLog(' auto-renewal-cancel.js - cancelAutoRenewal(): exception:\n\n' + JSON.stringify(error));
            history.push("/auto-renewal/manage-auto-renewal/error-cancel");
        });
    }

    return (
        <>
            <div className="pageContainer auto-renewal detail-page">

                <AutoRenewalHeader titleHeader="labels.autoRenewal.buttonLabelCancelAutoRenewal"
                                   onBackCallBack={() => {
                                       props.showSpinner();
                                       history.push("/auto-renewal/manage-auto-renewal");
                                   }}/>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">

                        <div className="main-content-box">

                            <div className="main-content-title centered-title no-margin">{cfg('policy.policyDetails') }</div>
                            <div className="content-boxed-block lined-table">

                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.policyNumber') }</div>
                                    <div className="content-right">{ props.policy.policyNumber }</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.productName') }</div>
                                    <div className="content-right">{ getProductName(cfg, props.policy.country, props.policy.catalogueCode)}</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.policyHolder') }</div>
                                    <div className="content-right">{ props.policy.clientName }</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.customerEmailAddress') }</div>
                                    <div className="content-right">{ props.policy.email }</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.statusTitle') }</div>
                                    <div className="content-right">{ cfg('labels.autoRenewal.statusOffDisplay') }</div>
                                </div>
                                <section className="manageAutoRenewalDetails-section main-info">
                                    <div className="info-sub-container card-details">
                                        <div className="info-sub-list">
                                            <div className="main-list"
                                                 dangerouslySetInnerHTML={{__html: cfg("labels.autoRenewal.statusOffList")}}/>
                                        </div>
                                    </div>
                                </section>
                                <div className="btn-container">
                                    <QnectButton color="secondary"
                                            className="btn btn-round btn-secondary"
                                            onClick={() => {
                                                props.showSpinner();
                                                history.push("/auto-renewal/manage-auto-renewal");
                                            }}>{cfg('labels.cancel')}</QnectButton>
                                    <QnectButton id="submitUpdateButton"
                                            className="btn btn-round btn-primary"
                                            onClick={cancelAutoRenewal}
                                    >{cfg('labels.submit')}</QnectButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    setLang: _setLang,
    cancelAutoRenewal: _cancelAutoRenewal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoRenewalCancel));

