import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import LinkedIn from '../../assets/icon_linkedin.svg';
import YouTube from '../../assets/icon_youtube.svg';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _getPolicyToken,
    _getPortfolioToken,
    _hideModal,
    _hideSpinner,
    _setLang,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import '../../assets/styles/payment.css';
import {_formatPaymentAmount, _getPolPaymentCurrency, _validateForm} from "../../utils/utility";
import {_getPolicyPayment, _storePolicyPayment} from "../../store/policy-payment/service";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import {_resetPolicy} from "../../store/policy/service";
import Header from "../custom/header";
import * as TagManager from "react-gtm-module";
import AutoRenewalSectionRenewalStatus from "../auto-renewal/auto-renewal-section-status";
import {OPTOUT} from "../auto-renewal/auto-renewal-constants";


const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const BackWrapper = styled.div`
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const BackIcon = styled(Back)`
  margin-right: 8px;
`;

const TopWrapper = styled.div`
    position: relative;
    height: 100%;
    margin: 0 166px 0 166px;
    width: 100%;
    max-width: 1110px;
`;

const AdhocDetail = (props) => {
    const formId = 'adhocDetail';
    const cfg = useConfig(props.lang);

    const [lang, setLang] = useState( props.lang);
    const [productName] = useState (props.policyPayment.productType);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [customerName] = useState (props.policyPayment.applicant?.fullName);
    const [paymentAmount] = useState(props.policyPayment.amount);
    const [emailAddress, setEmailAddress] = useState (props.policyPayment.email);
    const [eligibleForAutoRenewal] = useState (props.policyPayment.eligibleForAutoRenewal);
    const [autoRenewalStatus, setAutoRenewalStatus] = useState (props.policyPayment.autoRenewalStatus);
    const [confirmed, setConfirmed] = useState (false);
    const [callFunction, setCallFunction] = useState("");
    const [isBulkPayment] = useState (props.policyPayment.bulkPayment);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Payment Detail - Payment - ' + productName,
                vPath: '/payment-detail',
                vProduct: productName,
                vPolicyNo: policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                vPaymentAmount: paymentAmount,
                vCountry: props.policyPayment.country
            }
        });
        setConfirmed(false);
    }, []);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as '+newLang);
    }

    const updateAutoRenewalStatus = (autoRenewalStatus) => {
        setAutoRenewalStatus(autoRenewalStatus);
        props.policyPayment.autoRenewalStatus = autoRenewalStatus;
        props.storePolicyPayment(props.policyPayment);
        if(autoRenewalStatus === OPTOUT) {
            setConfirmed(false);
            setCallFunction("");
        }
    }

    const autoRenewalConfirmModal = () => {

        props.showModal({
            disableBackDropClick: true,
            flat: true,
            content:
                <div className="auto-renewal-modal_turn-on">
                    <div className="modal-block">
                        <div className="modal-top">
                            <span className="modal-title">{ cfg('labels.autoRenewal.areYouSure') }</span>
                        </div>
                        <div className="modal-body">
                            <div className="note-top">{ cfg('labels.autoRenewal.confirmationAutoRenewalMsg') }</div>
                        </div>
                        <div className="modal-bottom">
                            <div className="button-container">
                                <QnectButton color="primary" className="btn-big" field="turnOffAutoRenewal" onClick={() => {
                                    updateAutoRenewalStatus(OPTOUT);
                                    setConfirmed(true);
                                    props.hideModal();
                                    props.history.push('/payment-process');
                                }}/>
                                <QnectButton color="primary" className="btn-big" field="turnOnAutoRenewal" onClick={() => {
                                    setCallFunction("showAutoRenewalTurnOnModal")
                                    setConfirmed(true);
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
        });
    }

    const continueNextPage = () => {
        if (_validateForm(formId)) {
            props.policyPayment.email = emailAddress;
            props.storePolicyPayment(props.policyPayment);

            if(eligibleForAutoRenewal && !confirmed && props.policyPayment.autoRenewalStatus === OPTOUT) {
                autoRenewalConfirmModal();
            } else {
                props.history.push('/payment-process');
            }
        }
    }

    const handleBack = () => {
        if (!props.noLogin) {
            if (props.policyToken) {
                props.history.push('/policy');
            }
            else {
                props.history.push('/portfolio');
            }
        }
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }

    return (

        <div className="pageContainer adhoc-detail-page">
            <div className="container">
                {canGoBack() ?
                  <Header pathname={props.history.location.pathname}/>
                  :
                  <div className="topRow">
                      <div className="left">
                          <a href="https://www.qbe.com/hk/en/about-qbe">
                              <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                          </a>
                      </div>
                      <div className="right">
                          <div className="languageSelect" onClick={() => switchLanguage()}
                               style={{ visibility: (props.policyPayment.country === 'HKG' ||
                                   props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                              {(lang === 'en') ?
                                cfg("labels.lang_zh")
                                :
                                cfg("labels.lang_en")
                              }
                          </div>

                      </div>
                  </div>
                }
                <TopWrapper>
                    {canGoBack() &&
                    <BackWrapper onClick={() => handleBack()}>
                        <BackIcon/>
                        Back
                    </BackWrapper>
                    }
                </TopWrapper>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        <div className="main-content-title">{ cfg('labels.payment') }</div>
                        <div className="main-content-box">

                            <div className="form-container">
                                { !isBulkPayment && (<>
                                    <div className="form-field-container">
                                        <InputGroupStyle>
                                            <QnectTextField field="adhocPolicyNumber" value={policyNumber} disabled={true}/>
                                        </InputGroupStyle>
                                    </div>
                                    <div className="form-field-container">
                                        <InputGroupStyle>
                                            <QnectTextField field="productName" value={productName} disabled={true}/>
                                        </InputGroupStyle>
                                    </div>
                                    <div className="form-field-container">
                                        <InputGroupStyle>
                                            <QnectTextField field="customerName" value={customerName} disabled={true}/>
                                        </InputGroupStyle>
                                    </div>
                                </>)
                                }
                                <div className={"form-field-container" + (isBulkPayment ? " bulkPayment-field" : "")}>
                                    <InputGroupStyle>
                                        <QnectTextField field="paymentAmount" value={_formatPaymentAmount(props.policyPayment.country, paymentAmount)} disabled={true}/>
                                    </InputGroupStyle>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField formId={formId} field="emailAddress" value={emailAddress}
                                                        onChange={(event) => setEmailAddress(event.target.value)}/>
                                    </InputGroupStyle>
                                    <div className="form-caption">
                                        <span>{ cfg('labels.confirmWording') }</span>
                                    </div>
                                </div>
                            </div>
                            {
                                eligibleForAutoRenewal &&
                                <AutoRenewalSectionRenewalStatus
                                    updateAutoRenewalStatusCallBack={updateAutoRenewalStatus}
                                    autoRenewalStatus={autoRenewalStatus}
                                    callFunction={callFunction}
                                />
                            }
                            <div className="form-container">
                                <div className="form-field-container button-container button-size1">
                                    <InputGroupStyle>
                                        {(isBulkPayment) ?
                                            <QnectButton field="confirm" onClick={continueNextPage} disabled={!(emailAddress)}/>
                                            : <QnectButton field="confirm" onClick={continueNextPage} disabled={!(policyNumber && emailAddress)}/>
                                        }
                                    </InputGroupStyle>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    resetPolicy: _resetPolicy,
    storePolicyPayment: _storePolicyPayment,
    showError: _showError,
    hideSpinner: _hideSpinner,
    showSpinner: _showSpinner,
    showModal: _showModal,
    hideModal: _hideModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdhocDetail));

