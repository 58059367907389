import React, {useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from 'styled-components';
import {mdDownN} from "../ui/common";
import QnectButton from "../components/qnect-ui/qnect-button";
import QnectTextField from "../components/qnect-ui/qnect-text-field";
import QnectTextAreaField from "../components/qnect-ui/qnect-text-area-field";
import QnectDateField from "../components/qnect-ui/qnect-date-field";
import QnectSelectField from "../components/qnect-ui/qnect-select-field";
import QnectRadioBox from "../components/qnect-ui/qnect-radio-box";
import QnectCheckBox from "../components/qnect-ui/qnect-check-box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as SearchIcon} from "../assets/Search.svg";
import {_getLang, _hideModal, _setLang, _showModal} from "../store/system/service";
import QnectError from "../components/qnect-ui/qnect-erorr";
import {ReactComponent as Diamond} from "../assets/Diamond.svg";


const StyledSearch = styled(SearchIcon)`
  width: 17.49px;
  height: 17.49px;
`;

const SectionStyle = styled.div`
    margin: 0 auto;
    text-align: center;
    border: 1px solid rgb(225, 228, 232);
`;

const InputGroupStyle = styled.div`
    width: 346px;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const DefaultHeadingStyle = styled.div`
    margin-left: auto;
    margin-right: auto;
    color: #0A1F44;
    font-family: Inter-Medium;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    display:inline-block;
    ${mdDownN`
        color: #0A1F44;
        font-family: Inter-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
    `}
`;

const NavigationPage = (props) => {

    const [lang, setLang] = useState( props.lang);

    const switchLanguage = (lang) => {
        setLang(lang);
        props.setLang(lang);
        console.log('setting language as '+lang);
    }

    const showPopup = () => {
        props.showModal({
            icon: Diamond,
            title: 'Popup test',
            content:
                <div>
                    <br/>
                    <div>This is test popup. Please click close button to close this popup</div>
                    <br/><br/>
                </div>,
            primaryAction: {
                label: 'OK',
                onClick: () => {
                    props.hideModal();
                }
            }
        });
    }

    return (<>
        <SectionStyle>
            <DefaultHeadingStyle>
                Navigation
            </DefaultHeadingStyle>
            <InputGroupStyle>
                <QnectButton field="login" onClick={() => {props.history.push("/login")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/registration/policyNumber")}}>
                    Registration - policy number
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/registration/authentication")}}>
                    Registration - authentication
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/registration/personalDetail")}}>
                    Registration - personal detail
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/registration/verify")}}>
                    Registration - verify
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/profile")}}>
                    Profile
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/portfolio")}}>
                    Portfolio
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton
                    onClick={() => {
                        props.history.push("/policy");
                    }}
                >
                    Policy Detail
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/find-my-policy/individual/authentication")}}>
                    Find My Policy - Individual Authentication
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/find-my-policy/company/authentication")}}>
                    Find My Policy - Company Authentication
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton onClick={() => {props.history.push("/find-my-policy/company/online-registration")}}>
                    Find My Policy - Online Registration
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="adhocDetail" onClick={() => {props.history.push("/payment-detail")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="adhocPayment" onClick={() => {props.history.push("/payment-process")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="adhocFinish" onClick={() => {props.history.push("/payment-finish")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="renewalDetail" onClick={() => {props.history.push("/renewal-detail")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="renewalFinish" onClick={() => {props.history.push("/renewal-payment-finish")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="payLaterDetail" onClick={() => {props.history.push("/renew/pay-later-detail")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="payLaterFinish" onClick={() => {props.history.push("/renew/pay-later-finish")}}>
                </QnectButton>
            </InputGroupStyle>
            <InputGroupStyle>
                <QnectButton field="onlinePayment" onClick={() => {props.history.push("/online-payment-detail")}}>
                </QnectButton>
            </InputGroupStyle>
        </SectionStyle>
    </>);
};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    setLang: _setLang
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationPage));
