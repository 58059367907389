import classnames from 'classnames';
import noop from 'lodash/noop';
import property from 'lodash/property';
import propTypes from 'prop-types';
import React, {useMemo, useRef} from 'react';
import styled from 'styled-components';

import {cssIf} from '../../common/conditional-styles';
import useParentBlur from '../../common/use-parent-blur';
import CheckCard from '../form-controls/check-card';
import Paragraph from '../typography/paragraph';

const StyledCheckCard = styled(CheckCard)`
    & + .CheckCard {
        margin-top: 16px;
    }
`;

const HelperText = styled(Paragraph)`
    margin: 0 5px;
    padding-top: 16px;

    ${cssIf('scIsDisabled')`
        color: ${property('theme.color.grey60')};
    `}
`;

const CheckboxesField = React.forwardRef((
    {
        className,
        checkboxesContainer: CheckboxesContainer = 'div',
        checkboxComponent: CheckboxComponent = StyledCheckCard,
        options,
        value = [],
        onChange = noop,
        onBlur,
        helperText,
        errorMessage,
        disabled,
    },
    ref
) => {
    // Avoid linear search on rendering each CheckCard
    const valueSet = useMemo(() => new Set(value), [value]);

    // Fallback ref so we can get onBlur events without an externally provided ref
    const internalRef = useRef(null);

    // Detect focus outside the group of radios
    useParentBlur(ref || internalRef, onBlur);

    return (
        <div className={classnames('CheckboxesField', className)}>
            <CheckboxesContainer ref={ref || internalRef}>
                {options.map((option) => (
                    <CheckboxComponent
                        key={option.value}
                        label={option.text}
                        checked={valueSet.has(option.value)}
                        disabled={disabled || option.disabled}
                        onChange={(event) => {
                            if (event.target.checked) {
                                onChange([...value, option.value]);
                            } else {
                                const index = value.indexOf(option.value);

                                if (index >= 0) {
                                    onChange([
                                        ...value.slice(0, index),
                                        ...value.slice(index + 1),
                                    ]);
                                }
                            }
                        }}
                    />
                ))}
            </CheckboxesContainer>

            {!!(errorMessage || helperText) && (
                <HelperText
                    size="xSmall"
                    color={errorMessage ? 'error' : 'tertiary90'}
                    scIsDisabled={disabled}
                >
                    {errorMessage || helperText}
                </HelperText>
            )}
        </div>
    );
});

CheckboxesField.propTypes = {
    className: propTypes.string,
    checkboxesContainer: propTypes.elementType,
    checkboxComponent: propTypes.elementType,
    options: propTypes.arrayOf(propTypes.shape({
        text: propTypes.string.isRequired,
        value: propTypes.string.isRequired,
        disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    })).isRequired,
    value: propTypes.arrayOf(propTypes.string),
    onChange: propTypes.func,
    onBlur: propTypes.func,
    helperText: propTypes.string,
    errorMessage: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

CheckboxesField.displayName = 'CheckboxesField';

export default CheckboxesField;
