import React, { memo, useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Circle } from "../../../assets/login/CurrentStep.svg";
import Popper from "./popper";

const useStyles = makeStyles(theme => ({
  leftStepper: {
    position: "relative",
    minHeight: "100%",
    backgroundColor: "#f6f8fa",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: "120px",
      width: "244px"
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "140px",
      width: "420px"
    }
  },
  stepLabel: {
    "&.MuiStepLabel-root .MuiStepLabel-label.MuiStepLabel-active, .MuiStepLabel-label.MuiStepLabel-completed": {
      color: "#003DA5",
      "& path": {
        fill: "#003DA5"
      }
    },
    "& .MuiStepLabel-labelContainer": {
      display: "flex",
      justifyContent: "center"
    },
    "& .MuiStepLabel-label": {
      textAlign: "center",
      fontFamily: "Stag",
      color: "#8A94A6",
      [theme.breakpoints.between("sm", "md")]: {
        width: "140px",
        fontSize: 18
      },
      [theme.breakpoints.up("lg")]: {
        width: "160px",
        fontSize: 20
      }
    }
  },
  stepConnector: {
    "&.MuiStepConnector-root": {
      display: "flex",
      justifyContent: "center",
      paddingTop: 31,
      paddingBottom: 31
    },
    "& .MuiStepConnector-lineVertical": {
      height: 60,
      width: 3,
      backgroundColor: "#C1C9EA",
      radius: 2
    },
    "&.MuiStepConnector-vertical": {
      marginLeft: 0
    }
  },
  circleIcon: {
    [theme.breakpoints.between("sm", "md")]: {
      width: "45px",
      height: "45px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "80px",
      height: "80px"
    }
  },
  customIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "999",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    "& svg": {
      width: "auto",
      [theme.breakpoints.between("sm", "md")]: {
        height: "60px"
      },
      [theme.breakpoints.up("lg")]: {
        height: "80px"
      }
    }
  },
  iconWrapper: {
    position: "relative",
    margin: "0 auto",
    display: "flex",
    marginBottom: "10px",
    justifyContent: "flex-end",
    [theme.breakpoints.between("sm", "md")]: {
      width: "88px",
      height: "70px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "140px",
      height: "102px"
    }
  }
}));

const StyledStepper = withStyles({
  root: {
    backgroundColor: "transparent"
  }
})(Stepper);

const LeftStepper = ({ steps = [], activeStep = -1, cfg }) => {
  const outterRef = useRef(null);
  const targetRef = useRef(null);
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  function handleShowPopper() {
    setOpen(open => !open);
  }

  useEffect(() => {
    if (activeStep === 1) {
      const stepDom =
        outterRef.current &&
        outterRef.current.getElementsByClassName("MuiStep-root")[1];
      const labelDom =
        stepDom && stepDom.getElementsByClassName("MuiStepLabel-label")[0];
      targetRef.current = labelDom;
      labelDom && handleShowPopper();
    } else {
      setOpen(false);
    }

    return;
  }, [activeStep]);

  return (
    <>
      <div className={classes.leftStepper}>
        <StyledStepper
          ref={outterRef}
          activeStep={activeStep}
          nonLinear
          orientation="vertical"
          connector={<StepConnector className={classes.stepConnector} />}
        >
          {steps.slice(0, activeStep + 1).map(({ label, icon }, index) => (
            <Step key={label}>
              <StepLabel
                className={classes.stepLabel}
                icon={null}
                onClick={index === 1 ? handleShowPopper : null}
              >
                <div className={classes.iconWrapper}>
                  <div className={classes.customIcon}>{icon}</div>
                  {activeStep === index && (
                    <Circle className={classes.circleIcon} />
                  )}
                </div>
                {cfg(label)}
              </StepLabel>
            </Step>
          ))}
        </StyledStepper>
      </div>

      <Popper
        open={open}
        anchorEl={targetRef.current}
        placement="right"
        arrowDirection="left"
        onOk={handleShowPopper}
      />
    </>
  );
};

LeftStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  props: PropTypes.object
};

export default memo(LeftStepper);
