import React from 'react';
import OnlinePaymentProcess from "../components/online-payment/online-payment-process";

const OnlinePaymentProcessPage = () => {
    return (
        <div>
            <OnlinePaymentProcess/>
        </div>
    )
}

export default OnlinePaymentProcessPage;
