import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import LeftStepper from "../custom/registration/left-stepper";
import PolicyNumberSection from "./policy-number";
import AuthenticationSection from "./authentication";
import PersonalDetailsSection from "./personal-details";
import { ReactComponent as RegistrationIcon } from "../../assets/registration/icon_step_registration.svg";
import { ReactComponent as PolicyNumberIcon } from "../../assets/registration/icon_step_policy_number.svg";
import { ReactComponent as VerificationIcon } from "../../assets/registration/icon_step_verification.svg";
import { smDown, lgDown } from '../../ui/common/breakpoints';
import {_getCountry, _getLang} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  ${smDown`
    min-height: 667px;
  `}
`;

const Container = styled.div`
  padding-top: 115px;
  margin: 0 auto;
  width: 460px;
  ${lgDown`
    padding-top: 143px;
    width: 364px;
  `}
  ${smDown`
    padding-top: 0;
    width: 100%;
  `}
`;

const ContentContainer = styled.div`
  min-height: 880px;
  ${lgDown`
    min-height: auto;
  `}
  ${smDown`
    min-height: initial;
  `}
`;

const IconWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  justify-content: center;
`;

const StyledPolicyNumberIcon = styled(PolicyNumberIcon)`
  ${lgDown`
    height: 51.6px;
    width: 50.4px;
  `}
`

const StyledVerificationIcon = styled(VerificationIcon)`
  ${lgDown`
    height: 54px;
    width: 52.8px;
  `}
`

const StyledRegistrationIcon = styled(RegistrationIcon)`
  ${lgDown`
    height: 54px;
    width: 52.8px;
  `}
`

const Registration = (props) => {
  const cfg = useConfig(props.lang);
  const steps = [cfg("findMyPolicy.authentication"), cfg("labels.personalDetails.title")];


  const stepIcons = [
    <IconWrapper>
      <StyledVerificationIcon />
    </IconWrapper>,
    <IconWrapper>
      <StyledRegistrationIcon />
    </IconWrapper>
  ];


  return (
    <Router>
      <StyledDiv>
        <Hidden smDown>
          <LeftStepper steps={steps} activeStep={props.currentStep} img={stepIcons} country={props.country}/>
        </Hidden>
        <Container>
          <ContentContainer>
            { props.children }
          </ContentContainer>
        </Container>
      </StyledDiv>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: _getLang(state),
    country: _getCountry(state)
  };
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration));
