import React from 'react';
import {connect} from 'react-redux';

import { _getShowSpinner } from '../store/system/service';
import styled from "styled-components";
import {rgba} from "polished";
import {CircularProgress} from "../ui/components/progress";

const SpinnerOverlay = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* stylelint-disable-line scale-unlimited/declaration-strict-value */ /* Stop form labels appearing above overlay */
    background: ${({theme}) => rgba(theme.color.white, 0.8)};
`;

function Spinner ({spinner}) {
    return (
        <React.Fragment>
        {spinner &&
            <SpinnerOverlay>
                <CircularProgress/>
            </SpinnerOverlay>
        }
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        spinner: _getShowSpinner(state),
    };
}

export default connect(mapStateToProps)(Spinner);
