import React, {useState} from 'react';
import {_setLang, _showError, _storePaymentToken} from "../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { _onlinePaymentLoadPolicy, _storePolicyPayment} from "../store/policy-payment/service";
import {_sendLog} from "../utils/utility";
import QBESpinner from "../assets/QBE-spinner.gif";

const OnlinePaymentPage = (props) => {
    console.debug('OnlinePaymentPage: processing online payment');
    const [loading, setLoading] = useState( false );
    let url = props.history.location.search;
    console.log('url', url);
    _sendLog(' online-payment-page.js - url = ' + url, 'info');
    if (url && url.indexOf('&amp;') != -1) {
        url = url.replace('&amp;', '&');
    }
    const params = new URLSearchParams(url);
    let token = params.get('token');
    let iv = params.get('vector');
    console.debug('OnlinePaymentPage: token = ' + token + ', iv=' + iv);
    _sendLog(' online-payment-page.js - token = ' + token + ', iv = ' + iv, 'info');

    if (!loading) {
        setLoading(true);

        if (token && iv) {
            _sendLog(' online-payment-page.js - calling onlinePaymentLoadPolicy()...', 'info');
            props.onlinePaymentLoadPolicy(token, iv).then((resp) => {
                if (resp?.status === 'OK') {
                    _sendLog(['Online Payment: _onlinePaymentLoadPolicy policy loaded ', JSON.stringify(resp?.policy)], 'info');
                    props.storePolicyPayment(resp?.policy);
                    props.setLang('en')
                    props.storePaymentToken(resp?.token, resp?.iv);
                    props.history.push("/online-payment-detail");
                } else {
                    props.showError([resp?.errorMessage]);
                    _sendLog(' online-payment-page.js - onlinePaymentLoadPolicy(): errorMessage = ' + resp?.errorMessage);
                }
            }, (error) => {
                props.showError([error.response?.data]);
                _sendLog(' online-payment-page.js - onlinePaymentLoadPolicy(): exception:\n\n' + JSON.stringify(error));
            });
        }
        else {
            props.showError(['Invalid URL']);
            _sendLog(' online-payment-page.js - Invalid URL', 'info');
        }
    };

    return (
        <>
            <div id="splash-screen">
                <img src={QBESpinner} alt="QBE spinner" />
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    onlinePaymentLoadPolicy: _onlinePaymentLoadPolicy,
    showError: _showError,
    storePolicyPayment: _storePolicyPayment,
    setLang: _setLang,
    storePaymentToken: _storePaymentToken
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlinePaymentPage));
