import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {smUp} from '../../common/breakpoints';
import Button from '../buttons/button';
import IconArrowRight from '../icons/legacy/arrow-right';
import Table from '../tables/table';
import Heading from '../typography/heading';
import Text from '../typography/text';

const Risk = styled.section`
    border: 1px solid ${property('theme.color.grey40')};
    border-radius: 10px;
    background: ${property('theme.color.grey10')};
    padding: 24px;
    overflow: hidden;
    /* Can't seem to style directly */
    > h4 {
        margin: 16px 0 32px;
    }
`;

const StyledHeading = styled(Heading)`
    margin: 0;
`;

const StyledButton = styled(Button)`
    float: right;
    margin: 48px 0 0;
`;

const StyledTable = styled(Table)`
    ${smUp`
        tr {
            &:not(:last-of-type) {
                border-top: 0;
            }
        }
    `}

    /* stylelint-disable */
    :first-of-type:not(:last-of-type) {
        margin-bottom: 18px;

        ${smUp`
            margin-bottom: 32px;
        `}
    }
    /* stylelint-enable */
`;

export default function PolicyRisk({
    className,
    title,
    period,
    riskTypeHeading,
    sumInsuredHeading,
    excessHeading,
    covers = [],
    additionalCoversHeading,
    additionalCovers = [],
    canMakeClaim,
    makeClaimText,
    onClickMakeClaim,
    isSubmitting,
}) {
    return (
        <Risk className={classnames('PolicyRisk', className)}>
            <StyledHeading
                level={3}
                size="medium"
                weight="medium"
                color="primary"
            >
                {title}
            </StyledHeading>
            <Text
                as="h4"
                size="small"
                weight="medium"
                color="tertiary90"
            >
                {period}
            </Text>

            {!!covers.length && (
                <StyledTable
                    columns={[
                        {
                            heading: riskTypeHeading,
                            gridColumn: 1,
                            gridColumnSpan: 2,
                            gridRow: 1,
                            width: '40%',
                            align: 'start',
                        },
                        {
                            heading: sumInsuredHeading,
                            gridColumn: 1,
                            gridRow: 2,
                            width: '20%',
                            align: 'end',
                        },
                        {
                            heading: excessHeading,
                            gridColumn: 1,
                            gridRow: 3,
                            align: 'end',
                        },
                    ]}
                    rows={covers.map(({riskType, sumInsured, excess}) => [
                        (
                            <Text
                                weight="semiBold"
                                color="tertiary90"
                            >
                                {riskType}
                            </Text>
                        ),
                        sumInsured,
                        excess,
                    ])}
                />
            )}

            {!!additionalCovers.length && (
                <StyledTable
                    columns={[
                        {
                            heading: additionalCoversHeading,
                            gridColumn: 1,
                            gridRow: 1,
                            width: '40%',
                            align: 'start',
                        },
                        {
                            heading: '',
                            gridColumn: 1,
                            gridRow: 2,
                            width: '60%',
                            align: 'start',
                        },
                    ]}
                    rows={additionalCovers.map(({riskType, value}) => [
                        (
                            <Text
                                weight="semiBold"
                                color="tertiary90"
                            >
                                {riskType}
                            </Text>
                        ),
                        value,
                    ])}
                />
            )}

            {!!canMakeClaim && (
                <StyledButton
                    color="secondary"
                    icon={<IconArrowRight />}
                    disabled={isSubmitting}
                    onClick={onClickMakeClaim}
                >
                    {makeClaimText}
                </StyledButton>
            )}
        </Risk>
    );
}

PolicyRisk.propTypes = {
    className: propTypes.string,
    title: propTypes.string,
    period: propTypes.string,
    riskTypeHeading: propTypes.string,
    sumInsuredHeading: propTypes.string,
    excessHeading: propTypes.string,
    covers: propTypes.arrayOf(propTypes.shape({
        riskType: propTypes.string,
        sumInsured: propTypes.string,
        excess: propTypes.string,
    })),
    additionalCoversHeading: propTypes.string,
    additionalCovers: propTypes.arrayOf(propTypes.shape({
        riskType: propTypes.string,
        value: propTypes.node,
    })),
    canMakeClaim: propTypes.bool,
    makeClaimText: propTypes.string,
    onClickMakeClaim: propTypes.func,
    isSubmitting: propTypes.bool,
};
