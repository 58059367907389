import React, { useState } from "react";
import styled from "styled-components";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import QnectTextAreaField from "../qnect-ui/qnect-text-area-field";
import {getDocName} from "../../utils/utility";
import QnectButton from "../qnect-ui/qnect-button";
import QnectTextField from "../qnect-ui/qnect-text-field";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("md")]: {
        margin: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 24px 52px",
        maxWidth: "630px"
      }
    }
  },
  actions: {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
    },
    [theme.breakpoints.up("sm")]: {
      padding: "24px 24px",
      marginTop: "53px"
    }
  }
}));

const StyledMessage = styled.div`
  margin-top: 30px;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  color: #0a1f44;
  font-family: Inter;
  width: 534px;
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
`;

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin: 45px 0 0px;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
    padding-left: 12px;
    padding-right: 12px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  > .BalloonTip {
    top: -16px;
    left: -129px;
  }
  > .BalloonTip .contentDiv {
    top: -150px !important;
    width: 306px !important;
    height: 68px !important;
    border-radius: 2px !important;
    margin-left: 201px !important;
    padding: 4px 12px 4px;
  }
  > div:not(.form-caption) {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 29px;
    right: 20px;
  }
  input[type="text"], textarea {
    background-color : #fff; 
  }
`;

const StyledTextAreaFieldWrapper = styled.div`
  width: 100%;
  margin: 39px 0 28px;
  border-radius: 12px;
  border: 1px solid #c9ced6;
  background-color : #fff;
`;

const StyledFirstRowWrapper = styled(StyledTextFieldWrapper)`
  //   padding-top: 82px;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
`;

const SelectWrapper = styled(StyledTextFieldWrapper)`
  width: 161px;
  margin-bottom: 32px;
  .MuiFormLabel-root {
    padding: 0 !important;
    transform: none !important;
  }
  .MuiInputBase-root .MuiSelect-root {
    padding: 12px 16px 10px 18px;
  }
`;

const ShortTextInputWrapper = styled(StyledTextFieldWrapper)`
  margin-left: 20px;
  margin-bottom: 32px;
  width: 355px;
`;

const StyledPolicyholderField = styled(StyledTextFieldWrapper)`
  margin-top: 69px;
  margin-bottom: 0;
  > .BalloonTip {
    top: -16px !important;
    left: -173px !important;
  }
  > .BalloonTip .contentDiv {
    top: -130px !important;
    width: 334px !important;
    margin-left: 360px !important;
    padding: 4px 12px 4px;
    height: 48px !important;
  }
`;

const SubText = styled.div`
  height: 48px;
  color: #8a94a6;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 8px;
  //   padding-top: ${({ isError }) => (isError ? "43px" : "8px")};
  padding-bottom: 18px;
`;

const StyledNeedHelp = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #003da5;
  cursor: pointer;
  font-family: Inter;
  padding-top: 22px;
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #d54040;
  word-break: keep-all;
  white-space: pre-wrap;
  font-family: "Inter";
  margin-top: -25px;
`;

const PolicyDocumentEmailDialog = ({ open, onClose, onSubmit, tran, cfg }) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const classes = useStyles();

  const sendEmail = () => {
    let emailObj = {
      to: to,
      subject: subject,
      content: content
    }
    onSubmit(emailObj);
  }

  return (
    <div>
      <Dialog className={classes.dialog}  open={open} onClose={onClose}>
        <DialogTitle title="Email" onClose={onClose} />
        <DialogContent>
          <StyledTextFieldWrapper>
            <QnectTextField
                field="policy.documentEmailTo"
                value={to}
                onChange={(e) => setTo(e.target.value)}
            />
          </StyledTextFieldWrapper>

          <StyledTextFieldWrapper>
            <QnectTextField
                field="policy.documentEmailSubject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
            />
          </StyledTextFieldWrapper>

          <StyledTextFieldWrapper>
            <StyledMessage>{ cfg("policy.documentEmailContentLabel") }</StyledMessage>
              <QnectTextAreaField
                  field="policy.documentEmailContent"
                  value={content}
                  rows={5}
                  onChange={(e) => setContent(e.target.value)}
              />
          </StyledTextFieldWrapper>

          <StyledTextFieldWrapper>
            <StyledMessage>{ cfg("policy.attachment") }</StyledMessage>
            <ul>
              {tran?.documentInfoBeans.map((doc, index) => (
                  <li>{getDocName(doc, cfg, "HKG")}</li>
              ))}
            </ul>

          </StyledTextFieldWrapper>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <StyledQnectButton
              field="cancel"
              onClick={() => {
                onClose();
              }}>
          </StyledQnectButton>
          <StyledQnectButton
              field="continue"
              onClick={() => {
                sendEmail();
              }}
              disabled={!to}>
          </StyledQnectButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PolicyDocumentEmailDialog;
