import React, {useState, useEffect} from 'react';
import {_adhocSimulateQrCodePayment} from '../../store/policy-payment/service';
import {
    _showError,
    _showSpinner,
    _hideSpinner,
} from '../../store/system/service';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import styles from './style/qr-code.module.css';

/**
 * Checks whether current environment is from production or not.
 *
 * <br>Note:
 *  * that "process.env.NODE_ENV" will always be equal to "production" whenever command: "npm run build" is used.
 *  * "process.env.REACT_APP_ENV" is defined in this package.json.
 *
 * @returns {boolean} Returns true, iff environment is not of "prod"
 */
const isEnvironmentNonProd = () => {
    return process.env.REACT_APP_ENV !== "prod";
}

const QrCodeSimulatePayment = (props) => {
    const [simulateSuccess, setSimulateSuccess] = useState(false);
    const {txnRef}  = props;

    useEffect(() => {
        if (simulateSuccess) {
            props.showSpinner();
            setTimeout(() => {
                props.hideSpinner();
                setSimulateSuccess(false);
            }, 5000); // 5 seconds to show spinner
        }
    }, [simulateSuccess]);

    const handleSimulateQrCodePayment = () => {
        props.simulateQrCodePayment(txnRef).then(resp => {
            setSimulateSuccess(resp.success);
        }, (error) => {
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
        });
    }

    return (<div> {isEnvironmentNonProd()
        && <button className={styles.buttonToLink}
                   onClick={() => handleSimulateQrCodePayment()}>Simulate Client Payment</button>}</div>
    );
}

const mapDispatchToProps = {
    simulateQrCodePayment: _adhocSimulateQrCodePayment,
    showError: _showError,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
};

export default withRouter(connect(null, mapDispatchToProps)(QrCodeSimulatePayment));
