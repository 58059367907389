import React from "react";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";
import QnectButton from "../../qnect-ui/qnect-button";
import useLoginStyles, {StyledModalDiv, ButtonContainer} from "../../login-style";
import "../../login-sgp-addon-style.css"


const TermsNConditionDialog = ({
  open,
  onPics,
  onTermDetail,
  onClose,
  onAccept,
  cfg,
  ...props
}) => {
  const classes = useLoginStyles(props);

  return (
      <div>
      <Dialog id="TermsConditions" className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
            title={
              <div className="title">
                {cfg('login.modalTCTitle')}
              </div>
            }
            onClose={onClose}
            lang={props.lang}
        />
        <StyledModalDiv className="TermsAndConditions_Modal">
          <div className="content">
            <p>{cfg('login.modalTCContent')}</p>
            <p className="listTitle">{cfg('login.modalTCReadObserve')}</p>
            <ol type="a">
              <li className="terms-link" onClick={onTermDetail}>{cfg('login.modalTCLink1')}</li>
              <li className="terms-link" onClick={() => {
                window.open(process.env.REACT_APP_TERMS_OF_USE_SG, '_blank');
              }}> {cfg('login.modalTCLink2')}</li>
              <li className="terms-link" onClick={onPics}>{cfg('login.modalTCLink3')}</li>
            </ol>
            <p>{cfg('login.modalTCFooterMessage')}</p>
            <ButtonContainer>
              <QnectButton
                  field="agreeTermsAndConditions"
                  color="primary"
                  className="agreeButton"
                  onClick={onAccept}
              >
              </QnectButton>
            </ButtonContainer>
          </div>

        </StyledModalDiv>
      </Dialog>
      </div>
  );
}

TermsNConditionDialog.propTypes = {
  open: PropTypes.bool,
  onPics: PropTypes.func,
  onTermDetail: PropTypes.func,
  onClose: PropTypes.func
};

export default TermsNConditionDialog;
