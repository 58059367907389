import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {cssIf} from '../../common/conditional-styles';
import AlertIcon from '../alerts/alert-icon';
import Actions from '../buttons/actions';
import Paragraph from '../typography/paragraph';
import FormLayout from './form-layout';

const StyledAlertIcon = styled(AlertIcon)`
    margin: 0 auto;
`;

const StyledParagraph = styled(Paragraph)`
    ${cssIf('scIsCentered')`
        text-align: center;
    `}
`;

export default function AlertForm({
    className,
    alertLevel,
    paragraphs = [],
    submitButtonText,
    onSubmit,
    disabled,
    secondaryAction,
    tertiaryAction,
}) {
    const [firstParagraph, ...restParagraphs] = paragraphs;

    return (
        <FormLayout
            className={classnames('AlertForm', className)}
            description={(
                <div>
                    {!!alertLevel && (
                        <StyledAlertIcon alertLevel={alertLevel} />
                    )}

                    {!!firstParagraph && (
                        <StyledParagraph
                            color="tertiary90"
                            size="big"
                            scIsCentered={restParagraphs.length === 0}
                        >
                            {firstParagraph}
                        </StyledParagraph>
                    )}

                    {restParagraphs.map((paragraph, index) => (
                        <Paragraph
                            key={index}
                            color="tertiary90"
                            size="normal"
                        >
                            {paragraph}
                        </Paragraph>
                    ))}
                </div>
            )}
            submission={(
                <Actions
                    primaryAction={{
                        label: submitButtonText,
                        isDisabled: disabled,
                        canSubmit: true,
                    }}
                    secondaryAction={
                        secondaryAction
                            ? {...secondaryAction, isDisabled: secondaryAction.isDisabled || disabled}
                            : undefined
                    }
                    tertiaryAction={
                        tertiaryAction
                            ? {...tertiaryAction, isDisabled: tertiaryAction.isDisabled || disabled}
                            : undefined
                    }
                />
            )}
            onSubmit={onSubmit}
        />
    );
}

AlertForm.propTypes = {
    className: propTypes.string,
    alertLevel: propTypes.oneOf(['info', 'success', 'warning', 'error']),
    paragraphs: propTypes.arrayOf(propTypes.node),
    submitButtonText: propTypes.string.isRequired,
    onSubmit: propTypes.func,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    secondaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    tertiaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
};
