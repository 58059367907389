import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

/**
 * React hook to manage a rendering portal.
 *
 * The portal's lifecycle is the same as the parent element.
 *
 * @param {React.ElementType} reactElement - the React element to render
 * @returns {React.ElementType} the portal-rendered React element
 */
export default function usePortal(reactElement) {
    const [domNode] = useState(() => document.createElement('div'));

    useEffect(
        () => {
            document.body.appendChild(domNode);

            return () => void document.body.removeChild(domNode);
        },
        []
    );

    return reactElement
        ? ReactDOM.createPortal(reactElement, domNode)
        : null;
}
