import React, {useState} from "react";
import Login from "../components/login/login";
import {connect} from "react-redux";
import {_setNoLogin} from "../store/system/service";

const PolicyStatusPage = (props) => {

  return (
    <div>
      <Login/>
    </div>
  );
};

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyStatusPage);
