import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    margin: "30px auto 20px",
    display: "flex",
    fontFamily: "Inter",
    fontWeight: "500",
    boxSizing: "border-box",
    border: "1.5px solid #b0b7c3",
    borderRadius: "35px",
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "228px",
      height: "44px",
      lineHeight: "24px",
      fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "304px",
      height: "60px",
      lineHeight: "28px",
      fontSize: "20px"
    }
  },
  background: {
    [theme.breakpoints.down("sm")]: {
      height: "44px"
    },
    [theme.breakpoints.up("sm")]: {
      height: "60px"
    }
  },
  tabBackground: props => ({
    "@media all and (-ms-high-contrast: none)": props => ({
      transform:
        props.selectedValue === "B"
          ? "translateX(100%) translateX(3px)"
          : "translateX(0)"
    }),
    transform:
      props.selectedValue === "B"
        ? "translateX(calc(100% + 3px))"
        : "translateX(0)"
  })
}));

const CustomerTab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ value, selectedValue }) =>
    value === selectedValue ? "#fff" : "#003DA5"};
  z-index: 999;
`;

const CustomerTabBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: -1.5px;
  width: 50%;
  background: #1d4fbb;
  border-radius: 35px;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px 0 rgba(74, 105, 197, 0.4);
`;

function CustomizedTab({ selectedValue, tabs, onChange = null }) {
  const classes = useStyles({ selectedValue, tabs });
  return (
    <>
      <div className={classes.container}>
        {tabs.slice(0, 2).map(({ value, name }) => (
          <CustomerTab
            key={value}
            value={value}
            selectedValue={selectedValue}
            onClick={() => onChange && onChange(value)}
          >
            {name}
          </CustomerTab>
        ))}
        <CustomerTabBackground
          className={[classes.background, classes.tabBackground].join(" ")}
          selected={selectedValue === tabs[1]?.value}
        />
      </div>
    </>
  );
}

CustomizedTab.propTypes = {
  selectedValue: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onChange: PropTypes.func
};

export default memo(CustomizedTab);
