import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import '../../assets/styles/payment.css';
import LinkedIn from '../../assets/icon_linkedin.svg';
import YouTube from '../../assets/icon_youtube.svg';
import Tick from '../../assets/Tick.svg';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QbeLogo from "../../assets/QBE-logo-on-light.png";
import {
    _generatePolicyTokenFromPortfolioScreen,
    _getCountry,
    _getLang, _getPolicyToken, _getPortfolioToken,
    _setCpToken,
    _setLang, _setPolicyToken
} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";
import {_getPolicyPayment} from "../../store/policy-payment/service";
import Header from "../custom/header";
import * as TagManager from "react-gtm-module";
import {_getPolPaymentCurrency, getProductName} from "../../utils/utility";
import {_resetPolicyList} from "../../store/user/service";
import {_resetPolicy, _storePolicy} from "../../store/policy/service";
import {withRouter} from "react-router-dom";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const PayLaterFinish = (props) => {
    const cfg = useConfig(props.lang);

    const [lang, setLang] = useState( props.lang);
    const [productName, setProductName] = useState(null);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [emailAddress] = useState (props.policyPayment.email ? props.policyPayment.email : '');

    useEffect(() => {
        setProductName(getProductName(cfg, props.policyPayment.country, props.policyPayment.catalogCode));
    })

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Renewal Pay Later Finish - Payment - ' + productName,
                vPath: '/renew/pay-later-finish',
                vProduct: productName,
                vPolicyNo: policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                vPaymentAmount: props.policyPayment.amount,
                vCountry: props.policyPayment.country,
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: props.policyPayment.policyNumber,
                            affiliation: props.policyPayment?.agentNumber,
                            revenue: props.policyPayment.amount
                        },
                        products: [{
                            name: productName,
                            price: props.policyPayment.amount,
                            dimension1: 'cheque',
                            dimension2: props.policyPayment.country,
                            category: 'Renewal',
                            quantity: 1
                        }]
                    }
                }
            }
        });
    }, []);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as '+newLang);
    }

    const close = () => {
        if (props.portfolioToken || props.policyToken) {
            props.resetPolicyList();
            props.generatePolicyTokenFromPortfolioScreen(props.policyPayment.policyNumber, props.policyPayment.country)
                .then((resp) => {
                    props.setCpToken(resp.token);
                    props.setPolicyToken(resp.token);
                    props.history.push('/policy');
                });
        } else {
            if (props.policyPayment.country === 'SGP') {
                window.open(process.env.REACT_APP_QBE_ESERV_SGP,'_self');
            } else {
                window.open(process.env.REACT_APP_QBE,'_self');
            }
        }
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }

    return (
    <div className="pageContainer pay-later-finish-page">
        <div className="container">
            {canGoBack() ?
              <Header/>
              :
              <div className="topRow">
                  <div className="left">
                      <a href="https://www.qbe.com/hk/en/about-qbe">
                          <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                      </a>
                  </div>
                  <div className="right">
                      <div className="languageSelect" onClick={() => switchLanguage()}
                           style={{ visibility: (props.policyPayment.country === 'HKG' ||
                               props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                          {(lang === 'en') ?
                            cfg("labels.lang_zh")
                            :
                            cfg("labels.lang_en")
                          }
                      </div>

                  </div>
              </div>
            }

            <div className="main-page-title">
                <span> {cfg("labels.renewal") + ' ' + productName}</span>
            </div>

            <div className="main-content-container-boxed">
                <div className="main-content-container-box">
                    <div className="main-content-box">
                        <div className="thankyou-message">
                            <img src={Tick} width="80" height="80" alt="Info" />
                            <div className="main-content-title alignCenter">{ cfg('labels.paymentSuccessTitle')}</div>
                            <div className="main-description-text alignCenter" style={{marginBottom: '20px'}}>
                                { cfg('labels.renewalPayLaterThankYou') + "\n" }
                            </div>

                            <div className="main-description-text alignCenter" style={{marginBottom: '40px'}}>
                                { cfg('labels.renewalPaymentSuccessMsg') } <b>{ emailAddress }</b>
                            </div>
                            <div></div>
                            <div className="main-description-text alignCenter">
                                { cfg('labels.renewalPayLaterNoteSuccessMsg') }
                            </div>
                        </div>
                        <div className="table-details policy-details">
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="2">{ cfg('labels.policyDetail')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{ cfg('labels.policyNumber')}</td>
                                    <td>{ policyNumber }</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="form-field-container button-container button-size1">
                            <InputGroupStyle>
                                <QnectButton field="close" onClick={close}>
                                </QnectButton>
                            </InputGroupStyle>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state),
        country: _getCountry(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    resetPolicyList: _resetPolicyList,
    resetPolicy: _resetPolicy,
    generatePolicyTokenFromPortfolioScreen: _generatePolicyTokenFromPortfolioScreen,
    setCpToken: _setCpToken,
    setPolicyToken: _setPolicyToken,
    storePolicy: _storePolicy,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayLaterFinish));

