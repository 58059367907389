import React, {Component} from 'react';
import AdhocPaymentFinish from "../components/adhoc-payment-finish/adhoc-payment-finish";

const AdhocPaymentFinishPage = () => {
    return (
        <div>
            <AdhocPaymentFinish/>
        </div>
    )
}

export default AdhocPaymentFinishPage;
