import classnames from 'classnames';
import propTypes from 'prop-types';
import property from 'lodash/property';
import styled from 'styled-components';
import React from 'react';

import {selectFieldType} from '../../common/common-prop-types';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import RadioButtonsField from '../form-fields/radio-buttons-field';
import Paragraph from '../typography/paragraph';

const CenteredParagraph = styled(Paragraph)`
    text-align: center;
    color: ${property('theme.color.tertiary90')};
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

export default function IncidentPremises({
    className,
    incidentPremises,
    isSubmitting,
    onSubmit,
    submitButtonText,
}) {
    return (
        <Form
            className={classnames('IncidentPremises', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            <CenteredParagraph>{incidentPremises.label}</CenteredParagraph>
            <RadioButtonsField {...incidentPremises} />
            <StyledButton
                isTall
                color="primary"
                disabled={isSubmitting}
                type="submit"
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

IncidentPremises.propTypes = {
    className: propTypes.string,
    incidentPremises: selectFieldType.isRequired,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
    submitButtonText: propTypes.string.isRequired,
};
