import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';

import Paragraph from '../typography/paragraph';
import CircularProgress from '../progress/circular-progress';
import SkeletonLoader from '../progress/skeleton-loader';

const LoadingBodyOverlay = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 1; /* stylelint-disable-line scale-unlimited/declaration-strict-value */ /* Stop form labels appearing above overlay */
    background: ${({theme}) => rgba(theme.color.white, 0.8)};
`;

const CenteredParagraph = styled(Paragraph)`
    text-align: center;
`;

const Card = styled.div`
    border-radius: 20px;
    box-shadow: 1px 1px 4px 0 ${({theme}) => rgba(theme.color.tertiary, 0.12)};
    width: 100%;
    max-width: 600px;
    overflow: hidden;
`;

const StepBody = styled.section`
    position: relative;
    background-color: ${property('theme.color.white')};
    padding: 24px;
`;

export default function StepCard({
    className,
    isLoading,
    isLoadingError,
    isLoadingBody,
    header,
    message,
    children,
}) {
    return (
        <Card className={classnames('StepCard', className)}>
            {isLoading || isLoadingError ? (
                <SkeletonLoader isAnimated={!isLoadingError} />
            ) : (
                <>
                    {header}
                    <StepBody>
                        {!!message && (
                            <CenteredParagraph color="tertiary90">
                                {message}
                            </CenteredParagraph>
                        )}

                        {children}

                        {!!isLoadingBody && !isLoading && (
                            <LoadingBodyOverlay>
                                <CircularProgress />
                            </LoadingBodyOverlay>
                        )}
                    </StepBody>
                </>
            )}
        </Card>
    );
}

StepCard.propTypes = {
    className: propTypes.string,
    isLoading: propTypes.bool,
    isLoadingError: propTypes.bool,
    isLoadingBody: propTypes.bool,
    header: propTypes.node,
    message: propTypes.node,
    children: propTypes.node,
};
