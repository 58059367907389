import React from 'react';
import RenewalNotice from "../components/renewal-notice/renewal-notice";

const RenewalNoticePage = () => {
    return (
        <div>
            <RenewalNotice/>
        </div>
    )
}

export default RenewalNoticePage;
