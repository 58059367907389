import React, {useEffect} from "react";
import axios from 'axios';
import moment from 'moment';
import useConfig from './qnect-config';
import {qnectFormValidator} from "../components/qnect-ui/qnect-validator";
import formatCurrency from "./format-currency";



export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const _formatPaymentAmount = (country, amount) => {
    let prefix = '';
    switch(country) {
        case 'MYS':
            prefix = 'MYR ';
            break;
        case 'SGP':
            prefix = 'S$ ';
            break;
        case 'HKG':
        case 'HGI':
            prefix = 'HK$ ';
            break;
        default:
            prefix = '$ ';
    }

    return _formatAmount(amount, null, prefix, 2);
}

export function _formatAmount(amount, locale, prefix, minFractionDigit = 0, maxFractionDigit = 2) {
    let value = _removeFormatting(amount);

    if (_isNotDecimalChar(value)) {
        return "";
    }

    let _prefix = prefix;

    return (_prefix || "") + Intl.NumberFormat(locale || "en", {
        maximumFractionDigits: maxFractionDigit,
        minimumFractionDigits: minFractionDigit
    }).format(value);
}

export function _valueOf(formattedAmount) {
    let value = _removeFormatting(formattedAmount);

    if (_isNotDecimalChar(value)) {
        return NaN;
    } else {
        return Number(value);
    }
}

export function _removeFormatting(value) {
    if (!!value) {
        return "".concat(value).replace(/[^0-9.]/g, "");
    } else {
        return "";
    }
}

export function _isNotDecimalChar(value) {
    if (!value || isNaN(value)) {
        return true;
    } else {
        return false;
    }
}

let scrollTimer = null;
export function _scrollToComponent(ref) {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            if(isIE){
                window.scrollTo(0, ref?.current?.offsetTop);
            } else {
                window.scrollTo({top: ref?.current?.offsetTop, behavior: 'smooth'});
            }
            scrollTimer = null;
        }, 500);
    }
}

export function _scrollTo(y) {

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            window.scrollTo(0, y);

            scrollTimer = null;
        }, 500);
    }
}


export function _scrollToTop() {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            if(isIE){
                window.scrollTo(0, 0);
            } else {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
            scrollTimer = null;
        }, 500);
    }
}


export async function _sendLog(message, type) {
    return new Promise(function (resolve, reject) {
        if (process.env.REACT_APP_SEND_LOG && message) {
            let data = ''
            if (Array.isArray(message)) {
                data = message.join(', ')
            } else {
                data = message;
            }
            axios.post(process.env.REACT_APP_SEND_LOG + (!type ? 'error' : type), {'data': data},
                {headers: {}, shouldIntercept: 'false'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(error => {
                    resolve();
                });
        }
    })
}

export function showGstDisclaimer(country) {
    const currentDate = new Date();
    const gstDisclaimerExpirationDate = new Date("2024-01-04")
    if(country =='SGP'){
        if(gstDisclaimerExpirationDate>=currentDate){
            return true;
        }
    }
    return false;
}

export const onChangeCardNumberFormatHandler = (event, setCardNumberMethod) => {
    let cardNoRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{4}$", "g");
    let newCardNo = '';
    let preventDefault = false;
    if (!cardNoRegex.test(event.target.value)) {
        let cardNo = event.target.value.replace(/-/g, '');
        if (cardNo.length > 16) {
            cardNo = cardNo.substring(0, 16);
        }

        if (!(/\D/g.test(cardNo))) {
            do {
                if (cardNo.length > 4) {
                    newCardNo = newCardNo + cardNo.substring(0, 4) + '-';
                    cardNo = cardNo.substring(4, cardNo.length);
                } else {
                    newCardNo = newCardNo + cardNo;
                    cardNo = '';
                }
            } while (cardNo.length > 0);
        } else {
            preventDefault = true;
        }
    } else {
        newCardNo = event.target.value;
    }

    if (!preventDefault) {
        setCardNumberMethod(newCardNo);
    } else {
        event.preventDefault();
    }
};

export const onChangeExpiryDateHandler = (event, setExpiryDateMethod) => {
    let newExpiryDate = event.target.value.trim();
    if (newExpiryDate) {
        if (newExpiryDate.length === 4 && newExpiryDate.indexOf("-") === -1) {
            newExpiryDate = newExpiryDate.substring(0, 2) + '-' + newExpiryDate.substring(2);
        }
    }

    setExpiryDateMethod(newExpiryDate);
};


export const _validateForm = (formId) => {
    let valid = true;
    if (qnectFormValidator.checkForm(formId).isValid !== true) {
        valid = false;
    }

    return valid;
}

export const _refreshFormField = (formId, field) => {
    qnectFormValidator.refreshItem(formId, field);
}

export const getDocName = (doc, cfg, country)  => {
    if (doc) {
        country = country === 'HGI' ? 'HKG' : country;
        let documentType = doc?.dDocType.replace('ASIA-', '');
        let documentName = cfg('policyDocumentNaming.' + country + '.' + documentType);

        if (doc?.dDocType === 'ASIA-RECEIPT'){
            return documentName+": "+doc.ddocName;
        }
        if (!documentName.startsWith('policyDocumentNaming.')) {
            // affix (CHINESE) on document name display if document title found has (CHINESE)
            if (doc.documentTitle && doc.documentTitle.includes("(CHINESE)")) {
                documentName = documentName + " (CHINESE)";
            }
            return documentName;
        } else {
            if (doc?.documentTitle) {
                return doc.documentTitle.replace(' - Client Copy', '')
            }
            if (doc?.did === 0) {
                return doc.ddocTitle;
            }
            return doc?.dDocType.replace('ASIA-', '');
        }
    }
    return '';
}

export const customReplaceAll = (regex, stringToBeChanged, changeToThisCharacter) => {
    let matchedString = stringToBeChanged.match(regex);
    if (matchedString[0]) {
        let changedString = matchedString[0].replaceAll(/./g, changeToThisCharacter);
        return stringToBeChanged.replaceAll(regex, changedString);
    }
}

export const _isRenewalStatus = (statusCode) => {
    if (statusCode === 'AR' || statusCode === 'FR' || statusCode === 'SR' || statusCode === 'PP') {
        return true;
    }

    return false;
}

export const _getPolPaymentCurrency = (country) => {
    switch (country) {
        case "HKG":
            return "HKD";
        case "SGP":
            return "SGD";
        case "MYS":
            return "MYR";
        default:
            return "N/A"
    }
}

export const getProductName = (cfg, country, catalogCode, lang) => {
    if (!catalogCode) {
        return cfg("productType.GENPRD");
    }

    let returnValue = '';

    if (country === 'SGP') {
        returnValue =  cfg("renewalProductType." +country + "." + catalogCode);
    } else {
        returnValue =  cfg("productType." + country + "." + catalogCode);
    }

    if (returnValue.startsWith("productType") || returnValue.startsWith("renewalProductType")) {
        return cfg("productType.GENPRD");
    } else {
        return returnValue;
    }
}

export const getPremiumFormat = (country, premium, hasGST) => {
    if (country === 'HKG') {
        return 'HKD' + formatCurrency(premium);
    } else if (country === 'MYS') {
        return 'MYR' + formatCurrency(premium);
    } else if (country === 'SGP') {
        return 'S' + formatCurrency(premium) + (hasGST === 'Y' ? ' w/GST' : ' GST Not Applicable');
    } else {
        return formatCurrency(premium);
    }

}

export const convertSecsToMins = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_mins = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_mins / 60);

        let divisor_for_secs = divisor_for_mins % 60;
        let seconds = Math.ceil(divisor_for_secs);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds < 10 ? `0`+seconds : seconds
        };
        let timeValue = obj.m + ":" + obj.s;
        return timeValue;
}