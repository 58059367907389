import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {smDown, smUp} from '../../common/breakpoints';
import IconAlert from '../icons/legacy/alert';
import IconInfoCircle from '../icons/legacy/info-circle';
import Heading from '../typography/heading';
import Text from '../typography/text';

const policyStatusIcon = {
    success: IconInfoCircle,
    warning: IconAlert,
    error: IconAlert,
};

// StatusBar's align-items: center doesn't work on IE11 without this extra level of display: flex.
// Otherwise, these styles are not needed.
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Summary = styled.section`
    background: ${property('theme.color.tertiary')};
    padding: 24px 32px;

    ${smUp`
        padding: 48px;
    `}
`;

const StyledHeading = styled(Heading)`
    margin: 0;
    padding-top: 27px;

    ${smUp`
        padding-top: 54px;
    `}
`;

const List = styled.dl`
    margin: 0;
    padding: 10px 0 0;

    ${smDown`
        overflow: hidden;
    `}

    ${smUp`
        display: flex;
        flex-wrap: unset;
        align-items: center;
    `}
`;

const Title = styled.dt`
    margin: 0 8px 0 0;
    padding: 4px 0 0;

    ${smDown`
        float: left;
        clear: left;
    `}
`;

const Definition = styled.dd`
    margin: 0;
    padding: 4px 0 0;

    ${smDown`
        float: left;
    `}

    ${smUp`
        &:first-of-type {
            flex-grow: 1;
        }
    `}
`;

const StatusBar = styled.section`
    display: flex;
    align-items: center;
    background: ${({theme, scColor}) => theme.color[scColor] || 'transparent'};
    padding: 0 32px;
    min-height: 42px;

    ${smUp`
        padding: 0 48px;
    `}

    > svg {
        margin: 0 10px 0 -4px;
    }
`;

export default function PolicySummary({
    className,
    heading,
    policyNumberTitle,
    policyNumber,
    dateOfLossTitle,
    dateOfLoss,
    policyStatus,
    policyStatusDescription,
}) {
    const Icon = policyStatusIcon[policyStatus];

    return (
        <Container className={classnames('PolicySummary', className)}>
            <Summary>
                <StyledHeading
                    level={2}
                    size="large"
                    weight="medium"
                    color="white"
                >
                    {heading}
                </StyledHeading>
                <List>
                    <Text
                        as={Title}
                        size="small"
                        color="white"
                    >
                        {policyNumberTitle}
                    </Text>
                    <Text
                        as={Definition}
                        size="small"
                        color="white"
                    >
                        {policyNumber}
                    </Text>
                    <Text
                        as={Title}
                        size="small"
                        color="white"
                    >
                        {dateOfLossTitle}
                    </Text>
                    <Text
                        as={Definition}
                        size="small"
                        color="white"
                    >
                        {dateOfLoss}
                    </Text>
                </List>
            </Summary>
            <StatusBar scColor={policyStatus}>
                {!!Icon && (
                    <Icon
                        color="white"
                        size={24}
                    />
                )}
                <Text
                    size="small"
                    color="white"
                >
                    {policyStatusDescription}
                </Text>
            </StatusBar>
        </Container>
    );
}

PolicySummary.propTypes = {
    className: propTypes.string,
    heading: propTypes.string,
    policyNumberTitle: propTypes.string,
    policyNumber: propTypes.string,
    dateOfLossTitle: propTypes.string,
    dateOfLoss: propTypes.string,
    policyStatus: propTypes.oneOf(['success', 'warning', 'error']),
    policyStatusDescription: propTypes.string,
};
