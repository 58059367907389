import React from 'react';
import propTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import classnames from 'classnames';

import typographyStyles from '../../common/typography-styles';
import Button from '../buttons/button';
import Paragraph from '../typography/paragraph';

const ContentBody = styled.div`
    padding: 32px 0;
    text-align: center;
`;

const ContentFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
`;

const StyledButton = styled(Button)`
    margin: 0 12px;
`;

const List = styled.ul`
    margin: 8px 0 0;
    padding: 0;
    list-style-type: none;
`;

const Item = styled.li`
    margin: 8px 0 0;

    ${typographyStyles({
        type: 'subheading',
        defaultSize: 'normal',
        defaultWeight: 'bold',
    })}

    &:not(:last-child)::after {
        content: ',';
    }
`;

export default function LodgementSuccessContent({
    className,
    claimNumberLabel,
    claimNumbers,
    lodgementStatus,
    message,
    onClickPrimaryAction,
    primaryActionLabel,
    primaryActionHref,
}) {
    return (
        <div className={classnames('LodgementSuccessContent', className)}>
            <ContentBody>
                <Paragraph color="tertiary90" size="big">
                    {message}
                    <br />
                    {lodgementStatus}
                </Paragraph>

                <Paragraph color="black" size="normal">
                    {claimNumberLabel}
                </Paragraph>

                {!!get(claimNumbers, 'length') && (
                    <List>
                        {claimNumbers.map(({claimNumber}) => (
                            <Item key={claimNumber}>
                                {claimNumber}
                            </Item>
                        ))}
                    </List>
                )}
            </ContentBody>

            <ContentFooter>
                <StyledButton
                    isTall
                    color="primary"
                    href={primaryActionHref}
                    onClick={onClickPrimaryAction}
                >
                    {primaryActionLabel}
                </StyledButton>
            </ContentFooter>
        </div>
    );
}

LodgementSuccessContent.propTypes = {
    className: propTypes.string,
    claimNumbers: propTypes.arrayOf(propTypes.shape({
        claimNumber: propTypes.string,
    })).isRequired,
    claimNumberLabel: propTypes.string.isRequired,
    lodgementStatus: propTypes.string.isRequired,
    message: propTypes.string.isRequired,
    onClickPrimaryAction: propTypes.func,
    primaryActionLabel: propTypes.string.isRequired,
    primaryActionHref: propTypes.string,
};
