import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    TopErrorContainer: {
        minHeight: "100px",
        color: "#ffffff",
        fontSize: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000000",
        zIndex: "10"
    },
    ErrorText: {
        maxWidth: "420px"
    },
    ErrorImg: {
        marginRight: "25px",
        height: "43px",
        flexShrink: "0"
    },
    ScheduledMaintenanceText: {
      width: "80%"
    }
}));

export default function ScheduledMaintenanceBanner({value, country}) {
    console.log(country);
    let link;
    if(country == "HKG") {
        link = "https://www.qbe.com/hk/qnect/scheduled-maintenance";
    } else if(country == "SGP"){
        link = "https://www.qbe.com/sg/qnect/scheduled-maintenance";
    } else {
        link = "https://www.qbe.com/my/qnect/scheduled-maintenance";
    }
    const classes = useStyles();
    return (
        <>
            <div className={classes.TopErrorContainer} id="scheduledMaintenance">
                <span/>
                <span className={classes.ScheduledMaintenanceText}>
                    {value.message + " " + value.longMessage} &nbsp;
                    You may also refer to upcoming scheduled maintenance <a className='error-notification-link' href={link} target="_blank" rel="noreferrer">here</a>.
                    Please accept our sincerest apologies for any inconvenience caused.
                </span>
            </div>
        </>
    );
}
