import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",

    "& input": {
      [theme.breakpoints.down("sm")]: {
        height: "56px!important",
        paddingLeft: "13.5px!important"
      }
    },
    "& .MuiFormLabel-root": {
      padding: "0px!important",
      color: "#0a1f44",
      fontFamily: "Inter",
      letterSpacing: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "8px",
        fontSize: "16px",
        lineHeight: "24px"
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "12px",
        fontSize: "18px",
        lineHeight: "25px"
      }
    },
    "& .MuiInputBase-root": {
      [theme.breakpoints.down("sm")]: {
        height: "56px !important"
      }
    },
    "& .MuiInputBase-root:hover": {
      bordercolor: "#1547b1"
    },
    "& .MuiInputLabel-formControl": {
      position: "relative",
      top: 0,
      left: 0,
      transform: "none !important"
    },
    "& .MuiSelect-root": {
      padding: "0 45px 0 20px !important",
      color: "#0a1f44",
      fontFamily: "Inter",
      fontSize: "18px",
      letterSpacing: 0,
      lineHeight: "24px"
    },
    "& .MuiSelect-icon": {
      color: "#a7aebb !important",
      width: "32px !important",
      minWidth: "32px !important",
      height: "32px !important",
      minHeight: "32px !important",
      top: "calc(50% - 16px) !important"
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#858fa2 !important"
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#fafbfb !important",
      borderColor: "#c9ced6 !important",

      "&:hover": {
        borderColor: "#c9ced6 !important"
      },

      "& > input": {
        color: "#8a94a6 !important",
        cursor: "not-allowed"
      }
    },
    "& .passwordField": {
        paddingTop: "21px"
    }
  }
}));

const TextFieldWrapper = ({ children, className }) => {
  const classes = useStyles();

  return <div className={[classes.root, className].join(" ")}>{children}</div>;
};

TextFieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default TextFieldWrapper;
