import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const StyledPaginationWrapper = styled.div`
  text-align: right;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

const StyledPagination = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
`;

const StyledArrowBackWrapper = styled(IconButton)`
  margin-left: 25px !important;
  width: 18px;
  height: 18px;
`;

const StyledArrowForwardWrapper = styled(IconButton)`
  margin-left: 42px !important;
  width: 18px;
  height: 18px;
`;

const Pagination = ({ allItems, currentPage, currentItems, itemPerPage, firstItemIndex, lastItemIndex, action }) => {
  const pageStart = () => {
    return itemPerPage * (currentPage - 1) + 1;
  }

  const pageEnd = () => {
    let pageEnd = itemPerPage * currentPage
    if (pageEnd > allItems.length) {
      pageEnd = allItems.length;
    }
    return pageEnd;
  }

  return (
    <StyledPaginationWrapper>
      <StyledPagination>
        {currentPage !== 1
          ? `${pageStart()}-${pageEnd()} of ${allItems.length}`
          : `1-${itemPerPage} of ${allItems.length}`}
      </StyledPagination>
      <StyledArrowBackWrapper onClick={() => action("back")} disabled={allItems[firstItemIndex] === allItems[0]}>
        <ArrowBackIcon fontSize={"small"} htmlColor={allItems[firstItemIndex] === allItems[0] ? "#A7AEBB" : "#003DA5"} />
      </StyledArrowBackWrapper>
      <StyledArrowForwardWrapper onClick={() => action("front")} disabled={allItems[lastItemIndex] === allItems[allItems.length]}>
        <ArrowForwardIcon fontSize={"small"} htmlColor={allItems[lastItemIndex] === allItems[allItems.length] ? "#A7AEBB" : "#003DA5"} />
      </StyledArrowForwardWrapper>
    </StyledPaginationWrapper>
  );
};

Pagination.propTypes = {
  allItems: PropTypes.array,
  currentPage: PropTypes.number,
  currentItems: PropTypes.array,
  itemPerPage: PropTypes.number,
  firstItemIndex: PropTypes.number,
  lastItemIndex: PropTypes.number,
  action: PropTypes.func,
};

export default memo(Pagination);
