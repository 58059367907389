import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none"
    },
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("sm")]: {
        margin: "16px",
        padding: "4px 20px 40px",
        width: "343px"
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "596px"
      }
    },
    "& .MuiDialogContent-root": {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },
    "& .MuiDialogActions-root": {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },
    "& .MuiBackdrop-root": {
      opacity: "0.8 !important"
    }
  }
}));

const CustomizedDialog = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Dialog className={[classes.root, className].join(" ")} {...rest}>
      {children}
    </Dialog>
  );
};

CustomizedDialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default CustomizedDialog;
