import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger';     // redux-logger is a middleware that lets you log every state change
import thunk from 'redux-thunk';       // redux-thunk is a middleware that lets you dispatch async actions

import reducer from './reducer';

const middleware = process.env.REACT_APP_ENV === 'prod' ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

export default function createAppStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(reducer, composeEnhancers(middleware));
}
