import React, {Component} from 'react';
import PayLaterFinish from "../components/pay-later-finish/pay-later-finish";

const PayLaterFinishPage = () => {
    return (
        <div>
            <PayLaterFinish/>
        </div>
    )
}

export default PayLaterFinishPage;
