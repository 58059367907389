import * as type from "./types";
import { updateObject } from "../../utils/utility";

const INITIAL_STATE = {
    policyNumber: '',
    policyType: '',
    status: '',
    statusCode: '',
    effectiveDate: '',
    expiryDate: '',
    country: '',
    productCode: '',
    catalogueCode: '',
    insuredNames: [],
    name: '',
    premiumAmount: 0,
    agentDetails: {},
    email: '',
    paymentFlag: '',
    renewalFlag: '',
    origPolicyPeriod: ''
};

const storePolicy = (state, action) => {
    let newPolicy = action.data;

    if (!action.data) {
        newPolicy = INITIAL_STATE;
    }

    return updateObject(
        state,
        newPolicy
    )
}

const resetState = (state) => {
    let obj = {...INITIAL_STATE};
    return updateObject(
      state,
      obj
    )
}


export const policyReducer = (state = INITIAL_STATE, action) => {
    switch ( action.type ) {
        case type.POLICY_STORE:
            return storePolicy(state, action);
        case type.POLICY_FETCH_FAILED:
            return updateObject(
                state,
                { error: true }
            );
        case type.RESET_STATE:
            return resetState(state);
        default:
            return state;
    }
}
