import React, {useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';
import property from 'lodash/property';

import {cssIf} from '../../common/conditional-styles';
import {smUp} from '../../common/breakpoints';
import {grid, gridCell} from '../../common/grid';
import useParentBlur from '../../common/use-parent-blur';
import Paragraph from '../typography/paragraph';
import RadioCard from './radio-card';

const OptionsRow = styled.div`
    ${grid({columns: 1, rows: 2, rowGap: '12px'})}

    ${smUp`
        ${grid({columns: 2, columnTemplate: ['1fr', '1fr'], columnGap: '32px', rows: 1})}
    `}
`;

const OptionOne = styled(RadioCard)`
    ${gridCell({column: 1, row: 1})}
`;

const OptionTwo = styled(RadioCard)`
    ${gridCell({column: 1, row: 2})}

    ${smUp`
        ${gridCell({column: 2, row: 1})}
    `}
`;

const HelperText = styled(Paragraph)`
    margin: 0 5px;
    padding-top: 16px;

    ${cssIf('isDisabled')`
        color: ${property('theme.color.grey60')};
    `}
`;

const RadioGroup = React.forwardRef((
    {
        className,
        name,
        helperText,
        value,
        onChange = noop,
        onBlur,
        errorMessage,
        optionOne,
        optionTwo,
        disabled,
    },
    ref
) => {
    // Fallback ref so we can get onBlur events without an externally provided ref
    const internalRef = useRef(null);

    // Detect focus outside the group of radios
    useParentBlur(ref || internalRef, onBlur);

    return (
        <div className={classnames('RadioGroup', className)}>
            <OptionsRow ref={ref || internalRef}>
                <OptionOne
                    label={optionOne.text}
                    disabled={disabled}
                    name={name}
                    value={optionOne.value}
                    checked={optionOne.value === value}
                    onChange={() => void onChange(optionOne.value)}
                />

                <OptionTwo
                    label={optionTwo.text}
                    disabled={disabled}
                    name={name}
                    value={optionTwo.value}
                    checked={optionTwo.value === value}
                    onChange={() => void onChange(optionTwo.value)}
                />
            </OptionsRow>

            {!!(errorMessage || helperText) && (
                <HelperText
                    size="xSmall"
                    color={errorMessage ? 'error' : 'tertiary90'}
                    isDisabled={disabled}
                >
                    {errorMessage || helperText}
                </HelperText>
            )}
        </div>
    );
});

RadioGroup.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    helperText: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    errorMessage: propTypes.string,
    optionOne: propTypes.shape({
        value: propTypes.string,
        text: propTypes.string,
    }),
    optionTwo: propTypes.shape({
        value: propTypes.string,
        text: propTypes.string,
    }),
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
