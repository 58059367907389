import React, { useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import QnectTextField from "../../qnect-ui/qnect-text-field";
import QnectSelectField from "../../qnect-ui/qnect-select-field";
import QnectButton from "../../qnect-ui/qnect-button";
import QnectDateField from "../../qnect-ui/qnect-date-field";
import DialogTitle from "../dialog-title";
import BalloonTip from "../../ui/balloon-tip/balloon-tip";
import { ReactComponent as ShowIcon } from "../../../assets/registration/icon_show.svg";
import { ReactComponent as HideIcon } from "../../../assets/registration/icon_hide.svg";
import ButtonWrapper from "../button-wrapper";
import { smDown, lgDown } from "../../../ui/common/breakpoints";

const StyledMessage = styled.div`
  margin-top: 30px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 534px;
  ${lgDown`
    margin-top: 0px;
    width: auto;
  `}
  ${smDown`
    margin-top: 4px;
    text-align: center;
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "14px 24px 28px",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "14px 22px 28px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    padding: "24px 24px",
    justifyContent: "space-between",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "24px 47px",
    },
  },
}))(DialogActions);

const StyledDialogContent = withStyles({
  root: {
    width: "100%",
    margin: "0 auto",
    display: "block",
  },
})(DialogContent);

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin: 39px 0 28px;
  position: relative;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  > .BalloonTip {
    top: -35px;
    left: 190px;
    position: absolute;
  }
  > .BalloonTip .contentDiv {
    top: -53px !important;
    width: 306px !important;
    height: 68px !important;
    border-radius: 2px !important;
    padding: 4px 12px 4px;
    background: rgba(78, 93, 120, 0.9) !important;
    margin-left: 2px;
  }
  > div:not(.form-caption) {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 29px;
    right: 20px;
  }
  ${smDown`
    & .MuiInputLabel-formControl {
      font-size: 16px !important;
      line-height: 24px !important;
      top: -30px !important;
    }
  `}
`;

const StyledFirstRowWrapper = styled(StyledTextFieldWrapper)`
  display: flex;
  margin-top: 39px;
  ${lgDown`
    width: 460px;
    margin-left: auto;
    margin-right: auto;
  `}
  ${smDown`
    width: auto;
    padding-top: 0;
    margin-top: 10px;
  `}
`;

const SelectWrapper = styled(StyledTextFieldWrapper)`
  width: 161px;
  margin-bottom: 32px;
  .MuiFormLabel-root {
    padding: 0 !important;
    transform: none !important;
  }
  .MuiInputBase-root .MuiSelect-root {
    padding: 12px 16px 10px 18px;
  }
  ${smDown`
    width: 113px;
    margin-bottom: 24px !important;
    & .MuiFormControl-root.MuiFormLabel-root {
      padding: 0;
      transform: none;
    }
  `}
`;

const ShortTextInputWrapper = styled(StyledTextFieldWrapper)`
  margin-left: 20px;
  margin-bottom: 32px;
  & > .BalloonTip {
    left: 99px !important;
    .contentDiv {
      top: -73px !important;
    }
  }
  ${lgDown`
    width: 280px;
    & > .BalloonTip {
      .contentDiv {
        margin-left: -110px !important;
      }
    }
  `}
  ${smDown`
    width: 182px !important;
    marginBottom: 24px !important;
    & .MuiInputAdornment-positionEnd {
      right: 10px !important;
    }
    & > .BalloonTip {
      left: 85px !important;
      top: -32px !important;
      .contentDiv {
        top: -65px !important;
        margin-left: -215px !important;
      }
    }
  `}
`;

const StyledPolicyholderField = styled(StyledTextFieldWrapper)`
  margin-top: 62px;
  margin-bottom: 0;
  > .BalloonTip {
    padding: 4px 12px 4px !important;
  }
  > .BalloonTip .contentDiv {
    width: 334px !important;
    padding: 4px 12px 4px !important;
    height: 48px !important;
    margin-left: -13px;
  }
  ${lgDown`
    width: 460px;
    margin-left: auto;
    margin-right: auto;
    & > .BalloonTip {
      .contentDiv {
        top: -60px !important;
        margin-left: -100px !important;
      }
    }
  `}
  ${smDown`
    margin-top: 39px;
    width: auto;
    & > .BalloonTip {
      left: 167px !important;
      top: -32px !important;
      .contentDiv {
        top: -55px !important;
        margin-left: -190px !important;
      }
    }
  `}
`;

const StyledDobField = styled(StyledTextFieldWrapper)`
  margin-top: 42px;
  ${lgDown`
    width: 460px;
    margin-left: auto;
    margin-right: auto;
  `}
  ${smDown`
    width: auto;
  `}
`;

const SubText = styled.div`
  height: 48px;
  color: #8a94a6;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 8px;
  padding-bottom: 18px;
  ${lgDown`
    padding-left: 22px;
  `}
  ${smDown`
    padding-left: 0;
    font-size: 14px;
    line-height: 20px;
  `}
`;

const StyledNeedHelp = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #003da5;
  cursor: pointer;
  font-family: Inter;
  padding-top: 22px;
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #d54040;
  word-break: keep-all;
  white-space: pre-wrap;
  font-family: "Inter";
  margin-top: -25px;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  ${smDown`
    & button {
      width: 160px !important;
    }
  `}
`;

const LocatePolicyFormDialog = ({ open, onClose, onOther, onSubmit, cfg }) => {
  const [type, setType] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [nameOfPolicyholder, setNameOfPolicyholder] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [showPolicyNumber, setShowPolicyNumber] = useState(true);

  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle title="Could not locate your policy?" onClose={onClose} />
        <StyledDialogContent>
          <StyledMessage>Please enter any 2 fields below to authenticate your policy.</StyledMessage>
          <StyledFirstRowWrapper>
            <SelectWrapper>
              <QnectSelectField field="HkidOrNricSelect" value={type} onChange={(val) => setType(val)} />
            </SelectWrapper>
            <ShortTextInputWrapper>
              <QnectTextField
                field="inputField"
                label={cfg("labels.idNumber")}
                value={policyNumber}
                onChange={(evt) => setPolicyNumber(evt.target.value)}
                type={showPolicyNumber ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPolicyNumber(!showPolicyNumber)}>
                      {showPolicyNumber ? <ShowIcon /> : <HideIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <BalloonTip>
                Letter and first 5 digits only, e.g. A123456; <br />
                Other passport please enter first 5 alphabets & digits.
              </BalloonTip>
            </ShortTextInputWrapper>
          </StyledFirstRowWrapper>
          {/* {policyNumber === "1" && <ErrorMessage>{cfg("labels.idNumberErrMsg")}</ErrorMessage>} */}

          <StyledPolicyholderField>
            <QnectTextField
              type={"text"}
              field="inputField"
              label={cfg("labels.nameOfPolicyholder")}
              value={nameOfPolicyholder}
              onChange={(evt) => setNameOfPolicyholder(evt.target.value)}
              // errorMessage={nameOfPolicyholder === "1" && cfg("labels.nameOfPolicyholderErrMsg")}
            />
            <BalloonTip>Joint policyholders please input all the names, user '&' to seperate the names.</BalloonTip>
          </StyledPolicyholderField>

          <SubText>Please refer to the full name of your policy schedule or notice</SubText>

          <StyledDobField>
            <QnectDateField
              field="inputDate"
              label={cfg("labels.dateOfBirthOfPolicyholder")}
              value={dateOfBirth}
              onChange={(val) => setDateOfBirth(val.toDate())}
            />
          </StyledDobField>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledNeedHelp
            onClick={() => {
              onClose();
              onOther();
            }}
          >
            Need Help?
          </StyledNeedHelp>
          <StyledButtonWrapper>
            <QnectButton
              onClick={() => {
                onClose();
                onSubmit();
              }}
              disabled={policyNumber === "" || nameOfPolicyholder === "" || dateOfBirth === ""}
            >
              {cfg("labels.continue")}
            </QnectButton>
          </StyledButtonWrapper>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
};

export default LocatePolicyFormDialog;
