import styled from 'styled-components';

import {smUp} from '../../common/breakpoints';

const CardSection = styled.section.attrs({className: 'CardSection'})`
    display: grid;
    grid-row-gap: 24px;

    ${smUp`
        grid-row-gap: 12px;
    `}
`;

export default CardSection;
