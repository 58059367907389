import React, {useEffect} from "react";
import PolicyNumberSection from "../components/registration/policy-number";
import AuthenticationSection from "../components/registration/authentication";
import PersonalDetailsSection from "../components/registration/personal-details";
import VerifyEmail from "../components/verify/verify-email";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {_getShowPageBroken, _hideSpinner, _setShowPageBroken} from "../store/system/service";
import {_getUser} from "../store/user/service";

const RegistrationPage = (props) => {

    useEffect(() => {
        if (props.showPageBroken) {
            props.history.push('/pageUnavailable');
            props.setShowPageBroken(false);
            props.hideSpinner();
        }
    }, [props.showPageBroken]);

    return (
        <React.Fragment>
            {
                (!props.match.params.section && <PolicyNumberSection/>)
                ||
                (props.match.params.section === 'policyNumber' && <PolicyNumberSection/>)
                ||
                ( !props.user.policyNumber ? <Redirect to='/'/> :
                    (props.match.params.section === 'authentication' && <AuthenticationSection/>)
                    ||
                    (props.match.params.section === 'personalDetail' && <PersonalDetailsSection/>)
                    ||
                    (props.match.params.section === 'verify' && <VerifyEmail/>)
                )
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        showPageBroken: _getShowPageBroken(state)
    }
}

const mapDispatchToProps = {
    setShowPageBroken: _setShowPageBroken,
    hideSpinner: _hideSpinner
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
