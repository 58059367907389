import React from 'react';

import BaseIcon from '../common/base-icon';

export default function IconCheckCircle(props) {
    return (
        <BaseIcon {...props}>
            <path
                d="M12 3a9 9 0 110 18 9 9 0 010-18zm4.036 5.086l-5.658 5.656-2.828-2.828-1.414 1.414 4.243 4.243 1.414-1.414L17.45 9.5l-1.414-1.414z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </BaseIcon>
    );
}
