import React from "react";
import QnectButton from "../qnect-ui/qnect-button";
import styled from "styled-components";
import ButtonWrapper from "../custom/button-wrapper";
import {smDown} from "../../ui/common";
import useConfig from "../qnect-ui/qnect-config";
import ActionModal from "../../ui/components/modals/action-modal";
import Heading from "../../ui/components/typography/heading";
import {ReactComponent as AlertCircle} from "../../assets/alert-red-circle.svg";
import {_getLang} from "../../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const StyledButtonWrapper = styled(ButtonWrapper)`
  & button {
    height: 70px !important;
    width: 180px !important;
    margin: 40px 0 0 0 !important;
    display: block !important;
    font-size: 20px !important;
    text-align: center !important;
    ${smDown`
      height: 56px !important;
      width: 180px !important;
      margin: 22px auto 0 !important;
    `}
  }
`;
const StyledAlertIcon = styled(AlertCircle)`
    transform: rotate(180deg);
    height: 65px;
    width: 65px;
`;
const StyledActionModal = styled(ActionModal)`
    max-width: 550px;
    margin-bottom: 0;
    padding-bottom: 10px !important;
    @media (min-width: 550px) {
      padding: 40px 60px;
    }
`;
const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
`;
const DialogTitle = styled(Heading)`
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #003DA5;
    font-family: "Stag Medium", "Stag-Medium", "StagMedium", "Stag", sans-serif;
    margin-top: 30px;
`;
const DialogContent = styled.div`
    max-width: 100%;
    text-align: center;
    font-family: "Inter-Regular", "Inter Regular", "InterRegular", "Inter", sans-serif;
    font-size: 18px;
    color: #0A1F44;
    line-height: 25px;
`;

const AutoRenewalErrorPopup = (props) => {
    const cfg = useConfig(props.lang);

    return (
        <StyledActionModal isOpen={props.open}>
            <StyledContainer>
                <StyledAlertIcon/>
                <DialogTitle size="big" weight="medium" color="tertiary">
                    {cfg("labels.autoRenewal.titleManageAutoRenewal")}
                </DialogTitle>
                <DialogContent>
                    {props.errorMessage}
                </DialogContent>
                <StyledButtonWrapper>
                    <QnectButton onClick={() => props.hidePopUp()} field="ok-autoRenewal"/>
                </StyledButtonWrapper>
            </StyledContainer>
        </StyledActionModal>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
    }
};

export default withRouter(connect(mapStateToProps, null)(AutoRenewalErrorPopup));
