import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  position: relative;
  width: ${({ device }) =>
    device === "desktop" ? "207px" : device === "mobile" ? "182px" : ""};
  height: ${({ device }) =>
    device === "desktop" ? "70px" : device === "mobile" ? "59px" : ""};
`;

const ProvideBoxShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    height: 15px;
    background: #1d4fbb;
    bottom: -7px;

    right: ${({ placement }) => (placement === "right" ? "-2%" : "")};
    left: ${({ placement }) => (placement === "left" ? "2%" : "")};

    border-right-width: ${({ device }) =>
      device === "desktop" ? "40px" : device === "mobile" ? "20px" : ""};
    border-style: solid;
    border-color: rgb(29, 79, 187);

    -webkit-border-bottom-left-radius: ${({ placement }) =>
      placement === "right" ? "110px 50px" : "0"};
    -moz-border-radius-bottomleft: ${({ placement }) =>
      placement === "right" ? "110px 50px" : "0"};
    border-bottom-left-radius: ${({ placement }) =>
      placement === "right" ? "110px 50px" : "0"};

    -webkit-border-bottom-right-radiuss: ${({ placement }) =>
      placement === "left" ? "40px 25px" : "0"};
    -moz-border-radius-bottomright: ${({ placement }) =>
      placement === "left" ? "40px 25px" : "0"};
    border-bottom-right-radius: ${({ placement }) =>
      placement === "left" ? "40px 25px" : "0"};

    -webkit-transform: translate(0, -2px);
    -moz-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    height: 17px;
    background: #fff;

    bottom: -8px;

    width: ${({ placement }) =>
      placement === "right" ? "20px" : placement === "left" ? "10px" : ""};

    right: ${({ placement }) => (placement === "right" ? "-16.6%" : "")};
    left: ${({ placement }) => (placement === "left" ? "18%" : "")};

    -webkit-border-bottom-left-radius: ${({ placement }) =>
      placement === "right" ? "95px 100px" : "0"};
    -moz-border-radius-bottomleft: ${({ placement }) =>
      placement === "right" ? "95px 100px" : "0"};
    border-bottom-left-radius: ${({ placement }) =>
      placement === "right" ? "95px 100px" : "0"};

    -webkit-border-bottom-right-radius: ${({ placement }) =>
      placement === "left" ? "20px 25px" : "0"};
    -moz-border-radius-bottomright: ${({ placement }) =>
      placement === "left" ? "20px 25px" : "0"};
    border-bottom-right-radius: ${({ placement }) =>
      placement === "left" ? "20px 25px" : "0"};

    -webkit-transform: translate(-30px, -2px);
    -moz-transform: translate(-30px, -2px);
    -ms-transform: translate(-30px, -2px);
    -o-transform: translate(-30px, -2px);
    transform: translate(-30px, -2px);
  }
`;

const Blockquote = styled.blockquote`
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px 0 rgba(74, 105, 197, 0.4);
  z-index: 3;

  line-height: ${({ device }) =>
    device === "desktop" ? "70px" : device === "mobile" ? "59px" : ""};
  margin: 0;
  text-align: center;
  color: #fff;
  background: #1d4fbb;
  border-radius: 40px;
  width: 100%;
  height: 100%;
`;

const P = styled.p`
  margin: 0;
  font-family: Inter;
  font-size: ${({ device }) =>
    device === "desktop" ? "18px" : device === "mobile" ? "16px" : ""};
  font-weight: 500;
  letter-spacing: 0;
`;

const OvalSpeech = ({ device, placement = "left", cfg }) => {
  return (
    <Wrapper device={device}>
      <ProvideBoxShadow device={device} placement={placement} />
      <Blockquote device={device} placement={placement}>
        <P>{cfg('policy.heading.howMayIHelp')}</P>
      </Blockquote>
    </Wrapper>
  );
};

OvalSpeech.propTypes = {
  device: PropTypes.oneOf(["mobile", "tablet", "desktop"]).isRequired,
  placement: PropTypes.oneOf(["left", "right"])
};

export default OvalSpeech;
