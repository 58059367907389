import {css} from 'styled-components';

import {smUp} from './breakpoints';

/**
 * @typedef {('heading'|'subheading'|'paragraph'|'text')} TypographyType
 */

/**
 * Generate style-components styles for the specified theme typography entry.
 *
 * @param {object}             options                     - (see below)
 * @param {TypographyType}     options.type                - the name of the typography entry in the theme
 * @param {string[]}           [options.sizes]             - the set of valid theme sizes
 * @param {string}             [options.defaultSize]       - the default theme size
 * @param {string[]}           [options.weights]           - the set of valid theme weights
 * @param {string}             [options.defaultWeight]     - the default theme weight
 * @param {string}             [options.defaultColor]      - the default theme color
 * @returns {*} typography styles that could be resolved given the specified options
 */
export default function typographyStyles({
    type,
    sizes,
    defaultSize,
    weights,
    defaultWeight,
    defaultColor,
}) {
    const sizesMap = arrayAsMap(sizes);
    const weightsMap = arrayAsMap(weights);

    /* eslint-disable indent */
    return css`
        ${({theme, scSize, scWeight, scColor}) => {
            const themeSizing = getThemeSizing({
                theme,
                type,
                scSize,
                sizes: sizesMap,
                defaultSize,
            });

            const effectiveColor = theme.color[scColor] || theme.color[defaultColor];

            return css`
                font-family: ${theme.typography[type].fontFamily};
                font-weight: ${theme.typography[type].fontWeight[weightsMap[scWeight] || defaultWeight]};

                ${
                    effectiveColor
                        ? `
                            color: ${effectiveColor};
                        `
                        : ''
                }

                ${
                    themeSizing
                        ? css`
                            line-height: ${themeSizing.lineHeightPx.mobile}px;
                            letter-spacing: ${themeSizing.letterSpacingPx.mobile}px;
                            font-size: ${themeSizing.fontSizePx.mobile}px;

                            ${smUp`
                                line-height: ${themeSizing.lineHeightPx.desktop}px;
                                letter-spacing: ${themeSizing.letterSpacingPx.desktop}px;
                                font-size: ${themeSizing.fontSizePx.desktop}px;
                            `}
                        `
                        : ''
                }
            `;
        }}
    `;
    /* eslint-enable indent */
}

function getThemeSizing({theme, type, scSize, sizes, defaultSize}) {
    return theme.typography[type].size[sizes[scSize] || defaultSize];
}

function arrayAsMap(array) {
    return (array || []).reduce((acc, value) => ({...acc, [value]: value}), {});
}
