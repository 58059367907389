import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';

const Timer = (props) => {
    const expireText = 'Expires in ';
    const { countdownMillis } = props;

    // Renderer callback with condition
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            props.onCompleteTime(true);
            return <span />;

        } else {
            // Render a countdown
            return <span>{expireText}{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    };

    return <Countdown
        date={Date.now() + countdownMillis}
        renderer={renderer}
    />
}

export default Timer;