import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import PropTypes from "prop-types";
import {_getCountry, _getLang} from "../../../store/system/service";
import {connect, useSelector} from "react-redux";
import QnectButton from "../../qnect-ui/qnect-button";
import ButtonWrapper from "../button-wrapper";
import WhatsAppMessage from "../whats-app-message";
import styled from "styled-components";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 60px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialogTitle-root": {
        padding: " 16px 0"
      }
    },
    "& .MuiDialogContent-root": {
      padding: "8px 10px"
    }
  },
  title: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "43px"
    }
  },
  back: {
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
      marginRight: "12px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "28px",
      height: "28px",
      marginRight: "16px"
    },
    cursor: "pointer"
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "30px",
      fontSize: "18px",
      lineHeight: "25px"
    },
    "& > span": {
      color: "#003DA5",
      cursor: "pointer",
      fontWeight: "600"
    },
    whiteSpace: "pre-wrap"
  },
  row: {
    color: "#0a1f44",
    textAlign: "center",
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        marginTop: "40px"
      },
      margin: "20px auto 0",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      "&:first-child": {
        marginTop: "30px"
      },
      margin: "30px auto 0",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  icon: {
    width: "24px",
    height: "24px",
    marginRight: "4px"
  },
  phoneMessage: {
    textAlign: "center",
    color: "#8A94A6",
    fontSize: "16px",
    fontFamily: "Inter-Regular",
    marginTop: "4px"
  },
  buttonStyle: {
    margin: "23px auto 0",
    width: "180px"
  }
}));

const StyledIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > :first-child {
    padding-right: 4px;
  }
  a {
    text-decoration: none;
    color: black;
  }
`;

const ForgetEmailContactDialog = ({
  open,
  onBack,
  onClose,
    cfg,
    ...props
}) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [url, setUrl] = useState("");
  const lang = useSelector(state => _getLang(state));

  useEffect(() => {
    setPhoneNumber(cfg('needHelpDialog.' + props.country + '.phoneNumber'));
    setUrl(cfg('needHelpDialog.' + props.country + '.email'));
  }, [props.country]);

  function openContactUsForm() {
    if (props.country === 'SGP') {
      window.open(process.env.REACT_APP_ONLINE_ENQUIRY_SG, '_blank');
    } else  if(props.country === 'MYS') {
      window.open(process.env.REACT_APP_ONLINE_ENQUIRY_MY, '_blank');
    }else{
      if (props.lang === 'zh') {
        window.open(process.env.REACT_APP_ONLINE_ENQUIRY_ZH, '_blank');
      } else {
        window.open(process.env.REACT_APP_ONLINE_ENQUIRY, '_blank');
      }
    }
  }

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title={
            <div className={classes.title}>
              {
                onBack &&
                <Back className={classes.back} onClick={onBack}/>
              }
              { props.country === 'HKG' &&
                <div style={lang === 'zh' ? {marginLeft: '24px'} : null}>
                  {cfg("forgotEmail.needHelp")}
                </div>
              }
              { props.country === 'MYS' &&
              <div style={{marginLeft: '24px'}}>
                {cfg("forgotEmail.needHelp")}
              </div>
              }
              { props.country === 'SGP' &&
                <div style={{marginLeft: '41px'}}>
                  {cfg("forgotEmail.needHelp")}
                </div>
              }
            </div>
          }
          onClose={onClose}
          lang={props.lang}
        />
        <DialogContent>
          <div className={classes.message}>
            <p style={{margin: '0px'}}>{props.country === 'HKG' ? cfg("forgotEmail.helpMessage1") : cfg("forgotEmail.helpMessage1_mys")}</p>
            <br/>
            <WhatsAppMessage country={props.country}/>
            <br/>
            {cfg("forgotEmail.helpMessage."+props.country)}
            <span onClick={() => openContactUsForm()}>
              {cfg("forgotEmail.contactUsForm")}
            </span>
          </div>
          <br/>
            <StyledIconRow onClick={()=>{window.open(props.country === 'MYS' ? 'tel:+60378618480' : 'tel:+85228283138')}}>
              <PhoneIcon /> {props.country === 'MYS' ? '+60 3 7861 8480' : '+852 2828 3138'}
            </StyledIconRow>
            <div className={classes.phoneMessage}>{props.country === 'MYS' ? cfg("forgotEmail.phoneMessage_my") : cfg("forgotEmail.phoneMessage")}</div>

          <div>
            {props.country === 'HKG' &&
            <>
              <div className={classes.row}>
                <Phone className={classes.icon}/>
                {phoneNumber}
              </div>
              <div className={classes.phoneMessage}>{cfg("forgotEmail.phoneMessage")}</div>
            </>
            }
            {props.country === 'SGP' &&
            <ButtonWrapper className={classes.buttonStyle}>
              <QnectButton
                  field="ok"
                  onClick={() => onClose()}
              ></QnectButton>
            </ButtonWrapper>
            }
            <div className={classes.row}>
              <Mail className={classes.icon} />
              <a href={props.country === 'MYS' ? "mailTo:cs@qbe.com.my" : "mailTo:customerservice@qbe.com"} style={{color: "black"}}>{props.country === 'MYS' ? "cs@qbe.com.my" : "customerservice@qbe.com"}</a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ForgetEmailContactDialog.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = state => {
  return {
    country: _getCountry(state)
  };
};

export default connect(mapStateToProps)(ForgetEmailContactDialog);
