import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {smUp} from '../../common/breakpoints';
import Text from '../typography/text';
import Link from '../typography/link';
import typographyStyles from '../../common/typography-styles';

const List = styled.dl`
    margin: 0;
    padding: 0;

    ${smUp`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 180px 1fr;
        grid-template-columns: 180px auto;
    `}
`;

const Title = styled.dt.attrs(({scRow}) => ({
    style: {
        msGridRow: scRow,
    },
}))`
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 8px;

    ${smUp`
        margin-right: 12px;
        -ms-grid-column: 1;
    `}

    &:last-of-type {
        padding: 0;
    }
`;

const Definition = styled.dd.attrs(({scRow}) => ({
    style: {
        msGridRow: scRow,
    },
}))`
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 24px;

    ${smUp`
        margin-left: 12px;
        padding: 0 0 8px;
        -ms-grid-column: 2;
    `}

    &:last-of-type {
        padding: 0;
    }
`;

const StyledLink = styled(Link)`
    margin-left: 12px;
`;

const Address = styled.address`
    font-style: normal;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'semiBold',
        defaultColor: 'tertiary90',
    })}
`;

const NormalText = (props) => <Text {...props} size="normal" color="tertiary90" />;
const SemiBoldText = (props) => <NormalText {...props} weight="semiBold" />;

export default function PolicyDetails({
    className,
    insuredTitle,
    insured,
    insuredDurationTitle,
    insuredDuration,
    inceptionDate,
    addressTitle,
    address,
    previousClaimsTitle,
    previousClaims,
    canViewRecent,
    viewRecentLabel,
    viewRecentHref,
    onClickViewRecent,
}) {
    let row = 0;

    return (
        <List className={classnames('PolicyDetails', className)}>
            {!!insuredTitle && (
                <>
                    <NormalText
                        as={Title}
                        scRow={++row}
                    >
                        {insuredTitle}
                    </NormalText>
                    <SemiBoldText
                        as={Definition}
                        scRow={row}
                    >
                        {insured}
                    </SemiBoldText>
                </>
            )}

            {!!insuredDurationTitle && (
                <>
                    <NormalText
                        as={Title}
                        scRow={++row}
                    >
                        {insuredDurationTitle}
                    </NormalText>
                    <Definition scRow={row}>
                        <SemiBoldText>
                            {insuredDuration}
                        </SemiBoldText>
                        {' '}
                        <NormalText as="em">
                            {inceptionDate}
                        </NormalText>
                    </Definition>
                </>
            )}

            {!!addressTitle && (
                <>
                    <NormalText
                        as={Title}
                        scRow={++row}
                    >
                        {addressTitle}
                    </NormalText>
                    <Definition scRow={row}>
                        <Address>
                            {(address || []).map((addressLine, index) => (
                                <React.Fragment key={index}>
                                    {addressLine}
                                    {index < address.length - 1 && (
                                        <br />
                                    )}
                                </React.Fragment>
                            ))}
                        </Address>
                    </Definition>
                </>
            )}

            {!!previousClaimsTitle && (
                <>
                    <NormalText
                        as={Title}
                        scRow={++row}
                    >
                        {previousClaimsTitle}
                    </NormalText>
                    <Definition scRow={row}>
                        <SemiBoldText>
                            {previousClaims}
                        </SemiBoldText>
                        {!!canViewRecent && (
                            <>
                                {' '}
                                <StyledLink
                                    href={viewRecentHref}
                                    onClick={onClickViewRecent}
                                >
                                    {viewRecentLabel}
                                </StyledLink>
                            </>
                        )}
                    </Definition>
                </>
            )}
        </List>
    );
}

PolicyDetails.propTypes = {
    className: propTypes.string,
    insuredTitle: propTypes.string,
    insured: propTypes.string,
    insuredDurationTitle: propTypes.string,
    insuredDuration: propTypes.string,
    inceptionDate: propTypes.string,
    addressTitle: propTypes.string,
    address: propTypes.arrayOf(propTypes.string),
    previousClaimsTitle: propTypes.string,
    previousClaims: propTypes.string,
    canViewRecent: propTypes.bool,
    viewRecentLabel: propTypes.string,
    viewRecentHref: propTypes.string,
    onClickViewRecent: propTypes.func,
};
