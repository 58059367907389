import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../dialog-title";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import QnectButton from "../../qnect-ui/qnect-button";
import { ReactComponent as AuthTipsOne } from "../../../assets/registration/icon_authentication_one.svg";
import { ReactComponent as AuthTipsTwo } from "../../../assets/registration/icon_authentication_two.svg";
import AuthTipsThree from "../../../assets/registration/icon_authentication_three.svg";
import { ReactComponent as ArrowRight } from "../../../assets/registration/arrow_forward_sharp.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/registration/back_sharp.svg";
import Dialog from "../dialog";
import ButtonWrapper from "../button-wrapper";
import * as TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none",
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiDialog-paper": {
        padding: "14px 14px 52px",
        width: "596px",
        [theme.breakpoints.up("lg")]: {
          padding: "14px 38px 52px",
          width: "720px",
        },
      },
    },
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    fontSize: "18px",
    lineHeight: "25px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  iconWrapper: {
    margin: "69px auto 16px",
    width: "414px",
    position: "relative",
    height: "154px",
    [theme.breakpoints.down("md")]: {
      marginTop: "44px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "142px",
      margin: "40px auto 10px",
      width: "auto",
    },
  },
  icon: {
    display: "block",
    margin: "0 auto",
    width: "230px",
    height: "154px",
    [theme.breakpoints.down("sm")]: {
      height: "142px",
      width: "210px",
    },
  },
  iconExtra: {
    display: "block",
    margin: "0 auto",
    width: "305px",
    height: "149px",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      height: "103px",
      width: "210px",
    },
  },
  leftIcon: {
    position: "absolute",
    top: "calc(50% - 16px)",
    left: "0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
    },
    [theme.breakpoints.up("md")]: {
      width: "32px",
      height: "32px",
    },
  },
  rightIcon: {
    position: "absolute",
    top: "calc(50% - 16px)",
    right: "0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
    },
    [theme.breakpoints.up("md")]: {
      width: "32px",
      height: "32px",
    },
  },
  iconDescription: {
    fontFamily: "Inter-Medium",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "25px",
    textAlign: "center",
    color: "#0a1f44",
    width: "520px",
    height: "50px",
    margin: "0 auto",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      width: "303px",
      height: "63px",
      fontSize: "14px",
      lineHeight: "21px",
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  mobileStepper: {
    "&.MuiMobileStepper-root": {
      backgroundColor: "transparent",
      width: "100%",
    },
    "& .MuiMobileStepper-dot": {
      width: "6px",
      height: "6px",
      borderRadius: "6px",
      opacity: "0.3",
      backgroundColor: "#003DA5",
    },
    "& .MuiMobileStepper-dotActive": {
      opacity: "1",
      color: "#003DA5",
      width: "20px",
      height: "6px",
      borderRadius: "6px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "36px",
    },
  },
  actions: {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: "22px 0 0 0 !important",
    },
    [theme.breakpoints.down("md")]: {
      margin: "35px 54px 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "8px 62px",
      marginTop: "26px",
    },
  },
  actionText: {
    fontFamily: "Inter",
    lineHeight: "24px",
    color: "#003da5",
    fontWeight: 500,
    fontSize: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginTop: "12px",
    },
  },
}));

export default function AuthTipsDialog({ open, onClose, onOpenAnother, cfg, lang, country }) {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    TagManager.dataLayer({
      dataLayer: {
        event: 'Icon-Click',
        eventCategory: 'Right-Arrow'
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    TagManager.dataLayer({
      dataLayer: {
        event: 'Icon-Click',
        eventCategory: 'Left-Arrow'
      }
    });
  };

  return (
    <>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle title={cfg('labels.authenticationTips.title')} onClose={onClose} lang={lang}/>
        <DialogContent>
          <div className={classes.message}>{cfg('labels.authenticationTips.pleaseConfirmFormatMessage')}</div>
          <div className={classes.iconWrapper}>
            {activeStep !== 0 && <ArrowLeft className={classes.leftIcon} onClick={handleBack} />}
            {activeStep === 0 && <AuthTipsOne className={classes.icon} />}
            {activeStep === 1 && <AuthTipsTwo className={classes.icon} />}
            {activeStep !== 1 && <ArrowRight className={classes.rightIcon} onClick={handleNext} />}
          </div>

          <div className={classes.iconDescription}>
            {activeStep === 0 && (
              <>
                {cfg('labels.authenticationTips.lettersAnd6Digits'+country)}
                <br/>
                {cfg('labels.authenticationTips.noPassport')}
              </>
            )}
            {activeStep === 1 && <>{cfg('labels.authenticationTips.pleaseReferToFullName')}</>}
          </div>
          <MobileStepper
            className={classes.mobileStepper}
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            nextButton={<Button disabled />}
            backButton={<Button disabled />}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div
            className={classes.actionText}
            onClick={() => {
              onClose();
              onOpenAnother();
            }}
          >
            {cfg('labels.authenticationTips.needHelpContactUs')}
          </div>
          <ButtonWrapper>
            <QnectButton onClick={onClose}>{cfg("labels.tryAgain")}</QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </>
  );
}
