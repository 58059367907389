import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import clamp from 'lodash/clamp';

import {colorType} from '../../common/common-prop-types';
import typographyStyles from '../../common/typography-styles';

const sizes = [
    'huge',
    'big',
    'large',
    'medium',
    'normal',
    'small',
];

const weights = [
    'medium',
    'bold',
];

const StyledHeading = styled.h1`
    margin: 16px 0;

    ${typographyStyles({
        type: 'heading',
        sizes,
        defaultSize: 'huge',
        weights,
        defaultWeight: 'bold',
        defaultColor: 'primary',
    })}
`;

export default function Heading({
    className,
    size,
    weight,
    level,
    color,
    children,
    ...rest
}) {
    return (
        <StyledHeading
            {...rest}
            className={classnames('Heading', className)}
            as={`h${level ? clamp(level, 1, 6) : 1}`}
            scSize={size}
            scWeight={weight}
            scColor={color}
        >
            {children}
        </StyledHeading>
    );
}

Heading.propTypes = {
    className: propTypes.string,
    size: propTypes.oneOf(sizes),
    weight: propTypes.oneOf(weights),
    level: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
    color: colorType,
    children: propTypes.node,
};
