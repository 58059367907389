import React, { memo } from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const ErrorMessage = styled.div`
  margin: 8px auto 0;
  width: ${({ numInputs }) =>
    numInputs ? numInputs * 74 - 24 + "px" : "auto"};
  font-size: 16px;
  line-height: 24px;
  color: #d54040;
  margin-top: 11px;
  word-break: keep-all;
  white-space: pre-wrap;
  font-family: "Inter";
`;

const StyledOtpInput = styled(OtpInput)`
  input[value=""] {
    border-bottom-color: ${({ hasErrored }) =>
      hasErrored ? "#D54040" : "#003da5"};
  }
`;

const useStyles = makeStyles(theme => ({
  input: {
    margin: "0 12px",
    padding: "6px 0",
    width: "50px !important",
    fontSize: "28px",
    lineHeight: "41px",
    color: "#0A1F44",
    border: 0, //default
    outline: "none",
    borderBottom: "2px solid #C4CAE0",
    [theme.breakpoints.down("sm")]: {
      margin: "0 8px",
      width: "32px !important",
      fontSize: "20px",
      lineHeight: "29px"
    }
  },
  errorMessage: {
    [theme.breakpoints.down("sm")]: {
      width: "auto !important"
    }
  }
}));

function CustomizedOtpInput({
  value = "",
  onChange,
  numInputs = 6,
  isDisabled,
  errorMessage,
  shouldAutoFocus,
  className
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <StyledOtpInput
        value={value}
        numInputs={numInputs}
        isDisabled={isDisabled}
        hasErrored={!!errorMessage}
        shouldAutoFocus={shouldAutoFocus}
        isInputNum={true}
        containerStyle={{
          justifyContent: "center"
        }}
        inputStyle={classes.input}
        focusStyle={{
          outline: "none",
          borderColor: "#003DA5"
        }}
        errorStyle={{
          borderColor: "#D54040 !important"
        }}
        onChange={onChange}
      />
      {!!errorMessage && (
        <ErrorMessage className={classes.errorMessage} numInputs={numInputs}>
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
}

CustomizedOtpInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  numInputs: PropTypes.number,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  className: PropTypes.string
};

export default memo(CustomizedOtpInput);
