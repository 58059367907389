import trim from 'lodash/trim';

export default function checkmark(color) {
    return ({theme}) => (
        `url('data:image/svg+xml;base64,${
            btoa(
                `
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20" style="width:20px;height:20px;">
                        <circle fill="${theme.color[color]}" fill-rule="evenodd" cx="10" cy="10" r="5" />
                    </svg>
                `
                    .split('\n')
                    .map(trim)
                    .join('')
            )
        }')`
    );
}
