import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import QnectTextField from "../../qnect-ui/qnect-text-field";
import BalloonTip from "../../../components/ui/balloon-tip/balloon-tip";
import ButtonWrapper from "../button-wrapper";
import { smDown, lgDown } from "../../../ui/common/breakpoints";

const StyledMessage = styled.div`
  margin-top: 30px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 534px;
  ${lgDown`
    width: 462px;
    margin: 20px auto;
  `}
  ${smDown`
    margin-top: 4px;
    font-size: 16px;
    line-height: 20px;
    width: auto;
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "14px 24px 52px",
    [theme.breakpoints.between("sm", "md")]: {
      margin: "14px 42px 52px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

const StyledDialogActions = withStyles({
  root: {
    margin: "0 auto",
    fontFamily: "Inter",
  },
})(DialogActions);

const StyledTextFieldWrapper = styled.div`
  width: 534px;
  margin: 85px 0 28px;
  position: relative;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  > .BalloonTip {
    top: -17px;
    left: ${({ lang }) => lang === 'en' ? "-203px" : "-230px"};
    @media all and (-ms-high-contrast: none) {
      left: -405px;
    }
  }
  > .BalloonTip .contentDiv {
    top: -140px !important;
    width: 306px !important;
    height: 48px !important;
    border-radius: 2px !important;
    margin-left: ${({ lang }) => lang === 'en' ? "331px !important" : "300px !important"};
    padding: 4px 12px 4px;
    background: rgba(78, 93, 120, 0.9) !important;
  }
  > div:not(.form-caption) {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  ${lgDown`
    width: 462px;
    margin: 70px auto 24px;
    & > .BalloonTip {
      left: -166px;
      left: ${({ lang }) => lang === 'en' ? "-166px" : "-193px"};
      .contentDiv {
        margin-left: ${({ lang }) => lang === 'en' ? "300px !important" : "270px !important"};
      }
    }
  `}
  ${smDown`
    width: auto;
    margin: 60px 0 0;
    & .MuiInputLabel-formControl {
      font-size: 16px !important;
      line-height: 24px !important;
      top: -30px !important;
    }
    & > .BalloonTip {
      top: -15px;
      left: ${({ lang }) => lang === 'en' ? "-94px" : "-118px"};
      .contentDiv {
        margin-left: ${({ lang }) => lang === 'en' ? "90px !important" : "110px !important"};
      }
    }
  `}
`;

const StyledHowFind = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #003da5;
  font-family: Inter;
  text-decoration: underline;
  margin-bottom: 0 !important;
  cursor: pointer;
  margin-top: 36px;
  width: 279px;
  ${smDown`
    width: 248px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 30px !important;
  `}
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  ${smDown`
    & button {
      width: 160px;
    }
  `}
`;

const LocatePolicyDialog = ({ open, onClose, onOther, onNext, cfg, lang }) => {
  const [policyNumber, setPolicyNumber] = React.useState("");

  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle title="Could not locate your policy?" onClose={onClose} />
        <DialogContent>
          <StyledMessage>
            Please enter the policy number. We will review your request and add it to your account once it has been verified.
          </StyledMessage>
          <StyledTextFieldWrapper lang={lang}>
            <QnectTextField
              field="policyNumber"
              value={policyNumber}
              onChange={(e) => setPolicyNumber(e.target.value)}
              errorMessage={policyNumber === "1" && cfg("labels.policyNumberErrMsg")}
            />
            <BalloonTip>Please note this platform is not applicable to group medical insurance policy</BalloonTip>
          <StyledHowFind
            onClick={() => {
              onClose();
              onOther();
            }}
            isError={policyNumber === "1"}
          >
            { cfg('login.howToFindPolicy') }
          </StyledHowFind>
          </StyledTextFieldWrapper>
        </DialogContent>
        <StyledDialogActions>
          <StyledButtonWrapper>
            <QnectButton
              onClick={() => {
                onClose();
                onNext();
              }}
              disabled={policyNumber === ""}
            >
              {cfg("labels.continue")}
            </QnectButton>
          </StyledButtonWrapper>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
};

export default LocatePolicyDialog;
