import styled from "styled-components";
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as QbeLogo } from "../../../assets/icon_logo.svg";
import { ReactComponent as Circle } from "../../../assets/login/CurrentStep.svg";
import { lgDown } from '../../../ui/common/breakpoints';

const StyledLeftStepper = styled.div`
  background-color: #f6f8fa;
  position: relative;
  min-height: 100vh;
  width: 420px;
  ${lgDown`
    width: 244px;
  `}
`;

const StyledStepper = styled(Stepper)`
  background-color: transparent !important;
  
  .MuiStep-root.MuiStep-vertical {
    text-align: center;
  }
`;

const CircleIcon = styled(Circle)`
  width: 80px;
  height: 80px;
  margin-top: -10px;
  ${lgDown`
    height: 45px;
    width: 45px;
    margin-top: 10px;
    margin-right: 25px;
  `}
`;

const StyledQbeLogo = styled(QbeLogo)`
  display: block;
  height: 35px;
  width: 122px;
  margin: 40px auto 85px;
  ${lgDown`
    margin: 22px auto 30px 24px;
  `}
`;

const IconWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  padding-bottom: 10px;
  justify-content: flex-end;
  height: 102px;
  width: 136px;
`;

const CustomIcon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (-ms-high-contrast: none) and (max-width: 1280px) {
    left: -40px !important;
  }
  @media all and (-ms-high-contrast: none) {
    left: -35px;
  }
`;

const StyledConnector = withStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 31,
    paddingBottom: 31,
  },
  lineVertical: {
    height: 60,
    width: 3,
    backgroundColor: "#C1C9EA",
    radius: 2,
  },
})(StepConnector);

const StyledLabel = withStyles({
  root: {
    "& .MuiStepLabel-label.MuiStepLabel-active, .MuiStepLabel-label.MuiStepLabel-completed": {
      color: "#003DA5",
      "& path": {
        fill: "#003DA5",
      },
    },
  },
  labelContainer: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontFamily: "Stag",
    fontSize: 20,
    color: "#8A94A6",
    paddingLeft: 10,
  },
})(StepLabel);

const LeftStepper = (props) => {
  const { steps, activeStep } = props;
  const homeIconLink = props.country === "MYS" ? "https://www.qbe.com/my/about-qbe" : "https://www.qbe.com/hk/en/about-qbe";

  return (
    <StyledLeftStepper>
      <a href={homeIconLink}>
      <StyledQbeLogo />
      </a>
      <StyledStepper activeStep={activeStep} nonLinear orientation="vertical" connector={<StyledConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StyledLabel icon={null}>
              <IconWrapper>
                <CustomIcon>{props.img[index]}</CustomIcon>
                {index === activeStep ? <CircleIcon /> : null}
              </IconWrapper>
              {label}
            </StyledLabel>
          </Step>
        ))}
      </StyledStepper>
    </StyledLeftStepper>
  );
};

export default LeftStepper;
