import trim from 'lodash/trim';

export default function checkmark(color) {
    return ({theme}) => (
        `url('data:image/svg+xml;base64,${
            btoa(
                `
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20" style="width:20px;height:20px;color:${theme.color[color]};">
                        <path fill="currentColor" fill-rule="evenodd" d="M4.843 9.207l3.536 3.536 7.778-7.779L17.57 6.38 8.38 15.57l-4.95-4.95 1.414-1.414z"/>
                    </svg>
                `
                    .split('\n')
                    .map(trim)
                    .join('')
            )
        }')`
    );
}
