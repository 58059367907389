import React from 'react';

import styles from './style/qr-code.module.css';

const QrCodeDisplay = (props) => {
    const { isExpired, qrCodeValue } = props;
    const opacityValue = isExpired ? 0.1 : 1;

    return (<div>
        <img className={styles.qrCode} src={`data:image/png;base64,${qrCodeValue}`}
             style={{opacity: opacityValue}}
        />
    </div>);
}

export default QrCodeDisplay;