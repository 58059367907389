import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectButton from "../qnect-ui/qnect-button";
import FindMyPolicyDialog from "../custom/registration/find-my-policy-dialog";
import Registration from "./registration";
import {_scrollToTop} from "../../utils/utility";
import {connect} from "react-redux";
import {_getPolicy, _verifyPolicy, _storePolicy} from "../../store/policy/service";
import {
    _getCountry,
    _getLang,
    _hideSpinner,
    _setFindMyPolicyBusinessState,
    _setLang,
    _showSpinner
} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";
import {LanguageSelectDiv} from "../login-style";
import Hidden from "@material-ui/core/Hidden";
import MobileHeader from "../custom/registration/mobile-header";
import { smDown, lgDown } from '../../ui/common/breakpoints';
import {_getUser, _storeUser} from "../../store/user/service";

const Title = styled.div`
  font-family: "Stag";
  font-size: 40px;
  color: #003da5;
  line-height: 48px;
  text-align: left;
  ${lgDown`
    font-size: 32px;
    line-height: 40px;
  `}
`;

const Message = styled.div`
  background-color: rgba(193, 201, 234, 0.3);
  color: #0a1f44;
  border-left: 2px solid #003da5;
  margin-top: 30px;
  margin-bottom: 30px;
  ${smDown`
    margin: 20px 16px;
  `}
  & > div {
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    padding-right: 14px;
    padding-left: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    ${smDown`
      font-size: 14px;
      line-height: 20px;
      padding: 12px;
    `}
  }
  
  span.clickHere {
      cursor: pointer;
      color: #1B50AE;
  }
`;

const InputGroupStyle = styled.div`
  margin-top: 80px;
  text-align: right;
  ${lgDown`
    margin-top: 60px;
  `}
`;

const FindPolicyEl = styled.div`
  font-family: "Inter";
  line-height: 24px;
  font-size: 18px;
  color: #003da5;
  margin-top: 12px;
  cursor: pointer;
  text-decoration: underline;
  ${lgDown`
    font-size: 16px;
  `}
  ${smDown`
    margin-left: 16px;
    margin-top: 15px;
  `}
`;

const PopperButtonText = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

const LoginTextContainer = styled.div`
  text-align: center;
  font-size: 18px;
  font-family: "Inter";
  line-height: 21px;
  font-weight: 500;
  margin-top: 185px;
  & > :first-child {
    color: #8a94a6;
  }
  & > :nth-child(2) {
    color: #003da5;
    cursor: pointer;
  }
  ${lgDown`
    margin-top: 65px;
  `}
`;

const StyledPaper = styled(Paper)`
  width: 540px !important;
  height: auto !important;
  background-color: #4a69c5 !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  line-height: 36px !important;
  position: relative !important;
  z-index: 999 !important;
  margin-right: 290px !important;
  @media (max-width: 1100px) {
    margin-left: 50px;
  }
  ${lgDown`
    width: 400px !important;
    margin-right: 150px !important;
    margin-left: 0px;
  `}
  ${smDown`
    width: 343px !important;
    margin: 0 auto !important;
  `}
`;

const StyledUpArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #4a69c5;
  margin-left: 255px;
  @media (max-width: 1100px) {
    margin-left: 400px;
  }
  ${lgDown`
    margin-left: 190px;
  `}
  ${smDown`
    margin: 0 auto;
    display: block;
  `}
`;

const PaperContent = styled.div`
  padding: 16px;
  text-align: center;
  & > :first-child {
    font-family: "Stag";
    font-weight: 500;
    line-height: 36px;
    align-text: center;
    font-size: 24px;
    ${lgDown`
      font-size: 18px;
      line-height: 24px;
    `}
  }
  & > :nth-child(2) {
    width: auto;
    font-size: 18px;
    font-family: "Inter";
    line-height: 25px;
    padding-top: 12px;
    ${lgDown`
      font-size: 14px;
      line-height: 21px;
    `}
  }
  & > :nth-child(3) {
    padding-top: 20px;
    text-align: right;
    ${smDown`
      text-align: center;
    `}
    & > .Button {
      width: 120px !important;
      height: 50px !important;
      border-radius: 35px !important;
      background-color: #14284b !important;
    }
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: 80px;
  text-align: right;
  ${lgDown`
    margin-top: 248px;
  `}
  ${smDown`
    margin-top: 60px;
    padding-right: 16px;
  `}
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  text-align: center;
  ${smDown`
    width: 160px;
    height: 56px;
    font-size: 16px;
    line-height: 24px;
  `}
`;

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-top: 40px;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  > div:not(.form-caption) {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 29px;
    right: 20px;
  }
  
   div.MuiFormControl-root > p.MuiFormHelperText-root.Mui-error {
       height: auto;
       padding: 10px 0 0 0;
   }
  
  ${lgDown`
    .MuiInputLabel-formControl {
      font-size: 16px !important;
      line-height: 24px !important;
      top: -30px !important;
    }
  `}
  ${smDown`
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
  `}
`;

const StyledPopper = styled(Popper)`
  z-index: 999 !important;
  ${smDown`
    width: 100vw;
    margin: 45px auto;
  `}
`;

const StyledLanguageDiv = styled(LanguageSelectDiv)`
    font-weight: normal;
    top: 5%;
    right: 15%;
`;

const PolicyNumberSection = (props) => {
  const cfg = useConfig(props.lang);
  const titleRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [policyNumber, setPolicyNumber] = React.useState(props.user.policyNumber);
  const [policyNumberErrorMessage, setPolicyNumberErrorMessage] = useState('');


    useEffect(() => {
        _scrollToTop();
        setAnchorEl(titleRef.current);
        setOpenPopper(!openPopper);
    }, []);

  const handleClickTitle = () => {
    setOpenPopper(!openPopper);
  };

    const continueNextStep = () => {
        if (checkIfPolicyNumberIsValid()) {
            props.showSpinner();
            props.verifyPolicy(policyNumber, 'true').then((response) => {
                if (response.success) {
                    props.hideSpinner();
                    props.user.policyNumber = policyNumber;
                    props.setCpToken(response.token);
                    props.storeUser(props.user);
                    props.history.push("/registration/authentication");
                } else {
                    setPolicyNumberErrorMessage(cfg(response.errorCode));
                }
                props.hideSpinner();
            }, (error) => {
                setPolicyNumberErrorMessage(error.response.statusText);
                props.hideSpinner();
            })
        }
    }

    const checkIfPolicyNumberIsValid = () => {
        let regex = new RegExp("^([a-zA-Z])([a-zA-Z0-9]{7})$|^([a-zA-Z]{2})([a-zA-Z0-9]{6})$", "g");
        if (regex.test(policyNumber)) {
            return true;
        }
        return false;
    }

    const switchLanguage = () => {
        let newLang = props.lang === 'en' ? 'zh' : 'en';
        props.setLang(newLang);
    }

    const redirectToBusinessLogin = () => {
        props.setFindMyPolicyBusinessState(true);
        props.history.push('/login');
    }

  return (
    <Registration currentStep={0}>
      <Hidden xsDown>
        <Title ref={setAnchorEl}>
              Registration
              <StyledLanguageDiv onClick={switchLanguage} style={{visibility: (props.country === 'SGP' ? 'hidden' : 'visible')}}>
                  {(props.lang === 'en') ?
                      cfg("labels.lang_zh")
                      :
                      cfg("labels.lang_en")
                  }
              </StyledLanguageDiv>
          </Title>
      </Hidden>
      <Hidden smUp>
        <MobileHeader
          title={"Registration"}
          lang={(props.lang === 'en') ? cfg("labels.lang_zh") : cfg("labels.lang_en")}
          onBack={() => props.history.go(-1)}
          onToggleLang={() => switchLanguage()}
          onClickTitle={() => handleClickTitle()}
          isHidden={props.country === 'SGP' ? true : false}
        />
      </Hidden>
      <Message>
        <div>
          Customer Portal's registration is yet to be ready for business clients and Group policyholder at the moment.
          <br />
            For business clients, you may click <span className="clickHere" onClick={redirectToBusinessLogin}>here</span> to check for your policy information.
        </div>
      </Message>

      <StyledTextFieldWrapper>
        <InputGroupStyle>
          <QnectTextField
                field="registrationPolicyNumber"
                value={policyNumber}
                onBlur={(evt) => {
                    setPolicyNumber(evt.target.value);
                    setPolicyNumberErrorMessage('');
                }}
                onChange={(evt) => {
                    setPolicyNumber(evt.target.value);
                    setPolicyNumberErrorMessage('');
                }}
                errorMessage={policyNumberErrorMessage}
                />
        </InputGroupStyle>
      </StyledTextFieldWrapper>

      <FindPolicyEl onClick={() => setOpenDialog(true)}>{ cfg('login.howToFindPolicy')}</FindPolicyEl>
      <StyledButtonWrapper>
        <StyledQnectButton onClick={continueNextStep} disabled={!policyNumber}>
          {cfg("labels.continue")}
        </StyledQnectButton>
      </StyledButtonWrapper>
      <Hidden smDown>
        <LoginTextContainer>
          <span>Already registered?</span> <span onClick={() => props.history.push("/login")}>Login Now</span>
        </LoginTextContainer>
      </Hidden>
      <StyledPopper
        open={openPopper}
        anchorEl={anchorEl}
        placement={"bottom"}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <StyledUpArrow />
              <StyledPaper>
                <PaperContent>
                  <div>What for?</div>
                  <div>
                    In order to deliver the policy schedules in the fastest way possible, we would like you to advise the best way
                      to reach you. After registering, you may access your information anywhere. You may also issue and check claims
                      status in here.
                  </div>
                  <div>
                    <QnectButton onClick={handleClickTitle}>
                      <PopperButtonText>OK</PopperButtonText>
                    </QnectButton>
                  </div>
                </PaperContent>
              </StyledPaper>
            </div>
          </Fade>
        )}
      </StyledPopper>
      <FindMyPolicyDialog open={openDialog} onClose={() => setOpenDialog(false)} cfg={cfg} />
    </Registration>
  );
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        lang: _getLang(state),
        country: _getCountry(state)
    }
}

const mapDispatchToProps = {
    verifyPolicy: _verifyPolicy,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storeUser: _storeUser,
    setLang: _setLang,
    setFindMyPolicyBusinessState: _setFindMyPolicyBusinessState
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PolicyNumberSection));
