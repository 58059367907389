import React from "react";
import {useStyles} from "../common-style";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";

import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";

import {connect} from "react-redux";
import {_getCountry} from "../../../store/system/service";
import WhatsAppMessage from "../whats-app-message";


const NoEmailOrMobileDialog = ({open, onClose, cfg, lang, ...props}) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                <DialogTitle title={cfg("registration.policyNotFound")} onClose={onClose} lang={lang}/>
                <DialogContent>
                    <div className={classes.input_wrapper2}>
                        <div>{cfg("registration.policyNotFoundMessage")}</div>
                        <br/>
                        <WhatsAppMessage/>
                        {lang === 'zh' ?
                            <div className={classes.input_wrapper3}>
                                <div>
                                    {cfg("registration.policyNotFoundMessage2")}
                                    <span className={classes.contact_us} onClick={() => {
                                        window.open(process.env.REACT_APP_ONLINE_ENQUIRY_ZH, '_blank');
                                    }}>
                                            {cfg("registration.contactUsForm")}
                                        </span>
                                </div>
                            </div>
                            :
                            <div className={classes.input_wrapper3}>
                                <div> {cfg("registration.policyNotFoundMessage2")}</div>
                                <div className={classes.contact_us} onClick={() => {
                                    window.open(process.env.REACT_APP_ONLINE_ENQUIRY, '_blank');
                                }}>
                                    {cfg("registration.contactUsForm")}
                                </div>
                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <ButtonWrapper className={classes.buttonStyle}>
                        <QnectButton
                            field="ok"
                            onClick={() => onClose("true")}
                        ></QnectButton>
                    </ButtonWrapper>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        country: _getCountry(state),
    };
};

export default connect(mapStateToProps, null)(NoEmailOrMobileDialog);
