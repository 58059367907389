import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 24px 32px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 32px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      lineHeight: "24px"
    }
  },
  b: {
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  actions: {
    flexDirection: "column",
    alignItems: "center",
    "&.MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: 0
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "24px"
    }
  },
  button: {
    marginBottom: "40px",
    [theme.breakpoints.up("sm")]: {
      width: "350px"
    }
  },
  received: {
    fontFamily: "Inter",
    letterSpacing: "0",
    color: "#0a1f44",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
      "&:nth-child(2)": {
        marginBottom: "4px"
      }
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
      "&:nth-child(2)": {
        marginBottom: "8px"
      }
    }
  },
  resend: {
    display: "inline-block",
    marginLeft: "8px",
    color: "#003da5",
    cursor: "pointer",
    textDecoration: "underline"
  },
  resend_disabled: {
    color: "#b0b7c3",
    cursor: "auto"
  },
  help: {
    display: "inline-block",
    color: "#003da5",
    cursor: "pointer"
  }
}));

export default function VerifyEmailDialog({
  email,
  open = false,
  onClose,
  onResend = null,
  onHelp = null,
  onConfirm = null
}) {
  const classes = useStyles();

  const timer = useRef(null);

  const [counter, setCounter] = useState(60);

  useEffect(() => {
    if (counter === 60 && open) {
      setCounter(59);
    }

    if (counter !== 60 && counter > 0) {
      timer.current = setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, open]);

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  function handleResend() {
    setCounter(60);
    onResend && onResend();
  }

  function handleHelp() {
    onHelp && onHelp();
  }

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title="Verify Your Updated Email Address"
          closable={false}
          onClose={onClose}
        />
        <DialogContent>
          <div className={classes.message}>
            A verification email has been sent to your email:
            <b className={classes.b}>{email}</b>
            Please click the link in the email to verify your email in order to
            update. You will be logged out and require to login again with your
            new email address.
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper>
            <QnectButton className={classes.button} onClick={onConfirm}>
              Confirm
            </QnectButton>
          </ButtonWrapper>

          <div className={classes.received}>
            Hasn't received it?
            {counter === 0 ? (
              <div
                className={[classes.received, classes.resend].join(" ")}
                onClick={handleResend}
              >
                Resend
              </div>
            ) : (
              <div
                className={[
                  classes.received,
                  classes.resend,
                  classes.resend_disabled
                ].join(" ")}
              >
                Resend ({counter})
              </div>
            )}
          </div>
          <div className={classes.received}>
            or do you{" "}
            <div className={classes.help} onClick={handleHelp}>
              need help?
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

VerifyEmailDialog.propTypes = {
  email: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onResend: PropTypes.func,
  onHelp: PropTypes.func,
  onConfirm: PropTypes.func
};
