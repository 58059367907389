import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 48px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "26px 50px 52px",
        width: "600px"
      }
    }
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-50px"
    }
  },
  back: {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginRight: "12px",
      width: "24px",
      height: "24px"
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "39px",
      width: "28px",
      height: "28px"
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      lineHeight: "24px"
    }
  },
  wrapper: {
    margin: "40px auto 0",
    color: "#0a1f44",
    textAlign: "center",
    fontFamily: "Inter",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
      "&:nth-child(3)": {
        marginTop: "20px"
      }
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
      "&:nth-child(3)": {
        marginTop: "30px"
      }
    }
  }
}));

const StyledPhone = styled(Phone)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const StyledMail = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

export default function NeedHelpDialog({
  open = false,
  onBack = null,
  onClose = null
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title={
            <div className={classes.title}>
              <Back className={classes.back} onClick={onBack} />
              Need Help?
            </div>
          }
          closable={false}
          onClose={onClose}
        />
        <DialogContent>
          <div className={classes.message}>
            Are you having trouble updating your email address? Please contact
            QBE Hong Kong’s Customer Service.
          </div>

          <div className={classes.wrapper}>
            <StyledPhone />
            +852 2123-1234
          </div>

          <div className={classes.wrapper}>
            <StyledMail />
            customerservice@qbe.com
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

NeedHelpDialog.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func
};
