
export default function formatCurrency(amount, fractionDigit = 2) {
    const formatter = new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD', minimumFractionDigits: fractionDigit, maximumFractionDigits: fractionDigit});

    if (amount === '') {
        return '';
    }
    if (isNaN(Number(amount))) {
        return '';
    }
    return formatter.format(amount);
}
