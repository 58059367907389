import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Actions from '../buttons/actions';
import Paragraph from '../typography/paragraph';
import FormLayout from './form-layout';

const CenteredParagraph = styled(Paragraph)`
    text-align: center;
`;

export default function QuestionForm({
    className,
    question = [],
    field,
    submitButtonText,
    onSubmit,
    disabled,
}) {
    return (
        <FormLayout
            className={classnames('QuestionForm', className)}
            description={question.map((paragraph, index) => (
                <CenteredParagraph
                    key={index}
                    color="tertiary90"
                    size={index === 0 ? 'big' : 'normal'}
                >
                    {paragraph}
                </CenteredParagraph>
            ))}
            fields={React.cloneElement(field, {disabled})}
            submission={(
                <Actions
                    primaryAction={{
                        label: submitButtonText,
                        isDisabled: disabled,
                        canSubmit: true,
                    }}
                />
            )}
            onSubmit={onSubmit}
        />
    );
}

QuestionForm.propTypes = {
    className: propTypes.string,
    question: propTypes.arrayOf(propTypes.string),
    field: propTypes.element.isRequired,
    submitButtonText: propTypes.string,
    onSubmit: propTypes.func,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};
