import MomentUtils from '@date-io/moment';
import {locale} from "moment/moment";

/**
 * Customised MomentUtils to provide two-letter days of the week in the date
 * picker.
 */
export default class PickerUtils extends MomentUtils {
    getWeekdays() {
        if(this.locale === 'zh-hk')
            return '日_一_二_三_四_五_六'.split('_')
        return this.moment.weekdaysMin();
    }
}
