import React from 'react';
import RenewalDetail from "../components/renewal-detail/renewal-detail";

const RenewalDetailPage = () => {
    return (
        <div>
            <RenewalDetail/>
        </div>
    )
}

export default RenewalDetailPage;
