import React from "react";
import {ReactComponent as WhatsAppIcon} from "../../assets/whatsapp.svg";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {_getLang} from "../../store/system/service";
import useConfig from "../../utils/qnect-config";

const StyledWhatsAppIcon = styled(WhatsAppIcon)`
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
`;

const StyledDiv = styled.div`
    & > span {
        color: blue;
    }
    display: inline;
    cursor: pointer;
`;

export default function WhatsAppMessage({country}) {

    const lang = useSelector(state => _getLang(state));
    const cfg = useConfig(lang);

    return (
        <>
            <div>
                {country === 'MYS' ?
                    <>
                        <span>{cfg('labels.whatsapp.mysContactNumber')}</span>
                        <StyledDiv onClick={() => {
                            window.open(process.env.REACT_APP_WHATSAPP_MYS, '_blank');
                        }}>
                            <StyledWhatsAppIcon/><span> WhatsApp</span>
                        </StyledDiv>
                    </>
                    :
                    <>
                        <span>{cfg('labels.whatsapp.hangSeng')}</span>
                        <StyledDiv onClick={() => {
                            window.open(process.env.REACT_APP_WHATSAPP_HANGSENG, '_blank');
                        }}>
                            <StyledWhatsAppIcon/><span> WhatsApp</span>
                        </StyledDiv>
                        <br/>
                        <span>{cfg('labels.whatsapp.manulifeAndOthers')}</span>
                        <StyledDiv onClick={() => {
                            window.open(process.env.REACT_APP_WHATSAPP_MANULIFE, '_blank');
                        }}>
                            <StyledWhatsAppIcon/><span> WhatsApp</span></StyledDiv>
                    </>
                }
            </div>
        </>
    );
}
