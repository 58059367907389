import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import SelectCard from './common/select-card';
import SelectCardLabel from './common/select-card-label';
import Checkbox from './checkbox';

const Label = styled.label`
    display: flex;
    flex-grow: 1;
    margin: 0;
    cursor: inherit;
    padding: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-top: 2px;
`;

const HelpButton = styled.button.attrs({
    type: 'button',
})`
    appearance: none;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 8px;
    min-width: 32px;
    height: 100%;

    &:focus {
        outline: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const HelpButtonContent = styled.div`
    min-height: 100%;
`;

const CheckCard = React.forwardRef((
    {
        className,
        disabled,
        label,
        help,
        onClickHelp,
        checked, // must be provided externally for the card to be styled correctly
        onChange,
        ...rest
    },
    ref
) => (
    <SelectCard
        isDisabled={disabled}
        isSelected={checked}
        className={classnames('CheckCard', className)}
    >
        <Label>
            <StyledCheckbox
                {...rest}
                {...{ref, disabled, checked, onChange}}
            />

            <SelectCardLabel>
                {label}
            </SelectCardLabel>
        </Label>

        {!!help && (
            <div>
                <HelpButton
                    disabled={disabled}
                    onClick={onClickHelp}
                >
                    <HelpButtonContent>
                        {help}
                    </HelpButtonContent>
                </HelpButton>
            </div>
        )}
    </SelectCard>
));

CheckCard.propTypes = {
    className: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    label: propTypes.node,
    help: propTypes.node,
    onClickHelp: propTypes.func,
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func,
};

CheckCard.displayName = 'CheckCard';

export default CheckCard;
