import {expandFontMatrix} from '../util';

const fallbackFontFamily = 'Verdana, Arial, sans-serif';

function fontFamily(name) {
    return [name, fallbackFontFamily].join(', ');
}

// Font matrix row format:
// * name
// * font-size (px)      - desktop
// * line-height (px)    - desktop
// * letter-spacing (px) - desktop
// * font-size (px)      - mobile
// * line-height (px)    - mobile
// * letter-spacing (px) - mobile

/* eslint-disable no-multi-spaces */
const headingFontMatrix = [
    ['huge',   56, 64, 0.5, 40, 48, 0.5],
    ['big',    40, 48, 0.5, 32, 40, 0.5],
    ['large',  32, 40, 0.5, 24, 32, 0.5],
    ['medium', 24, 36, 0.5, 20, 28, 0.5],
    ['normal', 20, 28, 0.5, 18, 26, 0.5],
    ['small',  18, 26, 0.5, 18, 26, 0.5],
];

const subheadingFontMatrix = [
    ['normal', 20, 28,   0, 20, 28,   0],
    ['small',  18, 25,   0, 18, 25,   0],
];

const paragraphFontMatrix = [
    ['big',    18, 25,   0, 18, 25,   0],
    ['normal', 16, 22,   0, 16, 22,   0],
    ['small',  14, 20,   0, 14, 20,   0],
    ['xSmall', 12, 18,   0, 12, 18,   0],
];

const textFontMatrix = [
    ['normal', 16, 24,   0, 16, 24,   0],
    ['small',  14, 20,   0, 14, 20,   0],
    ['xSmall', 12, 18,   0, 12, 18,   0],
];
/* eslint-enable no-multi-spaces */

const heading = {
    fontFamily: fontFamily('Georgia'),
    size: expandFontMatrix(headingFontMatrix),
    fontWeight: {
        medium: 500,
        bold: 700,
    },
};

const subheading = {
    fontFamily: fontFamily('"Segoe UI"'),
    size: expandFontMatrix(subheadingFontMatrix),
    fontWeight: {
        medium: 500,
        bold: 700,
    },
};

const paragraph = {
    fontFamily: fontFamily('"Segoe UI"'),
    size: expandFontMatrix(paragraphFontMatrix),
    fontWeight: {
        regular: 400,
        semiBold: 600,
    },
};

const text = {
    fontFamily: fontFamily('"Segoe UI"'),
    size: expandFontMatrix(textFontMatrix),
    fontWeight: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
};

const typography = {
    heading,
    subheading,
    paragraph,
    text,
};

export default typography;
