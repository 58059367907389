import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import OvalSpeech from "./oval-speech";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import { ReactComponent as Close } from "../../../assets/login/close_sharp.svg";
import BlankImage from "../../../assets/profile/avatar_image_placeholder.svg";
import {_getPolicy} from "../../../store/policy/service";
import {_getCountry} from "../../../store/system/service";
import {connect} from "react-redux";

const Title = styled.div`
  position: relative;
  width: 100%;
  height: 76px;
`;

const AvatarWrapper = styled.div`
  margin-top: -12px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
`;

const Avatar = styled.img`
  width: 67.43px;
  height: 67.43px;
  box-shadow: 2px 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
`;

const OvalSpeechWrapper = styled.div`
  position: absolute;
  top: -24px;
  left: 88px;
`;

const CloseIcon = styled(Close)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 0;
`;

const StyledMessage = styled.div`
  margin-bottom: 8px;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0a1f44;
`;

const StyledMessage2 = styled(StyledMessage)`
  margin-bottom: 2px;
  white-space: pre-wrap;
`;

const StyledTitle = styled(StyledMessage)`
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #003da5;
`;

const StyledName = styled(StyledMessage)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

const PhoneIcon = styled(Phone)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const MailIcon = styled(Mail)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const ContactWayInner = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0a1f44;

  &:nth-child(5) {
    margin-top: 20px;
  }
`;

const StyledDialog = withStyles({
  paper: {
    paddingBottom: "20px!important",
    overflowY: "initial"
  }
})(Dialog);

const AgentContactDialog =({ open, onClose, cfg, ...props }) => {

  const getAgentName = () => {
    if (props.country === 'HKG') {
      if (props.policy?.agentDetails?.agentType === 'H') {
        return cfg('policy.heading.hangSengName');
      } else if (props.policy?.agentDetails?.agentType === 'HD') {
        return cfg('policy.heading.hangSengDirect');
      } else if (props.policy?.agentDetails?.agentType === 'A' || props.policy?.agentDetails?.agentType === 'M') {
        return props.policy?.agentDetails?.agentName;
      } else if (props.policy?.agentDetails?.agentType === 'C') {
        return cfg('policy.heading.ccba');
      } else if (props.policy?.agentDetails?.agentType === 'MQ') {
        return cfg('policy.heading.manulifeQbeAgent');
      } else {
        return cfg('policy.heading.qbeDefaultIntermediary');
      }
    } else if (props.country === 'SGP') {
      return props.policy?.agentDetails?.agentName ? props.policy?.agentDetails?.agentName : "QBE";
    }
  }

  const getLicenseWording = () => {
    if (props.country === 'HKG') {
      return null;
    } else if (props.country === 'SGP') {
      return <>
        <StyledMessage>{cfg('policy.heading.licensedAgent')}</StyledMessage>
        <StyledMessage>{cfg('policy.heading.IALicNo') + (props.policy?.agentDetails?.agentLicenseNumber ?
            props.policy?.agentDetails?.agentLicenseNumber : '')}</StyledMessage>
      </>
    }
  }

  const getContactDetails = () => {
    const getPhoneHref = () => {
      if (props.policy?.agentDetails?.agentPhoneNumber) {
        return '+852' + props.policy?.agentDetails?.agentPhoneNumber;
      }
      return cfg('genericLabels.phoneHref.MANU');
    }

    const getPhoneNumber = () => {
      if (props.policy?.agentDetails?.agentPhoneNumber) {
        return ('+' + props.policy?.agentDetails?.agentPhoneNumber);
      }
      return cfg('genericLabels.phone.MANU');
    }

    const getMailTo = () => {
      if (props.policy?.agentDetails?.agentEmailAddress) {
        return props.policy?.agentDetails?.agentEmailAddress;
      }
      return cfg('genericLabels.email.HKG-CS');
    }

    if (props.country === 'HKG') {
      if (props.policy?.agentDetails?.agentType === 'H' || props.policy?.agentDetails?.agentType === 'HD') {
        return <>
          <ContactWayInner>
            <PhoneIcon />
            <a href={'tel:' + cfg('genericLabels.phoneHref.' + props.country)}>
              {cfg('genericLabels.phone.' + props.country)}</a>
          </ContactWayInner>
          <ContactWayInner>
            <MailIcon />
            <a href={'mailto:cs.gihk@qbe.com'}>cs.gihk@qbe.com</a>
          </ContactWayInner>
        </>
      } else if (props.policy?.agentDetails?.agentType === 'A') {
        return null;
      } else if (props.policy?.agentDetails?.agentType === 'M') {
        return <>
          <ContactWayInner>
            <PhoneIcon />
            <a href={'tel:' + getPhoneHref()}>
              {getPhoneNumber()}</a>
          </ContactWayInner>
          <ContactWayInner>
            <MailIcon />
            <a href={'mailto:' + getMailTo()}>
              {getMailTo()}</a>
          </ContactWayInner>
        </>
      } else if (props.policy?.agentDetails?.agentType === 'MQ') {
        return <>
          <ContactWayInner>
            <PhoneIcon />
            <a href={'tel:' + cfg('genericLabels.phoneHref.MANU')}>
              {cfg('genericLabels.phone.MANU')}</a>
          </ContactWayInner>
          <ContactWayInner>
            <MailIcon />
            <a href={'mailto:' + cfg('genericLabels.email.HKG-CS')}>{cfg('genericLabels.email.HKG-CS')}</a>
          </ContactWayInner>
        </>
      } else {
        return <>
          <ContactWayInner>
            <PhoneIcon />
            <a href={'tel:' + cfg('genericLabels.phoneHref.HKG-CS')}>
              {cfg('genericLabels.phone.HKG-CS')}</a>
          </ContactWayInner>
          <ContactWayInner>
            <MailIcon />
            <a href={'mailto:' + cfg('genericLabels.email.HKG-CS')}>
              {cfg('genericLabels.email.HKG-CS')}</a>
          </ContactWayInner>
        </>
      }
    } else if (props.country === 'SGP') {
      return null;
    }
  }

  const displayThis = () => {
    return !(props.policy?.agentDetails?.agentType === 'HD' || props.policy?.agentDetails?.agentType === 'NA' ||
        props.policy?.agentDetails?.agentType === 'Q');
  }

  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <Title>
          <AvatarWrapper>
            <Avatar
              alt=""
              src={BlankImage}
            />
          </AvatarWrapper>
          <OvalSpeechWrapper>
            <OvalSpeech device="mobile" placement="left" cfg={cfg}/>
          </OvalSpeechWrapper>
          <CloseIcon onClick={onClose} />
        </Title>
        <DialogContent>
          { displayThis() && <StyledTitle>{cfg('policy.agentContact')}</StyledTitle>}
          <StyledName>{getAgentName()}</StyledName>
          {getLicenseWording()}
          {getContactDetails()}
        </DialogContent>
      </StyledDialog>
    </div>
  );
}

AgentContactDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = state => {
  return {
    country: _getCountry(state),
    policy: _getPolicy(state)
  };
};

export default connect(mapStateToProps)(AgentContactDialog);
