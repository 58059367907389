import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./carousel.css"
import {
    QnectButtonWraper,
    StyledInfoBox,
    StyledInfoSubTitle,
    StyledInfoTitle,
    StyledInfoWrapper,
    StyledQnectButton
} from "../../portfolio/portfolio-style";
import * as TagManager from "react-gtm-module";
import LearnMoreDialog from "../portfolio/learn-more-dialog";
import useConfig from "../../qnect-ui/qnect-config";

export const CarouselDot = (props) => {
    return (
        <div className="topDotContainer">
            {props.dotIcon}
            <span className="topControlsTitle">{props.dotTitle}</span>
        </div>
    )
};

function openInNewTab(url) {
    window.open(url, "_blank", "noreferrer");
}

export const CarouselContent = (props) => {
    const cfg = useConfig(props.lang);
    const [state, setState] = React.useState({
        openLearnMoreDialog: false
    });

    return (
         <React.Fragment>
             <LearnMoreDialog
                open={state.openLearnMoreDialog}
                onClose={() =>
                    setState(prev => ({...prev, openLearnMoreDialog: false}))
                }
                onConfirm={() => {
                    openInNewTab(props.carouselItemListItem2)
                    setState(prev => ({...prev, openLearnMoreDialog: false}))
                }}
                cfg={cfg}
                lang={props.lang}
            />
            <StyledInfoBox>
                <StyledInfoWrapper>
                    <StyledInfoTitle>{props.carouselItemTitle}</StyledInfoTitle>
                    <StyledInfoSubTitle>{props.carouselItemListItem1}</StyledInfoSubTitle>
                </StyledInfoWrapper>
                <QnectButtonWraper>
                    {props.carouselItemIndex == 0 ?
                        <StyledQnectButton onClick={() => {
                                    setState(prev => ({...prev, openLearnMoreDialog: true}));
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: 'popup',
                                            popupPath: '/portfolio',
                                            popupTitle: 'You are about to leave the customer portal'
                                        }
                                    });
                                }}>
                            {props.carouselItemListItem3}
                        </StyledQnectButton> :
                        <>{props.carouselItemListItem2 && <StyledQnectButton field="seeMore" onClick={() => {openInNewTab(props.carouselItemListItem2)}}/>}</>
                    }
                </QnectButtonWraper>
            </StyledInfoBox>
        </React.Fragment>
    )
};

export default CarouselDot;
