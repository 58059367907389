import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {Paragraph} from '../typography';
import typographyStyles from '../../common/typography-styles';

const Title = styled.div`
    text-align: center;
    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultWeight: 'bold',
        defaultColor: 'tertiary90',
    })}
`;

const StyledParagraph = styled(Paragraph)`
    margin: 8px 12px;
`;

export default function SupplierInstructions({
    className,
    title,
    instructions,
}) {
    return (
        <div className={classnames('SupplierInstructions', className)} >
            {!!title && (
                <Title>
                    {title}
                </Title>
            )}

            {instructions.map((paragraph, index) => (
                <StyledParagraph
                    size="small"
                    key={index}
                    color="tertiary90"
                >
                    {paragraph}
                </StyledParagraph>
            ))}
        </div>
    );
}

SupplierInstructions.propTypes = {
    className: propTypes.string,
    title: propTypes.string,
    instructions: propTypes.arrayOf(propTypes.string),
};

SupplierInstructions.displayName = 'SupplierInstructions';
