import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as Download } from "../../../assets/policy-details/download_white.svg";
import { ReactComponent as Mail } from "../../../assets/policy-details/mail_white.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  speedDial: {
    "& .MuiFab-root": {
      width: "44px",
      height: "44px",
      zIndex: "999"
    },
    "& .MuiSpeedDial-actions": {
      marginTop: "-44px",
      paddingTop: "44px",
      width: "44px",
      opacity: 0.5,
      borderRadius: "22px",
      backgroundColor: "#4776FF",
      boxShadow: "inset 0 10px 8px 0 rgba(74,105,197,0.5)",
      transition:
        "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s"
    },
    "& .MuiSpeedDial-actions.MuiSpeedDial-actionsClosed": {
      opacity: 0
    },
    "& .MuiSpeedDialAction-fab": {
      margin: "0 auto",
      background: "initial",
      boxShadow: "none"
    }
  }
}));

const actionNames = {
  mail: "MAIL",
  download: "DOWNLOAD"
};

const actions = [
  { icon: <Mail />, name: actionNames.mail },
  { icon: <Download />, name: actionNames.download }
];

const IconSpeed = ({ id, onClickEmail = null, onClickDownload = null }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = name => {
    if (name === actionNames.mail) {
      onClickEmail && onClickEmail(id);
    }

    if (name === actionNames.download) {
      onClickDownload && onClickDownload(id);
    }

    setOpen(false);
  };

  return (
    <SpeedDial
      ariaLabel="actions"
      open={open}
      direction="down"
      className={classes.speedDial}
      icon={
        <SpeedDialIcon
          icon={<KeyboardArrowDownIcon />}
          openIcon={<CloseIcon />}
        />
      }
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {actions.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleClose(action.name)}
        />
      ))}
    </SpeedDial>
  );
};

IconSpeed.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClickEmail: PropTypes.func,
  onClickDownload: PropTypes.func
};

export default IconSpeed;
