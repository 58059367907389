import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ReactComponent as LearnMoreIcon } from "../../../assets/portfolio/icon_learn_more.svg";
import { smDown, lgDown } from "../../../ui/common/breakpoints";
import {_getLang} from "../../../store/system/service";
import {connect} from "react-redux";
import useConfig from "../../qnect-ui/qnect-config";
import TagManager from "react-gtm-module";

const StyledNewsItem = styled.div`
  width: 350px;
  padding-top: 40px;
  margin-bottom: 100px;
  margin-left: ${({ index }) => (index !== 0 ? "30px" : "0")};
  height: 673px;
  position: relative;
  ${lgDown`
    width: 270px;
    margin-left: 0;
    padding-top: 30px;
    margin-bottom: 0px;
    height: 614px;
  `}
  ${smDown`
    width: 180px;
    margin-left: 0;
    text-overflow: ellipsis;
    height: 400px;
    margin-bottom: 40px;
    padding-top: 29px;
    height: 420px;
  `}
  cursor: pointer;
`;

const StyledNewsImageBox = styled.div`
  height: 350px;
  width: 350px;
  border-radius: 12px;
  background-color: lightgrey;
  ${lgDown`
    width: 270px;
    height: 270px;
  `}
  ${smDown`
    width: 180px;
    height: 180px;
  `}
`;

const StyledNewsTitle = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  height: 96px;
  ${lgDown`
    margin-top: 12px;
    height: 116px;
  `}
  ${smDown`
    font-size: 18px;
    line-height: 24px;
    height: 48px;
    margin-top: 19px;
    overflow: hidden;
  `}
`;

const StyledNewsContent = styled.div`
  color: #8a94a6;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 12px;
  ${lgDown`
    height: 116px;
  `}
  ${smDown`
    font-size: 14px;
    line-height: 20px;
    height: 100px;
    overflow: hidden;
    width: 190px
  `}
`;

const StyledNewsLearnMore = styled.div`
  color: #003da5;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
  cursor: pointer;
  ${smDown`
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    padding-top: 14px;
  `}
  position: absolute;
  bottom: 0;
  right: 0;
`;

const StyledLearnMoreIcon = styled(LearnMoreIcon)`
  margin-left: 8px;
`;

const StyledImg = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const WhatsNewsItem = ({ image, title, content, index, linkText, link, lang, eventCategory }) => {
  const cfg = useConfig(lang);

    const openLink = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Interested-You-Click',
          eventCategory: eventCategory
        }
      })
      window.open(link, '_blank');
    }

  return (
    <StyledNewsItem index={index} onClick={openLink}>
      <StyledNewsImageBox>
          <StyledImg src={image}/>
      </StyledNewsImageBox>
      <StyledNewsTitle>{title}</StyledNewsTitle>
      <StyledNewsContent>{content}</StyledNewsContent>
      <StyledNewsLearnMore>
        { linkText ? linkText : cfg('whats_new.learnMore') } <StyledLearnMoreIcon />
      </StyledNewsLearnMore>
    </StyledNewsItem>
  );
};

WhatsNewsItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  linkText: PropTypes.string,
  index: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    lang: _getLang(state)
  }
}

export default connect(mapStateToProps)(WhatsNewsItem);
