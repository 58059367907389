import React from 'react';

import BaseIcon from '../common/base-icon';

export default function IconAlert(props) {
    return (
        <BaseIcon {...props}>
            <path
                d="M12.111 1.989l9.889 18H2l10.111-18zM12 16a1 1 0 100 2 1 1 0 000-2zm1-7h-2v6h2V9z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </BaseIcon>
    );
}
