import clamp from 'lodash/clamp';
import React from 'react';
import styled from 'styled-components';

import Heading from '../heading';
import {smDown, smUp} from '../../../common/breakpoints';
import {cssIf} from '../../../common/conditional-styles';

const levelSize = [
    'huge',
    'big',
    'large',
    'medium',
    'normal',
    'small',
];

const StyledHeading = styled(Heading)`
    ${cssIf('scIsHeadingCenteredOnMobile')`
        ${smDown`
            text-align: center;
        `}
    `}

    ${cssIf('scIsHeadingCenteredOnDesktop')`
        ${smUp`
            text-align: center;
        `}
    `}
`;

export default function headingRenderer({
    fromHeadingLevel = 1,
    isHeadingCenteredOnMobile,
    isHeadingCenteredOnDesktop,
}) {
    return function HeadingRenderer({level, children, ...rest}) { // eslint-disable-line react/prop-types
        const effectiveLevel = clamp(fromHeadingLevel + level - 1, 1, 6);

        return (
            <StyledHeading
                {...rest}
                level={effectiveLevel}
                size={levelSize[effectiveLevel - 1]}
                weight="medium"
                scIsHeadingCenteredOnMobile={isHeadingCenteredOnMobile}
                scIsHeadingCenteredOnDesktop={isHeadingCenteredOnDesktop}
            >
                {children}
            </StyledHeading>
        );
    };
}
