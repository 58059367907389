import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {dateFieldType, selectFieldType} from '../../common/common-prop-types';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import DateField from '../form-fields/date-field';
import RadioButtonsField from '../form-fields/radio-buttons-field';
import Paragraph from '../typography/paragraph';

const DateMessage = styled(Paragraph)`
    margin: 32px 0;
    text-align: center;
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

export default function ClaimSource({
    className,
    source,
    dateMessage,
    date,
    submitButtonText,
    isSubmitting,
    onSubmit,
}) {
    return (
        <Form
            className={classnames('ClaimSource', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            <RadioButtonsField
                name="source"
                {...source}
            />

            {!!dateMessage && (
                <DateMessage color="tertiary90">
                    {dateMessage}
                </DateMessage>
            )}

            <DateField
                maxDate={new Date()} // default to today
                {...date}
            />

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

ClaimSource.propTypes = {
    className: propTypes.string,
    source: selectFieldType,
    dateMessage: propTypes.string,
    date: dateFieldType,
    submitButtonText: propTypes.string,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func,
};
