import React, {useEffect, useState} from 'react';
import Login from '../components/login/login';
import LoginAuth from "../components/login-auth/login-auth";
import {connect} from "react-redux";
import {
    _getCountry,
    _getLang,
    _getShowPageBroken,
    _hideModal,
    _hideSpinner,
    _setGotoPage,
    _setNoLogin,
    _setShowOtpModal,
    _setShowPageBroken,
    _showModal,
    _showSpinner
} from "../store/system/service";
import {_getUser, _resendVerificationEmail, _storeUser, _verifyEmail} from "../store/user/service";
import {ReactComponent as BlueDone} from "../assets/BlueDone.svg";
import {ReactComponent as RedXMark} from "../assets/RedXMark.svg";
import styled from "styled-components";
import QnectButton from "../components/qnect-ui/qnect-button";
import useLoginStyles, {StyledModalDiv} from "../components/login-style";
import useConfig from "../components/qnect-ui/qnect-config";
import * as TagManager from "react-gtm-module";

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  background-color: #1d4fbb;
  margin-bottom: 20px;
`;

const LoginPage = (props) => {
    const params = new URLSearchParams(props.history.location.search);
    const [verifyEmailCalled, setVerifyEmailCalled] = useState(false);
    const [loading, setLoading] = useState( false );
    const classes = useLoginStyles(props);

    if (!loading) {
        setLoading(true);
        props.setNoLogin(false);
    }

    const cfg = useConfig(props.lang);

    const resend = () => {
        props.resendVerificationEmail(props.user.email, props.country).then(
            () => {
                props.hideSpinner();
            },
            error => {
                console.error(error.response.statusText);
                props.hideSpinner();
            }
        );
        props.hideModal();
    }

    useEffect(() => {
        if (verifyEmailCalled) {
            let token = params.get('token');
            let iv = params.get('iv');

            props.showSpinner();
            console.log("verify email");
            props.verifyEmail(token, iv).then((response) => {
                if (response.status === 'SUCCESS') {
                    props.user.email = response.email;
                    props.storeUser(props.user);
                    console.log("verify email success");

                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'Verification Success'
                        }
                    });

                    props.showModal({
                        flat: true,
                        content: <StyledModalDiv>
                            <div className="iconDiv">
                                <BlueDone/>
                            </div>
                            <div className="title" style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                                { cfg('labels.verificationSuccess.title') }
                            </div>
                            <div className="content">{ cfg('labels.verificationSuccess.message1') }</div>
                            <div className="buttonDiv">
                                <StyledQnectButton
                                    field="continue"
                                    onClick={() => {
                                        //props.setShowOtpModal(true);
                                        props.hideModal();
                                    }}></StyledQnectButton>
                            </div>
                        </StyledModalDiv>
                    });
                } else if (response.status === 'LINK_EXPIRED') {
                    props.user.email = response.email;
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'Verification link is expired'
                        }
                    });

                    props.showModal({
                        flat: true,
                        content: <StyledModalDiv>
                            <div className="iconDiv">
                                <RedXMark/>
                            </div>
                            <div className="title" style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                                { cfg('labels.verificationExpired.title') }
                            </div>
                            <div className="content">{ cfg('labels.verificationExpired.message1') }</div>
                            {
                                props.country === 'MYS' ?
                                    <div className={classes.buttonsRow}>
                                            <StyledQnectButton
                                                field="confirm"
                                                onClick={() => {
                                                    props.history.push('/login');
                                                    props.hideModal();
                                                }}>
                                            </StyledQnectButton>
                                            <StyledQnectButton
                                                field="resend"
                                                onClick={resend}>
                                            </StyledQnectButton>
                                    </div>
                                    :
                                    <div className="buttonDiv">
                                        <StyledQnectButton
                                            field="confirm"
                                            onClick={() => {
                                                props.history.push('/login');
                                                props.hideModal();
                                            }}>
                                        </StyledQnectButton>
                                    </div>
                            }
                        </StyledModalDiv>
                    });
                } else {

                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'Verification link is invalid'
                        }
                    });


                    props.showModal({
                        flat: true,
                        content: <StyledModalDiv>
                            <div className="iconDiv">
                                <RedXMark/>
                            </div>
                            <div className="title">Verification link is invalid</div>
                            <div className="content"></div>
                            <div className="buttonDiv">
                                <StyledQnectButton onClick={() => {
                                    props.history.push('/login');
                                    props.hideModal();
                                }}>Continue</StyledQnectButton>
                            </div>
                        </StyledModalDiv>
                    });
                }
                props.hideSpinner();
            }, () => {

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'popup',
                        popupPath: '/login',
                        popupTitle: 'Verification link is invalid'
                    }
                });

                props.showModal({
                    flat: true,
                    content: <StyledModalDiv>
                        <div className="iconDiv">
                            <RedXMark/>
                        </div>
                        <div className="title">Verification link is invalid</div>
                        <div className="content"></div>
                        <div className="buttonDiv">
                            <StyledQnectButton onClick={() => {
                                props.history.push('/login');
                                props.hideModal();
                            }}>Continue</StyledQnectButton>
                        </div>
                    </StyledModalDiv>
                });
                props.hideSpinner();
            });
        }
    }, [verifyEmailCalled]);

    useEffect(() => {
        if (props.showPageBroken) {
            props.history.push('/pageUnavailable');
            props.setShowPageBroken(false);
            props.hideSpinner();
        }
    }, [props.showPageBroken]);

    console.log(params);
    // store goto page
    if (params.get('gotoPage') && params.get('gotoPn')) {
        console.log('gotoPage = '+ params.get('gotoPage') + ", pn = " + params.get('gotoPn'));
        props.setGotoPage(params.get('gotoPage'), params.get('gotoPn'));
    }

    // clear token
    if (params.get('token')) {
        if (!verifyEmailCalled) {
            setVerifyEmailCalled(true);
        }
        return (
            <>
                <Login/>
            </>
        );
    } else {
        return (
            <React.Fragment>
                {
                    (!props.match.params.section && <Login/>)
                    ||
                    (props.match.params.section === 'auth' && <LoginAuth/>)
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        showPageBroken: _getShowPageBroken(state),
        lang: _getLang(state),
        country:_getCountry(state)
    }
}


const mapDispatchToProps = {
    verifyEmail: _verifyEmail,
    showModal: _showModal,
    hideModal: _hideModal,
    setNoLogin: _setNoLogin,
    storeUser: _storeUser,
    setShowOtpModal: _setShowOtpModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    setShowPageBroken: _setShowPageBroken,
    resendVerificationEmail:_resendVerificationEmail,
    setGotoPage: _setGotoPage
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
