import React, {useMemo, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import invoke from 'lodash/invoke';

import useAutoComplete from '../../common/use-auto-complete';
import useParentBlur from '../../common/use-parent-blur';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import Paragraph from '../typography/paragraph';
import IncidentCoverCard from './incident-cover-card';

const Message = styled(Paragraph)`
    margin: 0 0 38px;
    text-align: center;
`;

const StyledIncidentCoverCard = styled(IncidentCoverCard)`
    :not(:first-of-type) {
        margin-top: 28px;
    }
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

const HelperText = styled(Paragraph)`
    margin: 0 14px;
    padding-top: 16px;
`;

const IncidentCovers = React.forwardRef((
    {
        className,
        message,
        additionalMessage,
        covers = {},
        submitButtonText,
        isSubmitting,
        onSubmit,
    },
    ref
) => {
    // Fallback ref so we can get onBlur events without an externally provided ref
    const internalRef = useRef(null);

    // Detect focus outside the list of covers
    useParentBlur(ref || internalRef, covers.onBlur);

    // Prevent iterating over covers.value several times per render.
    const selectedCovers = useMemo(() => new Set(covers.value), [covers.value]);

    return (
        <Form
            className={classnames('IncidentCovers', className)}
            onSubmit={onSubmit}
            autoComplete={useAutoComplete()}
            noValidate
        >
            {!!message && (
                <Message color="tertiary90">
                    {message}
                </Message>
            )}

            {!!additionalMessage && (
                <Message color="tertiary90" size="normal">
                    {additionalMessage}
                </Message>
            )}

            {(covers.options || []).map(({
                value,
                text,
                details,
                helpText,
                onClickHelp,
            }) => (
                <StyledIncidentCoverCard
                    key={value}
                    coverType={text}
                    details={details}
                    disabled={isSubmitting}
                    helpText={helpText}
                    onClickHelp={onClickHelp}
                    checked={selectedCovers.has(value)}
                    onChange={(event) => void invoke(
                        covers,
                        'onChange',
                        // Add/remove the cover if checked/unchecked. (Using the set to avoid dupes creeping in.)
                        event.target.checked
                            ? [...selectedCovers, value]
                            : [...selectedCovers].filter((item) => item !== value)
                    )}
                />
            ))}

            {!!(covers.errorMessage || covers.helperText) && (
                <HelperText
                    size="xSmall"
                    color={covers.errorMessage ? 'error' : 'tertiary90'}
                >
                    {covers.errorMessage || covers.helperText}
                </HelperText>
            )}

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
});

IncidentCovers.displayName = 'IncidentCovers';

IncidentCovers.propTypes = {
    className: propTypes.string,
    message: propTypes.string,
    additionalMessage: propTypes.string,
    covers: propTypes.shape({
        value: propTypes.arrayOf(propTypes.string).isRequired,
        onChange: propTypes.func.isRequired,
        onBlur: propTypes.func,
        helperText: propTypes.string,
        errorMessage: propTypes.string,
        disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
        options: propTypes.arrayOf(propTypes.shape({
            value: propTypes.string,
            text: propTypes.string,
            details: propTypes.arrayOf(propTypes.string),
            helpText: propTypes.string,
            onClickHelp: propTypes.func,
        })),
    }),
    submitButtonText: propTypes.string.isRequired,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
};

export default IncidentCovers;
