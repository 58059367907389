import React from 'react';
import {connect} from 'react-redux';
import {CarouselProvider, DotGroup, Slide, Slider} from 'pure-react-carousel';
import {withRouter} from "react-router";
import {_getCountry, _getLang} from "../../../store/system/service";
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./carousel.css"
import {CarouselContent} from "./carousel-template";
import moment from "moment";
import useConfig from "../../qnect-ui/qnect-config";

const Carousel = ({bannerList, props, isPlaying}) => {
    const cfg = useConfig(props.lang);
    let bannerSize = bannerList.length + 1;
    const slides = (banners)=>banners.map((item, i) => {
        return(
        <Slide index={i + 1} key={item.id}>
                    <CarouselContent
                        carouselItemIndex={i + 1}
                        carouselItemTitle={item.title}
                        carouselItemListItem1={item.content}
                        carouselItemListItem2={item.url}
                    />
        </Slide>);
    });

    const getGreetingTime = () => {
        const currentTimeHour = moment().format('H');
        if (currentTimeHour < 12) {
            return cfg('labels.goodMorning' );
        } else if (currentTimeHour >= 12) {
            if (currentTimeHour < 18) {
                return cfg('labels.goodAfternoon' );
            } else {
                return cfg('labels.goodEvening' );
            }
        }
    }

    const eclaimsLink = () => {
        if (props.country === 'HKG') {
            return (
                props.lang === 'zh' ?
                    'https://qbe.com/hk/zh-hk/intro-to-eclaims' : 'https://qbe.com/hk/en/intro-to-eclaims')
        } else if (props.country === 'SGP') {
            return 'https://www.qbe.com/sg/eclaims';
        } else {
            return 'https://claims.qbe.com/claims/?country=MYS';
        }
    }

    function toTitleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    let title = getGreetingTime() + ", " + toTitleCase(props.user.userName) + ".";



    return (
    <CarouselProvider
        visibleSlides={1}
        naturalSlideWidth={100}
        naturalSlideHeight={150}
        totalSlides={bannerSize}
        currentSlide={0}
        infinite={true}
        interval={5000}
        isPlaying={isPlaying}
        step={1}
        dragStep={1}
        isIntrinsicHeight={true}
    >
        <div className="sliderContainerScroller">
            <Slider children={bannerSize}>
                 <Slide index={0} key={0}>
                    <CarouselContent
                        carouselItemIndex={0}
                        carouselItemTitle={title}
                        carouselItemListItem1={cfg( 'labels.mayIHelpYou' )}
                        carouselItemListItem2={ eclaimsLink()}
                        carouselItemListItem3={cfg('labels.learnMore')}
                    />
                </Slide>
                {slides(bannerList)}
            </Slider>
        </div>
        <div className="SliderButtonContainer">
            <DotGroup
                disableActiveDots={false}
            >
            </DotGroup>
        </div>

    </CarouselProvider>

    )

};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state),
    }
};

export default withRouter(connect(mapStateToProps)(Carousel));
