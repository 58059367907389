import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {smUp} from '../../common/breakpoints';
import {cssIf} from '../../common/conditional-styles';
import Heading from '../typography/heading';

const Header = styled.header`
    box-sizing: border-box;
    background: ${property('theme.color.grey20')};
    padding: 16px;
    text-align: center;

    ${cssIf('scIsCompact')`
        display: flex;
        align-items: center;
        justify-items: center;
        min-height: 58px;

        ${smUp`
            min-height: 80px;
        `}
    `}
`;

const StyledHeading = styled(Heading).attrs(({scIsCompact}) => ({
    size: scIsCompact ? 'normal' : 'medium',
}))`
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;

    ${cssIf('scHasIcon')`
        margin: 0 0 8px;

        ${smUp`
            margin: 0 0 16px;
        `}
    `}

    ${cssIf('scIsCompact')`
        flex-grow: 1;
        margin: 0;
    `}
`;

const IconContainer = styled.div`
    margin: 3px auto 8px;
    width: 36px;
    height: 36px;
    color: ${property('theme.color.primary')};

    ${smUp`
        margin-top: 16px;
    `}
`;

export default function StepCardHeader({
    icon,
    title,
    isCompact,
}) {
    const hasIcon = !!(icon && !isCompact);

    return (
        <Header scIsCompact={isCompact}>
            {hasIcon && (
                <IconContainer>
                    {icon}
                </IconContainer>
            )}

            <StyledHeading
                color="primary"
                weight="medium"
                scIsCompact={isCompact}
                scHasIcon={hasIcon}
            >
                {title}
            </StyledHeading>
        </Header>
    );
}

StepCardHeader.propTypes = {
    icon: propTypes.node,
    title: propTypes.string,
    isCompact: propTypes.bool,
};
