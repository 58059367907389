import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 41px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px"
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px"
    }
  }
}));

export default function BeforeUpdateEmailDialog({
  open = false,
  onClose,
  onConfirm = null
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title="Update Information"
          closable={false}
          onClose={onClose}
        />
        <DialogContent>
          <div className={classes.message}>
            Updating the email address would require verification and logging
            into your account again. It will also be reflected in all of your
            policies with QBE Hong Kong. Would you like to proceed?
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper color="secondary">
            <QnectButton color="secondary" onClick={onClose}>
              Cancel
            </QnectButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <QnectButton className={classes.button} onClick={onConfirm}>
              Confirm
            </QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BeforeUpdateEmailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
