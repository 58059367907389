import React, { memo } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import QnectButton from "../../qnect-ui/qnect-button";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: "999"
  },
  paper: {
    backgroundColor: "#4a69c5",
    borderRadius: "20px",
    color: "#fff",
    "&.MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        width: "343px",
        height: "213px"
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "354px",
        height: "200px"
      },
      [theme.breakpoints.up("lg")]: {
        width: "540px",
        height: "225px"
      }
    }
  },
  button: {
    "& button": {
      fontFamily: "Inter",
      fontWeight: "500",
      backgroundColor: "#14284b"
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        height: "56px",
        width: "160px",
        fontSize: "18px",
        lineHeight: "24px"
      }
    },
    [theme.breakpoints.up("sm")]: {
      "& button": {
        float: "right",
        width: "120px",
        height: "50px",
        fontSize: "20px",
        lineHeight: "28px"
      }
    }
  },
  paperContent: {
    textAlign: "center",
    "& > :first-child": {
      fontFamily: "Stag",
      fontWeight: "500"
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px 16px",
      "& > :first-child": {
        lineHeight: "24px",
        fontSize: "18px"
      },
      "& > :nth-child(2)": {
        marginTop: "10px",
        fontSize: "14px",
        fontFamily: "Inter",
        lineHeight: "21px"
      },
      "& > :nth-child(3)": {
        marginTop: "20px"
      }
    },
    [theme.breakpoints.up("lg")]: {
      padding: "16px 20px",
      "& > :first-child": {
        lineHeight: "36px",
        fontSize: "24px"
      },
      "& > :nth-child(2)": {
        marginTop: "12px",
        fontSize: "18px",
        fontFamily: "Inter",
        lineHeight: "25px"
      },
      "& > :nth-child(3)": {
        marginTop: "20px"
      }
    }
  }
}));

const StyledPopperContainer = styled.div`
  margin-top: 0px;
  display: ${({ direction }) =>
    direction === "left" || direction === "right" ? "flex" : "block"};
  align-items: center;
`;

const StyledArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-right: 16px solid #4a69c5;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
`;

const StyledArrowRight = styled.div`
  width: 0;
  height: 0;
  border-left: 16px solid #4a69c5;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
`;

const StyledArrowTop = styled.div`
  margin: 0 auto;
  height: 0;
  width: 0;
  border-bottom: 16px solid #4a69c5;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
`;

const StyledArrowBottom = styled.div`
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 16px solid #4a69c5;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
`;

const CustomizedPopper = ({
  open,
  anchorEl,
  placement = "right",
  arrowDirection = "left",
  style,
  onOk
}) => {
  const classes = useStyles();

  return (
    <Popper
      className={classes.popper}
      style={style}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      modifiers={{
        flip: {
          enabled: false
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent"
        },
        arrow: {
          enabled: true
        }
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledPopperContainer direction={arrowDirection}>
            {arrowDirection === "left" && <StyledArrowLeft />}
            {arrowDirection === "top" && <StyledArrowTop />}
            <Paper className={classes.paper}>
              <div className={classes.paperContent}>
                <div>One More Step</div>
                <div>
                  In order to deliver the policy in the fastest way possible, we
                  would like to confirm with you on the best way of
                  communications.
                </div>
                <div className={classes.button}>
                  <QnectButton onClick={onOk}>OK</QnectButton>
                </div>
              </div>
            </Paper>
            {arrowDirection === "right" && <StyledArrowRight />}
            {arrowDirection === "bottom" && <StyledArrowBottom />}
          </StyledPopperContainer>
        </Fade>
      )}
    </Popper>
  );
};

CustomizedPopper.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.node,
  placement: PropTypes.oneOf([
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top"
  ]),
  arrowDirection: PropTypes.oneOf(["left, top, right, bottom"]),
  style: PropTypes.object,
  onOk: PropTypes.func
};

export default memo(CustomizedPopper);
