import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import { smDown, lgDown } from "../../../ui/common/breakpoints";

const StyledPhone = styled(Phone)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  vertical-align: bottom;
`;

const StyledMail = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  vertical-align: bottom;
`;

const StyledMessage = styled.div`
  margin-top: 12px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 470px;
  ${lgDown`
    width: auto;
  `}
  ${smDown`
    margin-top: 4px;
    font-size: 16px;
    line-height: 20px;
  `}
`;

const Wrapper = styled.div`
  margin: 40px auto 0;
  font-size: 18px;
  line-height: 25px;
  color: #0a1f44;
  text-align: center;
  font-family: Inter;
  ${smDown`
    font-siz2: 16px;
    line-height: 20px;
  `}
`;

const SecondWrapper = styled(Wrapper)`
  margin: 30px auto 0;
`;

const StyledBack = styled(Back)`
  width: 28px;
  height: 28px;
  margin-right: 16px;
  vertical-align: sub;
  cursor: pointer;
  ${smDown`
    width: 24px;
    height: 24px;
    margin-left: -24px;
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "14px 76px 52px",
    [theme.breakpoints.down("md")]: {
      padding: "14px 50px 52px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "4px 20px 60px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

export default function LocatePolicyHelpDialog({ open, onClose, onBack }) {
  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <Hidden smDown>
          <DialogTitle
            title={
              <>
                <StyledBack
                  onClick={() => {
                    onClose();
                    onBack();
                  }}
                />
                Need Help?
              </>
            }
            closable={false}
          />
        </Hidden>
        <Hidden smUp>
          <DialogTitle
            title={
              <>
                <StyledBack
                  onClick={() => {
                    onClose();
                    onBack();
                  }}
                />
                Could not locate your policy?
              </>
            }
            closable={false}
          />
        </Hidden>
        <DialogContent>
          <StyledMessage>Are you having trouble locating your policy? Please contact QBE Hong Kong's Customer Service.</StyledMessage>
          <Wrapper>
            <StyledPhone />
            +852 2123-1234
          </Wrapper>
          <SecondWrapper>
            <StyledMail />
            customerservice@qbe.com
          </SecondWrapper>
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
