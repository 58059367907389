import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {cssIf, cssIfElse, cssIfNot} from '../../common/conditional-styles';
import typographyStyles from '../../common/typography-styles';

const sizes = [
    'normal',
    'small',
    'xSmall',
];

const weights = [
    'regular',
    'medium',
    'semiBold',
    'bold',
];

// TODO: Issues to raise with design:
//  - Missing focus state
//  - Visited state doesn't contrast well with background when isNegative
//  - Is the visited state necessary? Is it only for external links? Should it be configurable?

const baseStyles = css`
    font-family: ${property('theme.typography.text.fontFamily')};

    ${({scSize, scWeight}) => typographyStyles({
        type: 'text',
        sizes,
        defaultSize: scSize,
        weights,
        defaultWeight: scWeight,
    })}

    ${cssIfElse('scIsNegative')`
        color: ${property('theme.color.white')};

        &:hover,
        &:focus,
        &:active {
            color: ${property('theme.color.white')};
        }
    ``
        color: ${property('theme.color.primary')};

        &:hover,
        &:focus,
        &:active {
            color: ${property('theme.color.primary')};
        }
    `}

    &:focus {
        outline: 0;
    }
`;

const Button = styled.button`
    ${baseStyles}

    appearance: none;
    display: inline;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    line-height: inherit;

    ${cssIfNot('scSize')`
        font-size: inherit;
    `}

    ${cssIfNot('scWeight')`
        font-weight: inherit;
    `}
`;

const A = styled.a`
    ${baseStyles}

    text-decoration: none;

    ${cssIf('scHasVisitedStyle')`
        ${cssIfElse('scIsNegative')`
            &:visited {
                color: ${property('theme.color.accent10')};
            }
        ``
            &:visited {
                color: ${property('theme.color.accent10')};
            }
        `}
    `}
`;

export default function Link({
    className,
    size,
    weight,
    isNegative,
    href,
    onClick,
    children,
    type = 'button',
    ...rest
}) {
    const Component = href ? A : Button;

    return (
        <Component
            {...rest}
            className={classnames('Link', className)}
            href={href}
            onClick={onClick}
            scSize={size}
            scWeight={weight}
            scIsNegative={isNegative}
            scHasVisitedStyle={!!href && href.startsWith('http')} // only show the visited style for external links
            type={type}
        >
            {children}
        </Component>
    );
}

Link.propTypes = {
    className: propTypes.string,
    size: propTypes.oneOf(sizes),
    weight: propTypes.oneOf(weights),
    isNegative: propTypes.bool,
    href: propTypes.string,
    onClick: propTypes.func,
    children: propTypes.node,
    type: propTypes.string,
};
