import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _getPolicyToken,
    _getPortfolioToken, _hideModal, _hideSpinner,
    _setLang, _showError,
    _showModal, _showSpinner
} from "../../store/system/service";
import '../../assets/styles/auto-renewal.css';
import styled from "styled-components";
import {_getPolicy} from "../../store/policy/service";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {_isNotDecimalChar, _sendLog, _validateForm, getProductName} from "../../utils/utility";
import AutoRenewalHeader from "./auto-renewal-header";
import {
    _initAutoRenewalTransactionSignature,
    _updateAutoRenewalCreditCardDetails
} from "../../store/user/service";
import QnectButton from "../qnect-ui/qnect-button";


const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const AutoRenewalUpdateCreditCard = (props) => {
    const formId = 'updateCreditCardDetailsForm';
    const history = useHistory();
    const cfg = useConfig(props.lang);

    const formRef = useRef(null);

    const [cardholderName, setCardholderName] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [cvv, setCvv] = useState("");

    const [paymentSignature, setPaymentSignature] = useState(null);

    useEffect(() => {
        props.hideSpinner();
    },[]);

    useEffect(() => {
        window.autoRenewalCybersourceSuccess = autoRenewalCybersourceSuccess.bind(this);
        window.autoRenewalCybersourceError = autoRenewalCybersourceError.bind(this);

        //cleanup
        return () => {
            delete window.autoRenewalCybersourceSuccess;
            delete window.autoRenewalCybersourceError;
        }
    }, [paymentSignature]);

    const onChangeCardNumberFormatHandler = (event) => {
        let cardNoRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{4}$", "g");
        let newCardNo = '';
        let preventDefault = false;
        if (!cardNoRegex.test(event.target.value)) {
            let cardNo = event.target.value.replace(/-/g, '');
            if (cardNo.length > 16) {
                cardNo = cardNo.substring(0, 16);
            }

            if (!(/\D/g.test(cardNo))) {
                do {
                    if (cardNo.length > 4) {
                        newCardNo = newCardNo + cardNo.substring(0, 4) + '-';
                        cardNo = cardNo.substring(4, cardNo.length);
                    } else {
                        newCardNo = newCardNo + cardNo;
                        cardNo = '';
                    }
                } while (cardNo.length > 0);
            } else {
                preventDefault = true;
            }
        } else {
            newCardNo = event.target.value;
        }

        if (!preventDefault) {
            setCardNumber(newCardNo);
        } else {
            event.preventDefault();
        }
    };

    const onChangeExpiryDateHandler = (event) => {
        let newExpiryDate = event.target.value.trim();
        if (newExpiryDate) {
            if (newExpiryDate.length === 4 && newExpiryDate.indexOf("-") === -1) {
                newExpiryDate = newExpiryDate.substring(0, 2) + '/' + newExpiryDate.substring(2);
            }
        }
        setExpiryDate(newExpiryDate);
    };
    
    const updateCreditCardDetails = () => {
        if(!_validateForm(formId)){
            return;
        }
        props.showSpinner();
        props.initTransactionSignature(props.policy.policyNumber, props.policy.country, cardholderName, 'UPDATE').then((resp) => {
            let cardType = '001';
            let ccExpiryDate = expiryDate.substring(0, 2) + "-20" + expiryDate.substring(3);
            let creditCardNumber = '';
            if (cardNumber) {
                creditCardNumber = cardNumber.replace(/\s/g, '').replace(/-/g, '');
                if (new RegExp('^5[1-5]*').test(cardNumber)) {
                    cardType = '002';
                }
            }
            resp.cardType = cardType;
            resp.cardNumber = creditCardNumber;
            resp.expiryDate = ccExpiryDate;
            setPaymentSignature(resp);
            formRef.current.submit();
        }, (error) => {
            props.showSpinner();
            console.error(error);
            _sendLog(' update-credit-card-auto-renewal.js - updateCreditCardDetails(): exception:\n\n' + JSON.stringify(error));
            history.push("/auto-renewal/manage-auto-renewal/error-update");
        })
    }

    const autoRenewalCybersourceSuccess = (autoRenewalDetails) => {
        autoRenewalDetails['policyNumber'] = props.policy.policyNumber;
        autoRenewalDetails['agentDetails'] = props.policy.agentDetails;
        autoRenewalDetails['customerName'] = props.policy.clientName;
        autoRenewalDetails['customerEmail'] = props.policy.email;
        autoRenewalDetails['productName'] = props.policy.policyType;

        _sendLog('update-credit-card-auto-renewal.js - updateCreditCardDetailsSuccess():'+ JSON.stringify(autoRenewalDetails) ,'info');
        props.updateCreditCard(autoRenewalDetails).then(resp => {
            _sendLog('auto-renewal-update-credit-card.js - updateCreditCardDetailsSuccess(): resp =\n\n' + JSON.stringify(resp), 'info');
            props.hideSpinner();
            history.push("/auto-renewal/manage-auto-renewal/success-update");
        }, (error) => {
            props.hideSpinner();
            _sendLog('auto-renewal-update-credit-card.js - updateCreditCardDetailsSuccess(): exception:\n\n' + JSON.stringify(error));
            history.push("/auto-renewal/manage-auto-renewal/error-update");
        });
    }

    const autoRenewalCybersourceError= (error) => {
        props.hideSpinner();
        _sendLog('update-credit-card-auto-renewal.js - autoRenewalCybersourceError():' + + JSON.stringify(error), 'info');
        history.push("/auto-renewal/manage-auto-renewal/error-update");
    }

    return (
        <>
            <div className="pageContainer auto-renewal detail-page">

                <AutoRenewalHeader titleHeader="labels.autoRenewal.titleUpdateCreditCardDetails"
                    onBackCallBack={() => {
                        props.showSpinner();
                        history.push("/auto-renewal/manage-auto-renewal");
                }}/>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">

                        <div className="main-content-box">
                            <div className="content-boxed-block lined-table">
                                <div className="lined-title linked-title">
                                    <div className="content-center">{ cfg('labels.autoRenewal.renewalDetails.policyNumber') }</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.policyNumber') }</div>
                                    <div className="content-right">{ props.policy.policyNumber }</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.productName') }</div>
                                    <div className="content-right">{ getProductName(cfg, props.policy.country, props.policy.catalogueCode)}</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.policyHolder') }</div>
                                    <div className="content-right">{ props.policy.clientName }</div>
                                </div>
                                <div className="content-row">
                                    <div className="content-left">{ cfg('labels.autoRenewal.renewalDetails.customerEmailAddress') }</div>
                                    <div className="content-right">{ props.policy.email }</div>
                                </div>
                            </div>
                        </div>

                        <div className="main-content-box">
                            <div className="centered-title shaded-title">
                                <span className="">{cfg('labels.autoRenewal.updateCreditCardDetailsTitle')}</span>
                            </div>

                            <div className="infoPadded-container">
                                <section className="credit-card-details">
                                    <div className="centered-title sub-title">
                                        <span className="">{cfg('labels.autoRenewal.blockTitleProvideCardDetails')}</span>
                                    </div>
                                    <div className="card-type-container">
                                        <div className="cc-icon visa"/>
                                        <div className="cc-icon mastercard"/>
                                    </div>

                                    <div className="content-boxed-block">
                                        <div className="form-container credit-card-fields">
                                            <div className="form-field-container">
                                                <InputGroupStyle>
                                                    <QnectTextField
                                                        formId={formId}
                                                        field="cardholderName"
                                                        className="field_cardholderName"
                                                        value={cardholderName}
                                                        onChange={(event) => {
                                                            setCardholderName(event.target.value.toUpperCase());
                                                        }}
                                                    />
                                                </InputGroupStyle>
                                            </div>
                                            <div className="form-field-container">
                                                <InputGroupStyle>
                                                    <QnectTextField
                                                        formId={formId}
                                                        field="cardNumber"
                                                        className="field_cardNumber"
                                                        value={cardNumber} onChange={(event) => {
                                                        onChangeCardNumberFormatHandler(event)
                                                    }}
                                                        onKeyPress={(event) => {
                                                            if (_isNotDecimalChar(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={19}
                                                    />
                                                </InputGroupStyle>
                                            </div>
                                            <div className="form-field-container">
                                                <InputGroupStyle>
                                                    <QnectTextField
                                                        formId={formId}
                                                        field="expiryDate"
                                                        className="field_expiryDate"
                                                        value={expiryDate}
                                                        onChange={(event) => onChangeExpiryDateHandler(event)}
                                                        onKeyPress={(event) => {
                                                            if (_isNotDecimalChar(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={5}
                                                    />
                                                </InputGroupStyle>
                                                <InputGroupStyle>
                                                    <QnectTextField
                                                        formId={formId}
                                                        field="cvv"
                                                        className="field_cvv"
                                                        value={cvv}
                                                        onChange={(event) => setCvv(event.target.value)}
                                                        onKeyPress={(event) => {
                                                            if (_isNotDecimalChar(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </InputGroupStyle>
                                            </div>
                                            <div className="form-field-container">
                                                <div className="form-caption cc-note">
                                                    <span>{cfg('labels.autoRenewal.creditCardNote')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn-container">
                                            <QnectButton color="secondary" id="renewButton"
                                                    className="btn btn-round btn-secondary"
                                                    onClick={() => {
                                                        props.showSpinner();
                                                        history.push("/auto-renewal/manage-auto-renewal");
                                                    }}
                                                    >{cfg('labels.cancel')}</QnectButton>
                                            <QnectButton id="submitUpdateButton"
                                                    className="btn btn-round btn-primary"
                                                    onClick={updateCreditCardDetails}
                                                     disabled={!(cardholderName && cardNumber && expiryDate && cvv)}
                                                    >{cfg('labels.submit')}</QnectButton>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pay_confirm_section">
                    <form ref={formRef} action={paymentSignature?.paymentUrl} method="post" target="update_credit_card_iframe">
                        <input type="hidden" id="access_key" name="access_key"
                               value={paymentSignature?.access_key || ''}/>
                        <input type="hidden" id="profile_id" name="profile_id"
                               value={paymentSignature?.profile_id || ''}/>
                        <input type="hidden" id="transaction_uuid"
                               name="transaction_uuid" value={paymentSignature?.transaction_uuid || ''}/>
                        <input type="hidden" id="signed_field_names" name="signed_field_names"
                               value={paymentSignature?.signed_field_names || ''}/>
                        <input type="hidden" id="unsigned_field_names" name="unsigned_field_names"
                               value={paymentSignature?.unsigned_field_names || ''}/>
                        <input type="hidden" id="signed_date_time" name="signed_date_time"
                               value={paymentSignature?.signed_date_time || ''}/>
                        <input type="hidden" id="locale" name="locale" value={paymentSignature?.locale || ''}/>
                        <input type="hidden" id="transaction_type" name="transaction_type"
                               value={paymentSignature?.transaction_type || ''}/>
                        <input type="hidden" id="reference_number" name="reference_number"
                               value={paymentSignature?.reference_number || ''}/>
                        <input type="hidden" id="amount" name="amount"
                               value={paymentSignature?.amount || ''}/>
                        <input type="hidden" id="currency" name="currency"
                               value={paymentSignature?.currency || ''}/>
                        <input type="hidden" id="payment_method" name="payment_method"
                               value={paymentSignature?.payment_method || ''}/>
                        <input type="hidden" id="bill_to_forename" name="bill_to_forename"
                               value={paymentSignature?.bill_to_forename || ''}/>
                        <input type="hidden" id="bill_to_surname" name="bill_to_surname"
                               value={paymentSignature?.bill_to_surname || ''}/>
                        <input type="hidden" id="bill_to_email" name="bill_to_email"
                               value={paymentSignature?.bill_to_email || ''}/>
                        <input type="hidden" id="bill_to_phone" name="bill_to_phone"
                               value={paymentSignature?.bill_to_phone || ''}/>
                        <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1"
                               value={paymentSignature?.bill_to_address_line1 || ''}/>
                        <input type="hidden" id="bill_to_address_city" name="bill_to_address_city"
                               value={paymentSignature?.bill_to_address_city || ''}/>
                        <input type="hidden" id="bill_to_address_state" name="bill_to_address_state"
                               value={paymentSignature?.bill_to_address_state || ''}/>
                        <input type="hidden" id="bill_to_address_country" name="bill_to_address_country"
                               value={paymentSignature?.bill_to_address_country || ''}/>
                        <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code"
                               value={paymentSignature?.bill_to_address_postal_code || ''}/>
                        <input type="hidden" id="override_custom_receipt_page" name="override_custom_receipt_page"
                               value={paymentSignature?.override_custom_receipt_page || ''}/>
                        { (paymentSignature?.payment_token) &&
                            <input type="hidden" id="payment_token" name="payment_token"
                                   value={paymentSignature?.payment_token || ''}/>
                        }
                        <input type="hidden" id="signature" name="signature"
                               value={paymentSignature?.paymentSignatureKey || ''}/>

                        <input type="hidden" id="card_type" name="card_type"
                               value={paymentSignature?.cardType || ''}/>
                        <input type="hidden" id="card_number" name="card_number"
                               value={paymentSignature?.cardNumber || ''}/>
                        <input type="hidden" id="card_expiry_date" name="card_expiry_date"
                               value={paymentSignature?.expiryDate || ''}/>
                        <input type="hidden" id="card_cvn" name="card_cvn" value={cvv || ''}/>
                    </form>
                    <iframe frameBorder="0" height="0" name="update_credit_card_iframe" src="" width="0"></iframe>

                </div>
            </div>
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    setLang: _setLang,
    updateCreditCard: _updateAutoRenewalCreditCardDetails,
    initTransactionSignature: _initAutoRenewalTransactionSignature
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoRenewalUpdateCreditCard));

