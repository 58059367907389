import React, {useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import './login-auth.css';
import {_getUser} from "../../store/user/service";
import {_getPolicy, _storePolicy, _verifyCredentials} from "../../store/policy/service";
import {_setCpToken, _setPolicyToken} from "../../store/system/service";

const LoginAuth = (props) => {

    const [hkId, setHkId] = useState(props.policy?.hkId);
    const [name, setName] = useState(props.policy?.name);
    const [dateOfBirth, setDateOfBirth] = useState(props.policy?.dateOfBirth);

    const continueNextPage = () => {
        if (!props.user.userName) {
            props.policy.hkId = hkId;
            props.policy.name = name;
            props.policy.dateOfBirth = dateOfBirth;
            props.storePolicy(props.policy);
            props.setPolicyToken(props.policy.policyAccessToken);

            //show policy-payment details page after online registration
            let credentials = {
                clientID: hkId,
                clientName: name,
                clientDOB: dateOfBirth,
                policyNumber: props.policy?.policyNumber,
                country: props.policy?.country,
                policyHolderType: props.policy?.customerType
            }

            props.verifyCredentials(credentials).then((resp) => {
                if (resp.success) {
                    if (resp.token) {
                        props.setCpToken(resp.token);
                        props.history.push('/policy-payment');
                    }
                }
            }, error => {
                console.error('Verify Credentials Error ', error);
            });
        }
    }

    return (<>
        <div>
            <span>Verification</span>
            <br/>
            <br/>
            <div>
                <label htmlFor="hkId">HKID Number</label>
                <input id="hkId" type="text" value={hkId} onChange={(event) => setHkId(event.target.value)}></input>
            </div>
            <div>
                <label htmlFor="name">Name of Policyholder</label>
                <input id="name" type="text" value={name} onChange={(event) => setName(event.target.value)}></input>
            </div>
            <div>
                <label htmlFor="dob">Date of Birth of Policyholder</label>
                <input id="dob" type="text" value={dateOfBirth}
                       onChange={(event) => setDateOfBirth(event.target.value)}></input>
            </div>
            <div>
                <input type="button" value="Next" onClick={continueNextPage}></input>
            </div>
        </div>
    </>);
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        policy: _getPolicy(state)
    }
}

const mapDispatchToProps = {
    verifyCredentials: _verifyCredentials,
    storePolicy: _storePolicy,
    setCpToken: _setCpToken
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginAuth));
