import React, {useEffect} from "react";
import Portfolio from "../components/portfolio/portfolio";
import {connect} from "react-redux";
import {_getShowPageBroken, _hideSpinner, _setShowPageBroken} from "../store/system/service";

const PortfolioPage = (props) => {

    useEffect(() => {
        if (props.showPageBroken) {
            props.history.push('/pageUnavailable');
            props.setShowPageBroken(false);
            props.hideSpinner();
        }
    }, [props.showPageBroken]);

    return (
        <React.Fragment>
            <Portfolio/>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        showPageBroken: _getShowPageBroken(state)
    }
}

const mapDispatchToProps = {
    setShowPageBroken: _setShowPageBroken,
    hideSpinner: _hideSpinner
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPage);
