import React from 'react';

import BaseIcon from '../common/base-icon';

export default function IconChevronDown(props) {
    return (
        <BaseIcon {...props}>
            <path
                d="M12 13.293L6.707 8 5.293 9.414 12 16.121l6.707-6.707L17.293 8z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </BaseIcon>
    );
}
