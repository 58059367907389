import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _getPolicyToken,
    _getPortfolioToken,
    _setLang
} from "../../store/system/service";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import Header from "../custom/header";
import '../../assets/styles/auto-renewal.css';
import styled from "styled-components";

const BackWrapper = styled.div`
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const BackIcon = styled(Back)`
  margin-right: 8px;
`;

const TopWrapper = styled.div`
    position: relative;
    height: 100%;
    margin: 0 166px 0 166px;
    width: 100%;
    max-width: 1110px;
`;

const AutoRenewalHeader = (props) => {
    const cfg = useConfig(props.lang);
    const [lang, setLang] = useState( props.lang);


    useEffect(() => {

    }, [])

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
    }

    const canGoBack = () => {
        return props.portfolioToken || props.policyToken;
    }


    return ( <>
            <div className="container header-container">
                {canGoBack() ?
                    <Header pathname={props.pathname}/>
                    :
                    <div className="topRow">
                        <div className="left">
                            <a href="https://www.qbe.com/hk/en/about-qbe">
                                <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                            </a>
                        </div>
                        <div className="right">
                            <div className="languageSelect" onClick={() => switchLanguage()}
                                 style={{ visibility: (props.policy.country === 'HKG' ||
                                     props.policy.country === 'HGI'  ? 'visible' : 'hidden')}}>
                                {(lang === 'en') ?
                                    cfg("labels.lang_zh")
                                    :
                                    cfg("labels.lang_en")
                                }
                            </div>

                        </div>
                    </div>
                }
                <TopWrapper className="titleHeader">
                    { canGoBack() &&
                        <BackWrapper className="auto-renewal-back" onClick={() => {
                            props.onBackCallBack();
                        }}> <BackIcon/>
                            <span className="back-label">{ cfg ('policy.back') }</span>
                        </BackWrapper>
                    }
                    <div className="main-content-title top-title">{ cfg(props.titleHeader) }</div>
                </TopWrapper>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoRenewalHeader);

