import propTypes from 'prop-types';
import React, {useState} from 'react';

import {LabelledCheckbox} from "../../ui/components/form-controls";
import useConfig from "./qnect-config";
import styled from "styled-components";
import {QnectValidator} from "./qnect-validator";
import TextField from "../../ui/components/form-fields/text-field";
import {_getLang} from "../../store/system/service";
import {connect} from "react-redux";

const QnectCheckBox = React.forwardRef((
    {
        lang,
        field,
        formId,
        classNames,
        errorMessage,
        checked,
        disabled,
        onChange,
        children,
        ...rest
    },
    ref
) => {
    const cfg = useConfig(lang);

    return (
        <React.Fragment>
            <QnectValidator
                formId={formId}
                lang={lang}
                onChangeCallback="onChange"
                errorMessage={errorMessage}
                validators={cfg(field, "validators")}
                valueProp="checked"
            >
                <LabelledCheckbox
                    label={cfg(field, "label")}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    className={classNames}
                    errorMessage={cfg(errorMessage)}
                >
                    {children}
                </LabelledCheckbox>
            </QnectValidator>
        </React.Fragment>
    )
});

QnectCheckBox.propTypes = {
    field: propTypes.string,
    formId: propTypes.string,
    className: propTypes.string,
    checked: propTypes.bool,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming,
    onChange: propTypes.func,
    errorMessage: propTypes.string
};

QnectCheckBox.defaultProps = {
    button: "primary"
}

QnectCheckBox.displayName = 'QnectCheckBox';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps)(QnectCheckBox);
