import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import Logo from '../logos/qbe-logo';
import Heading from '../typography/heading';
import Paragraph from '../typography/paragraph';

const Page = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background: ${property('theme.color.tertiary')};
    padding: 36px;
    width: 100vw;
    height: 100%;
`;

const Grid = styled.div`
    display: grid;
    grid-row-gap: 32px;
    justify-items: center;
    text-align: center;

    & > * {
        margin: 0;
    }
`;

export default function ExitPage({
    className,
    heading,
    message,
}) {
    return (
        <Page className={classnames('ExitPage', className)}>
            <Grid>
                <Logo width={122} />
                <Heading color="white" weight="medium" size="large">
                    {heading}
                </Heading>
                <Paragraph color="white" size="big">
                    {message}
                </Paragraph>
            </Grid>
        </Page>
    );
}

ExitPage.propTypes = {
    className: propTypes.string,
    heading: propTypes.string,
    message: propTypes.string,
};
