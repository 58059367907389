import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {colorType} from '../../common/common-prop-types';
import typographyStyles from '../../common/typography-styles';

const sizes = [
    'big',
    'normal',
    'small',
    'xSmall',
];

const StyledParagraph = styled.p`
    margin: 24px 0;

    ${typographyStyles({
        type: 'paragraph',
        sizes,
        defaultSize: 'big',
        defaultWeight: 'regular',
        defaultColor: 'primary',
    })}

    & strong {
        font-weight: ${property('theme.typography.paragraph.fontWeight.semiBold')};
    }
`;

export default function Paragraph({
    className,
    size,
    color,
    children,
    ...rest
}) {
    return (
        <StyledParagraph
            {...rest}
            className={classnames('Paragraph', className)}
            scSize={size}
            scWeight="normal"
            scColor={color}
        >
            {children}
        </StyledParagraph>
    );
}

Paragraph.propTypes = {
    className: propTypes.string,
    size: propTypes.oneOf(sizes),
    color: colorType,
    children: propTypes.node,
};
