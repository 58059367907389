import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {smUp} from '../../common/breakpoints';

const CardContainer = styled.div`
    border: solid 1px ${property('theme.color.grey40')};
    border-radius: 10px;
    overflow-x: auto;
`;

const CardBody = styled.section`
    display: grid;
    grid-row-gap: 48px;
    background-color: ${property('theme.color.grey10')};
    padding: 24px 18px;
    color: ${property('tertiary90')};

    ${smUp`
        grid-row-gap: 32px;
        padding: 36px 32px;
    `}
`;

export default function Card({
    className,
    heading,
    children,
}) {
    return (
        <CardContainer className={classnames('Card', className)}>
            {heading}

            <CardBody>
                {children}
            </CardBody>
        </CardContainer>
    );
}

Card.propTypes = {
    className: propTypes.string,
    heading: propTypes.node,
    children: propTypes.node,
};
