import React, {useContext} from 'react';
import propTypes from 'prop-types';
import {ThemeContext} from 'styled-components';

import {displayType} from '../../../common/common-prop-types';

export default function BaseIcon({
    ariaLabel,
    children,
    color,
    display = 'block',
    size = '100%',
    ariaHidden = ariaLabel ? undefined : true,
    style,
    title,
    ...rest
}) {
    const theme = useContext(ThemeContext) || {};

    return (
        <svg
            {...rest}
            viewBox="0 0 24 24"
            aria-hidden={ariaHidden}
            aria-label={ariaLabel}
            style={{
                ...style,
                color: theme.color[color],
                display,
                width: size,
                minWidth: size,
                height: size,
                minHeight: size,
            }}
        >
            {!!title && (
                <title>
                    {title}
                </title>
            )}
            {children}
        </svg>
    );
}

BaseIcon.propTypes = {
    ariaHidden: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    ariaLabel: propTypes.string,
    color: propTypes.string,
    children: propTypes.node,
    display: displayType,
    size: propTypes.oneOfType([propTypes.string, propTypes.number]),
    style: propTypes.object,
    title: propTypes.string,
};
