import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {textFieldType} from '../../common/common-prop-types';
import {grid, gridCell} from '../../common/grid';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import TextField from '../form-fields/text-field';
import Paragraph from '../typography/paragraph';
import LabelledCheckbox from '../form-controls/labelled-checkbox';

const Fields = styled.div`
    ${grid({rows: 2, rowGap: '40px'})}
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

const PhoneNumber = styled(TextField)`
    ${gridCell({row: 1, column: 1})}
`;

const EmailAddress = styled(TextField)`
    ${gridCell({row: 2, column: 1})}
`;

const StyledParagraph = styled(Paragraph)`
    margin: 0 0 32px;
    text-align: center;
`;

export default function BrokerDetails({
    className,
    agentNumberLabel,
    agentNumber,
    phoneNumber,
    emailAddress,
    contactLabel,
    isContact,
    onChangeIsContact,
    submitButtonText,
    isSubmitting,
    onSubmit,
}) {
    return (
        <Form
            className={classnames('BrokerDetails', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            {!!(agentNumberLabel && agentNumber) && (
                <StyledParagraph
                    color="tertiary90"
                    size="normal"
                >
                    {`${agentNumberLabel}: `}
                    <strong>
                        {agentNumber}
                    </strong>
                </StyledParagraph>
            )}

            <Fields>
                <PhoneNumber
                    {...phoneNumber}
                    type="tel"
                    disabled={isSubmitting}
                />
                <EmailAddress
                    {...emailAddress}
                    type="email"
                    disabled={isSubmitting}
                />
            </Fields>

            <LabelledCheckbox
                label={contactLabel}
                disabled={isSubmitting}
                checked={isContact}
                onChange={onChangeIsContact}
            />

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

BrokerDetails.propTypes = {
    className: propTypes.string,
    agentNumberLabel: propTypes.string,
    agentNumber: propTypes.string,
    phoneNumber: textFieldType,
    emailAddress: textFieldType,
    contactLabel: propTypes.string,
    isContact: propTypes.bool,
    onChangeIsContact: propTypes.func,
    submitButtonText: propTypes.string,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func,
};
