import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";
import {ReactComponent as Back} from "../../../assets/login/back_sharp.svg";
import ArrowIconBack from "../../../assets/icon_arrow_left.svg";
import {StyledModalDiv} from "../../login-style";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 60px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialogTitle-root": {
        padding: " 16px 0"
      }
    },
    "& .MuiDialogContent-root": {
      padding: "8px 10px"
    }
  },
  title: {
    display: "flex",
    marginLeft: "-20px"
  },
  message: {
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "30px",
      fontSize: "18px",
      lineHeight: "25px"
    },
    "& > span": {
      color: "#003DA5",
      cursor: "pointer",
      fontWeight: "600"
    },
    whiteSpace: "pre-wrap",
    "& > ol": {
      counterReset: "list",
      paddingInlineStart: "30px",
      "& > li": {
        marginTop: "20px",
        listStyle: "none",
        position: "relative",
        '&::before': {
          content: "counter(list, lower-alpha) ') '",
          counterIncrement: "list",
          left: "-30px",
          position: "absolute"
        }
      }
    },
  },
  "innerList": {
    counterReset: "list",
    paddingInlineStart: "30px",
    "& > li": {
      listStyle: "none",
      position: "relative",
      '&::before': {
        content: "counter(list, lower-roman) '. '",
        counterIncrement: "list",
        left: "-30px",
        position: "absolute"
      }
    }
  },
  back: {
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
      marginRight: "12px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "28px",
      height: "28px",
      marginRight: "16px"
    },
    marginTop: "6px",
    cursor: "pointer"
  }
}));

const PicsSgpDialog = ({
  open,
  onBack,
  onClose,
    cfg,
    ...props
}) => {
  const classes = useStyles();

  return (
    <div>

      <Dialog id="PICS" className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title={
            <div className={classes.title}>
              <Back className={classes.back} onClick={onBack}/>
              <div className="desktop-only" dangerouslySetInnerHTML={{__html:  cfg("login.modalPICSTitle")}}/>
              <div className="mobile-only" dangerouslySetInnerHTML={{__html:  cfg("login.modalPICSTitle_mobile")}}/>
            </div>
          }
          onClose={onClose}
        />
        <StyledModalDiv className="PICS_Modal">
          <div className="content">
            <p>{cfg('login.modalPICSContentHeader')}</p>
            <ol className="mainList2">
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalPICSContentListItemA')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">
                  {cfg('login.modalPICSContentListItemB_a')}
                  <a href={cfg('login.modalPICSContentListItemB_b')} target='_blank'>{cfg('login.modalPICSContentListItemB_b')}</a>
                  {cfg('login.modalPICSContentListItemB_c')}
                </div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalPICSContentListItemC')}</div>
                <ol className="subList" type="i">
                  <li className="subListItem">{cfg('login.modalPICSContentListItemC1')}</li>
                  <li className="subListItem">{cfg('login.modalPICSContentListItemC2')}</li>
                  <li className="subListItem">{cfg('login.modalPICSContentListItemC3')}</li>
                  <li className="subListItem">{cfg('login.modalPICSContentListItemC4')}</li>
                  <li className="subListItem">{cfg('login.modalPICSContentListItemC5')}</li>
                </ol>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalPICSContentListItemD_a')}</div>
                <div className="itemText">{cfg('login.modalPICSContentListItemD_b')}<br/>
                  {cfg('login.modalPICSContentListItemD_c')}<br/>
                  {cfg('login.modalPICSContentListItemD_d')}<a href={cfg('login.modalPICSContentListItemD_e')} target="_blank">{cfg('login.modalPICSContentListItemD_e')}</a>{cfg('login.modalPICSContentListItemD_f')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalPICSContentListItemE')}</div>
              </li>
            </ol>
          </div>

        </StyledModalDiv>
      </Dialog>
    </div>
  );
}

PicsSgpDialog.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func
}

export default PicsSgpDialog;
