export function expandFontMatrix(fontMatrix) {
    return fontMatrix.reduce(
        (
            acc,
            [
                name,
                fontSizePxDesktop,
                lineHeightPxDesktop,
                letterSpacingPxDesktop,
                fontSizePxMobile,
                lineHeightPxMobile,
                letterSpacingPxMobile,
            ]
        ) => Object.assign(
            acc,
            {
                [name]: {
                    fontSizePx: {
                        mobile: fontSizePxMobile,
                        desktop: fontSizePxDesktop,
                    },
                    lineHeightPx: {
                        mobile: lineHeightPxMobile,
                        desktop: lineHeightPxDesktop,
                    },
                    letterSpacingPx: {
                        mobile: letterSpacingPxMobile,
                        desktop: letterSpacingPxDesktop,
                    },
                },
            }
        ),
        {}
    );
}

/**
 * Convert an array of colors into an object whose properties follow the styled guide's color naming convention.
 *
 * @param {string[]}     colors     - array of colors (typically 9)
 * @param {string}       prefix     - color name prefix
 * @returns {object.<string>} an object that maps style guide color names to their values
 */
export function numberedColors(colors, prefix) {
    return colors.reduce((acc, color, index) => Object.assign(acc, {[`${prefix}${10 * (index + 1)}`]: color}), {});
}
