import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import get from 'lodash/get';
import property from 'lodash/property';

import {cssIf, cssIfElse} from '../../common/conditional-styles';
import {buttonType} from '../../common/common-prop-types';

const buttonStyles = {
    primary: buttonComponents({
        positiveBackgroundColor: property('theme.color.primary'),
        positiveColor: property('theme.color.white'),
        positiveHoverFocusBackgroundColor: property('theme.color.primary90'),
        positiveShadowColor: ({theme}) => rgba(get(theme, 'color.primary'), 0.2),
        positiveHoverShadowColor: ({theme}) => rgba(get(theme, 'color.primary90'), 0.4),
        positiveActiveBackgroundColor: property('theme.color.primary70'),
        negativeBackgroundColor: property('theme.color.white'),
        negativeColor: property('theme.color.primary'),
        negativeHoverFocusBackgroundColor: property('theme.color.grey30'),
        negativeShadowColor: ({theme}) => rgba(get(theme, 'color.white'), 0.2),
        negativeActiveBackgroundColor: property('theme.color.grey10'),
    disabledBackgroundColor: property("theme.color.grey60"),
    disabledColor: property("theme.color.white"),
    }),
    secondary: buttonComponents({
        positiveBorderColor: property('theme.color.primary70'),
        positiveColor: property('theme.color.primary'),
        positiveHoverColor: property('theme.color.primary90'),
        // positiveHoverFocusBorderColor: property('theme.color.primary60'),
        positiveHoverFocusBackgroundColor: property('theme.color.white'),
        // positiveShadowColor: ({theme}) => rgba(get(theme, 'color.primary'), 0.1),
        positiveActiveBorderColor: property('theme.color.primary70'),
        negativeBorderColor: property('theme.color.white'),
        negativeColor: property('theme.color.white'),
        negativeHoverFocusBorderColor: property('theme.color.white'),
        // negativeHoverFocusBackgroundColor: property('theme.color.grey30'), // TODO: Flag with design
        negativeShadowColor: ({theme}) => rgba(get(theme, 'color.white'), 0.1),
    negativeActiveBackgroundColor: property("theme.color.grey60"),
    disabledColor: property("theme.color.white"), // Disabled state not specified in design. TODO: flag with design
    }),
    whiteOutline: buttonComponents({
        positiveBorderColor: property('theme.color.white'),
        positiveColor: property('theme.color.white'),
        positiveHoverFocusBorderColor: property('theme.color.white60'),
        positiveHoverFocusBackgroundColor: "#ffffff33",
        positiveShadowColor: "#ffffff33",
        positiveActiveBorderColor: ({theme}) => rgba(get(theme, 'color.primary30'), 0.1),
        negativeBorderColor: property('theme.color.white'),
        negativeColor: property('theme.color.white'),
        negativeHoverFocusBorderColor: property('theme.color.white'),
        // negativeHoverFocusBackgroundColor: property('theme.color.grey30'), // TODO: Flag with design
        negativeShadowColor: ({theme}) => rgba(get(theme, 'color.white'), 0.1),
    negativeActiveBackgroundColor: property("theme.color.grey60"),
    disabledColor: property("theme.color.white"), // Disabled state not specified in design. TODO: flag with design
    }),
};

const Button = React.forwardRef((
    {
        color,
        className,
        href,
        type = 'button',
        disabled,
        isTall,
        isNegative,
        isBlock,
        startIcon,
        icon,
        children,
        ...rest
    },
    ref
) => {
    const {Button, A, IconPositioner,StartIconPositioner} = buttonStyles[color] || buttonStyles.primary;
    const Component = href ? A : Button;

    return (
        <Component
            {...rest}
            ref={ref}
        className={classnames("Button", className)}
            type={href ? null : type}
            aria-disabled={disabled}
            hasIcon={!!icon}
            hasStartIcon={!!startIcon}
            {...{href, disabled, isTall, isNegative, isBlock}}
        >
            {!!startIcon && (
                <StartIconPositioner>
                    {startIcon}
                </StartIconPositioner>
            )}
            {children}
            {!!icon && (
                <IconPositioner>
                    {icon}
                </IconPositioner>
            )}
        </Component>
    );
});

Button.propTypes = {
    color: propTypes.oneOf(['primary', 'secondary','whiteOutline']),
    className: propTypes.string,
    href: propTypes.string,
    type: buttonType,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    isTall: propTypes.bool,
    isNegative: propTypes.bool,
    isBlock: propTypes.bool,
    icon: propTypes.node,
    children: propTypes.node,
};

Button.displayName = 'Button';

export default Button;

// Build button components for the specified colours
function buttonComponents({
    positiveBorderColor,
    positiveBackgroundColor,
    positiveColor,
    positiveHoverColor,
    positiveHoverShadowColor,
    positiveHoverFocusBorderColor,
    positiveHoverFocusBackgroundColor,
    positiveShadowColor,
    positiveActiveBorderColor,
    positiveActiveBackgroundColor,
    positiveActiveColor,

    negativeBorderColor,
    negativeBackgroundColor,
    negativeColor,
    negativeHoverFocusBorderColor,
    negativeHoverFocusBackgroundColor,
    negativeShadowColor,
    negativeActiveBorderColor,
    negativeActiveBackgroundColor,
    negativeActiveColor,

    disabledBorderColor,
    disabledBackgroundColor,
    disabledColor,
}) {
    // Positive (default) variant styles
    const positiveStyles = getVariantStyles({
        borderColor: positiveBorderColor,
        backgroundColor: positiveBackgroundColor,
        color: positiveColor,
        hoverColor: positiveHoverColor,
        hoverShadowColor: positiveHoverShadowColor,
        hoverFocusBorderColor: positiveHoverFocusBorderColor,
        hoverFocusBackgroundColor: positiveHoverFocusBackgroundColor,
        shadowColor: positiveShadowColor,
        activeBorderColor: positiveActiveBorderColor,
        activeBackgroundColor: positiveActiveBackgroundColor,
        activeColor: positiveActiveColor,
    });

    // Negative variant styles
    const negativeStyles = getVariantStyles({
        borderColor: negativeBorderColor,
        backgroundColor: negativeBackgroundColor,
        color: negativeColor,
        hoverFocusBorderColor: negativeHoverFocusBorderColor,
        hoverFocusBackgroundColor: negativeHoverFocusBackgroundColor,
        shadowColor: negativeShadowColor,
        activeBorderColor: negativeActiveBorderColor,
        activeBackgroundColor: negativeActiveBackgroundColor,
        activeColor: negativeActiveColor,
    });

    // hover/focus/active padding - subtract 2 - otherwise subtract 1

    // Styles shared between button and anchor
    const baseStyles = css`
        box-sizing: border-box;
        transition: box-shadow 100ms linear, background-color 100ms linear, color 100ms linear;
        margin: 0;
        font-family: Inter, Verdana, Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;

        ${cssIfElse('hasIcon')`
            position: relative;
            padding: 0 59px 0 30px;
        ``
            padding: 0 30px;
        `}
        
        ${cssIfElse('hasStartIcon')`
            position: relative;
            padding: 0 30px 0 59px;
        ``
            padding: 0 30px;
        `}

        ${cssIfElse('isTall')`
            border-radius: 35px;
            height: 70px;
        ``
            border-radius: 35px;
            height: 70px;
        `}

        ${cssIfElse('isNegative')`
            ${negativeStyles}
        ``
            ${positiveStyles}
        `}

        &:focus {
            outline: 0;
        }

        /* stylelint-disable order/order */
        &:hover:not([disabled]),
        &:focus:not([disabled]),
        &:active:not([disabled]) {
            ${cssIfElse('hasIcon')`
                padding: 0 58px 0 30px;
            ``
                padding: 0 30px;
            `}
            ${cssIfElse('hasStartIcon')`
                padding: 0 30px 0 58px;
            ``
                padding: 0 30px;
            `}
        }
        /* stylelint-enable order/order */

        &[disabled] {
            border: 1px solid ${disabledBorderColor || 'transparent'};
            background: ${disabledBackgroundColor};
            cursor: not-allowed;
            color: ${disabledColor};
      box-shadow: none;
        }
    `;

    // Button-specific styles
    const Button = styled.button`
        appearance: none;
        cursor: pointer;
        box-shadow: none;
        ${cssIf('isBlock')`
            width: 100%;
        `}

        ${baseStyles}
    `;

    // Anchor-specific styles
    const A = styled.a`
        display: ${({isBlock}) => isBlock ? 'flex' : 'inline-flex'};
        align-items: center;
        justify-content: center;
        text-decoration: none;

        ${baseStyles}
    `;
    const StartIconPositioner = styled.div`
        position: absolute;
        // top: 50%;
        left: 24px;
        // transform: translateY(-50%);
        width: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-item: center;

        ${Button}:hover:not([disabled]) > &,
        ${Button}:focus:not([disabled]) > &,
        ${Button}:active:not([disabled]) > &,
        ${A}:hover:not([disabled]) > &,
        ${A}:focus:not([disabled]) > &,
        ${A}:active:not([disabled]) > & {
            left: 23px;
        }
    `;

    const IconPositioner = styled.div`
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        width: 24px;

        ${Button}:hover:not([disabled]) > &,
        ${Button}:focus:not([disabled]) > &,
        ${Button}:active:not([disabled]) > &,
        ${A}:hover:not([disabled]) > &,
        ${A}:focus:not([disabled]) > &,
        ${A}:active:not([disabled]) > & {
            right: 23px;
        }
    `;

    return {
        Button,
        A,
        IconPositioner,
        StartIconPositioner,
    };
}

// Build variant-specific styles
function getVariantStyles({
    borderColor,
    backgroundColor,
    color,
    hoverColor,
    hoverShadowColor,
    hoverFocusBorderColor,
    hoverFocusBackgroundColor,
    shadowColor,
    activeBorderColor,
    activeBackgroundColor,
    activeColor,
}) {
    /* eslint-disable indent */
    return css`
        border: 1px solid ${borderColor || 'transparent'};
        background: ${backgroundColor || 'transparent'};
        color: ${color};

        &:hover:not([disabled]),
        &:focus:not([disabled]) {
            border: 1px solid ${hoverFocusBorderColor || borderColor || 'transparent'};
            background: ${hoverFocusBackgroundColor || backgroundColor || 'transparent'};

            ${
                hoverColor ? css`
                    color: ${hoverColor};
                ` : activeColor ? css`
                    color: ${activeColor};
                ` : ''
            }

            ${
                hoverShadowColor ? css`
                    box-shadow: 0 10px 30px 0 ${hoverShadowColor};
                ` : shadowColor ? css`
                    box-shadow: 0 10px 30px 0 ${shadowColor};
                ` : ''
            }
        }

        &:active:not([disabled]) {
            border: 2px solid ${activeBorderColor || borderColor || 'transparent'};
            background: ${activeBackgroundColor || backgroundColor || 'transparent'};
        }
    `;
    /* eslint-enable indent */
}
