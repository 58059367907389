import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {smUp} from '../../common/breakpoints';
import {selectFieldType, textAreaType} from '../../common/common-prop-types';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import RadioGroup from '../form-controls/radio-group';
import SelectField from '../form-fields/select-field';
import TextAreaField from '../form-fields/text-area-field';
import Paragraph from '../typography/paragraph';

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

const Message = styled(Paragraph)`
    display: block;
    margin: 32px 0;
    text-align: center;
`;

const Spacing = styled.div`
    height: 24px;

    ${smUp`
        height: 42px;
    `}
`;

export default function IncidentNatureDetails({
    className,
    incident,
    description,
    descriptionMessage,
    isShowClaimRentOrDamage = false,
    claimRentOrDamage,
    claimRentOrDamageMessage,
    submitButtonText,
    isSubmitting,
    onSubmit,
}) {
    return (
        <Form
            className={classnames('IncidentNatureDetails', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            <SelectField
                {...incident}
                placeholder={incident.label}
                disabled={isSubmitting}
            />

            {isShowClaimRentOrDamage && (
                <>
                    <Message color="tertiary90">
                        {claimRentOrDamageMessage}
                    </Message>

                    <RadioGroup
                        {...claimRentOrDamage}
                        disabled={isSubmitting}
                    />
                </>
            )}

            {!isShowClaimRentOrDamage && (
                <Spacing />
            )}

            {!!descriptionMessage && (
                <Message color="tertiary90">
                    {descriptionMessage}
                </Message>
            )}

            <TextAreaField
                {...description}
                disabled={isSubmitting}
            />

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

IncidentNatureDetails.propTypes = {
    className: propTypes.string,
    incident: selectFieldType,
    isShowClaimRentOrDamage: propTypes.bool,
    claimRentOrDamage: selectFieldType,
    claimRentOrDamageMessage: propTypes.string,
    description: textAreaType,
    descriptionMessage: propTypes.string,
    submitButtonText: propTypes.string,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func,
};
