import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 47px 32px",
        width: "630px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 32px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "28px"
    }
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px"
    }
  }
}));

export default function UnsaveDialog({
  open = false,
  onClose,
  onConfirm = null
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title="You have unsaved changes"
          closable={false}
          onClose={onClose}
        />
        <DialogContent>
          <Hidden smUp>
            <div className={classes.message}>
              Are you sure you want to cancel the action? By doing so, the
              mobile number will remain unchanged. You may have to revisit the
              process again.
            </div>
          </Hidden>
          <Hidden smDown>
            <div className={classes.message}>
              Are you sure you want to cancel the action?
              <br /> By doing so, the mobile number will remain unchanged.
              <br />
              You may have to revisit the process again.
            </div>
          </Hidden>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper color="secondary">
            <QnectButton color="secondary" onClick={onConfirm}>
              Leave
            </QnectButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <QnectButton className={classes.button} onClick={onClose}>
              Stay
            </QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UnsaveDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
