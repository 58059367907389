import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../dialog-title";
import Dialog from "../dialog";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {_getLang} from "../../../store/system/service";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 60px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    },
    [theme.breakpoints.up("sm")]: {
      "& .MuiDialogTitle-root": {
        padding: "16px 0"
      }
    }
  },
  title: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-15px"
    },
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      marginLeft: "30px"
    }
  },
  back: {
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
      marginRight: "12px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "28px",
      height: "28px",
      marginRight: "16px"
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "57px"
    }
  }
}));

export default function ReconfirmMobileDialog({
  open = false,
  onClose,
  onBack,
  onOk,
  cfg
}) {
  const classes = useStyles();
  const lang = useSelector(state => _getLang(state));

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title={
            <div className={classes.title}>
              <Back className={classes.back} onClick={onBack} />
              <div style={lang === 'zh' ? {marginLeft: '70px'} : null}>
                {cfg("verification.reconfirmMobile")}
              </div>
            </div>
          }
          onClose={onClose}
        />
        <DialogContent>
          <div className={classes.message}>
            {cfg("verification.reconfirmMessage")}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper>
            <QnectButton onClick={onOk}>{cfg("verification.yes")}</QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ReconfirmMobileDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  onOk: PropTypes.func
};
