import {useEffect} from 'react';
import get from 'lodash/get';

/**
 * Custom hook to ensure the provided blur handler is called when all children
 * are unfocused.
 *
 * @param {*}        ref        - the parent element's ref
 * @param {Function} onBlur     - called when all radios have lost focus
 */
export default function useParentBlur(ref, onBlur) {
    useEffect(
        () => {
            // Handles keyboard triggering loss of focus.
            function onFocusOut({relatedTarget}) {
                if (
                    get(ref, 'current')
                    && relatedTarget
                    && !ref.current.contains(relatedTarget)
                ) {
                    onBlur();
                }
            }

            // Handles passing focus via touch/mouse
            function onMouseDown({target}) {
                if (
                    get(ref, 'current')
                    && ref.current.contains(document.activeElement)
                    && target
                    && !ref.current.contains(target)
                ) {
                    onBlur();
                }
            }

            if (onBlur) {
                window.addEventListener('mousedown', onMouseDown);
                window.addEventListener('focusout', onFocusOut);

                return () => {
                    window.removeEventListener('focusout', onFocusOut);
                    window.removeEventListener('mousedown', onMouseDown);
                };
            }
        },
        [onBlur]
    );
}
