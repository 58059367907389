import styled from "styled-components";
import React, {useState} from "react";
import RadioCard from "../ui/components/form-controls/radio-card";
import propTypes from 'prop-types';
import {mdDownN} from "../ui/common";

const RadioBoxGroupsStyle = styled.div.attrs({
    className: 'RadioBoxGroupsStyle',
})`
    position: relative;
    // width: 600px;
    margin: 0 auto;

    width: 378px;
    padding: unset;
    justify-items: center;
    align-items: center;
    // height: 96px;
    display: grid;
    justify-content: center;
    grid-template-columns: 189px 189px;
    // padding-left: 128px;
    text-align: left;
    display: -ms-grid;
    -ms-grid-columns: 189px 189px;
    -ms-grid-rows: 1fr;
    ${mdDownN`
        width: 295px;
        grid-template-columns: 133px 133px;
        -ms-grid-columns: 133px 133px;
        column-gap: 29px;
    `}
`;



const RadioGroupStyle = styled.div.attrs({
    className: 'RadioGroupStyle',
})`
    position: relative;
    margin-top: 16px;
    width: 158px;
    height:60px;
    // height:76px;
    display: inline-block;
    margin-right: 25px;
    margin: auto auto;
    -ms-grid-row:1;
    -ms-grid-row-span:1;
    -ms-grid-column: ${({index})=>index+1};
    -ms-grid-column-span: 1;
    ${mdDownN`
        width: 133px;
    `}
`;


const RadioBoxErrorStyle =  styled.div.attrs({
    className: 'RadioBoxErrorStyle',
})`
    display: block;
    color: #a50000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0px;
    margin: 10px 0px 10px 0px;
    text-align: left;
`;

const RadioBox = (props) => {
    const [selectedValue, setSelectedValue] = useState (props.value);

    const selectValue = (value) => {
        setSelectedValue(value);
        if(!!props.onClick){
            props.onClick(value);
        }
    }

    return (
        <RadioBoxGroupsStyle>
            {props.options.map(({optionLabel, optionValue, optionIcon}, index) =>
                <RadioGroupStyle index={index} key={optionValue + index}>
                    <RadioCard
                        label={optionLabel}
                        value={optionValue}
                        icon={optionIcon}
                        disabled={props.disabled}
                        checked={optionValue === selectedValue}
                        onChange={(event) => {
                            selectValue(optionValue);
                            if(!!props.onChange){
                                props.onChange(event);
                            }
                        }}
                        name={!!props.name ? props.name : "radioBox"}
                    />
                </RadioGroupStyle>
            )}
            { props.errorMessage &&
            <RadioBoxErrorStyle>
                {props.errorMessage}
            </RadioBoxErrorStyle>
            }

        </RadioBoxGroupsStyle>
    );
}

RadioBox.propTypes = {
    field: propTypes.string,
    formId: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.shape({
        optionLabel: propTypes.string,
        optionValue: propTypes.string,
        optionIcon: propTypes.any
    })),
    disabled: propTypes.bool,
    onBlur: propTypes.func,
};

RadioBox.displayName = 'RadioBox';

export default RadioBox;
