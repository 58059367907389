import React, { memo } from "react";
import styled, {css} from "styled-components";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import { LanguageSelectDiv } from "../../login-style";
import PropTypes from "prop-types";

const MWrapper = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e4e8;
`;

const MBackIconWrapper = styled.div`
  height: 42px;
  width: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackIcon = styled(Back)`
  height: 24px;
  width: 24px;
`;

const MTitle = styled.div`
  color: #003da5;
  font-family: Stag;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  margin-right: 13px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
`;

const StyledLanguageDiv = styled(LanguageSelectDiv)`
    top: 13px;
    right: 12px;
    ${({isHidden}) => {
        if (isHidden) {
            return css`
                visibility: hidden;
            `  
        } 
    }}
`;

const MobileHeader = ({ title, lang, onBack = null, onToggleLang = null, onClickTitle = null, isHidden = false }) => {
  return (
    <>
      <MWrapper>
        <MBackIconWrapper onClick={onBack}>
          <BackIcon />
        </MBackIconWrapper>
        <MTitle onClick={onClickTitle}>{title}</MTitle>
        <Right>
          <StyledLanguageDiv onClick={onToggleLang} isHidden={isHidden}>
              { lang }
          </StyledLanguageDiv>
        </Right>
      </MWrapper>
    </>
  );
};

MobileHeader.propTypes = {
  title: PropTypes.string,
  lang: PropTypes.string,
  onBack: PropTypes.func,
  onToggleLang: PropTypes.func,
  onClickProfile: PropTypes.func,
};

export default memo(MobileHeader);
