import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../dialog-title";
import QnectButton from "../../qnect-ui/qnect-button";
import { ReactComponent as SuccessImage } from "../../../assets/portfolio/icon_success_image.svg";
import ButtonWrapper from "../button-wrapper";
import { smDown } from "../../../ui/common/breakpoints";

const StyledMessage = styled.div`
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 470px;
  margin: 36px auto 0;
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    width: auto;
    margin-top: 30px;
  `}
`;

const StyledSuccessImage = styled(SuccessImage)`
  margin: 20px auto;
  display: block;
  ${smDown`
    height: 146px;
    width: 112px;
    margin: 4px auto;
  `}
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  ${smDown`
    & button {
      width: 160px !important;
    }
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "14px 76px 32px",
    [theme.breakpoints.down("md")]: {
      padding: "14px 23px 32px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

const StyledDialogActions = withStyles({
  root: {
    margin: "28px auto 0",
    fontFamily: "Inter",
  },
})(DialogActions);

export default function LocatePolicySuccessDialog({ open, onClose, cfg }) {
  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle title={"Request Submitted"} closable={false} />
        <DialogContent>
          <StyledSuccessImage />
          <StyledMessage>
            You request of adding policy to the account has been submitted successfully. We will review it shortly.
          </StyledMessage>
        </DialogContent>
        <StyledDialogActions>
          <StyledButtonWrapper>
            <QnectButton onClick={() => onClose()}>{cfg("labels.confirm")}</QnectButton>
          </StyledButtonWrapper>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
}
