import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import OtpInput from "../opt-input";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 60px 32px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    },
  },
  message: props => ({
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: props.lang === "zh" ? "46px" : "6px",
      fontSize: "18px",
      lineHeight: "25px"
    },
    letterSpacing: "0.05px"
  }),
  otp_wrapper: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "30px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "65px",
      marginBottom: "40px"
    }
  },
  received: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  resend: {
    "& span": {
      color: "#003da5",
      cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "8px"
    }
  },
  resend_diabled: {
    color: "#b0b7c3",
    cursor: "auto"
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "24px"
    }
  }
}));

export default function VerificationCodeDialog(props) {
  const {
    open,
    value,
    maskedPhone,
    errorMessage,
    setValue,
    setOtpErrorMessage,
    onClose,
    handleResend,
    resendTimer,
    onOther,
    onConfirm,
    cfg,
    lang
  } = props;
  const classes = useStyles({lang});

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogTitle
        lang={lang}
        title={cfg("verification.label")}
        onClose={onClose}
      />
      <DialogContent>
        <div className={classes.message}>
          {cfg("verification.message") }{ maskedPhone.replace(/\*/g, '•').split('').map(x=><span>{x}</span>)}
        </div>
        <OtpInput
          className={classes.otp_wrapper}
          value={value}
          onChange={v => setValue(v)}
          errorMessage={value !== "" ? errorMessage : ""}
        />
        <div className={classes.received}>{cfg("verification.resendQuestion")}</div>
        {resendTimer === 0 ? (
          <div className={[classes.received, classes.resend].join(" ")}>
            <span onClick={handleResend}>{cfg("verification.resend")}</span>
          </div>
        ) : (
          <div
            className={[
              classes.received,
              classes.resend,
              classes.resend_diabled
            ].join(" ")}
          >
            {cfg("verification.resend")} ({resendTimer})
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ButtonWrapper>
          <QnectButton
              onClick={() => {
                onConfirm && onConfirm();
              }}
              disabled={value.length !== 6}
          >
            {cfg("verification.confirm")}
          </QnectButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
}

VerificationCodeDialog.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.string,
  maskedPhone: PropTypes.string,
  errorMessage: PropTypes.string,
  setValue: PropTypes.func,
  setOtpErrorMessage: PropTypes.func,
  onClose: PropTypes.func,
  handleResend: PropTypes.func,
  resendTimer: PropTypes.number,
  onOther: PropTypes.func,
  onConfirm: PropTypes.func,
  cfg: PropTypes.func
};
