import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import noop from 'lodash/noop';
import property from 'lodash/property';
import classnames from 'classnames';

import {cssIf} from '../../common/conditional-styles';
import typographyStyles from '../../common/typography-styles';
import Paragraph from '../typography/paragraph';

const TextAreaContainer = styled.div`
    position: relative;
    margin: 10px auto 0;
    background-color: ${({isDisabled})=>isDisabled?'#C9CED6':'#fff'};
    padding: 16px 2px 3px 16px;
    max-width: 100%;
    border-radius: 12px;
    border: 1px solid #c9ced6;
`;

const CharacterCountText = styled(Paragraph)`
    margin: 0;
    padding: 0 14px 0 0;
    text-align: right;

    ${cssIf('isDisabled')`
        color: ${property('theme.color.grey60')};
    `}
`;

const StyledTextArea = styled.textarea`
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    resize: vertical;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
    })}

    &:focus {
        outline: 0;
    }

    &:disabled {
        color: ${property('theme.color.black')};
    }

    & + ${CharacterCountText}::after {
        position: absolute;
        bottom: 0;
        left: 0;
        transition: box-shadow 200ms;
        // box-shadow: inset 0 -1px 0 ${property('theme.color.tertiary90')};
        width: 100%;
        height: 2px;
        content: ' ';
        pointer-events: none;
    }

    &:hover + ${CharacterCountText}::after {
        // box-shadow: inset 0 -2px 0 ${property('theme.color.tertiary90')};
    }

    &:focus + ${CharacterCountText}::after {
        // box-shadow: inset 0 -2px 0 ${property('theme.color.secondary')};
    }

    &:not(:disabled) + ${CharacterCountText}::after {
        ${cssIf('scIsError')`
            // box-shadow: inset 0 -1px 0 ${property('theme.color.error')};
        `}
    }

    &:not(:disabled):hover + ${CharacterCountText}::after {
        ${cssIf('scIsError')`
            // box-shadow: inset 0 -2px 0 ${property('theme.color.error')};
        `}
    }

    &:not(:disabled):focus + ${CharacterCountText}::after {
        ${cssIf('scIsError')`
            // box-shadow: inset 0 -2px 0 ${property('theme.color.error')};
        `}
    }

    &:disabled + ${CharacterCountText}::after {
        // box-shadow: inset 0 -1px 0 ${property('theme.color.grey60')};
    }
`;

const HelperText = styled(Paragraph)`
    margin: 0 14px;
    padding-top: 8px;

    ${cssIf('isDisabled')`
        color: ${property('theme.color.grey60')};
    `}
`;

const TextAreaField = React.forwardRef((
    {
        className,
        name,
        label,
        helperText,
        value,
        rows = 5,
        onChange,
        onFocus = noop,
        onBlur = noop,
        errorMessage,
        disabled,
        maxLength = 400,
        ...rest
    },
    ref
) => (
    <div className={classnames('TextArea', className)}>
        <TextAreaContainer isDisabled={disabled}>
            <StyledTextArea
                {...rest}
                scIsError={!!errorMessage}
                ref={ref}
                name={name}
                label={label}
                value={value}
                rows={rows}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={label}
            />

            <CharacterCountText
                size="xSmall"
                color="tertiary90"
                isDisabled={disabled}
            >
                {/*{`${(value || '').length}/${maxLength}`}*/}
            </CharacterCountText>
        </TextAreaContainer>

        {!!(errorMessage || helperText) && (
            <HelperText
                size="xSmall"
                color={errorMessage ? 'error' : 'tertiary90'}
                isDisabled={disabled}
            >
                {errorMessage || helperText}
            </HelperText>
        )}
    </div>
));

TextAreaField.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    helperText: propTypes.string,
    value: propTypes.string,
    rows: propTypes.number,
    onChange: propTypes.func,
    onFocus: propTypes.func,
    onBlur: propTypes.func,
    maxLength: propTypes.number,
    errorMessage: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
