import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';

import typographyStyles from '../../common/typography-styles';
import SocialIconPerson from '../icons/functional/social/person';

const TRANSITION_DURATION_MS = 100;

const commonStyles = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: box-shadow ${TRANSITION_DURATION_MS}ms, border-color ${TRANSITION_DURATION_MS}ms;
    border: 1px solid ${property('theme.color.grey40')};
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 ${({theme}) => rgba(theme.color.tertiary, 0.12)};
    background: ${property('theme.color.white')};
    padding: 24px;
    width: 100%;
    min-height: 93px;

    & > svg {
        transition: color ${TRANSITION_DURATION_MS}ms;
        color: ${property('theme.color.grey')};
    }

    /* stylelint-disable order/order */
    &:focus {
        border-color: ${property('theme.color.primary90')};
        box-shadow: 4px 4px 12px 0 ${({theme}) => rgba(theme.color.tertiary, 0.2)};
    }

    &:hover {
        border-color: ${property('theme.color.primary90')};
        box-shadow: 5px 5px 15px 0 ${({theme}) => rgba(theme.color.tertiary, 0.2)};
    }

    &:hover,
    &:focus {
        outline: 0;

        & > svg {
            color: ${property('theme.color.primary')};
        }
    }
    /* stylelint-enable order/order */
`;

const A = styled.a`
    ${commonStyles}

    &:hover {
        text-decoration: none;
    }
`;

const Button = styled.button`
    appearance: none;
    cursor: pointer;

    ${commonStyles}
`;

const ButtonText = styled.div`
    flex-grow: 1;
    padding-left: 24px;
    text-align: left;
`;

const commonTextStyles = css`
    transition: color ${TRANSITION_DURATION_MS}ms;

    ${Button}:hover &,
    ${Button}:focus & {
        color: ${property('theme.color.primary')};
    }
`;

const Name = styled.div`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'medium',
        defaultColor: 'tertiary90',
    })}

    ${commonTextStyles}
`;

const Role = styled.div`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'xSmall',
        defaultWeight: 'regular',
        defaultColor: 'tertiary90',
    })}

    ${commonTextStyles}
`;

const iconProps = {
    size: 26,
    color: 'currentColor',
};

export default function ReporterButton({
    className,
    icon,
    name,
    type,
    href,
    onClick,
    ...rest
}) {
    const Component = href ? A : Button;

    return (
        <Component
            {...rest}
            className={classnames('ReporterButton', className)}
            href={href}
            onClick={onClick}
        >
            {icon ? React.cloneElement(icon, iconProps) : <SocialIconPerson {...iconProps} />}

            <ButtonText>
                {!!name && (
                    <Name>
                        {name}
                    </Name>
                )}

                {!!type && (
                    <Role>
                        {type}
                    </Role>
                )}
            </ButtonText>
        </Component>
    );
}

ReporterButton.propTypes = {
    className: propTypes.string,
    icon: propTypes.node,
    name: propTypes.string,
    type: propTypes.string,
    href: propTypes.string,
    onClick: propTypes.func,
};
