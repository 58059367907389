import React, { memo, useState, useMemo } from "react";
import {useHistory, withRouter} from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import _ from "lodash";
import QnectTextField from "../qnect-ui/qnect-text-field";
import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import StyledTextFieldWrapper from "../custom/style-text-field-wrapper";
import { ReactComponent as Back } from "../../assets/login/back_sharp.svg";
import { ReactComponent as Success } from "../../assets/find-my-policy/icon_tick.svg";
import NeedHelpDialog from "../custom/find-my-policy/need-help-dialog";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _hideSpinner,
    _isNoLogin, _setCpToken,
    _setPolicyToken,
    _showSpinner
} from "../../store/system/service";
import {_getPolicy, _storePolicy, _verifyCredentials} from "../../store/policy/service";
import {connect} from "react-redux";
import FindMyPolicy from "./find-my-policy";
import QnectError from "../qnect-ui/qnect-erorr";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "500",
    fontFamily: "Stag",
    color: "#003da5",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: "-40px",
      marginBottom: "8px",
      lineHeight: "40px",
      fontSize: "32px",
      letterSpacing: "0px"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-60px",
      marginBottom: "12px",
      lineHeight: "48px",
      fontSize: "40px",
      letterSpacing: "1px"
    }
  },
  backIcon: {
    width: "28px",
    height: "28px",
    cursor: "pointer",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "12px"
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "32px"
    }
  },
  subTitle: {
    fontFamily: "Inter",
    color: "#0a1f44",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px"
    }
  },
  successSubTitle: {
    color: "#009ae4"
  },
  successIcon: {
    marginRight: "4px"
  },
  form_wrapper: {
    marginBottom: "52px",
    [theme.breakpoints.between("sm", "md")]: {
      "& input": {
        height: "56px!important",
        paddingLeft: "13.5px!important"
      },
      "& .MuiFormLabel-root": {
        marginBottom: "8px",
        fontSize: "16px",
        lineHeight: "24px"
      },
      "& .MuiInputBase-root": {
        height: "56px !important"
      },
    },
  },
  row: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
      "&:first-child": {
        marginTop: "19px"
      }
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "24px",
      "&:first-child": {
        marginTop: "40px"
      }
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "32px",
      "&:first-child": {
        marginTop: "60px"
      }
    }
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      "& button": {
        width: "160px"
      }
    }
  },
  help: {
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    color: "#003da5",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      marginTop: "13px"
    }
  },
  errorMessage: {
    marginBottom: "10px",
      "& .customClass": {
        marginTop: "0px"
      }

  }
}));

const AuthenticationCompanySection = props => {
  const history = useHistory();
  const cfg = useConfig(props.lang);
  const classes = useStyles();

  const [openHelp, setOpenHelp] = useState(false);

  const [name, setName] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const hasInput = useMemo(() => {
    let num = 0;
    const arr = [name, regNumber, phoneNumber, email];
    arr.map((val) => {
      if (_.isNil(val) || val === "") return false;
      return num++;
    });

    return num > 1;
  }, [name, regNumber, phoneNumber, email]);

    const authenticate = () => {
        let credential = {};
        if (props.noLogin) {
            credential.policyNumber = props.policy.policyNumber;
            credential.policyHolderType = 'C';
            credential.companyName = name;
            credential.companyRegoNumber =  regNumber;
            credential.companyPhone = phoneNumber;
            credential.companyEmail = email;

            props.showSpinner();
            props.verifyCredentials(credential).then((response) => {
                if (response.success) {
                    if (response.token) {
                        props.storePolicy(response.policy);
                        props.setCpToken(response.token);
                        history.push("/policy");
                    }
                    else {
                        setErrorMessage(cfg('errorMessage.notMatch'));
                    }
                } else {
                    setErrorMessage(cfg(response.errorCode));
                }
                props.hideSpinner();
            }, (error) => {
              if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                setErrorMessage(cfg('errorMessage.serverError'));
              }
              else {
                setErrorMessage(error.response.statusText);
              }
                props.hideSpinner();
            })
        }
        else {
            credential.policyNumber = props.policy.policyNumber;
            credential.policyHolderType = 'C';
            credential.companyName = name;
            credential.companyRegoNumber =  regNumber;
            credential.companyPhone = phoneNumber;
            credential.companyEmail = email;

            props.showSpinner();
            props.verifyCredentials(credential).then((response) => {
                if (response.success) {
                    if (response.token) {
                        props.storePolicy(response.policy);
                        props.setCpToken(response.token);
                        history.push("/policy");
                    }
                    else {
                        setErrorMessage(cfg('errorMessage.notMatch'));
                    }
                } else {
                    setErrorMessage(cfg(response.errorCode));
                }
                props.hideSpinner();
            }, (error) => {
                if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                    setErrorMessage(cfg('errorMessage.serverError'));
                }
                else {
                    setErrorMessage(error.response.statusText);
                }
                props.hideSpinner();
            })
        }
    }

  return (
    <FindMyPolicy section="authentication" cfg={cfg}>
      <Hidden smDown>
        <div className={classes.title}>
          <Back
            className={classes.backIcon}
            onClick={() =>
              props.noLogin ? history.push("/") : history.push("/login")
            }
          />
          {cfg("findMyPolicy.authentication")}
        </div>
      </Hidden>

      <div
        className={[classes.subTitle, hasInput ? classes.successSubTitle : ""]
          .join(" ")
          .trim()}
      >
        {cfg("findMyPolicy.any2Fields")}
        {hasInput && <Success className={classes.successIcon} />}
      </div>

      <div className={classes.form_wrapper}>
        <div className={classes.row}>
          <StyledTextFieldWrapper>
            <QnectTextField
              field="businessName"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </StyledTextFieldWrapper>
        </div>

        <div className={classes.row}>
          <StyledTextFieldWrapper>
            <QnectTextField
              field="businessRegistrationNumber"
              value={regNumber}
              onChange={(e) => setRegNumber(e.target.value)}
            />
          </StyledTextFieldWrapper>
        </div>

        <div className={classes.row}>
          <StyledTextFieldWrapper>
            <QnectTextField
              field="businessPhoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </StyledTextFieldWrapper>
        </div>

        <div className={classes.row}>
          <StyledTextFieldWrapper>
            <QnectTextField
              field="businessEmailAddress"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </StyledTextFieldWrapper>
        </div>
      </div>

        {errorMessage && (
            <div className={classes.errorMessage}>
                <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
            </div>
        )}

      <div className={classes.buttonWrapper}>
      	{!props.noLogin &&
	        <div className={classes.help} onClick={() => setOpenHelp(true)}>
	          {cfg("findMyPolicy.needHelp")}
	        </div>
		    }

        <ButtonWrapper className={classes.button}>
          <QnectButton
            field="continue"
            onClick={() => authenticate()}
            disabled={!hasInput}
          />
        </ButtonWrapper>
      </div>

      <NeedHelpDialog
        open={openHelp}
        title="Need Help?"
        closable={true}
        onClose={() => setOpenHelp(false)}
        onBack={() => setOpenHelp(false)}
      />
    </FindMyPolicy>
  );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        policy: _getPolicy(state),
        lang: _getLang(state),
        noLogin: _isNoLogin(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storePolicy: _storePolicy,
    setPolicyToken: _setPolicyToken,
    setCpToken: _setCpToken,
    verifyCredentials: _verifyCredentials,
}

export default withRouter(memo(connect(mapStateToProps, mapDispatchToProps)(AuthenticationCompanySection)));
