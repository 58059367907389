import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import {HashRouter as Router} from 'react-router-dom';
import noop from 'lodash/noop';
import {qbeTheme, ConfigProvider} from './ui';
import styled from 'styled-components';

import Routes, {getHistory} from "./routes";
import ErrorModal from "./components/error-modal";
import Spinner from "./components/spinner";
import ModalPopup from "./components/modal-popup";
import Footer from "./components/custom/footer";
import { mdDown } from "./ui/common/breakpoints";

const Main = styled.div`
  padding-bottom: 64px;
  ${mdDown`padding-bottom: 87px;`}
`;

function App({
                 inputDateFormat,
                 onLoad = noop,
                 onChangePath = noop,
             }) {
    useEffect(() => {
            onLoad();
            console.log('Home React: ', window.innerHeight)
            // Notify subscribers of history path changes, including the initial path.
            onChangePath(getHistory().location.pathname);

            return getHistory().listen(({pathname}) => {
                onChangePath(pathname);
            });

    }, [onLoad, onChangePath]);

  return (
    <ConfigProvider theme={qbeTheme} imageBaseUrl="/static/img" inputDateFormat="DD/MM/YYYY">
      <Main>
        <Router history={getHistory()}>
          <Routes />
        </Router>
      </Main>
      <ModalPopup />
      <ErrorModal />
      <Spinner />
      <Footer />
    </ConfigProvider>
  );
}

App.propTypes = {
    inputDateFormat: propTypes.string,
    onLoad: propTypes.func,
    onChangePath: propTypes.func,
};


export default App;
