import {combineReducers} from 'redux';
import {systemReducer} from "./system/reducers";
import {policyReducer} from "./policy/reducers";
import {policyPaymentReducer} from "./policy-payment/reducers";
import {userReducer} from "./user/reducers";

const reducer = combineReducers({
    sys: systemReducer,  // state in systemReducer will be visible through system.xxx
    user: userReducer,
    pl: policyReducer,
    pp: policyPaymentReducer
});

export default reducer;
