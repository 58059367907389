import property from 'lodash/property';
import styled, {ThemeContext} from 'styled-components';
import React, {useContext} from 'react';

import typographyStyles from '../../../common/typography-styles';
import {DEFAULT_TEXT_COLOR, DEFAULT_TEXT_SIZE} from './common';

const List = styled.ul`
    margin: 24px 0;
    padding: 0 0 0 40px;

    ${({scSize}) => typographyStyles({
        type: 'paragraph',
        defaultSize: scSize || DEFAULT_TEXT_SIZE,
        defaultWeight: 'regular',
    })}

    & strong {
        font-weight: ${property('theme.typography.paragraph.fontWeight.semiBold')};
    }
`;

export default function listRenderer({size, color}) {
    // const theme = useContext(ThemeContext);

    return function ListRenderer({start, ordered, children, ...rest}) { // eslint-disable-line react/prop-types
        return (
            <List
                {...rest}
                start={start > 1 ? start : undefined}
                as={ordered ? 'ol' : 'ul'}
                scSize={size}
                // style={{color: theme.color[color] || theme.color[DEFAULT_TEXT_COLOR]}}
            >
                {children}
            </List>
        );
    };
}
