import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';

import checkmark from './checkmark';

const StyledCheckbox = styled.input.attrs({
    type: 'checkbox',
})`
    appearance: none;
    box-sizing: border-box;
    display: inline-block;
    transition: box-shadow 100ms linear, border-color 100ms linear, background-color 100ms linear;
    margin: 0;
    border: 1px solid ${property('theme.color.tertiary10')};
    background-color: ${property('theme.color.white')};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;

    &:focus,
    &:active {
        border: 1px solid ${property('theme.color.tertiary90')};
        box-shadow: 0 0 0 5px ${({theme}) => rgba(theme.color.tertiary, 0.1)};
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        border: 1px solid ${property('theme.color.grey50')};
    }

    &:checked {
        border: 0;
        background-color: ${property('theme.color.primary')};
        background-image: ${checkmark('white')};

        &::-ms-check {
            display: none;
        }

        &:disabled {
            background-color: ${property('theme.color.grey40')};
            background-image: ${checkmark('tertiary10')};
        }
    }

    &:hover:not(:disabled) {
        border: 1px solid ${property('theme.color.tertiary90')};
        box-shadow: 0 0 0 7px ${({theme}) => rgba(theme.color.tertiary, 0.1)};
    }
`;

const Checkbox = React.forwardRef((
    {
        className,
        checked,
        onChange,
        ...rest
    },
    ref
) => (
    <StyledCheckbox
        {...rest}
        ref={ref}
        className={classnames('Checkbox', className)}
        checked={checked}
        onChange={onChange}
    />
));

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
    className: propTypes.string,
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func,
};

export default Checkbox;
