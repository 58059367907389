import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/login/icon_logo.svg";
import {ReactComponent as LogoLight} from "../../assets/QBE-logo-all-white.svg";

import {_getCountry, _getLang, _hideSpinner, _setPortfolioToken, _showError, _showSpinner, _singpassInit, _showModal, _hideModal,
    _getSystemAvailable, _getScheduledMaintenance} from "../../store/system/service";

import {connect, useSelector} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import useLoginStyles, {StyledModalDiv, ButtonContainer} from "../login-style";
import "../login-sgp-addon-style.css";

import Hidden from "@material-ui/core/Hidden";
import ForgetEmailContactDialog from "../custom/login/forget-email-contact-dialog";
import TagManager from "react-gtm-module";
import {_sendLog} from "../../utils/utility";
import QnectButton from "../qnect-ui/qnect-button";
import SystemUnavailableBanner from "../custom/system-unavailable-banner";
import ScheduledMaintenanceBanner from "../custom/scheduled-maintenance-banner";

const LoginSgp = (props) => {
    const classes = useLoginStyles(props);
    const ref = useRef(null);
    const lang = useSelector(state => _getLang(state));
    const cfg = useConfig(lang)
    const [contactOpen, setContactOpen] = useState(false);

    useEffect(() => {
        let url = window.location.href;
        if (url && (url.indexOf("/sg") !== -1 || url.indexOf("sg-") !== -1 || url.indexOf("#sg") !== -1)) {

        }
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'login',
                vPath: '/login'
            }
        });
    }, []);

    /* Handles the redirection to Singpass Site  */
    const redirectSingpass = () =>{
        const searchParams = new URLSearchParams();
        searchParams.append("scope", "openid");
        searchParams.append("response_type", "code");
        props.singpassInit().then( //Get the URL, Redirect URI, Client id in b2c-server
            resp => { //Successful Response
                console.log("_singpassInit()", resp);
                searchParams.append("redirect_uri", resp.callback);
                searchParams.append("client_id", resp.clientId);
                searchParams.append("nonce", "dummySessionNonce");
                searchParams.append("state", "dummySessionState");
                window.location.href = resp.redirectUrl + searchParams.toString(); //Redirection command
            }, (error) => { //Error in Response
                console.error('init has failed', error);
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'popup',
                        popupPath: '/login',
                        popupTitle: 'Error'
                    }
                });
                props.showError(['Redirecting QR code has failed']);
                _sendLog(' login-sgp.js - Initialising QR code has failed, exception:\n\n' + JSON.stringify(error));
            }
        )

    }

    const handleCloseContact = () => {
        setContactOpen(false);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
    };

    const modalNeedHelp = () => {
        props.showModal({
            flat: true,
            content: 
                <StyledModalDiv id="NeedHelp" className="NeedHelp_Modal">
                    <div className="title">{cfg('login.modalNeedHelp')}</div>
                    <div className="content">{cfg('login.modalNeedHelpMessage')}</div>
                    <div className="contactForm"><span><a href="https://www.qbe.com/sg/eservice/contact-us">{cfg('login.modalContactForm')}</a></span></div>
                    <div className="contactEmail"><a href={"mailto:" + cfg('login.modalContactEmail')}>{cfg('login.modalContactEmail')}</a></div>
                </StyledModalDiv>
        });
    }

    const hasError = !props.systemAvailable;
    const[scheduledMaintenance, setScheduledMaintenance] = useState(null);

    useEffect(() => {
        props.getScheduledMaintenance(props?.country).then(resp => {
            if (Object.keys(resp.data).length) {
                setScheduledMaintenance(resp.data);
            }
        });
    },[props.country]);

    return (
        <>
            <div id="SGLogin" className={classes.container}>
                { hasError && <SystemUnavailableBanner /> }
                { scheduledMaintenance && <ScheduledMaintenanceBanner value={scheduledMaintenance} country={props.country} /> }
                <div className={classes.bg_sgp}>
                    <Hidden mdDown>
                        <div className={classes.Cbg_img_sg}/>
                    </Hidden>

                    <div id="right_content" className={classes.right_content_sgp}>
                        <div className={classes.icon_wrapper}>
                            <a href="https://www.qbe.com/sg/about-qbe">
                            <Hidden smDown>
                                <Logo/>
                            </Hidden>
                            <Hidden smUp>
                                <LogoLight/>
                            </Hidden>
                            </a>
                        </div>
                        <Hidden smDown>
                            <div className={`${classes.title} ${classes.SGPtitle}`}>{cfg('login.welcomeSg')}</div>
                        </Hidden>
                        <div className={classes.Mform_sgp} ref={ref}>
                            <Hidden smUp>
                                <div id="SGTitle" className={`${classes.title} ${classes.SGPtitle}`}>{cfg('login.welcomeSg')}</div>
                            </Hidden>
                            <div id="SGLoginTabPanel" className={classes.tab_panel}>

                                <div id="login_sub_title" className={classes.sg_scan_qr}>
                                    {cfg('login.withSingpass')}
                                </div>

                                    <ButtonContainer>
                                        <QnectButton 
                                            field="loginSingpass" 
                                            color="primary"
                                            onClick={redirectSingpass}
                                        >
                                        </QnectButton>
                                    </ButtonContainer>

                                <div id="no_singpass" className={classes.sg_no_singpass} onClick={() => {
                                    window.open(process.env.REACT_APP_SINGPASS_APP, '_blank');
                                }}>
                                    {cfg('login.dontHaveSingpass')}
                                </div>

                                <div id="bottom_part" className={classes.right_content_bottomPart}>
                                    <div className={classes.sg_need_help}>
                                        <span>{cfg('login.bottomNeedHelp')}</span>
                                        <span className={classes.sg_contact_link} onClick={modalNeedHelp}>{cfg('login.bottomContactUs')}</span>
                                    </div>
                                    <div className={[classes.corporateCustomers, classes.PreWrap].join(" ")}>
                                        {cfg('login.corporateCustomersMessageSGP')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ForgetEmailContactDialog
                open={contactOpen}
                onClose={handleCloseContact}
                cfg={cfg}
                lang={props.lang}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state),
        systemAvailable: _getSystemAvailable(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    setPortfolioToken: _setPortfolioToken,
    showError: _showError,
    singpassInit: _singpassInit,
    showModal: _showModal,
    hideModal: _hideModal,
    getScheduledMaintenance: _getScheduledMaintenance
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginSgp));
