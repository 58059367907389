import React, {useEffect, useState} from 'react';
import LoginSgp from "../components/login/login-sgp";
import {
    _clearToken,
    _generatePolicyToken,
    _getGotoPage,
    _getGotoPn,
    _getLang,
    _hideSpinner,
    _setCpToken,
    _setGotoPage,
    _setPolicyToken,
    _setPortfolioToken,
    _showError,
    _showSpinner,
    _singpassCallback
} from "../store/system/service";
import {useHistory} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import TermsNConditionDialog from "../components/custom/login/terms-n-condition-dialog";
import NoNricDialog from "../components/custom/login/no-nric-dialog";
import TagManager from "react-gtm-module";
import useConfig from "../components/qnect-ui/qnect-config";
import PicsSgpDialog from "../components/custom/login/pics-sgp-dialog";
import TermsNConditionDetailDialog from "../components/custom/login/terms-n-condition-detail-dialogue";
import {_loadPolicyDetail, _resetPolicy, _storePolicy} from "../store/policy/service";
import {_resetUser} from "../store/user/service";

const LoginSgpPage = (props) => {
    const lang = useSelector(state => _getLang(state));
    const cfg = useConfig(lang)
    const history = useHistory();
    const [termOpen, setTermOpen] = useState(false);
    const [termDetailOpen, setTermDetailOpen] = useState(false);
    const [noNricOpen, setNoNricOpen] = useState(false);
    const [picsOpen, setPicsOpen] = useState(false);

    useEffect(() => {

        let url = window.location.href;

        // singpass redirect url cannot contain #, so need to redirect again with hash
        if (url.indexOf("/#") === -1 && url.indexOf("code=") !== -1) {
            let newUrl = new URL(url);
            let newHref = newUrl.origin + newUrl.pathname + newUrl.hash + newUrl.search;
            console.log("redirecting to "+ newHref);
            window.location.href = newHref;
        }

        const params = new URLSearchParams(props.history.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const gotoPageParam = params.get('gotoPage');
        const gotoPnParam = params.get('gotoPn');

        if (gotoPageParam && gotoPnParam) {
            props.setGotoPage(gotoPageParam, gotoPnParam);
        }

        if (code) {
            props.showSpinner();
            console.log("calling singpassCallback code="+code);

            props.singpassCallback(code, state).then((response) => {
                props.hideSpinner();
                if (response.success) {
                    props.setCpToken(response.token);
                    props.setPortfolioToken(response.token);
                    console.log("redirecting to portfolio");
                    if (response.blacklist || response.nonric) {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'popup',
                                popupPath: '/login',
                                popupTitle: 'Oops!'
                            }
                        });
                        setNoNricOpen(true);
                    } else {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'popup',
                                popupPath: '/login',
                                popupTitle: 'Terms & Conditions'
                            }
                        });
                        if(response.userDetails.status === 'A'){
                            // if gotoPage exists, redirect
                            if (props.gotoPage && props.gotoPn) {
                                console.log('generating policy token for ' + props.gotoPn);
                                props.generatePolicyToken(props.gotoPn, props.country).then(
                                    resp => {
                                        if (resp !== 'FAILED') {
                                            props.setCpToken(resp.token);
                                            props.setPolicyToken(resp.token);

                                            console.log('loading policy detail ' + props.gotoPn);
                                            props.loadPolicyDetail().then((policy) => {
                                                props.storePolicy(policy);
                                                props.hideSpinner();

                                                console.log("redirecting to " + props.gotoPage);
                                                history.push(props.gotoPage);
                                            }, (error) => {
                                                props.hideSpinner();
                                            });
                                        }
                                    }, (error) => {
                                        props.hideSpinner();
                                    });
                            }
                            else {
                                history.push("/portfolio");
                            }
                        }else{
                            setTermOpen(true);
                        }
                    }
                } else {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'Error'
                        }
                    });

                    props.showError([response.error]);
                }
            }, (error) => {
                props.hideSpinner();
                console.error('Callback failed');

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'popup',
                        popupPath: '/login',
                        popupTitle: 'Error'
                    }
                });

                props.showError(['Callback failed: '+ error.message]);
            })
        }
    }, []);

    const handleCloseTerm = () => {
        setTermOpen(false);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
        props.clearToken();
        props.resetPolicy();
        props.resetUser();
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
    };

    const handleCloseNoNric = () => {
        setNoNricOpen(false);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
    };

    const handleAcceptTerm = () => {
        setTermOpen(false);
        history.push("/portfolio");
    };

    return (
      <div>
          <LoginSgp/>

          <TermsNConditionDialog
              open={termOpen}
              onClose={handleCloseTerm}
              onAccept={handleAcceptTerm}
              cfg={cfg}
              onPics={() => {
                  setTermOpen(false);
                  setPicsOpen(true)
              }}
              onTermDetail={() => {
                  setTermOpen(false);
                  setTermDetailOpen(true)
              }}
              lang={props.lang}
          />
          <TermsNConditionDetailDialog
              open={termDetailOpen}
              onClose={() => {
                  setTermDetailOpen(false);
                  setTermOpen(true)
              }}
              onBack={() => {
                  setTermDetailOpen(false);
                  setTermOpen(true)
              }}
              cfg={cfg}
              lang={props.lang}
          />
          <NoNricDialog
              open={noNricOpen}
              onClose={handleCloseNoNric}
              cfg={cfg}
              lang={props.lang}
          />
          <PicsSgpDialog
              open={picsOpen}
              onClose={() => {
                  setPicsOpen(false);
                  setTermOpen(true)
              }}
              onBack={() => {
                  setPicsOpen(false);
                  setTermOpen(true)
              }}
              cfg={cfg}
              lang={props.lang}
          />

      </div>
    )
}

const mapStateToProps = (state) => {
    return {
        gotoPage: _getGotoPage(state),
        gotoPn: _getGotoPn(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    setCpToken: _setCpToken,
    setPortfolioToken: _setPortfolioToken,
    singpassCallback: _singpassCallback,
    resetPolicy: _resetPolicy,
    resetUser: _resetUser,
    clearToken: _clearToken,
    setGotoPage: _setGotoPage,
    storePolicy: _storePolicy,
    generatePolicyToken: _generatePolicyToken,
    setPolicyToken: _setPolicyToken,
    loadPolicyDetail: _loadPolicyDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginSgpPage);
