import React, {useEffect, useState} from "react";
import {useStyles} from "../common-style";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";

import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import StyledTextFieldWrapper from "../style-text-field-wrapper";
import QnectTextField from "../../qnect-ui/qnect-text-field";

import {connect} from "react-redux";
import {_getPolicy, _verifyPolicy} from "../../../store/policy/service";
import {_getCountry, _hideSpinner, _setCpToken, _showSpinner} from "../../../store/system/service";
import styled from "styled-components";
import {lgDown, smDown} from "../../../ui/common";
import {_getUser, _storeUser} from "../../../store/user/service";
import TagManager from "react-gtm-module";


const FindPolicyEl = styled.div`
    font-family: "Inter-Regular";
    line-height: 24px;
    font-size: 18px;
    color: #003da5;
    margin-top: 12px;
    cursor: pointer;
    text-decoration: underline;
    ${lgDown`
        font-size: 16px;
    `}
`;


const RegistrationPolicyNumberDialog = ({
                                            open,
                                            onClose,
                                            onSubmit,
                                            onAlreadyRegistered,
                                            cfg,
                                            lang,
                                            onShowHow,
                                            ...props
                                        }) => {
    const classes = useStyles();

    const [policyNumber, setPolicyNumber] = useState();
    const [policyNumberErrorMessage, setPolicyNumberErrorMessage] = useState('');
    const [showErrorNoEmailMobile, setShowErrorNoEmailMobile] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        setPhoneNumber(props.country === 'SGP' ? '+65 6224 6633' : '+852 2828 3138');
        setUrl(props.country === 'SGP' ? 'info.sing@qbe.com' : 'info.gihk@qbe.com');
    }, [props.country]);

    useEffect(() => {
        if (open) {
            setPolicyNumber('');
            setShowErrorNoEmailMobile(false);
            setPolicyNumberErrorMessage('');
        }
    }, [open]);

    const continueNextStep = () => {
        if (checkIfPolicyNumberIsValid()) {
            props.showSpinner();
            props.verifyPolicy(policyNumber, 'true').then((response) => {
                if (response.success) {
                    props.user.policyNumber = policyNumber;
                    props.setCpToken(response.token);
                    props.storeUser(props.user);
                    props.history.push("/registration/authentication");
                    props.hideSpinner();
                } else {
                    props.hideSpinner();
                    // apply no-email-mobile only to SGP. not sure if which database to check if HKG/HGI on the first screen
                    if (response.errorCode === 'no-email-mobile' && props.country === 'SGP') {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'popup',
                                popupPath: '/login',
                                popupTitle: 'Oops!'
                            }
                        });
                        setShowErrorNoEmailMobile(true);
                    } else if (response.errorCode === 'policySearch.errorMessage.linked-clients-registered') {
                        onAlreadyRegistered();
                    } else {
                        setPolicyNumberErrorMessage(cfg(response.errorCode));
                    }
                }
            }, (error) => {
                setPolicyNumberErrorMessage(error.response.statusText);
                props.hideSpinner();
            })
        }
    }

    const checkIfPolicyNumberIsValid = () => {
        let regex = new RegExp("^([a-zA-Z])([a-zA-Z0-9]{7})$|^([a-zA-Z]{2})([a-zA-Z0-9]{6})$", "g");
        if (regex.test(policyNumber)) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                { !showErrorNoEmailMobile ?
                    <>
                        <DialogTitle title={cfg("registration.policyNumberTitle")} onClose={onClose} lang={lang}/>
                        <DialogContent>
                            <div className={classes.input_wrapper}>
                                <div className="polNoFieldDiv">
                                    <StyledTextFieldWrapper>
                                        <QnectTextField
                                            value={policyNumber}
                                            field="registrationPolicyNumber"
                                            placeholder={cfg('registrationPolicyNumber.placeholder')}
                                            errorMessage={policyNumberErrorMessage}
                                            onBlur={(evt) => {
                                                setPolicyNumber(evt.target.value);
                                                setPolicyNumberErrorMessage('');
                                            }}
                                            onChange={(evt) => {
                                                setPolicyNumber(evt.target.value);
                                                setPolicyNumberErrorMessage('');
                                            }}
                                        />
                                    </StyledTextFieldWrapper>
                                </div>
                                <FindPolicyEl onClick={onShowHow}>{cfg("login.howToFindPolicy")}</FindPolicyEl>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <ButtonWrapper className={classes.buttonStyle}>
                                <QnectButton
                                    onClick={continueNextStep} field="continue"
                                    disabled={!policyNumber ? true : false}
                                ></QnectButton>
                            </ButtonWrapper>
                        </DialogActions>
                    </> :
                    <>
                        <DialogTitle title={cfg("registration.policyNotFound")} onClose={onClose} lang={lang}/>
                        <DialogContent>
                            <div className={classes.input_wrapper2}>
                                <div>{cfg("registration.policyNotFoundMessage")}</div>
                                { lang === 'zh' ?
                                    <div className={classes.input_wrapper3}>
                                        <div>
                                            {cfg("registration.policyNotFoundMessage2")}
                                            <span className={classes.contact_us} onClick={() => {window.open(process.env.REACT_APP_ONLINE_ENQUIRY_ZH, '_blank');}}>
                                                {cfg("registration.contactUsForm")}
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    <div className={classes.input_wrapper3}>
                                        <div> {cfg("registration.policyNotFoundMessage2")}</div>
                                        <div className={classes.contact_us} onClick={() => {window.open(process.env.REACT_APP_ONLINE_ENQUIRY, '_blank');}}>
                                            {cfg("registration.contactUsForm")}
                                        </div>
                                    </div>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <ButtonWrapper className={classes.buttonStyle}>
                                <QnectButton
                                    field="ok"
                                    onClick={() => onClose("true")}
                                ></QnectButton>
                            </ButtonWrapper>
                        </DialogActions>
                    </>
                }
            </Dialog>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        policy: _getPolicy(state),
        country: _getCountry(state),
        user: _getUser(state)
    };
};

const mapDispatchToProps = {
    verifyPolicy: _verifyPolicy,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storeUser: _storeUser,
    setCpToken: _setCpToken
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPolicyNumberDialog);
