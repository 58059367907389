import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    dialog: {
        "& .MuiDialog-paper": {
            [theme.breakpoints.between("sm", "md")]: {
                padding: "14px 44px 52px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "16px 60px 52px",
                width: "630px"
            }
        },
        "& .back": {
            [theme.breakpoints.down("sm")]: {
                width: "24px",
                height: "24px",
                marginRight: "12px",
                top: "20px"
            },
            [theme.breakpoints.up("sm")]: {
                width: "28px",
                height: "28px",
                marginRight: "16px",
                top: "37px",
                left: "50px"
            },
            cursor: "pointer",
            position: "absolute"
        }
    },
    message: {
        textAlign: "center",
        color: "#0a1f44",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "20px",
            fontSize: "18px",
            lineHeight: "25px"
        },
        "& > span": {
            whiteSpace: "pre-wrap"
        }
    },
    input_wrapper: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "31px",
            "& .BalloonTip": {
                top: "0",
                left: "118px",
                "& .contentDiv": {
                    width: "302px",
                    padding: "5px 5px 5px 8px",
                    top: "-29px",
                    left: "-118px"
                }
            }
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "39px",
            "& .BalloonTip": {
                top: "0",
                left: "132px",
                "& .contentDiv": {
                    width: "350px",
                    padding: "5px 5px 5px 8px",
                    top: "-50px",
                }
            }
        },
        "& .polNoFieldDiv": {
            position: "relative",
            "& .BalloonTip": {
                position: "absolute"
            }
        }
    },
    country: {
        [theme.breakpoints.down("sm")]: {
            marginRight: "8px",
            width: "112px",
            flexShrink: 0
        },
        [theme.breakpoints.up("sm")]: {
            marginRight: "20px",
            width: "160px"
        }
    },
    error_message: {
        margin: "8px 0",
        fontSize: "16px",
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "#d54040",
        wordBreak: "keep-all",
        whiteSpace: "pre-wrap",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("sm")]: {}
    },
    help: {
        fontSize: "16px",
        lineHeight: "24px",
        color: "#003da5",
        cursor: "pointer",
        fontFamily: "Inter",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "4px"
        },
        [theme.breakpoints.up("sm")]: {
            paddingTop: "22px"
        }
    },
    actions: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            marginTop: "44px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "60px",
            padding: "0 24px"
        }
    },
    backButton: {
        position: "absolute",
        top: "34px",
        left: "128px",
        cursor: "pointer",

        [theme.breakpoints.down("sm")]: {
            top: "23px",
            left: "43px",
            height: "20px",
            width: "20px"
        }
    },
    row: {
        color: "#0a1f44",
        textAlign: "center",
        fontFamily: "Inter",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            "&:first-child": {
                marginTop: "40px"
            },
            margin: "20px auto 0",
            fontSize: "16px",
            lineHeight: "20px"
        },
        [theme.breakpoints.up("sm")]: {
            "&:first-child": {
                marginTop: "48px"
            },
            margin: "40px auto 0",
            fontSize: "18px",
            lineHeight: "25px"
        }
    },
    icon: {
        width: "24px",
        height: "24px",
        marginRight: "4px"
    },
    buttonStyle: {
        margin: "0 auto"
    },
    input_wrapper2: {
        marginTop: "39px",
        textAlign: "center",
        fontFamily: "Inter-Regular",
        fontSize: "18px"
    },
    input_wrapper3: {
        marginTop: "20px"
    },
    contact_us: {
        color: "#003DA5",
        cursor: "pointer",
        fontWeight: "600"
    },
    buttonStyleRenewal: {
        "& > button:first-child": {
            marginRight: "30px"
        }
    },
    hyperlinkColor: {
        color: "#003DA5"
    },
    cursorPointer: {
        cursor: "pointer"
    }
}));