import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import RadioCard from '../../form-controls/radio-card';
import SupplierLabel from './supplier-label';

export default function SupplierCard({
    className,
    name,
    details,
    viewMoreText,
    viewLessText,
    value,
    disabled,
    checked,
    onChange,
}) {
    return (
        <RadioCard
            className={classnames('SupplierCard', className)}
            label={(
                <SupplierLabel
                    name={name}
                    details={details}
                    viewMoreText={viewMoreText}
                    viewLessText={viewLessText}
                />
            )}
            {...{disabled, checked, onChange, value}}
        />
    );
}

SupplierCard.propTypes = {
    className: propTypes.string,
    name: propTypes.string.isRequired,
    details: propTypes.shape({
        message: propTypes.string,
        contactDetails: propTypes.arrayOf(propTypes.string),
        combinationClaimInstructions: propTypes.string,
        specialInstructions: propTypes.string,
    }),
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func.isRequired,
    value: propTypes.string.isRequired,
    viewLessText: propTypes.string.isRequired,
    viewMoreText: propTypes.string.isRequired,
};
