import React, {memo, useState, useMemo, useEffect} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import QnectDateField from "../qnect-ui/qnect-date-field";
import BalloonTip from "../ui/balloon-tip/balloon-tip";
import StyledTextFieldWrapper from "../custom/style-text-field-wrapper";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import {ReactComponent as ShowIcon} from "../../assets/registration/icon_show.svg";
import {ReactComponent as HideIcon} from "../../assets/registration/icon_hide.svg";
import {ReactComponent as Success} from "../../assets/find-my-policy/icon_tick.svg";
import AuthTipsDialog from "../custom/registration/auth-tips-dialog";
import AuthTipsDialogMore from "../custom/registration/auth-tips-dialog-more";
import useConfig from "../qnect-ui/qnect-config";
import {connect} from "react-redux";
import {
    _generatePortfolioToken,
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _isNoLogin, _setCpToken,
    _setOtpToken,
    _setPolicyToken,
    _showModal,
    _showSpinner,
    _verifyOtp
} from "../../store/system/service";
import {
    _getPolicy,
    _storePolicy, _verifyCredentials
} from "../../store/policy/service";
import FindMyPolicy from "./find-my-policy";
import QnectError from "../qnect-ui/qnect-erorr";
import TooManyFailedAttemptsDialog from "../custom/login/too-many-failed-attempts-dialog";
import {_validateForm} from "../../utils/utility";
import {
    _getUserDetailsFromToken,
    _addEmailAddressToOTPLockedCache,
    _checkEmailIfRegistered,
    _checkIfPolicyNumberIsRegistered
} from "../../store/user/service";
import RegisterOrPolicyDialog from "../custom/find-my-policy/registration-or-policy-dialog";
import UpdateEmailMobileDialog from "../custom/policy-detail/update-email-mobile-dialog";

const SuccessIcon = styled(Success)`
  margin-left: 4px;
`;

const Row = styled.div`
  margin-top: 32px;
`;

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: "500",
        fontFamily: "Stag",
        color: "#003da5",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.between("sm", "md")]: {
            marginLeft: "-40px",
            marginBottom: "8px",
            lineHeight: "40px",
            fontSize: "32px",
            letterSpacing: "0px"
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "-60px",
            marginBottom: "12px",
            lineHeight: "48px",
            fontSize: "40px",
            letterSpacing: "1px"
        }
    },
    backIcon: {
        width: "28px",
        height: "28px",
        cursor: "pointer",
        [theme.breakpoints.between("sm", "md")]: {
            marginRight: "12px"
        },
        [theme.breakpoints.up("lg")]: {
            marginRight: "32px"
        }
    },
    subTitle: {
        fontFamily: "Inter",
        color: "#0a1f44",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px"
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "18px"
        }
    },
    successSubTitle: {
        color: "#009ae4"
    },
    form_wrapper: {
        marginBottom: "52px",
        [theme.breakpoints.between("sm", "md")]: {
            "& input": {
                height: "56px!important",
                paddingLeft: "13.5px!important"
            },
            "& .MuiFormLabel-root": {
                marginBottom: "8px",
                fontSize: "16px",
                lineHeight: "24px"
            },
            "& .MuiInputBase-root": {
                height: "56px !important"
            },
        },
    },
    firstRowWrapper: {
        display: "flex",
        "@media (max-width: 330px)": {
            display: "block"
        },
        [theme.breakpoints.down("sm")]: {
            margin: "20px 0 24px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            margin: "40px 0 24px"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "82px 0 42px"
        }
    },
    firstRowWrapperMVP1: {
        [theme.breakpoints.down("sm")]: {
            margin: "20px 0 24px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            margin: "40px 0 24px"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "82px 0 42px"
        }
    },
    [theme.breakpoints.down("md")]: {
        nameOfPolicyholder: props => ({
            "& .BalloonTip": props => ({
                left: props.lang === "en" ? "167px" : "115px"
            })
        }),
        hkidHolder: props => ({
            "& .BalloonTip": props => ({
                left: props.lang === "en" ? "167px" : "214px"
            })
        }),
        hkidHolderMVP2: props => ({
            "& .BalloonTip": props => ({
                left: "85px"
            })
        })
    },
    [theme.breakpoints.up("lg")]: {
        nameOfPolicyholder: props => ({
            "& .BalloonTip": props => ({
                left: props.lang === "en" ? "188px" : "132px"
            })
        }),
        hkidHolder: props => ({
            "& .BalloonTip": props => ({
                left: props.lang === "en" ? "180px" : "242px"
            })
        }),
        hkidHolderMVP2: props => ({
            "& .BalloonTip": props => ({
                left: "100px"
            })
        })
    },

    hkidHolder: {
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "167px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                minWidth: "180px",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.between("sm", "md")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "167px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.up("lg")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "188px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                width: "330px",
                background: "#4e5d78"
            }
        }
    },
    hkidHolderMVP2: {
        marginLeft: "20px",
        width: "100%",
        "@media (max-width: 330px)": {
            marginLeft: "0",
            marginTop: "10px"
        },
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "167px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                left:"-83px",
                minWidth: "190px",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.between("sm", "md")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "167px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.up("lg")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "188px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                width: "330px",
                background: "#4e5d78"
            }
        }

    },
    HKIDWrapper: {
        [theme.breakpoints.down("md")]: {
            width: "126px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "160px"
        }
    },
    shortTextInputWrapper: {
        flex: 1,
        position: "relative",

        [theme.breakpoints.down("sm")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "86px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                left: "-80px",
                // minWidth: "auto",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.between("sm", "md")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                left: "86px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.up("lg")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                left: "98px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                width: "380px",
                background: "#4e5d78"
            }
        }
    },
    nameOfPolicyholder: {
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "167px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                minWidth: "180px",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.between("sm", "md")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "167px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                background: "#4e5d78"
            }
        },
        [theme.breakpoints.up("lg")]: {
            "& > .BalloonTip": {
                position: "absolute",
                top: "0px",
                // left: "188px",
                cursor: "pointer"
            },
            "& > .BalloonTip .contentDiv": {
                bottom: "0px",
                top: "auto",
                width: "330px",
                background: "#4e5d78"
            }
        }
    },
    subText: {
        color: "#8a94a6",
        fontFamily: "Inter",
        letterSpacing: "0",
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
            lineHeight: "20px",
            paddingTop: "4px",
            paddingBottom: "10px"
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "16px",
            lineHeight: "24px",
            paddingTop: "15px",
            paddingBottom: "11px"
        }
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    button: {
        [theme.breakpoints.down("sm")]: {
            "& button": {
                width: "160px"
            }
        },
        [theme.breakpoints.up("lg")]: {}
    },
    dialog_title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-30px"
        },
        [theme.breakpoints.up("sm")]: {
            marginLeft: "-50px"
        }
    },
    dialog_back: {
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            marginRight: "12px",
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up("sm")]: {
            marginRight: "39px",
            width: "28px",
            height: "28px"
        }
    },
    errorMessage: {
        marginBottom: "10px"
    },
    idType: {
        [theme.breakpoints.up("md")]: {
            minWidth: "160px"
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "145px"
        }
    }
}));

const StyledHelp = styled.div`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #003da5;
  cursor: pointer;
`;

const AuthenticationSection = props => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const classes = useStyles(props);
    const formId = 'authentication-form';

    const [openAuth, setOpenAuth] = useState(false);
    const [openAuthMore, setOpenAuthMore] = useState(false);

    const [idCardType, setIdCardType] = useState("HKID");
    const [idNum, setIdNum] = useState("");
    const [nameOfPolicyholder, setNameOfPolicyholder] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [showIdNum, setShowIdNum] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [updateDetailsOpen, setUpdateDetailsOpen] = useState(false);
    const [policyNumberValue, setPolicyNumberValue] = useState("");

    const hasInput = useMemo(() => {
        let num = 0;
        const arr = [idNum, nameOfPolicyholder, dateOfBirth];
        arr.map((val) => {
            if (_.isNil(val) || val === "") return false;
            return num++;
        });

        return num > 1;
    }, [idNum, nameOfPolicyholder, dateOfBirth]);

    const authenticate = () => {
        let credential = {};
        if (props.noLogin) {
            credential.policyNumber = props.policy.policyNumber;
            credential.policyHolderType = 'I';
            credential.clientID = idNum;
            credential.clientName = nameOfPolicyholder;
            credential.clientDOB = dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : ''
            props.showSpinner();
            props.verifyCredentials(credential).then((response) => {
                if (response.success) {
                    if (response.token) {
                        props.storePolicy(response.policy);
                        props.setCpToken(response.token);

                        // history.push("/policy");
                        props.hideSpinner();
                        setPolicyNumberValue(response.policy.policyNumber);
                        setUpdateDetailsOpen(true);
                    } else {
                        setErrorMessage(cfg('errorMessage.notMatch'));
                        props.hideSpinner();
                    }
                } else {
                    setErrorMessage(cfg(response.errorCode));
                    props.hideSpinner();
                }
            }, (error) => {
                if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                    setErrorMessage(cfg('errorMessage.serverError'));
                } else {
                    setErrorMessage(error.response.statusText);
                }
                props.hideSpinner();
            })
        } else {
            if (_validateForm(formId)) {
                credential.policyNumber = props.policy.policyNumber;
                credential.policyHolderType = 'I';
                credential.clientID = idNum;
                credential.clientName = nameOfPolicyholder;
                credential.clientDOB = dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : ''
                credential.isRegistration = true;
                props.showSpinner();
                props.verifyCredentials(credential).then((resp) => {
                    if (resp.success) {
                        if (resp.token) {
                            props.storePolicy(resp.policy);
                            props.setCpToken(resp.token);
                            props.hideSpinner();
                            // history.push("/policy");
                            setPolicyNumberValue(resp.policy.policyNumber);
                            setUpdateDetailsOpen(true);
                        } else {
                            setErrorMessage(cfg('errorMessage.notMatch'));
                            props.hideSpinner();
                        }
                    } else {
                        setErrorMessage(cfg(resp.errorCode));
                        props.hideSpinner();
                    }
                }, (error) => {
                    if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                        setErrorMessage(cfg('errorMessage.serverError'));
                    } else {
                        setErrorMessage(error.response?.statusText);
                    }
                    props.hideSpinner();
                })
            }
        }
    }

    const onUpdateClose = () => {
        setUpdateDetailsOpen(false);
        history.push("/policy");
    }

    return (
        <FindMyPolicy section="authentication" cfg={cfg}>
            <Hidden smDown>
                <div className={classes.title}>
                    <Back
                        className={classes.backIcon}
                        onClick={() =>
                            props.noLogin ? history.push("/") : history.push("/login")
                        }
                    />
                    {cfg("findMyPolicy.authentication")}
                </div>
            </Hidden>

            {hasInput ? (
                <div className={[classes.subTitle, classes.successSubTitle].join(" ")}>
                    {cfg("findMyPolicy.any2Fields")}
                    <SuccessIcon/>
                </div>
            ) : (
                <div className={classes.subTitle}>
                    {cfg("findMyPolicy.any2Fields")}
                </div>
            )}

            <div className={classes.form_wrapper}>
                {props.noLogin ?
                    <div className={classes.firstRowWrapperMVP1}>
                        <div className={classes.hkidHolder}>
                            <StyledTextFieldWrapper>
                                <QnectTextField
                                    field="hkidNumber"
                                    value={idNum}
                                    onChange={(e) => setIdNum(e.target.value)}
                                    type={showIdNum ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowIdNum(!showIdNum)}>{showIdNum ?
                                                <ShowIcon/> :
                                                <HideIcon/>}</IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </StyledTextFieldWrapper>
                            <BalloonTip>{cfg("tooltip.hkidNumber")} </BalloonTip>
                        </div>
                    </div>
                    :
                    <div className={classes.firstRowWrapper}>
                        <StyledTextFieldWrapper className={classes.idType}>
                            { props.country === 'SGP' ?
                                <QnectTextField value="NRIC" readOnly={true} label="ID Type"/> :
                                <QnectSelectField
                                    field="HkidOrPassportSelect"
                                    value={idCardType}
                                    onChange={(val) => setIdCardType(val)}
                                />
                            }
                        </StyledTextFieldWrapper>
                        <div className={classes.hkidHolderMVP2}>
                            <StyledTextFieldWrapper>
                                <QnectTextField
                                    formId={formId}
                                    field="idNumber"
                                    value={idNum}
                                    onBlur={(e) => setIdNum(e.target.value.toUpperCase())}
                                    type={showIdNum ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowIdNum(!showIdNum)}>{showIdNum ?
                                                <ShowIcon/> :
                                                <HideIcon/>}</IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </StyledTextFieldWrapper>
                            <BalloonTip>
                                {cfg("tooltip.idNumber")}
                            </BalloonTip>
                        </div>
                    </div>
                }

                <div className={classes.nameOfPolicyholder}>
                    <StyledTextFieldWrapper>
                        <QnectTextField
                            field="policyHolder"
                            value={nameOfPolicyholder}
                            onChange={(e) => setNameOfPolicyholder(e.target.value?.toUpperCase())}
                            disabled={dateOfBirth ? true : false}
                        />
                    </StyledTextFieldWrapper>
                    <BalloonTip>{cfg("tooltip.policyHolder")} </BalloonTip>
                </div>

                <div className={classes.subText}>
                    {cfg("findMyPolicy.policyHolderSubText")}
                </div>

                <StyledTextFieldWrapper>
                    <QnectDateField
                        formId={formId}
                        field="policyHolderDob"
                        value={dateOfBirth}
                        onChange={(val) => setDateOfBirth(val)}
                        disabled={nameOfPolicyholder ? true : false}
                    />
                </StyledTextFieldWrapper>
            </div>

            {errorMessage && (
              <div className={classes.errorMessage}>
                  <QnectError errorTitle={"Error"} errorMessage={errorMessage}/>
              </div>
            )}
            <div className={classes.buttonContainer}>
                {!props.noLogin && (
                    <StyledHelp onClick={() => setOpenAuth(true)}>
                        {cfg("findMyPolicy.needHelp")}
                    </StyledHelp>
                )}
                <ButtonWrapper className={classes.button}>
                    <QnectButton
                        field="continue"
                        onClick={() => authenticate()}
                        disabled={!hasInput}
                    />
                </ButtonWrapper>
            </div>


            <AuthTipsDialog
                cfg={cfg}
                open={openAuth}
                onClose={() => setOpenAuth(false)}
                onOpenAnother={() => setOpenAuthMore(true)}
            />
            <AuthTipsDialogMore
                cfg={cfg}
                open={openAuthMore}
                onClose={() => setOpenAuthMore(false)}
                onOpenAnother={() => setOpenAuth(true)}
            />

            <UpdateEmailMobileDialog
                open={updateDetailsOpen}
                onClose={onUpdateClose}
                policyNumber={policyNumberValue}
                cfg={cfg}
            />


        </FindMyPolicy>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        policy: _getPolicy(state),
        lang: _getLang(state),
        noLogin: _isNoLogin(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storePolicy: _storePolicy,
    setPolicyToken: _setPolicyToken,
    verifyCredentials: _verifyCredentials,
    checkIfPolicyNumberIsRegistered: _checkIfPolicyNumberIsRegistered,
    checkEmailIfRegistered: _checkEmailIfRegistered,
    setOtpToken: _setOtpToken,
    setCpToken: _setCpToken,
    addEmailAddressToOTPLockedCache: _addEmailAddressToOTPLockedCache,
    showModal: _showModal,
    hideModal: _hideModal,
    verifyOtp: _verifyOtp,
    generatePortfolioToken: _generatePortfolioToken,
    getUserDetailsFromToken: _getUserDetailsFromToken
}

export default withRouter(memo(connect(mapStateToProps, mapDispatchToProps)(AuthenticationSection)));
