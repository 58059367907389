import React from 'react';
import AdminLogin from "../components/login/admin-login";


const AdminLoginPage = () => {
    return (
            <AdminLogin/>
    )
}

export default AdminLoginPage;
