import propTypes from 'prop-types';
import React from 'react';

import Button from "../../ui/components/buttons/button";
import useConfig from "./qnect-config";
import {_getLang} from "../../store/system/service";
import {connect} from "react-redux";

const QnectButton = React.forwardRef((
    {
        lang,
        field,
        color,
        disabled,
        className,
        onClick,
        children,
        ...rest
    },
    ref
) => {

    const cfg = useConfig(lang);

    return (
        <Button
            color={cfg(field, "color") ? cfg(field, "color") : color}
            className={className}
            onClick={onClick}
            disabled={disabled}
            isNegative={cfg(field, "negative") === 'true' ? true : false}
        >
            { cfg(field, "label")}
            { children }
        </Button>
    )
});

QnectButton.propTypes = {
    field: propTypes.string,
    color: propTypes.string,
    className: propTypes.string,
    onClick: propTypes.func,
    disabled: propTypes.bool,
};

QnectButton.defaultProps = {
    button: "primary"
}

QnectButton.displayName = 'QnectButton';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps)(QnectButton);
