import React, {Component} from 'react';
import RenewalFinish from "../components/renewal-finish/renewal-finish";

const RenewalPaymentFinishPage = () => {
    return (
        <div>
            <RenewalFinish/>
        </div>
    )
}

export default RenewalPaymentFinishPage;
