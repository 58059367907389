import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {smDown, smUp} from '../../common/breakpoints';
import {grid, gridCell, gridRowInlineStyle} from '../../common/grid';
import typographyStyles from '../../common/typography-styles';

/* eslint-disable indent */
const Questions = styled.dl`
    margin: 0;
    padding: 0;

    ${smUp`
        ${({scRows}) => grid({
            columns: 2,
            rows: scRows,
            columnTemplate: ['136px', '1fr'],
            columnGap: '21px',
            rowGap: '18px',
        })};
    `}

    & + & {
        margin-top: 48px;

        ${smUp`
            margin-top: 32px;
        `}
    }
`;
/* eslint-enable indent */

const Question = styled.dt`
    box-sizing: border-box;
    margin: 0;

    ${typographyStyles({
        type: 'text',
        defaultColor: 'tertiary90',
        defaultWeight: 'regular',
    })};

    ${smDown`
        padding: 0 0 12px;
    `}

    ${smUp`
        ${gridCell({column: 1})};
    `}
`;

const Answer = styled.dd`
    box-sizing: border-box;
    margin: 0;

    ${typographyStyles({
        type: 'text',
        defaultColor: 'tertiary90',
        defaultWeight: 'semiBold',
    })};

    ${smDown`
        padding: 0 0 24px;

        &:last-of-type {
            padding: 0;
        }
    `}

    ${smUp`
        ${gridCell({column: 2})};
    `}
`;

export default function QuestionsAndAnswers({
    className,
    questions = [],
}) {
    return (
        <Questions
            className={classnames('QuestionsAndAnswers', className)}
            scRows={questions.length}
        >
            {questions.map(({question, answer}, index) => {
                const style = gridRowInlineStyle(index + 1);

                return (
                    <React.Fragment key={index}>
                        <Question style={style}>
                            {question}
                        </Question>
                        <Answer style={style}>
                            {answer}
                        </Answer>
                    </React.Fragment>
                );
            })}
        </Questions>
    );
}

QuestionsAndAnswers.propTypes = {
    className: propTypes.string,
    questions: propTypes.arrayOf(propTypes.shape({
        question: propTypes.string,
        answer: propTypes.node,
    })),
};
