import React from 'react';
import OnlinePaymentDetail from "../components/online-payment/online-payment-detail";

const OnlinePaymentDetailPage = () => {
    return (
        <div>
            <OnlinePaymentDetail/>
        </div>
    )
}

export default OnlinePaymentDetailPage;
