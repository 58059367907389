import styled from "styled-components";
import React, {memo} from "react";
import Hidden from "@material-ui/core/Hidden";

const MStatusWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 83px;
  padding: 2px 4px;
  border-radius: 12px;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  word-break: break-word;
  color: ${({ status }) =>
    status === 'payment processed' ? "#11AC56"
        : status === 'claim closed' ? "#D43900"
        : status === 'claim received' || status === 'notification received' || 
            status === 'in assessment' ? "#F38300"
            : status === 'additional documents request via email / letter' ? "#009AE4"
                : "#4A69C5"};

  background-color: ${({ status }) =>
    status === 'payment processed' ? "#BFECD3"
        : status === 'claim closed' ? "#FCC1C1"
        : status === 'claim received' || status === 'notification received' ||
          status === 'in assessment' ? "#FBDFBE"
            : status === 'additional documents request via email / letter' ? "#B3E5FC"
                : "#C1C9EA"};
`;

const Status = styled.div`
  width: 130px;
  padding: 2px 4px;
  border-radius: 12px;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  word-break: break-word;
  color: ${({ status }) =>
    status === 'payment processed' ? "#11AC56"
        : status === 'claim closed' ? "#D43900"
        :  status === 'claim received' || status === 'notification received' ||
            status === 'in assessment' ? "#F38300"
            : status === 'additional documents request via email / letter' ? "#009AE4"
                : "#4A69C5"};

  background-color: ${({ status }) =>
    status === 'payment processed' ? "#BFECD3"
        : status === 'claim closed' ? "#FCC1C1"
        : status === 'claim received' || status === 'notification received' ||
           status === 'in assessment' ? "#FBDFBE"
            : status === 'additional documents request via email / letter' ? "#B3E5FC"
                : "#C1C9EA"};
`;

const ClaimStatus = ({status, cfg, ...props}) => {

    const retrieveClaimProperty = (status) => {

        switch(status) {
            case 'adjuster assessment in progress':
                return cfg('policy.claimStatus.adjusterAssessment');
            case 'fire services department investigation in progress':
                return cfg('policy.claimStatus.fireInvestigation');
            case 'police investigation in progress':
                return cfg('policy.claimStatus.policeInvestigation');
            case 'consultant investigation in progress':
                return cfg('policy.claimStatus.consultantInvestigation');
            case 'death inquest in progress':
                return cfg('policy.claimStatus.deathInquest');
            case 'pending for the third party claim':
                return cfg('policy.claimStatus.pendingThirdParty');
            case 'await the third party response':
                return cfg('policy.claimStatus.awaitThirdParty');
            case 'await policy excess from the insured':
                return cfg('policy.claimStatus.awaitPolicyExcess');
            case 'investigation in progress':
                return cfg('policy.claimStatus.investigation');
            case 'survey in progress':
                return cfg('policy.claimStatus.survey');
            case 'litigation in progress':
                return cfg('policy.claimStatus.litigation');
            case 'the third party claim is declined':
                return cfg('policy.claimStatus.declinedThirdParty');
            case 'negotiating with the third party':
                return cfg('policy.claimStatus.negoThirdParty');
            case 'labour department investigation in progress':
                return cfg('policy.claimStatus.labourDepartment');
            case 'medical clearance to be scheduled':
                return cfg('policy.claimStatus.clearanceScheduled');
            case 'medical clearance to be conducted':
                return cfg('policy.claimStatus.clearanceConducted');
            case 'medical assessment board to be scheduled':
                return cfg('policy.claimStatus.assessmentScheduled');
            case 'medical assessment board to be conducted':
                return cfg('policy.claimStatus.assessmentConducted');
            case 'claim received':
                return cfg('policy.claimStatus.claimReceived');
            case 'notification received':
                return cfg('policy.claimStatus.notifReceived');
            case 'additional documents request via email / letter':
                return cfg('policy.claimStatus.addtlDocuments');
            case 'in assessment':
                return cfg('policy.claimStatus.assessment');
            case 'payment processed':
                return cfg('policy.claimStatus.paid');
            case 'claim closed':
                return cfg('policy.claimStatus.closed');
            case 'received prosecution summons from the insured':
                return cfg('policy.claimStatus.summon');
            case 'additional documents request via email':
                return cfg('policy.claimStatus.documentRequest');
            default:
                return status;
        }

    }

    return (
        <>
            <Hidden mdUp>
                <MStatusWrapper status={status?.toLowerCase()}>{retrieveClaimProperty(status?.toLowerCase())}</MStatusWrapper>
            </Hidden>
            <Hidden smDown>
                <Status status={status?.toLowerCase()}>{retrieveClaimProperty(status?.toLowerCase())}</Status>
            </Hidden>
        </>
    );
}

export default memo(ClaimStatus);