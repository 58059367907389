import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "../dialog-title";
import QnectButton from "../../qnect-ui/qnect-button";
import { ReactComponent as LeftIcon } from "../../../assets/registration/icon_find_my_policy_one.svg";
import { ReactComponent as RightIcon } from "../../../assets/registration/icon_find_my_policy_two.svg";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import { smDown, lgDown } from "../../../ui/common/breakpoints";

const StyledBack = styled(Back)`
  width: 28px;
  height: 28px;
  vertical-align: sub;
  cursor: pointer;
  margin-right: 43px;
  margin-left: -28px;
  ${smDown`
    width: 23px;
    height: 23px;
    margin-right: 16px;
  `}
`;

const StyledMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: "Inter";
  ${lgDown`
    width: 519px;
    margin: 20px auto 0;
  `}
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    width: 295px;
  `}
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  align-items: center;
  & > :first-child {
    margin-right: 60px;
    ${smDown`
      margin-right: 24px;
    `}
  }
  ${smDown`
    margin-top: 20px;
  `}
`;

const StyledIconDescription = styled.div`
  font-weight: 500;
  color: #0a1f44;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  ${smDown`
    font-size: 14px;
    line-height: 21px;
    width: 144px;
    & > :first-child {
      height: 105px;
      width: 139px;
    }
  `}
`;

const StyledDialog = withStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "720px",
      padding: "14px 76px 130px",
      [theme.breakpoints.between("sm", "md")]: {
        width: "596px",
        padding: "14px 14px 144px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "16px",
        padding: "4px 15px 40px",
        width: "343px",
      },
    },
    "& .MuiDialogContent-root": {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  },
  paperWidthSm: {
    maxWidth: "none",
  },
}))(Dialog);

export default function HowToFindMyPolicyDialog({ open, onClose, onBack, cfg }) {
  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle
          title={
            <>
              <StyledBack
                onClick={() => {
                  onClose();
                  onBack();
                }}
              />
              { cfg('login.howToFindPolicyNumberTitle') }
            </>
          }
          closable={false}
        />
        <DialogContent>
          <StyledMessage>
            { cfg('login.howToFindPolicyNumberMsg') }
          </StyledMessage>
          <StyledIconContainer>
            <StyledIconDescription>
              <LeftIcon />
              <br />{ cfg('login.howToFindPolicyNumber1To7') }
            </StyledIconDescription>
            <StyledIconDescription>
              <RightIcon />
              <br />{ cfg('login.howToFindPolicyNumber2To6') }
            </StyledIconDescription>
          </StyledIconContainer>
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
