import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import OtpInput from "../opt-input";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 60px 56px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    }
  },
  otpInput: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "65px"
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "6px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  receive: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "40px"
    }
  },
  resend: {
    display: "inline-block",
    marginTop: 0,
    marginLeft: "8px",
    color: "#003da5",
    cursor: "pointer",
    textDecoration: "underline"
  },
  resend_disabled: {
    color: "#b0b7c3",
    cursor: "auto"
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "60px"
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      marginLeft: "57px"
    }
  }
}));

export default function VerificationCodeDialog({
  open = false,
  onResend = null,
  onClose,
  onConfirm = null
}) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const timer = useRef(null);

  const [value, setValue] = useState("");
  const [counter, setCounter] = useState(60);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (counter === 60 && open) {
      setCounter(59);
    }

    if (counter !== 60 && counter > 0) {
      timer.current = setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, open]);

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  function handleConfirm() {
    /* options ↓ */
    if (true) {
      onConfirm && onConfirm();
    } else {
      setErrMsg("xxxxxxxxxxxxxxxxxxxxx");
    }
  }

  function handleResend() {
    setCounter(60);
    onResend && onResend();
  }

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title="Please Enter the Verification Code Received"
          onClose={onClose}
          closable={matches}
        />
        <DialogContent>
          <div className={classes.message}>
            A text message with a 6-digit verification code has been sent to
            ••••1234
          </div>
          <OtpInput
            className={classes.otpInput}
            value={value}
            onChange={v => setValue(v)}
            errorMessage={value !== "" ? errMsg : ""}
          />
          <div className={[classes.message, classes.receive].join(" ")}>
            Hasn’t receive it?
            {counter === 0 ? (
              <div
                className={[classes.message, classes.resend].join(" ")}
                onClick={handleResend}
              >
                Resend
              </div>
            ) : (
              <div
                className={[
                  classes.message,
                  classes.resend,
                  classes.resend_disabled
                ].join(" ")}
              >
                Resend ({counter})
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper color="secondary">
            <QnectButton color="secondary" onClick={onClose}>
              Cancel
            </QnectButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <QnectButton
              className={classes.button}
              disabled={value.length !== 6}
              onClick={handleConfirm}
            >
              Confirm
            </QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
}

VerificationCodeDialog.propTypes = {
  open: PropTypes.bool,
  onResend: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
