import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Camera } from "../../assets/profile/icon_camera.svg";

import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import PropTypes from "prop-types";
import useConfig from "../qnect-ui/qnect-config";
import {connect} from "react-redux";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
     _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {_getUser, _updateMyProfileDetails} from "../../store/user/service";
import {Edit} from "@material-ui/icons";
import styled from "styled-components";
import {lgDown, smDown} from "../../ui";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import {_validateForm} from "../../utils/utility";
import {StyledModalDiv} from "../login-style";
import {ReactComponent as BlueDone} from "../../assets/BlueDone.svg";
import {StyledQnectButton} from "../portfolio/portfolio-style";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    boxShadow: "0 0 60px 0 rgba(0, 0, 0, 0.06)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "65px auto 0",
      padding: "14px 20px 0",
      width: "343px",
      height: "200px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      margin: "135px auto 0",
      padding: "40px 135px 0",
      width: "720px",
      height: "250px"
    },
    [theme.breakpoints.up("lg")]: {
      margin: "-105px auto 0",
      padding: "40px 75px 0",
      width: "600px",
      height: "250px"
    }
  },
  avatarWrapper: {
    display: "flex",
    // position: "absolute",
    // borderRadius: "50%",
    // backgroundColor: "#ffffff",
    // boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
    // justifyContent: "center",
    // alignItems: "center",
    // [theme.breakpoints.down("sm")]: {
    //   top: "-35px",
    //   left: "calc(50% - 45px)",
    //   width: "90px",
    //   height: "90px"
    // },
    // [theme.breakpoints.up("sm")]: {
    //   top: "-65px",
    //   left: "calc(50% - 65px)",
    //   width: "130px",
    //   height: "130px"
    // }
  },
  avatar: {
    display: "block",
    borderRadius: "50%",
    boxShadow: "2px 1px 5px 0 rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      height: "80px",
      width: "80px"
    },
    [theme.breakpoints.up("sm")]: {
      height: "116px",
      width: "116px"
    }
  },
  cameraWrapper: {
    position: "absolute",
    right: "0",
    height: "38px",
    width: "38px",
    border: "2px solid #ffffff",
    borderRadius: "50%",
    backgroundColor: "#003da5",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      bottom: "0px"
    },
    [theme.breakpoints.up("sm")]: {
      bottom: "10px"
    }
  },
  name: {
    color: "#0a1f44",
    fontFamily: "Stag",
    fontWeight: "500",
    letterSpacing: "1px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "33px",
      fontSize: "20px",
      lineHeight: "28px"
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "40px",
      fontSize: "32px",
      lineHeight: "40px"
    }
  },
  row: {
    display: "inline-flex",
    alignItems: "left",
    color: "#0a1f44",
    fontFamily: "Inter",
    fontSize: "16px",
    letterSpacing: 0,
    lineHeight: "20px",
    "&:nth-child(3)": {
      marginBottom: "20px"
    },
    "& > span:first-child": {
      [theme.breakpoints.down("sm")]: {
        width: "120px"
      },
      [theme.breakpoints.up("sm")]: {
        width: "200px"
      }
    }
  },
  button: {
    display: "block",
    margin: "40px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "150px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "160px"
    }
  }
}));

const ProfileBase = (props) => {
  const classes = useStyles();
  const cfg = useConfig(props.lang);

  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        {/*<img className={classes.avatar} src={imgSrc} alt={cfg("profilePage.avatar")} />*/}
      </div>
      <div className={classes.name}>{props.user.userName}</div>
      <div className={classes.row}>
        <span>{cfg("profilePage.mobileNumber")}</span>
        <span>
          { props.user.phoneNumber &&
            `${props.user.phoneNumber}`}
          { !props.user.phoneNumber && cfg('profilePage.notAvailable') }
        </span>
      </div>
      <div className={classes.row}>
        <span>{cfg("profilePage.emailAddress")}</span>
        <span>
          {props.user.email && props.user.email}
          { !props.user.email && cfg('profilePage.notAvailable') }
        </span>
      </div>
    </div>
  );
};

ProfileBase.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  countryCode: PropTypes.string,
  phoneNum: PropTypes.string,
  email: PropTypes.string,
  onLogout: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    user: _getUser(state),
    lang: _getLang(state)
  }
}

export default memo(connect(mapStateToProps)(ProfileBase));
