import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {textFieldType} from '../../common/common-prop-types';
import typographyStyles from '../../common/typography-styles';
import useAutoComplete from '../../common/use-auto-complete';
import Form from '../form-controls/common/form';
import RadioGroup from '../form-controls/radio-group';
import TextAreaField from '../form-fields/text-area-field';
import Button from '../buttons/button';

const CenteredParagraph = styled.p`
    text-align: center;

    ${typographyStyles({
        type: 'paragraph',
        defaultSize: 'big',
        defaultColor: 'tertiary90',
    })};
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

export default function DeathOrSeriousInjury({
    className,
    deathOrSeriousInjury = {},
    incidentDetails = {},
    isSubmitting,
    onSubmit,
    shouldShowIncidentDetails = false,
    submitButtonText,
}) {
    const {label: deathOrSeriousInjuryLabel, ...restDeathOrSeriousInjury} = deathOrSeriousInjury;
    const {label: incidentDetailsLabel, ...restIncidentDetails} = incidentDetails;

    return (
        <Form
            className={classnames('DeathOrSeriousInjury', className)}
            onSubmit={onSubmit}
            autoComplete={useAutoComplete()}
            noValidate
        >
            <CenteredParagraph>
                {deathOrSeriousInjuryLabel}
            </CenteredParagraph>

            <RadioGroup
                {...restDeathOrSeriousInjury}
                disabled={isSubmitting}
            />

            {!!shouldShowIncidentDetails && (
                <>
                    <CenteredParagraph>
                        {incidentDetailsLabel}
                    </CenteredParagraph>

                    <TextAreaField
                        {...restIncidentDetails}
                        disabled={isSubmitting}
                    />
                </>
            )}

            <StyledButton
                isTall
                color="primary"
                disabled={isSubmitting}
                type="submit"
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

DeathOrSeriousInjury.propTypes = {
    className: propTypes.string,
    deathOrSeriousInjury: propTypes.shape({
        name: propTypes.string,
        helperText: propTypes.string,
        value: propTypes.string,
        onChange: propTypes.func,
        onBlur: propTypes.func,
        errorMessage: propTypes.string,
        label: propTypes.string.isRequired,
        optionOne: propTypes.shape({
            value: propTypes.string.isRequired,
            text: propTypes.string.isRequired,
        }).isRequired,
        optionTwo: propTypes.shape({
            value: propTypes.string.isRequired,
            text: propTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    incidentDetails: textFieldType.isRequired,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
    shouldShowIncidentDetails: propTypes.bool,
    submitButtonText: propTypes.string.isRequired,
};
