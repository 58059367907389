import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {dateFieldType, textFieldType} from '../../common/common-prop-types';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import DateField from '../form-fields/date-field';
import TextField from '../form-fields/text-field';

const StyledButton = styled(Button)`
    margin: 0 12px;
`;

const ContentBody = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    & > :first-child {
        margin-bottom: 60px;
    }

    & > :last-child {
        margin-bottom: 24px;
    }
`;

const ContentFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
`;

export default function PolicySearchForm({
    className,
    policyNumber,
    dateOfLoss,
    submitButtonText,
    isSubmitting,
    onSubmit,
}) {
    return (
        <Form
            className={classnames('PolicySearchForm', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            <ContentBody>
                <TextField {...policyNumber} />
                <DateField
                    maxDate={new Date()} // default to today
                    {...dateOfLoss}
                />
            </ContentBody>
            <ContentFooter>
                <StyledButton
                    type="submit"
                    color="primary"
                    isTall
                    disabled={isSubmitting}
                >
                    {submitButtonText}
                </StyledButton>
            </ContentFooter>
        </Form>
    );
}

PolicySearchForm.propTypes = {
    className: propTypes.string,
    policyNumber: textFieldType,
    dateOfLoss: dateFieldType,
    submitButtonText: propTypes.string,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func,
};
