import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {smUp} from '../../common/breakpoints';
import {selectFieldType, textFieldType} from '../../common/common-prop-types';
import {grid, gridCell} from '../../common/grid';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import SelectField from '../form-fields/select-field';
import TextField from '../form-fields/text-field';

const Fields = styled.div`
    ${grid({rows: 7, rowGap: '40px'})}

    ${smUp`
        ${grid({rows: 6, rowGap: '40px', columns: 2, columnGap: '32px'})}
    `}
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

const FullName = styled(TextField)`
    ${gridCell({row: 1, column: 1})}

    ${smUp`
        ${gridCell({row: 1, column: 1, colSpan: 2})}
    `}
`;

const StreetAddress = styled(TextField)`
    ${gridCell({row: 2, column: 1})}

    ${smUp`
        ${gridCell({row: 2, column: 1, colSpan: 2})}
    `}
`;

const Locality = styled(TextField)`
    ${gridCell({row: 3, column: 1})}

    ${smUp`
        ${gridCell({row: 3, column: 1, colSpan: 2})}
    `}
`;

const Region = styled(SelectField)`
    ${gridCell({row: 4, column: 1})}

    ${smUp`
        ${gridCell({row: 4, column: 1})}
    `}
`;

const PostalCode = styled(TextField)`
    ${gridCell({row: 5, column: 1})}

    ${smUp`
        ${gridCell({row: 4, column: 2})}
    `}
`;

const PhoneNumber = styled(TextField)`
    ${gridCell({row: 6, column: 1})}

    ${smUp`
        ${gridCell({row: 5, column: 1, colSpan: 2})}
    `}
`;

const EmailAddress = styled(TextField)`
    ${gridCell({row: 7, column: 1})}

    ${smUp`
        ${gridCell({row: 6, column: 1, colSpan: 2})}
    `}
`;

export default function ClaimContactDetails({
    className,
    fullName,
    streetAddress,
    locality,
    region, // state means something else :)
    postalCode,
    phoneNumber,
    emailAddress,
    submitButtonText,
    isSubmitting,
    onSubmit,
}) {
    return (
        <Form
            className={classnames('ClaimContactDetails', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            <Fields>
                <FullName
                    {...fullName}
                    type="text"
                    disabled={isSubmitting}
                />
                <StreetAddress
                    {...streetAddress}
                    type="text"
                    disabled={isSubmitting}
                />
                <Locality
                    {...locality}
                    type="text"
                    disabled={isSubmitting}
                />
                <Region
                    {...region}
                    placeholder={region.label}
                    disabled={isSubmitting}
                />
                <PostalCode
                    {...postalCode}
                    type="text"
                    pattern="^\\d*$"
                    disabled={isSubmitting}
                />
                <PhoneNumber
                    {...phoneNumber}
                    type="tel"
                    disabled={isSubmitting}
                />
                <EmailAddress
                    {...emailAddress}
                    type="email"
                    disabled={isSubmitting}
                />
            </Fields>

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

ClaimContactDetails.propTypes = {
    className: propTypes.string,
    fullName: textFieldType,
    streetAddress: textFieldType,
    locality: textFieldType,
    region: selectFieldType,
    postalCode: textFieldType,
    phoneNumber: textFieldType,
    emailAddress: textFieldType,
    submitButtonText: propTypes.string,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func,
};
