import {InputAdornment} from '@material-ui/core';
import classnames from 'classnames';
import propTypes from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import {textInputType} from '../../common/common-prop-types';
import TextInput from '../form-controls/text-input';
import IconAlert from '../icons/legacy/alert';

const StyledIconAlert = styled(IconAlert)`
    padding: 12px;
`;

const TextField = React.forwardRef((
    {
        className,
        name,
        label,
        type,
        pattern,
        isPatternEnforced,
        value,
        onChange,
        onFocus,
        onBlur,
        helperText,
        placeholder,
        errorMessage,
        maxLength,
        disabled,
        endAdornment,
        ...rest
    },
    ref
) => {
    // Compile the pattern only when it changes, so it doesn't have to be recompiled on every keystroke when enforcing it.
    const compiledPattern = useMemo(() => {
        if (pattern) {
            try {
                return new RegExp(pattern);
            } catch (error) {
                return undefined;
            }
        }

        return undefined;
    }, [pattern]);

    // If an enforced pattern is provided, pass the event to the change handler only if the resulting value matches the pattern.
    function handleChange(event) {
        if (
            onChange
            && (
                !isPatternEnforced
                || !compiledPattern
                || compiledPattern.test(event.target.value)
            )
        ) {
            onChange(event);
        }
    }

    let {readOnly} = rest;

    return (
        <TextInput
            {...rest}
            ref={ref}
            className={classnames('TextField', className)}
            error={!!errorMessage}
            name={name}
            label={label}
            type={type}
            pattern={pattern}
            variant="filled"
            helperText={errorMessage || helperText}
            placeholder={placeholder}
            InputProps={{
                endAdornment: (endAdornment||!!errorMessage
                    && (
                        <InputAdornment position="end">
                            <StyledIconAlert color="error" size={24} />
                        </InputAdornment>
                    )),
                readOnly: readOnly || false
            }}
            inputProps={{ // eslint-disable-line react/jsx-no-duplicate-props
                pattern: compiledPattern ? pattern : undefined,
                maxLength,
            }}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}

        />
    );
});

TextField.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    type: textInputType,
    pattern: propTypes.string,
    isPatternEnforced: propTypes.bool,
    helperText: propTypes.string,
    placeholder: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onFocus: propTypes.func,
    onBlur: propTypes.func,
    errorMessage: propTypes.string,
    maxLength: propTypes.number,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

TextField.displayName = 'TextField';

export default TextField;
