import React from 'react';

import BaseIcon from '../../common/base-icon';

export default function ActionIconExitToApp(props) {
    return (
        <BaseIcon {...props}>
            <path fill="currentColor" fillRule="evenodd" transform="translate(3 3)" d="M7.09 12.59L8.5 14l5-5-5-5-1.41 1.41L9.67 8H0v2h9.67l-2.58 2.59zM18 0H0v6h2V2h14v14H2v-4H0v6h18V0z" />
        </BaseIcon>
    );
}
