import React, {useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import useAutoComplete from '../../../common/use-auto-complete';
import useParentBlur from '../../../common/use-parent-blur';
import Button from '../../buttons/button';
import Form from '../../form-controls/common/form';
import Paragraph from '../../typography/paragraph';
import AttendantDetails from './attendant-details';

const Message = styled(Paragraph)`
    margin: 0 0 38px;
    text-align: center;
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

const HelperText = styled(Paragraph)`
    margin: 0 14px;
    padding-top: 16px;
`;

const AttendedOfficials = React.forwardRef((
    {
        className,
        detailsHeading,
        detailsLabel,
        detailsMaxLength = 30,
        errorMessage,
        helperText,
        isSubmitting,
        message,
        noOneType,
        noOneLabel,
        options,
        onBlur,
        onChange,
        onSubmit,
        submitButtonText,
        value: attendants = new Map(),
    },
    ref
) => {
    const internalRef = useRef(null);
    const [isNoOneSelected, setIsNoOneSelected] = useState(false);

    // onBlur handled for focus outside of options
    useParentBlur(ref || internalRef, onBlur);

    const handleAttendantChange = (value, type) => {
        const isTypeNoOne = type === noOneType;
        const newMap = isTypeNoOne ? new Map() : new Map(attendants);

        setIsNoOneSelected(isTypeNoOne && value);

        if (value) {
            newMap.set(type, ''); // add the selected attendant
        } else {
            newMap.delete(type); // or remove from map if attendant deselected
        }

        return onChange(newMap);
    };

    return (
        <Form
            className={classnames('AttendedOfficials', className)}
            autoComplete={useAutoComplete()}
            noValidate
            onSubmit={onSubmit}
        >
            {!!message && (
                <Message color="tertiary90">
                    {message}
                </Message>
            )}

            {(options).map(({label, type}) => (
                <AttendantDetails
                    key={type}
                    officialType={{
                        label,
                        value: type,
                        checked: attendants.has(type),
                        disabled: (isSubmitting || isNoOneSelected),
                        onChange: (event) => void handleAttendantChange(event.target.checked, type),
                    }}
                    officialDetails={{
                        label: detailsLabel,
                        value: attendants.has(type) ? attendants.get(type) : '',
                        maxLength: detailsMaxLength,
                        disabled: (isSubmitting || isNoOneSelected),
                        onChange: (event) => void onChange(new Map(attendants).set(type, event.target.value)),
                    }}
                    detailsHeading={detailsHeading}
                />
            ))}

            <AttendantDetails
                officialType={{
                    label: noOneLabel,
                    value: noOneType,
                    checked: attendants.has(noOneType),
                    disabled: isSubmitting,
                    onChange: (event) => void handleAttendantChange(event.target.checked, noOneType),
                }}
            />

            {!!(errorMessage || helperText) && (
                <HelperText
                    size="xSmall"
                    color={errorMessage ? 'error' : 'tertiary90'}
                >
                    {errorMessage || helperText}
                </HelperText>
            )}

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
});

AttendedOfficials.displayName = 'AttendedOfficials';

AttendedOfficials.propTypes = {
    className: propTypes.string,
    message: propTypes.string,
    value: propTypes.object,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    helperText: propTypes.string,
    errorMessage: propTypes.string,
    options: propTypes.arrayOf(propTypes.shape({
        label: propTypes.string,
        type: propTypes.string,
    })).isRequired,
    detailsMaxLength: propTypes.number,
    detailsLabel: propTypes.string,
    detailsHeading: propTypes.string.isRequired,
    noOneType: propTypes.string.isRequired,
    noOneLabel: propTypes.string,
    submitButtonText: propTypes.string.isRequired,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
};

export default AttendedOfficials;
