import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import {colorType} from '../../../common/common-prop-types';
import {cssIf} from '../../../common/conditional-styles';
import headingRenderer from './heading-renderer';
import listRenderer from './list-renderer';
import paragraphRenderer from './paragraph-renderer';

/* eslint-disable indent */
const Container = styled.div`
    ${cssIf('scIsCompact')`
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    `}

    strong {
        font-weight: ${property('theme.typography.text.fontWeight.semiBold')};
    }

    a {
        text-decoration: none;
        color: ${property('theme.color.primary')};

        &:hover {
            text-decoration: underline;
        }
    }
`;
/* eslint-enable indent */

const allowedTypes = [
    'root',
    'text',
    'break',
    'delete',
    'paragraph',
    'emphasis',
    'strong',
    'link',
    'linkReference',
    'list',
    'listItem',
    'heading',
];

export default function Markdown({
    className,
    fromHeadingLevel = 1,
    isHeadingCenteredOnMobile,
    isHeadingCenteredOnDesktop,
    textSize,
    textColor,
    isCompact,
    markdown,
}) {
    return (
        <Container
            className={classnames('Markdown', className)}
            scIsCompact={isCompact}
        >
            <ReactMarkdown
                source={markdown}
                skipHtml
                allowedTypes={allowedTypes}
                renderers={{
                    heading: headingRenderer({
                        fromHeadingLevel,
                        isHeadingCenteredOnMobile,
                        isHeadingCenteredOnDesktop,
                    }),
                    paragraph: paragraphRenderer({
                        size: textSize,
                        color: textColor,
                    }),
                    list: listRenderer({
                        size: textSize,
                        color: textColor,
                    }),
                }}
            />
        </Container>
    );
}

Markdown.propTypes = {
    className: propTypes.string,
    fromHeadingLevel: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
    isHeadingCenteredOnMobile: propTypes.bool,
    isHeadingCenteredOnDesktop: propTypes.bool,
    textSize: propTypes.oneOf(['big', 'normal', 'small', 'xSmall']),
    textColor: colorType,
    isCompact: propTypes.bool,
    markdown: propTypes.string,
};
