import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import QnectCheckBox from "../../qnect-ui/qnect-check-box";
import QnectTextField from "../../qnect-ui/qnect-text-field";
import {smDown} from "../../../ui/common";
import {useSelector} from "react-redux";
import {_getCountry, _getLang} from "../../../store/system/service";
import {_validateForm} from "../../../utils/utility";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 25px 32px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "18px 25px 32px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px",
      overflow: "hidden"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "53px"
    }
  },
  button:{
    marginRight: "22px"
  },
  sentButton:{
    [theme.breakpoints.down("sm")]: {
      marginLeft: "80px"
    }
  }
}));

const EmailAddress = styled.div`
  font-weight: 600;
`;

const StyledMessage = styled.div`
  height: 50px;
  width: 532px;
  color: #0A1F44;
  font-family: Inter;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
`;

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin: 60px 0 0px;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
    padding-left: 12px;
    padding-right: 12px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  > .BalloonTip {
    top: -16px;
    left: -129px;
  }
  > .BalloonTip .contentDiv {
    top: -150px !important;
    width: 306px !important;
    height: 68px !important;
    border-radius: 2px !important;
    margin-left: 201px !important;
    padding: 4px 12px 4px;
  }
  > div:not(.form-caption) {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 29px;
    right: 20px;
  }
  input[type="text"], textarea {
    background-color : #fff; 
  }
`;

const StyledCheckBoxContent = styled.div`
  height: 48px;
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  
   ${smDown`
        height: 95px;
        width: 265px;
  `}
   
`;

const CheckBoxWrapper = styled.div`
  & label {
    margin: 32px auto 0;
  }
`

export default function EmailDocDialog({
  title,
  message,
  chkMessage,
  email,
  open,
  onClose,
  onSend,
    isEmailSentDialog = false
}) {
  const classes = useStyles();
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [update, setUpdate] = useState("");
  const [updateEmail, setUpdateEmail] = useState(false);

  const lang = useSelector((state) => _getLang(state));
  const country = useSelector((state) => _getCountry(state));

  useEffect(() => {
    if (email) {
      setTo(email);
    }
  }, [email]);

  const sendEmail = () => {
    if (updateEmail && (email !== to)) {
      setUpdate("Y");
      console.log("updating email")
    } else {
      setUpdate("N");
    }
    let emailObj = {
      to: to,
      subject: subject,
      content: content
    }
    onSend(emailObj, update);
    setUpdate("N")
  }

  const formId = "sendEmailForm";

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle title={title} closable={false} lang={lang}/>
        { !isEmailSentDialog && (
          <DialogContent>

          <div className={classes.message}>
            {message}
          </div>
          <StyledTextFieldWrapper>
            <QnectTextField
              field="policy.emailAddressTo"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              formId={formId}
            />
          </StyledTextFieldWrapper>
          { country === 'SGP' ? null :
            <CheckBoxWrapper>
              <QnectCheckBox
                checked={updateEmail}
                field="updateEmail"
                onChange={(e) => {
                  setUpdateEmail(e)
                }}>
                <StyledCheckBoxContent>{chkMessage}</StyledCheckBoxContent>
              </QnectCheckBox>
            </CheckBoxWrapper>
          }
        </DialogContent>
        )}

        { isEmailSentDialog && (
            <DialogContent>
              <div className={classes.message}>
                {message}
              </div>
            </DialogContent>
        )}

        <DialogActions className={classes.actions}>
          { !isEmailSentDialog && <>
            <ButtonWrapper color="secondary" className={classes.button}>
              <QnectButton color="secondary" field="cancel" onClick={onClose}></QnectButton>
            </ButtonWrapper>

            <ButtonWrapper>
              <QnectButton
                field="send"
                onClick={() => {
                  if (_validateForm(formId)){
                    sendEmail();
                  }
                }}
                disabled={!to}
              >

              </QnectButton>
            </ButtonWrapper>
          </>}

          { isEmailSentDialog && <>
            <ButtonWrapper className={classes.sentButton}>
              <QnectButton field="close" onClick={onClose}></QnectButton>
            </ButtonWrapper>
          </>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
