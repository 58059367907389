import React from 'react';
import propTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {smUp} from '../../common/breakpoints';
import {cssIf, cssIfElse, cssIfNot} from '../../common/conditional-styles';

const shimmer = keyframes`
    0% {
        background-position: -80px 0;
    }

    80%,
    100% {
        background-position: 600px 0;
    }
`;

const Container = styled.section`
    background-image: linear-gradient(to right, ${property('theme.color.grey30')}, ${property('theme.color.grey40')} 40px, ${property('theme.color.grey30')} 80px, ${property('theme.color.grey30')});
    background-size: 680px 100%;
    width: 100%;
    max-width: 600px;

    ${cssIf('scIsAnimated')`
        animation: ${shimmer} 2s infinite;
    `}
`;

const Shape = styled.div.attrs({'aria-hidden': true})`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow: hidden;

    &::after {
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 999px;
        max-width: calc(100% - 48px);
        height: 100px;
        content: '';

        ${cssIfNot('scIsTransparent')`
            ${cssIfElse('scIsHeader')`
                background: ${property('theme.color.grey20')};
            ``
                background: ${property('theme.color.white')};
            `}       
        `}

        ${cssIfElse('scIsHeader')`
            box-shadow: 0 0 0 1000px ${property('theme.color.grey20')};
        ``
            box-shadow: 0 0 0 1000px ${property('theme.color.white')};
        `}

        ${smUp`
            max-width: calc(100% - 104px);
        `}
    }
`;

const Shape1 = styled(Shape)`
    height: 112px;

    &::after {
        top: 48px;
        width: 250px;
        height: 23px;

        ${smUp`
            width: 300px;
        `}
    }
`;

const Shape2 = styled(Shape)`
    height: 68px;

    &::after {
        bottom: 0;
        width: 300px;
        height: 15px;

        ${smUp`
            width: 496px;
        `}
    }
`;

const Shape3 = styled(Shape)`
    height: 30px;

    &::after {
        bottom: 0;
        width: 220px;
        height: 15px;

        ${smUp`
            width: 300px;
        `}
    }
`;

const Shape4 = styled(Shape)`
    height: 349px;

    ${smUp`
        height: 215px;
    `}

    &::after {
        bottom: 44px;
        width: 124px;
        height: 56px;

        ${smUp`
            bottom: 54px;
            width: 254px;
        `}
    }
`;

export default function SkeletonLoader({
    className,
    isAnimated = true,
}) {
    return (
        <Container
            className={classnames('SkeletonLoader', className)}
            role="alert"
            aria-live="assertive"
            aria-busy="true"
            scIsAnimated={isAnimated}
        >
            <Shape1 scIsHeader scIsTransparent={isAnimated} />
            <Shape2 scIsTransparent={isAnimated} />
            <Shape3 scIsTransparent={isAnimated} />
            <Shape4 scIsTransparent={isAnimated} />
        </Container>
    );
}

SkeletonLoader.propTypes = {
    className: propTypes.string,
    isAnimated: propTypes.bool,
};
