import React from "react";
import Dialog from "../dialog";
import PropTypes from "prop-types";
import ArrowIconBack from "../../../assets/icon_arrow_left.svg";
import useLoginStyles, {StyledModalDiv, ButtonContainer} from "../../login-style";
import "../../login-sgp-addon-style.css"

const TermsNConditionDetailDialog = ({
  open,
  onBack,
  onClose,
    cfg,

}) => {
  const classes = useLoginStyles();

  return (
      <Dialog id="TermsConditionsDetail" className={classes.dialog} open={open} onClose={onClose}>
        <StyledModalDiv id="TermsConditionsFull" className="TermsAndConditionsFull_Modal">
          <div className="title">
            <img src={ArrowIconBack} alt="back" onClick={onBack} />
            {cfg('login.modalTCTitle')}
          </div>
          <div className="content">
            <p>{cfg('login.modalTCContentHeader')}</p>
            <ol className="mainList">
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemA')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemA2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemB')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemB2')}</div>
                <ul className="subList">
                  <li className="subListItem">{cfg('login.modalTCContentListItemB_a')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemB_b')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemB_c')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemB_d')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemB_e')}</li>
                </ul>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemC')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemC2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemD')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemD2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemE')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemE2a')}<br/>
                  {cfg('login.modalTCContentListItemE2b')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemF')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemF2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemG')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemG2a')}<br/>
                  {cfg('login.modalTCContentListItemG2b')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemH')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemH2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemI')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemI2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemJ')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemJ2a')}<br/>
                  {cfg('login.modalTCContentListItemJ2b')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemK')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemK2')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemL')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemL2')}</div>
                <ul className="subList">
                  <li className="subListItem">{cfg('login.modalTCContentListItemL_a')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemL_b')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemL_c')}</li>
                  <li className="subListItem">{cfg('login.modalTCContentListItemL_d')}</li>
                </ul>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemM')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemM2a')}<br/>
                  {cfg('login.modalTCContentListItemM2b')}</div>
              </li>
              <li className="mainItemTitle">
                <div className="mainItemTitle-Title">{cfg('login.modalTCContentListItemN')}</div>
                <div className="itemText">{cfg('login.modalTCContentListItemN2')}</div>
              </li>
            </ol>
          </div>

        </StyledModalDiv>
      </Dialog>
  );
}

TermsNConditionDetailDialog.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func
}

export default TermsNConditionDetailDialog;
