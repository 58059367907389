import clamp from 'lodash/clamp';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import typographyStyles from '../../common/typography-styles';

const Heading = styled.h3`
    margin: 0;

    ${typographyStyles({
        type: 'text',
        defaultColor: 'tertiary90',
        defaultSize: 'normal',
        defaultWeight: 'semiBold',
    })}
`;

export default function CardSectionHeading({
    level,
    children,
}) {
    return (
        <Heading
            as={`h${level ? clamp(level, 1, 6) : 3}`}
        >
            {children}
        </Heading>
    );
}

CardSectionHeading.propTypes = {
    level: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
    children: propTypes.node,
};
