import React, {useEffect, useState, useRef} from "react";
import {useHistory, withRouter} from "react-router-dom";
import QnectTextField from "../qnect-ui/qnect-text-field";
import CustomizedTab from "../custom/login/customized-tab";
import TabPanel from "../custom/tab-panel";
import VerificationCodeDialog from "../custom/login/verification-code-dialog";
import ReconfirmMobileDialog from "../custom/login/reconfirm-mobile-dialog";
import ReconfirmMobileAfterDialog from "../custom/login/reconfirm-mobile-after-dialog";
import ForgetEmailDialog from "../custom/login/forget-email-dialog";
import ForgetEmailContactDialog from "../custom/login/forget-email-contact-dialog";
import FindMyPolicyDialog from "../custom/registration/find-my-policy-dialog";
import TooManyFailedAttemptsDialog from "../custom/login/too-many-failed-attempts-dialog";
import {ReactComponent as Logo} from "../../assets/login/icon_logo.svg";
import {ReactComponent as ShowIcon} from "../../assets/registration/icon_show.svg";
import {ReactComponent as HideIcon} from "../../assets/registration/icon_hide.svg";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import BalloonTip from "../../components/ui/balloon-tip/balloon-tip";

import {
    _clearToken,
    _generatePortfolioToken,
    _getCountry,
    _getFindMyPolicyBusinessState,
    _getLang,
    _getShowOtpModal,
    _hideModal,
    _hideSpinner,
    _isNoLogin, _setCpToken,
    _setFindMyPolicyBusinessState,
    _setLang,
    _setOtpToken,
    _setPolicyToken,
    _setPortfolioToken,
    _setShowOtpModal,
    _showModal,
    _showSpinner,
    _verifyOtpAndGetUserDetail,
    _getSystemAvailable,
    _getScheduledMaintenance,
    _getGotoPage,
    _generatePolicyToken,
    _getGotoPn
} from "../../store/system/service";

import {connect} from "react-redux";
import {
    _getPolicy, _loadPolicyDetail, _resetPolicy,
    _storePolicy,
    _verifyPolicy
} from "../../store/policy/service";
import useConfig from "../qnect-ui/qnect-config";
import useLoginStyles, {StyledModalDiv} from "../login-style";
import {_refreshFormField, _validateForm} from "../../utils/utility";

import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import StyledTextFieldWrapper from "../custom/style-text-field-wrapper";
import Hidden from "@material-ui/core/Hidden";
import QbeLogo from "../../assets/QBE logo on light.png";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TopNotice from '../custom/top-notice';
import {
    _getUser,
    _getUserDetailsFromToken,
    _storeUser,
    _addEmailAddressToOTPLockedCache,
    _checkEmailIfRegistered,
    _sendRegisteredEmailToSMS,
    _sendRegisteredMobileNumberToEmail,
    _resetUser,
    _verifyUserCredentials,
    _sendResetPasswordLink
} from "../../store/user/service";
import ForgotPasswordDialog from "../custom/login/forgot-password-dialog";
import RegistrationPolicyNumberDialog from "../custom/login/registration-policyNumber";
import TagManager from "react-gtm-module";
import ScheduledMaintenanceBanner from "../custom/scheduled-maintenance-banner";
import SystemUnavailableBanner from "../custom/system-unavailable-banner";

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
    };
}

const tabEnum = {
    memberLogin: {
        name: 'login.memberLogin',
        value: "M",
    },
    findMyPolicy: {
        name: 'login.findMyPolicy',
        value: "F",
    },
    individual: {
        name: 'login.individual',
        value: "I",
    },
    business: {
        name: 'login.business',
        value: "B",
    },
};

const Login = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const formId = 'login-form';
    const formIdFindMyPolicy = 'find-my-policy-form';
    const classes = useLoginStyles(props);
    const ref = useRef(null);

    const [lang, setLang] = useState(props.lang);
    const [outerTab, setOuterTab] = useState(props.noLogin ? tabEnum.findMyPolicy.value : tabEnum.memberLogin.value);
    const [innerTab, setInnerTab] = useState(tabEnum.individual.value);

    const [emailAddress, setEmailAddress] = useState("");
    const [policyNumber, setPolicyNumber] = useState("");
    const [maskedPhone, setMaskedPhone] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [resendTimer, setResendTimer] = useState(0);
    const [resendTimerIntervalId, setResendTimerIntervalId] = useState(null);
    const [sendEmailToSMSError, setSendEmailToSMSError] = useState('');
    const [policyNumberErrorMessage, setPolicyNumberErrorMessage] = useState();

    const [verifyOpen, setVerifyOpen] = useState(false);
    const [reconfirmOpen, setReconfirmOpen] = useState(false);
    const [reconfirmOpen2, setReconfirmOpen2] = useState(false);
    const [forgetOpen, setForgetOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);
    const [policyOpen, setPolicyOpen] = useState(false);
    const [failedOpen, setFailedOpen] = useState(false);

    const [password, setPassword] = useState();
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState("");

    const [openRegistrationPolicyNumberDialog, setOpenRegistrationPolicyNumberDialog] = useState(false);
    const [openRegistrationPolNoHelpDialog, setOpenRegistrationPolNoHelpDialog] = useState(false);

    const [isShowPassword, setIsShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    useEffect(() => {
        props.clearToken();
        props.resetPolicy();
        props.resetUser();
        if (props.findMyPolicyBusinessState) {
            setOuterTab(tabEnum.findMyPolicy.value);
            setInnerTab(tabEnum.business.value);
            props.setFindMyPolicyBusinessState(false);
        }
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'login',
                vPath: '/login'
            }
        });
    }, []);

    const addEmailErrorToCache = () => {
        if (emailAddress || props.user?.email) {
            let email = emailAddress || props.user.email;
            props.addEmailAddressToOTPLockedCache(email).then(
                response => {
                    if (response > 5) {
                        setVerifyOpen(false);
                        setFailedOpen(true);
                    }
                }
            )
        }
    }

    const addEmailToPasswordLockCache = () => {
        if (emailAddress || props.user?.email) {
            let email = emailAddress || props.user.email;
            props.addEmailAddressToPasswordLockedCache(email).then(
                response => {
                    if (response > 5) {
                        setFailedOpen(true);
                    }
                }
            )
        }
    }

    const resetResendTimer = () => {
        if (resendTimerIntervalId) {
            clearInterval(resendTimerIntervalId);
        }
        setResendTimer(60);
        let setTimerInterval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(setTimerInterval);
                    return 0;
                }
            });
        }, 1000);
        setResendTimerIntervalId(setTimerInterval);
    }

    const handleClickMobileBtn = () => {
        const dom = ref.current;

        // dom.style.transform = dom?.style ? "translateY(-561px)" : "";
        dom.classList.add('show')
    };

    const handleChangeOuterTab = (event, newValue) => {
        setOuterTab(newValue);
    };

    const handleChangeInnerTab = (newValue) => {
        setInnerTab(newValue);
    };

    function handleLogin() {
        if (_validateForm(formId)) {
            props.showSpinner();
            setOtpValue("");
            setOtpErrorMessage('');
            setEmailErrorMessage("");
            setPasswordErrorMessage('');
            props.verifyUserCredentials(emailAddress, password).then((response) => {
                if (response.success) {
                    if (!response.lockType) {
                        if (response.isExisting === 'true') {

                            props.user.email = emailAddress;
                            setMaskedPhone(response.phoneNumber);
                            props.storeUser(props.user);
                            props.setCpToken(response.token);

                            resetResendTimer();
                            setVerifyOpen(true);

                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'popup',
                                    popupPath: '/login',
                                    popupTitle: 'Please Enter the Verification Code Received'
                                }
                            });

                        } else {
                            if(props.country === 'MYS'){
                                setPasswordErrorMessage(cfg("loginPassword.errorMessages.passwordWrong"));
                            }else{
                                setEmailErrorMessage(cfg("loginEmailAddress.errorMessages.emailNotFound"));
                                setPasswordErrorMessage(cfg("loginPassword.errorMessages.passwordWrong"));
                            }
                        }
                        props.hideSpinner();
                    } else {
                        props.showModal({
                            flat: true,
                            content: <StyledModalDiv>
                                <div className="iconDiv">
                                </div>
                                <div className="title">Email Address is Locked</div>
                                <div className="content">Please try again
                                    after {response.lockType === 'otp' ? '10 minutes' : '1 hour'}</div>
                                <div className="buttonDiv">
                                    <QnectButton onClick={() => {
                                        props.hideModal();
                                    }}>Continue</QnectButton>
                                </div>
                            </StyledModalDiv>
                        });
                        props.hideSpinner();
                    }
                }
                else {
                    props.hideSpinner();
                    setEmailErrorMessage(cfg('errorMessage.unknownError'));
                    setPasswordErrorMessage(cfg("errorMessage.unknownError"));
                }
            }, (error) => {
                if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                    setEmailErrorMessage(cfg('errorMessage.serverError'));
                    setPasswordErrorMessage(cfg("errorMessage.serverError"));
                } else {
                    setEmailErrorMessage(error.response.statusText);
                    setPasswordErrorMessage(error.response.statusText);
                }
                props.hideSpinner();
            });
        }
    }

    const handleFindMyPolicy = () => {
        if (_validateForm(formIdFindMyPolicy)) {
            props.showSpinner();
            props.setPortfolioToken("");
            props.verifyPolicy(policyNumber).then((response) => {
                props.hideSpinner();
                if (response.success) {
                    props.policy.policyNumber = policyNumber;
                    props.setCpToken(response.token);
                    props.storePolicy(props.policy);
                    let url = "/find-my-policy/individual/authentication";
                    // switch (innerTab) {
                    //     case "I":
                    //         url = "/find-my-policy/individual/authentication";
                    //         break;
                    //     case "B":
                    //         url = "/find-my-policy/company/authentication";
                    //         break;
                    //     default:
                    //         break;
                    // }
                    url && history.push(url);
                } else {
                    setPolicyNumberErrorMessage(response.errorCode);
                    _refreshFormField(formIdFindMyPolicy, "policyNumber");
                }
            }, (error) => {
                if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                    setPolicyNumberErrorMessage(cfg('errorMessage.serverError'));
                } else {
                    setPolicyNumberErrorMessage(error.response.statusText);
                }
                _refreshFormField(formIdFindMyPolicy, "policyNumber");
                props.hideSpinner();
            })
        }
    }

    const handleResend = () => {
        if (resendTimer === 0) {
            props.showSpinner();
            setOtpValue("");
            setOtpErrorMessage('');
            props.checkEmailIfRegistered(emailAddress, props.country).then((response) => {
                if (response.isExisting === 'true') {
                    let otpToken = {
                        iv: response.iv,
                        token: response.token
                    }
                    props.setCpToken(response.token);
                    props.setOtpToken(otpToken);
                }
                props.hideSpinner();
            });
            resetResendTimer();
            addEmailErrorToCache();
        }
    }

    const handleVerifyOtp = () => {
        props.showSpinner();
        props.verifyOtpAndGetUserDetail(otpValue, props.user.email).then((response) => {
            if (response.success) {
                props.setCpToken(response.token);
                props.setPortfolioToken(response.token);

                // if gotoPage exists, redirect
                if (props.gotoPage && props.gotoPn) {
                    console.log('generating policy token for ' + props.gotoPn);
                    props.generatePolicyToken(props.gotoPn, props.country).then(
                        resp => {
                            if (resp !== 'FAILED') {
                                props.setCpToken(resp.token);
                                props.setPolicyToken(resp.token);

                                console.log('loading policy detail ' + props.gotoPn);
                                props.loadPolicyDetail().then((policy) => {
                                    props.storePolicy(policy);
                                    props.hideSpinner();

                                    console.log("redirecting to " + props.gotoPage);
                                    history.push(props.gotoPage);
                                }, (error) => {
                                    props.hideSpinner();
                                });
                            }
                        }, (error) => {
                            props.hideSpinner();
                        });
                }
                else {
                    console.log("redirecting to portfolio");
                    history.push('/portfolio');
                }
            } else {
                setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
                addEmailErrorToCache();
                props.hideSpinner(); // to fix missed commits or merge issues in UAT1 and PROD
            }
        }, (error) => {
            setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
            props.hideSpinner();
        })
    }

    const handleClose4 = () => {
        setForgetOpen(false);
        setSendEmailToSMSError('');
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
    };

    const handleForgetEmailSubmit = (phoneNumber, countryCode) => {
        props.showSpinner();

        if(props.country === "MYS"){
            if(phoneNumber.length > 20){
                setSendEmailToSMSError("Invalid Phone Number")
                props.hideSpinner();
                return
            }
        }

        props.sendRegisteredEmailToSMS(phoneNumber, countryCode).then((response) => {
            if (response === 'SUCCESS') {
                TopNotice.show({
                    message: getForgetEmailNoticeMessage(props.country),
                    duration: 3,
                });
                setForgetOpen(false);
            } else {
                setSendEmailToSMSError(cfg('forgotEmail.errorMessages.mobileNumberNotFound'));
            }
            props.hideSpinner();
        }, (error) => {
            setSendEmailToSMSError(cfg('forgotEmail.errorMessages.mobileNumberNotFound'));
            props.hideSpinner();
        })
    }

    const getForgetEmailNoticeMessage = (country) => {
        if(country === 'MYS'){
            return "We have sent your registered email to your mobile via SMS if your mobile number is valid."
        }else{
            return "We have sent your registered email to your mobile via SMS."
        }
    }

    const handleSendRegisteredMobileToEmail = () => {
        props.showSpinner();
        props.sendRegisteredMobileNumberToEmail(emailAddress).then(() => {
            setReconfirmOpen(false);
            setReconfirmOpen2(true);
            props.hideSpinner();
        });
    }

    const handleClose5 = () => {
        setContactOpen(false);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
    };

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as ' + newLang);

        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Change-Language'
            }
        })

    }

    const maskEmail = () => {
        const regex = /.*(?=.{4}@)/g;
        const maskedPart = emailAddress.match(regex);

        // if more than 4 characters before @
        if (maskedPart && maskedPart[0]) {
            return maskedPart[0].replaceAll(/./g, '•') + emailAddress.replaceAll(regex, '');
        } else {
            const email = emailAddress.split('@');
            if (email[0]) {
                return email[0].replaceAll(/./g, '•') + emailAddress.match(/@.*/);
            }
        }
        return '';
    }

    const handleCloseForgotPasswordDialog = () => {
        setOpenForgotPasswordDialog(false);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Close'
            }
        })
    }

    const handleForgotPasswordSubmit = (email) => {
        props.showSpinner();
        props.sendResetPasswordLink(email)
            .then(() => {
                TopNotice.show({
                    message: cfg("forgotPassword.submitSuccessMessage"),
                    duration: 3,
                });
                setOpenForgotPasswordDialog(false);
                props.hideSpinner();
            }, () => {
                TopNotice.show({
                    message: cfg("forgotPassword.submitSuccessMessage"),
                    duration: 3,
                });
                setOpenForgotPasswordDialog(false);
                props.hideSpinner();
            });
    }

    const handleAlreadyRegistered = () => {
        setOpenRegistrationPolicyNumberDialog(false);

        TagManager.dataLayer({
            dataLayer: {
                event: 'popup',
                popupPath: '/login',
                popupTitle: 'Welcome Back'
            }
        });

        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="title">Welcome Back!</div>
                <div className="content">It has come to our attention that you have registered before.
                    Please login to your account with your registered email address
                </div>
                <ButtonWrapper>
                    <QnectButton
                      field="loginBtn"
                      onClick={() => {
                          props.hideModal();
                          props.history.push('/login');
                      }}
                    />
                </ButtonWrapper>
            </StyledModalDiv>
        });
    }

    const checkIfChrome = () => {
       return !!window.chrome;
    }

    const hasError = !props.systemAvailable;
    const[scheduledMaintenance, setScheduledMaintenance] = useState(null);

    useEffect(() => {
        props.getScheduledMaintenance(props?.country).then(resp => {
            if (Object.keys(resp.data).length) {
                setScheduledMaintenance(resp.data);
            }
        });
    },[]);

    return (
        <>
            <div className={classes.container}>
                { hasError && <SystemUnavailableBanner /> }
                { scheduledMaintenance && <ScheduledMaintenanceBanner value={scheduledMaintenance} country={props.country}/>}
                <div className={ props.country === 'SGP' ? classes.bg_sgp : classes.bg}>
                    <Hidden mdDown>
                        <div className={ props.country === 'SGP' ? classes.Cbg_img_sg : classes.Cbg_img}/>
                    </Hidden>

                    <Hidden smUp>
                        <div className={classes.Mtop}>
                            <div className={classes.Mheader}>
                                { props.country === 'HKG' &&
                                    <a href="https://www.qbe.com/hk/en/about-qbe">
                                        <img src={QbeLogo} alt="QbeLogo" className={classes.Mlogo}/>
                                    </a>
                                }
                                { props.country === 'MYS' &&
                                <a href="https://www.qbe.com/my/about-qbe">
                                    <img src={QbeLogo} alt="QbeLogo" className={classes.Mlogo}/>
                                </a>
                                }
                                { props.country !== 'SGP' && props.country !== 'MYS' &&
                                    <span
                                        className={classes.Mlanguage}
                                        onClick={() => switchLanguage()}
                                    >
                                          {lang === "en"
                                              ? cfg("labels.lang_zh")
                                              : cfg("labels.lang_en")}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className={classes.Mbuttons}>
                            {!props.noLogin && (
                                <ButtonWrapper className={classes.Mbutton}>
                                    <QnectButton
                                        onClick={() => {
                                            handleClickMobileBtn();
                                            setOuterTab(tabEnum.memberLogin.value);
                                        }}
                                    >
                                        {cfg('login.memberLogin')}
                                    </QnectButton>
                                </ButtonWrapper>
                            )}
                            {!props.noLogin &&
                            <div
                                className={[classes.no_account, classes.Mno_account].join(" ")}
                            >
                                {cfg('login.dontHaveAcccount')}
                                <br/>
                                <span
                                    className={[
                                        classes.register_link,
                                        classes.Mregister_link
                                    ].join(" ")}
                                    onClick={() => {
                                        setOpenRegistrationPolicyNumberDialog(true);
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'popup',
                                                popupPath: '/login',
                                                popupTitle: 'Enter your policy number to register'
                                            }
                                        });
                                    }}>
                                    {cfg('login.registerNow')}
                                </span>
                            </div>
                            }
                        </div>
                    </Hidden>

                    <div className={classes.right_content}>
                        <Hidden smDown>
                            <div className={classes.icon_wrapper}>
                                {props.country === 'HKG' &&
                                    <a href="https://www.qbe.com/hk/en/about-qbe">
                                        <Logo/>
                                    </a>
                                }
                                {props.country === 'MYS' &&
                                    <a href="https://www.qbe.com/my/about-qbe">
                                        <Logo/>
                                    </a>
                                }
                                { props.country !== 'SGP' && props.country !== 'MYS' &&
                                    <div
                                        className={classes.language_select}
                                        onClick={() => switchLanguage()}>
                                        {lang === "en"
                                            ? cfg("labels.lang_zh")
                                            : cfg("labels.lang_en")}
                                    </div>
                                }
                            </div>
                        </Hidden>
                        <div className={classes.Mform} ref={ref}>
                            <div className={classes.subtitle} style={ props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                                {cfg('login.title')}
                            </div>

                            {/*<Hidden smDown>*/}
                                {!props.noLogin && (
                                    <div className={props.lang === 'zh'?[classes.no_account, classes.no_account_zh].join(" "):[classes.no_account]} >
                                        {cfg('login.dontHaveAcccount') + " "}
                                        <span
                                            className={classes.register_link}
                                            onClick={() => {
                                                setOpenRegistrationPolicyNumberDialog(true);
                                                TagManager.dataLayer({
                                                    dataLayer: {
                                                        event: 'popup',
                                                        popupPath: '/login',
                                                        popupTitle: 'Enter your policy number to register'
                                                    }
                                                });
                                            }}>
                                            {cfg('login.registerNow')}
                                        </span>
                                    </div>
                                )}
                            {/*</Hidden>*/}
                            <TabPanel value={outerTab} index={tabEnum.memberLogin.value}>
                                <div className={classes.tab_panel}>
                                    <StyledTextFieldWrapper className={classes.member_input}>
                                        <QnectTextField
                                            field="loginEmailAddress"
                                            value={emailAddress}
                                            errorMessage={emailErrorMessage}
                                            onChange={e => {
                                                setEmailAddress(e.target.value);
                                                setEmailErrorMessage("");
                                            }}
                                            placeholder={ cfg('login.emailAddressPlaceHolder') }
                                            formId={formId}
                                        />
                                        <QnectTextField
                                            className="passwordField"
                                            field="loginPassword"
                                            type={isShowPassword ? 'text' : 'password'}
                                            value={password}
                                            formId={formId}
                                            errorMessage={passwordErrorMessage}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                                setPasswordErrorMessage('');
                                            }}
                                            endAdornment={
                                              <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onBlur={() => {
                                                        _validateForm(formId);
                                                    }}
                                                >
                                                  {isShowPassword ? <HideIcon/> : <ShowIcon/>}
                                                </IconButton>
                                              </InputAdornment>
                                            }
                                        />
                                    </StyledTextFieldWrapper>
                                    <ButtonWrapper
                                        className={classes.button_wrapper + (passwordErrorMessage || emailErrorMessage ? " loginButton" : "")}>
                                        <QnectButton
                                            field="continue"
                                            disabled={!emailAddress}
                                            onClick={handleLogin}
                                        />
                                    </ButtonWrapper>
                                </div>
                            </TabPanel>


                            {/* <Hidden> */}
                                <div className={classes.forgotEmailPassword}>
                                    <span onClick={() => {
                                        setForgetOpen(true);
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'popup',
                                                popupPath: '/login',
                                                popupTitle: 'Forgot My QBE Login ID?'
                                            }
                                        });
                                    }}>
                                        {cfg('login.forgotEmail')}
                                    </span>
                                    <span>{" · "}</span>
                                    <span onClick={() => {
                                        setOpenForgotPasswordDialog(true);
                                        setShowHelp(false);
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'popup',
                                                popupPath: '/login',
                                                popupTitle: 'Forgot Password?'
                                            }
                                        });
                                    }}>
                                        {cfg('login.forgotPassword')}
                                    </span>
                                </div>
                                <div className={[classes.corporateCustomers, classes.PreWrap].join(" ")}>
                                    {cfg('login.corporateCustomersMessage')}
                                </div>
                            {/* </Hidden> */}

                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.chromeOnlyDiv} style={checkIfChrome() ? {display: "none"} : {}}>
                {cfg('footer.chromeOnly')}
            </div>

            <VerificationCodeDialog
                open={verifyOpen}
                maskedPhone={maskedPhone}
                onClose={() => setVerifyOpen(false)}
                onConfirm={handleVerifyOtp}
                onOther={() => {
                    setVerifyOpen(false);
                    setReconfirmOpen(true);
                }}
                value={otpValue}
                setValue={setOtpValue}
                errorMessage={otpErrorMessage}
                setOtpErrorMessage={setOtpErrorMessage}
                handleResend={handleResend}
                resendTimer={resendTimer}
                cfg={cfg}
                lang={props.lang}
            />

            <ReconfirmMobileDialog
                open={reconfirmOpen}
                onBack={() => setReconfirmOpen(false)}
                onClose={() => setReconfirmOpen(false)}
                onOk={() => {
                    handleSendRegisteredMobileToEmail();
                }}
                cfg={cfg}
            />

            <ReconfirmMobileAfterDialog
                open={reconfirmOpen2}
                email={maskEmail()}
                onClose={() => {
                    setReconfirmOpen2(false);
                }}
                cfg={cfg}
            />

            <ForgetEmailDialog
                open={forgetOpen}
                onClose={handleClose4}
                onHelp={() => {
                    setForgetOpen(false);
                    setContactOpen(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'Need Help? Contact Us'
                        }
                    });
                }}
                onSubmit={handleForgetEmailSubmit}
                errorMessage={sendEmailToSMSError}
                setErrorMessage={setSendEmailToSMSError}
                cfg={cfg}
                lang={props.lang}
            />

            <ForgotPasswordDialog
                open={openForgotPasswordDialog}
                onClose={handleCloseForgotPasswordDialog}
                showHelp={() => {
                    setOpenForgotPasswordDialog(false);
                    setShowHelp(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'Need Help? Contact Us'
                        }
                    });
                }}
                errorMessage={forgotPasswordErrorMessage}
                setErrorMessage={setForgotPasswordErrorMessage}
                onSubmit={handleForgotPasswordSubmit}
                cfg={cfg}
                lang={props.lang}
            />

            <RegistrationPolicyNumberDialog
                open={openRegistrationPolicyNumberDialog}
                onClose={(isOkClicked = "false") => {
                    setOpenRegistrationPolicyNumberDialog(false);
                    if ( isOkClicked !== "true") {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'Icon-Click',
                                eventCategory: 'Close'
                            }
                        })
                    }
                }}
                onAlreadyRegistered={() => handleAlreadyRegistered()}
                onShowHow={() => {
                    setOpenRegistrationPolicyNumberDialog(false);
                    setOpenRegistrationPolNoHelpDialog(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/login',
                            popupTitle: 'How do I find my policy number?'
                        }
                    });

                }}
                cfg={cfg}
                lang={props.lang}
                history={props.history}
            />

            <ForgetEmailContactDialog
                open={contactOpen}
                onClose={handleClose5}
                onBack={() => {
                    setContactOpen(false);
                    setForgetOpen(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'Icon-Click',
                            eventCategory: 'Back-Arrow'
                        }
                    })
                }}
                cfg={cfg}
                lang={props.lang}
            />

            <ForgetEmailContactDialog
                open={showHelp}
                onClose={() => {
                    setShowHelp(false);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'Icon-Click',
                            eventCategory: 'Close'
                        }
                    });
                }}
                onBack={() => {
                    setShowHelp(false);
                    setOpenForgotPasswordDialog(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'Icon-Click',
                            eventCategory: 'Back-Arrow'
                        }
                    })
                }}
                cfg={cfg}
                lang={props.lang}
            />

            <FindMyPolicyDialog
              open={openRegistrationPolNoHelpDialog}
              onClose={() => {
                  setOpenRegistrationPolNoHelpDialog(false);
                  TagManager.dataLayer({
                      dataLayer: {
                          event: 'Icon-Click',
                          eventCategory: 'Close'
                      }
                  })
              }}
              cfg={cfg}
              isRegistration={true}
              onBack={() => {
                  setOpenRegistrationPolNoHelpDialog(false);
                  setOpenRegistrationPolicyNumberDialog(true);
                  TagManager.dataLayer({
                      dataLayer: {
                          event: 'Icon-Click',
                          eventCategory: 'Back-Arrow'
                      }
                  })
              }}
            />
            <TooManyFailedAttemptsDialog
                open={failedOpen}
                onClose={() => setFailedOpen(false)}
                cfg={cfg}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
        noLogin: _isNoLogin(state),
        showOtpModal: _getShowOtpModal(state),
        findMyPolicyBusinessState: _getFindMyPolicyBusinessState(state),
        user: _getUser(state),
        policy: _getPolicy(state),
        systemAvailable: _getSystemAvailable(state),
        gotoPage: _getGotoPage(state),
        gotoPn: _getGotoPn(state)
    }
}

const mapDispatchToProps = {
    setLang: _setLang,
    checkEmailIfRegistered: _checkEmailIfRegistered,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    clearToken: _clearToken,
    storePolicy: _storePolicy,
    verifyPolicy: _verifyPolicy,
    setOtpToken: _setOtpToken,
    setCpToken: _setCpToken,
    verifyOtpAndGetUserDetail: _verifyOtpAndGetUserDetail,
    sendRegisteredEmailToSMS: _sendRegisteredEmailToSMS,
    sendRegisteredMobileNumberToEmail: _sendRegisteredMobileNumberToEmail,
    addEmailAddressToOTPLockedCache: _addEmailAddressToOTPLockedCache,
    storeUser: _storeUser,
    showModal: _showModal,
    hideModal: _hideModal,
    setShowOtpModal: _setShowOtpModal,
    generatePortfolioToken: _generatePortfolioToken,
    setPortfolioToken: _setPortfolioToken,
    getUserDetailsFromToken: _getUserDetailsFromToken,
    setFindMyPolicyBusinessState: _setFindMyPolicyBusinessState,
    resetPolicy: _resetPolicy,
    resetUser: _resetUser,
    verifyUserCredentials: _verifyUserCredentials,
    sendResetPasswordLink: _sendResetPasswordLink,
    getScheduledMaintenance: _getScheduledMaintenance,
    generatePolicyToken: _generatePolicyToken,
    setPolicyToken: _setPolicyToken,
    loadPolicyDetail: _loadPolicyDetail
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
