import React, {memo, useCallback, useRef, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import LeftStepper from "../custom/find-my-policy/left-stepper";
import Header from "../custom/find-my-policy/header";
import Authentication from "./authentication";
import { ReactComponent as VerificationIcon } from "../../assets/registration/icon_step_verification.svg";
import { ReactComponent as RegistrationIcon } from "../../assets/registration/icon_step_registration.svg";
import Popper from "../custom/find-my-policy/popper";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 87px)"
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      minHeight: "calc(100vh - 64px)"
    }
  },
  container: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 16px 33px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      flex: "1",
      overflowY: "auto",
      display: "flex",
      justifyContent: "center",
      // padding: "143px 0 138px 80px"
      padding: "143px 0 138px"
    },
    [theme.breakpoints.up("lg")]: {
      flex: "1",
      display: "flex",
      justifyContent: "center",
      overflowY: "auto",
      // padding: "115px 0 162px 280px"
      padding: "115px 0 162px"
    }
  },
  contentContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.between("sm", "md")]: {
      width: "404px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "460px"
    }
  }
}));

const steps = [
  { label: "findMyPolicy.authentication", icon: <VerificationIcon /> },
  { label: "findMyPolicy.onlineRegistration", icon: <RegistrationIcon /> },
];

const FindMyPolicy = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const _currentStep = useCallback(() => {
    switch (props.section) {
      case "authentication":
        return 0;
      case "online-registration":
        return 1;
      default:
        return -1;
    }
  }, [props.section]);

  const _currentTitle = useCallback(() => {
    switch (props.section) {
      case "authentication":
        return "Authentication";
      case "online-registration":
        return "Online Registration";
      default:
        return "";
    }
  }, [props.section]);

  const handleShowPopper = useCallback(() => {
    setOpen(open => !open);
  }, []);

  function handleClickTitle() {
    if (props.section === "online-registration") {
      handleShowPopper();
    }
  }

  return (
    <div className={classes.wrapper}>
      <Header
        title={_currentTitle()}
        onBack={() => history.goBack()}
        onClickTitle={handleClickTitle}
        onToggleLang={() => {}}
        cfg={props.cfg}
      />

      <Hidden smDown>
        <LeftStepper steps={steps} activeStep={_currentStep()} cfg={props.cfg}/>
      </Hidden>

      <div className={classes.container}>
        <div className={classes.contentContainer}>{props.children}</div>
      </div>

      <Hidden smUp>
        <Popper
          open={open}
          anchorEl={ref.current}
          placement="bottom"
          arrowDirection="top"
          style={{
            marginTop: "10px"
          }}
          onOk={handleShowPopper}
        />
      </Hidden>
    </div>
  );
};

export default memo(FindMyPolicy);
