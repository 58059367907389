import React, {useEffect, useState, useRef} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import QbeLogo from "../../assets/QBE-logo-on-light.png";
import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import {makeStyles} from "@material-ui/core/styles";
import useConfig from "../qnect-ui/qnect-config";
import NeedHelpDialog from "../custom/find-my-policy/verify-need-help-dialog";
import {
    _clearState,
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _setLang,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {StyledModalDiv} from "../login-style";
import styled from "styled-components";
import {_getUser, _resendVerificationEmail} from "../../store/user/service";
import * as TagManager from "react-gtm-module";

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        background: "rgb(246,248,250)",
        minHeight: "calc(100vh - 64px)",
        [theme.breakpoints.down("sm")]: {
            minHeight: "calc(100vh - 87px)",
        },
        [theme.breakpoints.up("lg")]: {},
        whiteSpace: "pre-wrap"
    },
    topBg: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("md")]: {
            height: "432px"
        }
    },
    topRow: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            padding: "0 12px",
            alignItems: "center",
            borderBottom: "1px solid #E1E4E8"
        },
        [theme.breakpoints.up("md")]: {
            alignItems: "flex-end",
            height: "75px",
            padding: "0 30px 0"
        }
    },
    logo: {
        [theme.breakpoints.down("sm")]: {
            width: "70px"
        },
        [theme.breakpoints.up("md")]: {
            width: "122px",
            height: "35px"
        }
    },
    languageSelect: {
        color: "#1D4FBB",
        fontWeight: "bold",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px"
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "20px"
        }
    },
    main: {
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)",
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "40px 16px",
            height: "454px",
            width: "343px"
        },
        [theme.breakpoints.up("md")]: {
            margin: "-220px auto 0",
            padding: "40px 50px 38px",
            height: "450px",
            width: "93%"
        },
        [theme.breakpoints.up("lg")]: {
            width: "600px"
        }
    },
    title: {
        fontFamily: "Stag",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        textAlign: "center",
        color: "#003DA5",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "20px",
        },
        [theme.breakpoints.up("md")]: {
            marginBottom: "39px",
        }
    },
    message: {
        color: "#0A1F44",
        fontFamily: "Inter",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            "&:last-child": {
                marginTop: "4px"
            }
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "18px",
            "&:last-child": {
                marginTop: "8px"
            }
        }
    },
    button: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            margin: "30px auto 40px"
        },
        [theme.breakpoints.up("md")]: {
            margin: "40px auto",
            "& button": {
                width: "350px"
            }
        }
    },
    resend: {
        marginTop: 0,
        marginLeft: "4px",
        color: "#003da5",
        cursor: "pointer"
    },
    resend_disabled: {
        color: "#b0b7c3",
        cursor: "auto"
    },
    help: {
        color: "#003da5",
        cursor: "pointer"
    }
}));

const StyledQnectButton2 = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  background-color: #1d4fbb;
`;

const VerifyEmail = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [counter, setCounter] = useState(600);
    const [openHelp, setOpenHelp] = useState(false);
    const [timeInMinsSecs, setTimeInMinsSecs] = useState({});

    const cfg = useConfig(props.lang);
    const timer = useRef(null);

    const [sessionTimer, setSessionTimer] = useState(60 * 30); //30 minutes

    useEffect(() => {
        if (counter === 600) {
            setCounter(599);
            setTimeInMinsSecs(convertSecsToTime(599));
        }

        if (counter !== 600 && counter > 0) {
            timer.current = setTimeout(() =>
                setCounter(counter - 1), 1000);
            setTimeInMinsSecs(convertSecsToTime(counter));
        }
    }, [counter]);

    useEffect(() => {
        return () => clearTimeout(timer.current);
    }, []);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Verify your email to complete registration',
                vPath: '/registration/verify'
            }
        });
    }, []);

    useEffect(() => {
        if (sessionTimer > 0) {
            setTimeout(() => {
                setSessionTimer(sessionTimer - 1);
            }, 1000);
        } else {
            props.showModal({
                flat: true,
                content: <StyledModalDiv>
                    <div className="iconDiv">
                    </div>
                    <div className="title">Session Expired</div>
                    <div className="content"></div>
                    <div className="buttonDiv">
                        <StyledQnectButton2 onClick={() => {
                            props.hideModal();
                            props.clearState();
                            props.history.push('/login')
                        }}>Continue</StyledQnectButton2>
                    </div>
                </StyledModalDiv>
            })
        }
    }, [sessionTimer]);

    function handleResend() {
        if (counter === 0) {
            props.showSpinner();
            props.resendVerificationEmail(props.user.email, props.country).then(
                () => {
                    props.hideSpinner();
                },
                error => {
                    console.error(error.response.statusText);
                    props.hideSpinner();
                }
            );
            setCounter(600);
        }
    }

    function convertSecsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_mins = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_mins / 60);

        let divisor_for_secs = divisor_for_mins % 60;
        let seconds = Math.ceil(divisor_for_secs);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds < 10 ? `0`+seconds : seconds
        };
        return obj;
    }

    const getCountry = () => {
        if (props.country === "HKG") {
            return "Hong Kong";
        } else if (props.country === "SGP") {
            return "Singapore";
        }else if (props.country === "MYS") {
            return "Malaysia";
        }
    };

    const switchLanguage = () => {
        let newLang = props.lang === 'en' ? 'zh' : 'en';
        props.setLang(newLang);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Change-Language'
            }
        });
    }

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.topBg}>
                    <div className={classes.topRow}>
                        <img src={QbeLogo} className={classes.logo} alt="QBE"/>
                        <div className={classes.languageSelect}
                             onClick={() => {switchLanguage()}} style={{ visibility: (props.country === 'SGP' ? 'hidden' : 'visible')}}>
                            {props.lang === "en" ? cfg("labels.lang_zh") : cfg("labels.lang_en")}
                        </div>
                    </div>
                </div>
                <div className={classes.main}>
                    <div className={classes.title} style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                        {cfg('labels.verifyEmail.title')}
                    </div>
                    <div className={classes.message}>
                        { props.lang === 'en' ?
                            "Thank you for registering with QBE " + getCountry() + ".\n"+
                            "A verification email has been sent to your email:\n" :
                            cfg('labels.verifyEmail.message_zh')
                        }
                        <b>{props.user.email}</b>
                        <br/>
                        { cfg('labels.verifyEmail.message2') }
                    </div>
                    <ButtonWrapper className={classes.button}>
                        <QnectButton field="close" onClick={() => {
                            props.clearState();
                            history.push("/login")
                        }}></QnectButton>
                    </ButtonWrapper>
                    <div className={classes.message}>
                        { cfg('labels.verifyEmail.haventReceived') }
                    </div>
                    <div className={classes.message}>
                        {counter === 0 ? (
                            <span
                                className={[classes.message, classes.resend].join(" ")}
                                onClick={handleResend}
                            >
                                { cfg('labels.verifyEmail.resend') }
                            </span>
                        ) : (
                            <span
                                className={[
                                    classes.message,
                                    classes.resend,
                                    classes.resend_disabled
                                ].join(" ")}
                            >
                                { cfg('labels.verifyEmail.resend') } ({timeInMinsSecs.m}:{timeInMinsSecs.s})
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <NeedHelpDialog open={openHelp} country={props.country} cfg={cfg} onClose={() => setOpenHelp(false)}/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        country: _getCountry(state),
        lang: _getLang(state)
    }
}
const mapDispatchToProps = {
    setLang: _setLang,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    resendVerificationEmail: _resendVerificationEmail,
    showModal: _showModal,
    hideModal: _hideModal,
    clearState: _clearState
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));
