import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 52px 60px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 70px 60px",
        width: "630px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialog-paper": {
        paddingBottom: "60px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "16px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  wrapper: {
    margin: "40px auto 0",
    color: "#0a1f44",
    textAlign: "center",
    fontFamily: "Inter",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
      "&:nth-child(3)": {
        marginTop: "20px"
      }
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
      "&:nth-child(3)": {
        marginTop: "30px"
      }
    }
  }
}));

const StyledPhone = styled(Phone)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const StyledMail = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

export default function NeedHelpDialog({
  open = false,
  title,
  closable = false,
  onClose = null
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle title={title} closable={closable} onClose={onClose} />
        <DialogContent>
          <div className={classes.message}>
            Are you having trouble signing in to your account?
            <br />
            Please contact our customer service by the methods below.
          </div>

          <div className={classes.wrapper}>
            <StyledPhone />
            +852 2123-1234
          </div>

          <div className={classes.wrapper}>
            <StyledMail />
            customerservice@qbe.com
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

NeedHelpDialog.propTypes = {
  open: PropTypes.bool,
  closable: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func
};
