import React, {useState} from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import {cssIfElse} from '../../../common';
import {Link} from '../../typography';
import typographyStyles from '../../../common/typography-styles';
import SupplierDetails from './supplier-details';

const Details = styled.div`
    margin: 16px 0 0;
    padding: 0;
`;

const ExpandingView = styled.div`
    transition: max-height 700ms;
    overflow: hidden;

    ${cssIfElse('scIsExpand')`
        max-height: 100vh;
    ``
        max-height: 0;
    `}
`;

const StyledLink = styled(Link)`
    margin-top: 16px;
    text-decoration: underline;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'xSmall',
        defaultColor: 'tertiary90',
        defaultWeight: 'regular',
    })}
`;

export default function SupplierLabel({
    className,
    name,
    details,
    viewMoreText,
    viewLessText,
}) {
    const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
    const hasDetails = !isEmpty(details);

    return (
        <>
            {name}

            {hasDetails
                && (
                    <Details className={classnames('SupplierDetails', className)} >
                        <ExpandingView scIsExpand={isDetailsExpanded}>
                            <SupplierDetails {...details} />
                        </ExpandingView>

                        <StyledLink onClick={() => void setIsDetailsExpanded(!isDetailsExpanded)} >
                            {isDetailsExpanded ? viewLessText : viewMoreText}
                        </StyledLink>
                    </Details>
                )
            }
        </>
    );
}

SupplierLabel.propTypes = {
    className: propTypes.string,
    name: propTypes.string.isRequired,
    details: propTypes.shape({
        message: propTypes.string,
        contactDetails: propTypes.arrayOf(propTypes.string),
        combinationClaimInstructions: propTypes.string,
        specialInstructions: propTypes.string,
    }),
    viewMoreText: propTypes.string.isRequired,
    viewLessText: propTypes.string.isRequired,
};
