import classnames from 'classnames';
import get from 'lodash/get';
import propTypes from 'prop-types';
import React, {useContext} from 'react';

import ConfigContext from '../../common/config-context';
import IconAlert from '../icons/legacy/alert';
import IconCheckCircle from '../icons/legacy/check-circle';
import IconInfoCircle from '../icons/legacy/info-circle';

const icons = {
    info: IconInfoCircle,
    success: IconCheckCircle,
    warning: IconAlert,
    error: IconAlert,
};

const colors = {
    info: 'primary',
    success: 'success',
    warning: 'warning',
    error: 'error',
};

export default function AlertIcon({
    className,
    alertLevel = 'info',
    size = 48,
}) {
    const Icon = icons[alertLevel] || icons.info;
    const color = colors[alertLevel] || colors.info;
    const description = get(useContext(ConfigContext), ['alertLevels', alertLevel]);

    return (
        <Icon
            className={classnames('AlertIcon', className)}
            ariaLabel={description}
            color={color}
            size={size}
        />
    );
}

AlertIcon.propTypes = {
    className: propTypes.string,
    alertLevel: propTypes.oneOf(['info', 'success', 'warning', 'error']),
    size: propTypes.oneOfType([propTypes.number, propTypes.string]),
};
