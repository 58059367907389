import React from 'react';
import recurringIcon from './recurring-icon.png';

import styles from './style/qr-code.module.css';

const ResetQrCode = (props) => {
    const {onResetTime} = props;

    return (
        <div className={styles.reGenerateWrapper}>
            <button type='button'
                    onClick={onResetTime}
                    className={styles.reGenerateButton}
            >
                <span className={styles.reGenerateButtonText}>Click to reload QR code <img className={styles.recurringIcon} src={recurringIcon} alt={"QR"} /> </span>
            </button>
        </div>
    );
}

export default ResetQrCode;