import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "./dialog";
import DialogTitle from "./dialog-title";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 52px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 58px 52px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  row: {
    color: "#0a1f44",
    textAlign: "center",
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        marginTop: "40px"
      },
      margin: "20px auto 0",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      "&:first-child": {
        marginTop: "20px"
      },
      margin: "30px auto 0",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  icon: {
    width: "24px",
    height: "24px",
    marginRight: "4px"
  }
}));

export default function PopupDialogue({
  open = false,
  onClose,
  title,
  children,
  cfg,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose}>
      <DialogTitle title={cfg(title)} onClose={onClose}  lang={lang}/>
      <DialogContent>
        { children }
      </DialogContent>
    </Dialog>
  );
}

PopupDialogue.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node
};
