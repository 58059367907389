import React from 'react';

import BaseIcon from '../../common/base-icon';

export default function NavigationIconClose(props) {
    return (
        <BaseIcon {...props}>
            <path fill="currentColor" fillRule="evenodd" transform="translate(5 5)" d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" />
        </BaseIcon>
    );
}
