import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import {ReactComponent as Motor} from "../../assets/login/icon_decorative_motor.svg";
import {ReactComponent as Phone} from "../../assets/login/Phone_sharp.svg";
import {ReactComponent as Mail} from "../../assets/login/Mail_sharp.svg";
import {ReactComponent as Renew} from "../../assets/policy-details/icon_renew.svg";
import {ReactComponent as Claim} from "../../assets/policy-details/icon_claim.svg";
import {ReactComponent as AutoRenewal} from "../../assets/policy-details/icon_manage_autorenewal.svg";
import {ReactComponent as Arrow} from "../../assets/policy-details/icon_arrow_down.svg";
import {ReactComponent as Money} from "../../assets/Money.svg";
import OvalSpeech from "../custom/policy-detail/oval-speech";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import BlankImage from "../../assets/profile/avatar_image_placeholder.svg";
import PropTypes from "prop-types";
import LogoutDialog from "../custom/profile/logout-dialog";
import FileClaimDialog from "../custom/policy-detail/file-claim-dialog";
import LearnMoreDialog from "../custom/portfolio/learn-more-dialog";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    _getPolicy,
    _getFileClaimsUrl
} from "../../store/policy/service";
import {
    _getCountry, _getLang, _getPortfolioToken,
    _hideModal, _hideSpinner, _showError, _showModal, _showSpinner
} from "../../store/system/service";
import {_getPortfolioPolicy, _getUser, _preparePayment, _prepareRenewal} from "../../store/user/service";
import useConfig from "../qnect-ui/qnect-config";
import FileClaimInvalidDialog from "../custom/policy-detail/file-claim-invalid-dialog";
import {_storePolicyPayment} from "../../store/policy-payment/service";
import QnectButton from "../qnect-ui/qnect-button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import WorkerInjuryIcon from "../../assets/portfolio/icon_work_injury.svg";
import EmployerIcon from "../../assets/portfolio/icon_employer.svg";
import BizIcon from "../../assets/portfolio/icon_BIZ.svg";
import BoilerIcon from "../../assets/portfolio/icon_boiler_explosion.svg";
import DirectorIcon from "../../assets/portfolio/icon_directors_officers.svg";
import ContractorIcon from "../../assets/portfolio/icon_contractor_all_risk.svg";
import ContractorPlantIcon from "../../assets/portfolio/icon_Contractors_Plant_Eq.svg";
import ErectionIcon from "../../assets/portfolio/icon_erection_all_risk.svg";
import HomeIcon from "../../assets/portfolio/icon_home_contents.svg";
import DmhIcon from "../../assets/portfolio/icon_decorative_dmh.svg";
import MotorIcon from "../../assets/portfolio/icon_decorative_motor.svg";
import CommercialFireIcon from "../../assets/portfolio/icon_commercial_fire.svg";
import FidelityIcon from "../../assets/portfolio/icon_fidelity_gurantee.svg";
import GeneralPropIcon from "../../assets/portfolio/icon_general_property.svg";
import GolferIcon from "../../assets/portfolio/icon_golfers.svg";
import GroupMedicalIcon from "../../assets/portfolio/icon_group_medical.svg";
import HouseHolderIcon from "../../assets/portfolio/icon_householder.svg";
import ProfIndemnityIcon from "../../assets/portfolio/icon_professional_indemnity.svg";
import IndustrialIcon from "../../assets/portfolio/icon_industrial_risks.svg";
import MachineryIcon from "../../assets/portfolio/icon_machinery_breakdown.svg";
import FreightIcon from "../../assets/portfolio/icon_frieght_forwarder.svg";
import PersonalAccidentIcon from "../../assets/portfolio/icon_personal_accident.svg";
import BroadformIcon from "../../assets/portfolio/icon_broadform_liability.svg";
import TravelIcon from "../../assets/portfolio/icon_travel.svg";
import TradeCreditIcon from "../../assets/portfolio/icon_trade_credit.svg";
import GuaranteeBondIcon from "../../assets/portfolio/icon_guarantee_bond.svg";
import AviationIcon from "../../assets/portfolio/icon_aviation.svg";
import MedicalMalpracticeIcon from "../../assets/portfolio/icon_medical_malpractice.svg";
import MarineCargoIcon from "../../assets/portfolio/icon_marine_cargo.svg";
import SpecialRiskIcon from "../../assets/portfolio/icon_special_risk.svg";
import BurglaryIcon from "../../assets/portfolio/BUR.svg";
import JewelleryIcon from "../../assets/portfolio/JBK.svg";
import GenericIcon from "../../assets/portfolio/icon_generic.svg";
import * as TagManager from "react-gtm-module";
import {_sendLog} from "../../utils/utility";
import {OPTIN} from "../auto-renewal/auto-renewal-constants";
import AutoRenewalErrorPopup from "../auto-renewal/auto-renewal-error-popup";

const StyledModalDiv = styled.div`
    width: 504px;
    text-align: center;
    
    div.title {
        font-family: Stag-Medium;
        font-size: 24px;
        color: #003DA5;
        margin-top: 40px;
    }
    
    div.content {
        margin-top: 41px;
        font-family: Inter-Regular;
        font-size: 18px;
    }
    
    div.buttonDiv {
        margin-top: 40px;
        margin-left: 161px;
        margin-right: 163px;
    }

`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
`;

const StyledMenu = withStyles({
    paper: {
        boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        width: "232px",
        marginTop: "4px",
        "& .MuiList-padding": {
            padding: 0
        }
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left"
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        height: "64px",
        borderRadius: "10px",
        paddingLeft: "20px",
        "&:focus": {
            backgroundColor: "#fff"
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white
            }
        },
        "& .MuiListItemText-root": {
            marginLeft: "12px"
        }
    }
}))(MenuItem);

const useStyles = makeStyles(theme => ({
    contactWrapper: {
        width: "100%",
        borderRadius: "20px",
        boxShadow: "0 0 60px 20px rgba(0, 0, 0, 0.06)",
        background: "#fff",

        [theme.breakpoints.down("sm")]: {
            padding: "20px",
            // height: "208px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: "20px",
            maxHeight: "368px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "30px 40px 25px",
            maxHeight: "289px",
            display: "flex"
        }
    },
    contactSimpleWrapper: {
        width: "100%",
        borderRadius: "20px",
        boxShadow: "0 0 60px 20px rgba(0, 0, 0, 0.06)",
        background: "#fff",
        [theme.breakpoints.down("md")]: {
            padding: "20px"
        },
        [theme.breakpoints.up("lg")]: {
            padding: "30px 40px 25px",
            display: "flex"
        }
    },
    contactLeft: {
        [theme.breakpoints.between("sm", "md")]: {
            display: "flex",
            paddingBottom: "20px"
        },
        [theme.breakpoints.up("lg")]: {
            flex: 1.1,
            display: "flex"
        }
    },
    contactRight: {
        [theme.breakpoints.between("sm", "md")]: {
            paddingTop: "20px",
            display: "flex"
        },
        [theme.breakpoints.up("lg")]: {
            flex: 1,
            marginLeft: "30px"
        }
    },
    policyIcon: {
        [theme.breakpoints.down("sm")]: {
            width: "36px",
            marginRight: "12px"
        },
        [theme.breakpoints.up("sm")]: {
            flexShrink: 0,
            marginTop: "5px",
            marginRight: "20px"
        },
        "& img": {
            maxWidth: '100%'
        }
    },
    dflex: {
        display: "flex",
        alignItems: "center"
    },
    contactTitle: {
        fontFamily: "Stag",
        fontWeight: "500",
        lineHeight: "28px",
        color: "#003da5",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "12px",
            fontSize: "20px",
            letterSpacing: "0px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginBottom: "8px",
            fontSize: "24px",
            letterSpacing: "1px",
            lineHeight: "32px"
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "10px",
            fontSize: "24px",
            letterSpacing: "1px"
        }
    },
    contactSubTitle: {
        marginBottom: "8px",
        fontSize: "16px",
        lineHeight: "20px",
        fontFamily: "Inter",
        color: "#8a94a6",
        [theme.breakpoints.down("sm")]: {
            "&:nth-child(2)": {
                marginBottom: "20px"
            }
        },
        [theme.breakpoints.up("lg")]: {
            "&:nth-child(4)": {
                marginBottom: "20px"
            }
        },
        whiteSpace: "pre-wrap"
    },
    policyStatus: {
        [theme.breakpoints.up("lg")]: {
            fontWeight: 600,
            color: "#0a1f44"
        }
    },
    policyStatusActive:{
        [theme.breakpoints.down('sm')]:{
            color: "#11ac56"
        }
    },
    relative: {
        position: "relative",
        [theme.breakpoints.between("sm", "md")]: {
            flex: 1
        }
    },
    selectBtn: {
        height: "56px",
        width: "222px",
        border: "1px solid #1D4FBB",
        borderRadius: "35px",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        paddingLeft: "30px",
        paddingRight: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        lineHeight: "54px",
        color: "#1D4FBB",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 500
    },
    contactName: {
        marginBottom: "12px",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "600",
        fontFamily: "Inter",
        color: "#0a1f44",
        [theme.breakpoints.between("sm", "md")]: {},
        [theme.breakpoints.up("lg")]: {}
    },
    contactWay: {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        lineHeight: "20px",
        fontFamily: "Inter",
        color: "#0a1f44",
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: "8px"
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "12px"
        }
    },
    contactWayInner: {
        display: "flex",
        alignItems: "center",
        "&:last-child": {
            [theme.breakpoints.between("sm", "md")]: {
                marginLeft: "20px"
            },
            [theme.breakpoints.up("lg")]: {
                marginLeft: "24px"
            }
        },
        "& a": {
            color: "black"
        }
    },
    avatarWrapper: {
        borderRadius: "50%",
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
        [theme.breakpoints.between("sm", "md")]: {
            marginRight: "20px",
            width: "50px",
            height: "50px"
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "32px",
            marginLeft: "10px",
            width: "80px",
            height: "80px"
        }
    },
    avatar: {
        borderRadius: "50%",
        [theme.breakpoints.between("sm", "md")]: {
            width: "42.14px",
            height: "42.14px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "67.43px",
            height: "67.43px"
        }
    }
}));

const PhoneIcon = styled(Phone)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const MailIcon = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const MoneyIcon = styled(Money)`
  width: 18px;
  height: 18px;
`;

const StyledArrow = styled(Arrow)`
  transition: all 0.3s ease-in;
  transform: ${({open}) => (open ? "rotate(180deg)" : "")};
`;

const AgentWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: -14px;
  display: flex;
`;


const PolicyHeader = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [type, setType] = useState();
    const [claimsUrl, setClaimsUrl] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const [fileClaimOpen, setFileClaimOpen] = useState(false);
    const [fileClaimInvalidOpen, setFileClaimInvalidOpen] = useState(false);

    const [manageAutoRenewalErrorMsg, setManageAutoRenewalErrorMsg] = useState('');
    const [showManageAutoRenewalErrorPopup, setShowManageAutoRenewalErrorPopup] = useState(false);

    const cfg = useConfig(props.lang);

    useEffect(() => {

        if (type === 'CLAIM') {
            props.getFileClaimsUrl(props.policy.policyNumber, props.policy.statusCode, props.policy.country).then((response) => {
                if (response.isValid === 'true') {
                    setClaimsUrl(response.url);
                    setFileClaimOpen(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/policy',
                            popupTitle: 'You are about to leave the customer portal'
                        }
                    });
                } else {
                    setFileClaimInvalidOpen(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/policy',
                            popupTitle: 'Cannot proceed to file the claim'
                        }
                    });
                }
            });
        } else if (type === 'PAY') {
            props.showSpinner();
            _sendLog(' policy-header.js - calling preparePayment(), policyNumber = ' + props.policy.policyNumber + ', transactionNumber = ' + props.policy.transactionNumber + ', effectiveDate = '+ props.policy.effectiveDate + ', premium = ' + props.policy.paymentOutstanding ? props.policy.paymentOutstanding : props.policy.premiumAmount + ', country = ' + props.policy.country,'info');
            props.preparePayment(props.policy.policyNumber, props.policy.transactionNumber,
                props.policy.effectiveDate,
                props.policy.paymentOutstanding ? props.policy.paymentOutstanding : props.policy.premiumAmount,
                props.policy.country
            ).then((resp) => {
                props.hideSpinner();
                props.storePolicyPayment(resp.policyPayment);
                history.push("/payment-detail");
            }, (error) => {
                console.error(error);
                _sendLog(' policy-header.js - preparePayment(): exception:\n\n' + JSON.stringify(error));
                props.hideSpinner();
                history.push("/policy");
                props.showError(['Preparing payment has failed']);
                setType('');
            })
        } else if (type === 'RENEW') {
            props.showSpinner();
            _sendLog(' policy-header.js - calling prepareRenewal(), policyNumber = ' + props.policy.policyNumber + ', transactionNumber = ' + props.policy.transactionNumber + ', effectiveDate = '+ props.policy.effectiveDate + ', premium = ' + props.policy.paymentOutstanding ? props.policy.paymentOutstanding : props.policy.premiumAmount + ', country = ' + props.policy.country,'info');
            props.prepareRenewal(props.policy.policyNumber, props.policy.transactionNumber, props.policy.effectiveDate, props.policy.paymentOutstanding ? props.policy.paymentOutstanding : props.policy.premiumAmount, props.policy.country).then((resp) => {
                props.hideSpinner();
                if (resp.policyPayment?.errorMessage) {
                    props.showError([resp.policyPayment.errorMessage]);
                    _sendLog(' policy-header.js - prepareRenew() - prepareRenewal(): errorMessage:\n\n' + resp.policyPayment.errorMessage);
                }
                else {
                    props.storePolicyPayment(resp.policyPayment);
                    history.push("/renewal-notice");
                }
            }, (error) => {
                console.error(error);
                _sendLog(' policy-header.js - prepareRenewal(): exception:\n\n' + JSON.stringify(error));
                props.hideSpinner();
                history.push("/policy");
                props.showError(['Preparing renewal has failed']);
                setType('');
            })
        } else if (type === 'AUTO-RENEWAL') {
            props.showSpinner();
            if (props.policy.validToManageAutoRenewal) {
                history.push("/auto-renewal/manage-auto-renewal");
            } else {
                showManageAutoRenewalError();
                props.hideSpinner();
                history.push("/policy");
                setType('');
            }
        }
    }, [type]);

    const showManageAutoRenewalError = () => {
        if (!(props.policy.statusCode === "IF" || props.policy.statusCode === "AR")) {
            setManageAutoRenewalErrorMsg(cfg("labels.manageAutoRenewalErrorMsgIFAR"));
            setShowManageAutoRenewalErrorPopup(true);
        } else if (props.policy.errorType === "renewalCycle") {
            setManageAutoRenewalErrorMsg(cfg("labels.manageAutoRenewalErrorMsgRenewalCycle"));
            setShowManageAutoRenewalErrorPopup(true);
        }
    }

    const retrieveIcon = () => {
        if (props.policy.productCode === 'WCA') {
            return props.policy.country === 'SGP' ? <WorkerInjuryIcon/> : <EmployerIcon/>;
        }

        switch (props.policy.productCode) {
            case 'BIZ':
            case 'BPK':
            case 'OFF':
                return BizIcon;
            case 'BXP':
                return BoilerIcon;
            case 'DOL':
                return DirectorIcon;
            case 'CAR':
                return ContractorIcon;
            case 'CPM':
            case 'PGL':
                return ContractorPlantIcon;
            case 'EAR':
                return ErectionIcon;
            case 'HPK':
                return HomeIcon;
            case 'DMH':
                return DmhIcon;
            case 'MVA':
                return MotorIcon;
            case 'EPP':
                return EmployerIcon;
            case 'FFF':
                return CommercialFireIcon;
            case 'FGS':
                return FidelityIcon;
            case 'GEN':
                return GeneralPropIcon;
            case 'GOF':
                return GolferIcon;
            case 'GMB':
                return GroupMedicalIcon;
            case 'HHH':
                return HouseHolderIcon;
            case 'ICT':
            case 'PID':
                return ProfIndemnityIcon;
            case 'ISR':
                return IndustrialIcon;
            case 'MBD':
                return MachineryIcon;
            case 'MFF':
                return FreightIcon;
            case 'PAD':
                return PersonalAccidentIcon;
            case 'PLB':
                return BroadformIcon;
            case 'PIT':
            case 'PTR':
                return TravelIcon;
            case 'TRI':
                return TradeCreditIcon;
            case 'PBG':
            case 'PNI':
            case 'SUR':
                return GuaranteeBondIcon;
            case 'PPT':
            case 'PSA':
            case 'AVI':
                return AviationIcon;
            case 'MED':
            case 'PAM':
            case 'PAN':
                return MedicalMalpracticeIcon;
            case 'CAN':
            case 'MCA':
            case 'MCH':
            case 'MLL':
            case 'MPC':
            case 'OCN':
            case 'OCP':
                return MarineCargoIcon;
            case 'SPK':
                return SpecialRiskIcon;
            case 'WCD':
                return WorkerInjuryIcon;
            case 'BUR':
                return BurglaryIcon;
            case 'JBK':
                return JewelleryIcon;
            default:
                return GenericIcon;
        }
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const _renderName = () => {
        return cfg('policy.heading.managePolicy');
    }

    /*const canPay = () => {
        if (props.policy
            && props.policy?.policyNumber
            && props.policy?.statusCode === 'IF'
            && props.policy?.country === 'HKG'
            && props.policy?.paymentFlag === 'Y') {
            return true;
        } else {
            return false;
        }
    }
*/
    const canRenew = () => {
        if(props.country === "MYS"){
            if(!cfg('productsForRenew.' + props.country).includes(props.policy.catalogueCode)) {
                return false;
            }
        }
        if ((props.country === 'SGP' || props.country === 'HKG' || props.country === 'HGI' || props.country === 'MYS')&&
            ((props.policy.origStatus === "AR" || props.policy.origStatus === "FR" || props.policy.origStatus === "SR" || props.policy.origStatus === "PP")
            && (props.policy.renewalFlag === 'Y'))) {
            return true;
        } else {
            return false;
        }
    }

    const showPolicyStatus = () => {
        let statusToUse = props.policy.origStatus ? props.policy.origStatus : props.policy.statusCode;

        if (cfg('validPolicyStatusCodes.' + props.policy.country + '.expiresSoon').includes(statusToUse)) {
            return cfg('labels.portfolio.statusExpire') ;
        } else if (cfg('validPolicyStatusCodes.' + props.policy.country + '.active').includes(statusToUse)) {
            return cfg('labels.portfolio.statusActive');
        } else if (cfg('validPolicyStatusCodes.' + props.policy.country + '.inactive').includes(statusToUse)) {
            return cfg('labels.portfolio.statusInactive');
        } else {
            return "Invalid";
        }
    }

    const getAgentName = () => {
        if (props.country === 'HKG') {
            if (props.policy?.agentDetails?.agentType === 'H') {
                return cfg('policy.heading.hangSengName');
            } else if (props.policy?.agentDetails?.agentType === 'HD') {
                return cfg('policy.heading.hangSengDirect');
            } else if (props.policy?.agentDetails?.agentType === 'A' || props.policy?.agentDetails?.agentType === 'M') {
                return props.policy?.agentDetails?.agentName;
            } else if (props.policy?.agentDetails?.agentType === 'C') {
                return cfg('policy.heading.ccba');
            } else if (props.policy?.agentDetails?.agentType === 'MQ') {
                return cfg('policy.heading.manulifeQbeAgent');
            } else {
                return cfg('policy.heading.qbeDefaultIntermediary');
            }
        } else if (props.country === 'SGP') {
            return props.policy?.agentDetails?.agentName ? props.policy?.agentDetails?.agentName : "QBE";
        } else if (props.country === 'MYS') {
            return props.policy?.agentDetails?.agentName ? props.policy?.agentDetails?.agentName : "QBE";
        }
    }

    const getContactDetails = () => {
        if (props.country === 'HKG') {
            if (props.policy?.agentDetails?.agentType === 'H' || props.policy?.agentDetails?.agentType === 'HD') {
                return <>
                    <div className={classes.contactWayInner}>
                        <PhoneIcon/>
                        <a href={'tel:' + cfg('genericLabels.phoneHref.' + props.country)}>
                            {props.cfg('genericLabels.phone.' + props.country)}</a>
                    </div>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:cs.gihk@qbe.com'}>cs.gihk@qbe.com</a>
                    </div>
                </>
            } else if (props.policy?.agentDetails?.agentType === 'A') {
                return null;
            } else if (props.policy?.agentDetails?.agentType === 'M') {
                return <>
                    <div className={classes.contactWayInner}>
                        <PhoneIcon/>
                        <a href={'tel:' + (props.policy?.agentDetails?.agentPhoneNumber
                            ? '+852' + props.policy?.agentDetails?.agentPhoneNumber : props.cfg('genericLabels.phoneHref.MANU'))}>
                            {(props.policy?.agentDetails?.agentPhoneNumber
                                ? ('+852' + props.policy?.agentDetails?.agentPhoneNumber) : props.cfg('genericLabels.phone.MANU'))}</a>
                    </div>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + (props.policy?.agentDetails?.agentEmailAddress
                            ? props.policy?.agentDetails?.agentEmailAddress : props.cfg('genericLabels.email.HKG-CS'))}>
                            {(props.policy?.agentDetails?.agentEmailAddress
                                ? props.policy?.agentDetails?.agentEmailAddress : props.cfg('genericLabels.email.HKG-CS'))}</a>
                    </div>
                </>
            } else if (props.policy?.agentDetails?.agentType === 'MQ') {
                return <>
                    <div className={classes.contactWayInner}>
                        <PhoneIcon/>
                        <a href={'tel:' + cfg('genericLabels.phoneHref.MANU')}>
                            {props.cfg('genericLabels.phone.MANU')}</a>
                    </div>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + cfg('genericLabels.email.HKG-CS')}>{cfg('genericLabels.email.HKG-CS')}</a>
                    </div>
                </>
            } else {
                return <>
                    <div className={classes.contactWayInner}>
                        <PhoneIcon/>
                        <a href={'tel:' + cfg('genericLabels.phoneHref.HKG-CS')}>
                            {props.cfg('genericLabels.phone.HKG-CS')}</a>
                    </div>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + props.cfg('genericLabels.email.HKG-CS')}>
                            {props.cfg('genericLabels.email.HKG-CS')}</a>
                    </div>
                </>
            }
        } else if (props.country === 'SGP') {
            if (props.policy?.agentDetails?.agentPhoneNumber === '') {
                return <>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + (props.policy?.agentDetails?.agentEmailAddress
                            ? props.policy?.agentDetails?.agentEmailAddress : props.cfg('genericLabels.email.' + props.country))}>
                            {(props.policy?.agentDetails?.agentEmailAddress
                                ? props.policy?.agentDetails?.agentEmailAddress : props.cfg('genericLabels.email.' + props.country))}</a>
                    </div>
                    <div className={classes.contactWayInner}></div>
                </>
            } else {
                return <>
                    <div className={classes.contactWayInner}>
                        <PhoneIcon/>
                        <a href={'tel:' + ('+65' + props.policy?.agentDetails?.agentPhoneNumber)}>
                            {('+65' + props.policy?.agentDetails?.agentPhoneNumber)}</a>
                    </div>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + (props.policy?.agentDetails?.agentEmailAddress
                            ? props.policy?.agentDetails?.agentEmailAddress : props.cfg('genericLabels.email.' + props.country))}>
                            {(props.policy?.agentDetails?.agentEmailAddress
                                ? props.policy?.agentDetails?.agentEmailAddress : props.cfg('genericLabels.email.' + props.country))}</a>
                    </div>
                </>
            }
        } else if (props.country === 'MYS') {
            if (props.policy?.agentDetails?.agentPhoneNumber === '') {
                return <>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + (props.policy?.agentDetails?.agentEmailAddress
                            ? props.policy?.agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}>
                            {(props.policy?.agentDetails?.agentEmailAddress
                                ? props.policy?.agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}</a>
                    </div>
                    <div className={classes.contactWayInner}></div>
                </>
            } else {
                return <>
                    <div className={classes.contactWayInner}>
                        <MailIcon/>
                        <a href={'mailto:' + (props.policy?.agentDetails?.agentEmailAddress
                            ? props.policy?.agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}>
                            {(props.policy?.agentDetails?.agentEmailAddress
                                ? props.policy?.agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}</a>
                    </div>
                    <div className={classes.contactWayInner}>
                        <PhoneIcon/>
                        <a href={'tel:' + ('+60' + props.policy?.agentDetails?.agentPhoneNumber)}>
                            {('+60' + props.policy?.agentDetails?.agentPhoneNumber)}</a>
                    </div>
                </>
            }
        }
    }

        const displayThis = () => {
        return !(props.country === 'HKG' && (props.policy?.agentDetails?.agentType === 'HD' ||
            props.policy?.agentDetails?.agentType === 'NA' || props.policy?.agentDetails?.agentType === 'Q'));
    }

    const setDisplayStyle = () => {
        if (props.country === 'SGP' && props.policy?.agentDetails?.agentIntermediary === '') {
            return {display: 'none'}
        }
        return {};
    }

    return (
        <React.Fragment>
            {props.noLogin ? (
                <div
                    className={[classes.contactSimpleWrapper, props.className].join(" ")}
                >
                    <div className={classes.contactLeft}>
                        <div className={classes.policyIcon}>
                            <img src={retrieveIcon()}/>
                        </div>
                        <div>
                            <div className={classes.contactTitle}>
                                {props.policy.policyType}
                            </div>
                            <div className={classes.contactSubTitle}>
                                {props.cfg("policy.policyNumber")} {props.policy.policyNumber}
                            </div>
                            <div className={classes.contactSubTitle}>
                                {props.cfg("policy.policyStatusMsg")}{" "}
                                <span className={classes.policyStatus}>
                              {showPolicyStatus()}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={[classes.contactWrapper, props.className].join(" ")}>
                    <div className={classes.contactLeft}>
                        <Hidden smDown>
                            <div className={classes.policyIcon}>
                                <img src={retrieveIcon()}/>
                            </div>
                        </Hidden>

                        <Hidden smUp>
                            <div className={[classes.contactTitle, classes.dflex].join(" ")}>
                                <div className={classes.policyIcon}>
                                    <img src={retrieveIcon()}/>
                                </div>
                                {cfg('productType.' + props.policy.country + "." + props.policy.catalogueCode).startsWith('productType.') ?
                                    cfg('productType.GENPRD') : cfg('productType.' + props.policy.country + "." + props.policy.catalogueCode)}
                            </div>
                        </Hidden>
                        <div className={classes.relative}>
                            <Hidden smDown>
                                <div className={classes.contactTitle}>
                                    {cfg('productType.' + props.policy.country + "." + props.policy.catalogueCode).startsWith('productType.') ?
                                        cfg('productType.GENPRD') : cfg('productType.' + props.policy.country + "." + props.policy.catalogueCode)}
                                </div>
                            </Hidden>
                            <div className={classes.contactSubTitle}>
                                {props.cfg('policy.policyNumber')} {props.policy.policyNumber}
                            </div>
                            <div className={classes.contactSubTitle}>
                                {props.cfg('policy.policyStatusMsg')}{" "}
                                <span className={[
                                    classes.policyStatus,
                                    cfg('validPolicyStatusCodes.' + props.policy.country + '.active').includes(props.policy.statusCode)&&classes.policyStatusActive
                                ].join(' ')}>
                                {showPolicyStatus()}
                            </span>
                            </div>
                            { props.policy.eligibleForAutoRenewal &&
                                <div className={classes.contactSubTitle}>
                                    <span>{cfg('labels.autoRenewal.statusTitleDisplay')} </span>
                                    {
                                        props.policy.autoRenewalStatus === OPTIN ?
                                        <span className="statusOn">{cfg('labels.autoRenewal.statusOnDisplay')}</span> :
                                        <span className="statusOff">{cfg('labels.autoRenewal.statusOffDisplay')}</span>
                                    }
                                </div>
                            }
                            <Hidden only={'md'}>
                                <div className={classes.selectBtn} onClick={handleClick}>
                                    <span style={props.lang === 'zh' ? { marginLeft: '32px', fontWeight: 'bold'} : {}}>
                                        {_renderName()}
                                    </span>
                                    <StyledArrow open={Boolean(anchorEl)}/>
                                </div>
                            </Hidden>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {canRenew() &&
                                <StyledMenuItem onClick={() => {
                                    setType("RENEW");
                                    handleClose();
                                }}>
                                    <Renew/>
                                    <ListItemText primary="Renew Policy"/>
                                </StyledMenuItem>
                                }
                                { props.policy.eligibleForAutoRenewal &&
                                    <StyledMenuItem onClick={() => {
                                        setType("AUTO-RENEWAL");
                                        handleClose();
                                    }}>
                                        <AutoRenewal className="icon-manage-autorenewal" />
                                        <ListItemText primary={cfg('policy.heading.manageAutoRenewal')}/>
                                    </StyledMenuItem>
                                }
                                <StyledMenuItem onClick={() => {
                                    setType("CLAIM");
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: 'Manage-Policy',
                                            eventCategory: 'Submit-a-claim'
                                        }
                                    });
                                    handleClose();
                                }}>
                                    <Claim/>
                                    <ListItemText primary={cfg('policy.heading.submitClaim')}/>
                                </StyledMenuItem>
                            </StyledMenu>
                        </div>
                        <Hidden smDown lgUp>
                            <div className={classes.selectBtn} onClick={handleClick}>
                                <span style={props.lang === 'zh' ? { marginLeft: '32px', fontWeight: 'bold'} : {}}>
                                    {_renderName()}
                                </span>
                                <StyledArrow open={Boolean(anchorEl)}/>
                            </div>
                        </Hidden>
                    </div>

                    <Hidden smDown lgUp>
                        <Divider orientation="horizontal" style={setDisplayStyle()}/>
                    </Hidden>

                    <Hidden mdDown>
                        <Divider orientation="vertical" flexItem style={setDisplayStyle()}/>
                    </Hidden>

                    <Hidden smDown>
                        <div className={classes.contactRight} style={setDisplayStyle()}>
                            <Hidden smDown lgUp>
                                <div className={classes.avatarWrapper}>
                                    <img
                                        className={classes.avatar}
                                        alt=""
                                        src={BlankImage}
                                    />
                                </div>
                            </Hidden>

                            <div>
                                { displayThis() &&
                                    <div className={classes.contactTitle}
                                         style={props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                                        {cfg('policy.heading.youAgentsContact' + (props.country === 'MYS' ? props.country : ''))}
                                    </div>
                                }
                                { props.country === 'SGP' &&
                                    <div className={classes.contactName}>
                                        {props.policy.agentDetails.agentIntermediary}
                                    </div>
                                }
                                <div className={classes.contactName}>
                                    {getAgentName()}
                                </div>
                                <div className={classes.contactWay}>
                                    {getContactDetails()}
                                </div>
                            </div>
                        </div>
                    </Hidden>

                    <Hidden mdDown>
                        <AgentWrapper style={setDisplayStyle()}>
                            <OvalSpeech device="desktop" placement="right" cfg={cfg}/>
                            <div className={classes.avatarWrapper}>
                                <img
                                    className={classes.avatar}
                                    alt=""
                                    src={BlankImage}
                                />
                            </div>
                        </AgentWrapper>
                    </Hidden>

                    <FileClaimDialog
                        open={fileClaimOpen}
                        onClose={() => () => {
                            setFileClaimOpen(false);
                            setType("");
                            setClaimsUrl("");
                        }}
                        onConfirm={() => {
                            window.open(claimsUrl);
                            setFileClaimOpen(false);
                            setType("");
                            setClaimsUrl("");
                        }}
                        cfg={props.cfg}
                    />

                    <FileClaimInvalidDialog
                        open={fileClaimInvalidOpen}
                        onClose={() => {
                            setFileClaimInvalidOpen(false);
                            setType("");
                            setClaimsUrl("");
                        }}
                        cfg={props.cfg}
                    />

                    <AutoRenewalErrorPopup open={showManageAutoRenewalErrorPopup}
                                      errorMessage = {manageAutoRenewalErrorMsg}
                                      hidePopUp = {() => setShowManageAutoRenewalErrorPopup(false)}/>
                </div>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        policy: _getPolicy(state),
        portfolioToken: _getPortfolioToken(state),
        lang: _getLang(state)
    };
}

const mapDispatchToProps = {
    showModal: _showModal,
    hideModal: _hideModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    preparePayment: _preparePayment,
    prepareRenewal: _prepareRenewal,
    getPortfolioPolicy: _getPortfolioPolicy,
    storePolicyPayment: _storePolicyPayment,
    getFileClaimsUrl: _getFileClaimsUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyHeader);
