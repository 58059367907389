import React from 'react';
import ManageAutoRenewal from "../components/auto-renewal/auto-renewal-manage";

const AutoRenewalManagePage = () => {
    return (
        <div>
            <ManageAutoRenewal/>
        </div>
    )
}

export default AutoRenewalManagePage;
