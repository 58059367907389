import React from 'react';
import OnlinePaymentFinish from "../components/online-payment/online-payment-finish";

const OnlinePaymentFinishPage = () => {
    return (
        <div>
            <OnlinePaymentFinish/>
        </div>
    )
}

export default OnlinePaymentFinishPage;
