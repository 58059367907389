import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {colorType} from '../../common/common-prop-types';
import typographyStyles from '../../common/typography-styles';

const sizes = [
    'normal',
    'small',
    'xSmall',
];

const weights = [
    'regular',
    'medium',
    'semiBold',
    'bold',
];

const Span = styled.span`
    ${typographyStyles({
        type: 'text',
        sizes,
        defaultSize: 'normal',
        weights,
        defaultWeight: 'regular',
        defaultColor: 'primary',
    })}
`;

export default function Text({
    className,
    size,
    weight,
    color,
    children,
    ...rest
}) {
    return (
        <Span
            {...rest}
            className={classnames('Text', className)}
            scSize={size}
            scWeight={weight}
            scColor={color}
        >
            {children}
        </Span>
    );
}

Text.propTypes = {
    className: propTypes.string,
    size: propTypes.oneOf(sizes),
    weight: propTypes.oneOf(weights),
    color: colorType,
    children: propTypes.node,
};
