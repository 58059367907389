import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import QnectButton from "../../qnect-ui/qnect-button";
import ButtonWrapper from "../button-wrapper";
import { ReactComponent as LeftIcon } from "../../../assets/registration/icon_find_my_policy_one.svg";
import { ReactComponent as RightIcon } from "../../../assets/registration/icon_find_my_policy_two.svg";
import { ReactComponent as Close } from "../../../assets/login/close_sharp.svg";
import { smDown, lgDown } from "../../../ui/common/breakpoints";
import {ReactComponent as Back} from "../../../assets/login/back_sharp.svg";

const StyledMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: "Inter";
  ${lgDown`
    width: 519px;
    margin: 20px auto 0;
  `}
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    width: 295px;
  `}
  white-space: pre-wrap;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  align-items: center;
  & > :first-child {
    margin-right: 60px;
    ${smDown`
      margin-right: 24px;
    `}
  }
  ${smDown`
    margin-top: 20px;
  `}
`;

const StyledIconDescription = styled.div`
  font-weight: 500;
  color: #0a1f44;
  font-family: Inter-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  ${smDown`
    font-size: 14px;
    line-height: 21px;
    width: 144px;
    & > :first-child {
      height: 105px;
      width: 139px;
    }
  `}
`;

const StyledTitle = styled.div`
  margin: 0 auto;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: #003da5;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Stag";
  ${smDown`
    font-size: 18px;
    line-height: 24px;
    width: 231px;
  `}
`;

const StyledCloseWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${smDown`
    width: 24px;
    height: 24px;
  `}
`;

const StyledClose = styled(Close)`
  width: 32px;
  height: 32px;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  margin-top: 44px;
  ${smDown`
      margin-top: 20px;
    `}
  & button {
    ${smDown`
        width: 180px !important;
        height: 70px !important;
        font-size: 20px !important;
        line-height: 28px !important;
      `}
  }
`;

const StyledDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      width: "720px",
      padding: "14px 76px 52px",
      [theme.breakpoints.down("md")]: {
        width: "596px",
        padding: "14px 14px 52px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "4px 15px 40px",
      },
    },
    "& .back": {
      [theme.breakpoints.down("sm")]: {
        width: "24px",
        height: "24px",
        marginRight: "12px"
      },
      [theme.breakpoints.up("sm")]: {
        width: "28px",
        height: "28px",
        marginRight: "16px",
        top: "34px"
      },
      cursor: "pointer",
      position: "absolute",
    }
  },
  paperWidthSm: {
    maxWidth: "none",
  }
}))(Dialog);

export default function FindMyPolicyDialog({ open, onClose, cfg, onBack, isRegistration = false }) {
  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle>
          { isRegistration && <Back className={"back"} onClick={onBack} /> }
          <StyledTitle>{ cfg('login.howToFindPolicy') }</StyledTitle>
          <StyledCloseWrapper onClick={onClose}>
            <StyledClose />
          </StyledCloseWrapper>
        </DialogTitle>
        <DialogContent>
          <StyledMessage>
            { cfg('login.howToFindPolicyNumberMsg') }
          </StyledMessage>
          <StyledIconContainer>
            <StyledIconDescription>
              <LeftIcon />
              <br />{ cfg('login.howToFindPolicyNumber1To7') }
            </StyledIconDescription>
            <StyledIconDescription>
              <RightIcon />
              <br />{ cfg('login.howToFindPolicyNumber2To6') }
            </StyledIconDescription>
          </StyledIconContainer>
        </DialogContent>
        <DialogActions>
          <StyledButtonWrapper>
            {  !isRegistration ?
                <QnectButton field="close" onClick={onClose}></QnectButton> :
                <QnectButton onClick={onBack}>{ cfg("registration.back") }</QnectButton>
            }
          </StyledButtonWrapper>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
