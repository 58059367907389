/**
 * Styled Components helper to create a grid that supports IE11 with a fake grid gap.
 *
 * @param {object}                options                    - (see below)
 * @param {number}                [options.rows]             - the number of rows
 * @param {string|number}         [options.rowGap=0]         - the gap between rows
 * @param {(string|number)[]}     [options.columnTemplate]   - an array of column sizes
 * @param {number}                [options.columns]          - the number of columns (ignored if columnTemplate is specified and not empty)
 * @param {string|number}         [options.columnGap=0]      - the gap between columns
 * @returns {*} styles to pass to styled components
 */
export function grid({
    rows,
    rowGap = 0,
    columns,
    columnGap = 0,
    columnTemplate = [],
}) {
    return [
        'display: -ms-grid;',
        'display: grid;',
        rows > 1
            ? [
                `-ms-grid-rows: (auto ${rowGap})[${rows - 1}] auto;`,
                `grid-row-gap: ${rowGap};`,
            ]
            : rows === 1
                ? '-ms-grid-rows: auto;'
                : undefined,
        columnTemplate.length > 1
            ? [
                `-ms-grid-columns: ${
                    columnTemplate
                        .reduce((a, b) => [...a, b, `${columnGap}`], [])
                        .slice(0, -1)
                        .join(' ')
                };`,
                `grid-template-columns: ${columnTemplate.join(' ')};`,
                `grid-column-gap: ${columnGap};`,
            ]
            : columns > 1
                ? [
                    `-ms-grid-columns: (1fr ${columnGap})[${columns - 1}] 1fr;`,
                    `grid-template-columns: repeat(${columns}, 1fr);`,
                    `grid-column-gap: ${columnGap};`,
                ]
                : '-ms-grid-columns: 1fr;',
    ];
}

/**
 * Styled Components helper for items inside a grid created using the grid helper.
 *
 * @param {object} options               - (see below)
 * @param {number} [options.row]         - the row to place the cell
 * @param {number} options.column        - the column to place the cell
 * @param {number} [options.colSpan]     - the number of columns to span
 * @returns {*} styles to pass to styled components
 */
export function gridCell({row, column, colSpan}) {
    return [
        row
            ? [
                `grid-row: ${row};`,
                `-ms-grid-row: ${(row * 2) - 1};`,
            ]
            : null,
        column
            ? `-ms-grid-column: ${(column * 2) - 1};`
            : null,
        column && colSpan
            ? [
                `grid-column: ${column} / span ${colSpan};`,
                `-ms-grid-column-span: ${(colSpan * 2) - 1};`,
            ]
            : column
                ? `grid-column: ${column};`
                : undefined,
    ];
}

/**
 * Generate inline styles for dynamically setting an element's grid row.
 *
 * NOTE: Not useful when the row is different at various breakpoints.
 *
 * @param {number} [row] - the grid row
 * @returns {{gridRow: number, msGridRow: number}|null} the inline styles
 */
export function gridRowInlineStyle(row) {
    return row
        ? {gridRow: row, msGridRow: (row * 2) - 1}
        : null;
}
