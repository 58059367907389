import React, {useRef, useState} from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { ReactComponent as QbeLogo } from "../../../assets/login/icon_logo.svg";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import {connect, useSelector} from "react-redux";
import {_getCountry, _getLang, _setLang} from "../../../store/system/service";

const MBackIconWrapper = styled.div`
  height: 42px;
  width: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackIcon = styled(Back)`
  height: 24px;
  width: 24px;
`;

const MTitle = styled.div`
  color: #003da5;
  font-family: Stag;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  margin-right: 13px;
`;

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    zIndex: "999",
    [theme.breakpoints.down("sm")]: {
      height: "44px",
      paddingRight: "17.18px",
      alignItems: "center",
      borderBottom: "1px solid #e1e4e8"
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "0",
      left: "0"
    },
    [theme.breakpoints.between("sm", "md")]: {
      alignItems: "center",
      padding: "0 24px",
      height: "78.13px"
    },
    [theme.breakpoints.up("lg")]: {
      alignItems: "flex-end",
      padding: "0 165px",
      height: "75px"
    }
  },
  language: {
    color: "#A7AEBB",
    fontSize: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.up("sm")]: {
      // marginTop: "-7.5px"
    }
  }
}));

const Header = (props) => {
    const classes = useStyles();
    const [lang, setLang] = useState( props.lang);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as ' + newLang);
    }

    const country = useSelector(state => _getCountry(state));

    return (
        <>
            <div className={classes.wrapper}>
                <Hidden smDown>
                    <a href="https://www.qbe.com/hk/en/about-qbe">
                    <QbeLogo/>
                    </a>
                </Hidden>

                <Hidden smUp>
                    <MBackIconWrapper onClick={props.onBack}>
                        <BackIcon/>
                    </MBackIconWrapper>
                </Hidden>

                <Hidden smUp implementation="css">
                    <MTitle onClick={props.onClickTitle}>
                        {props.title}
                    </MTitle>
                </Hidden>

                <div className={classes.language} style={{visibility: (country === 'SGP' ? 'hidden' : 'visible')}} onClick={() => switchLanguage()}>
                    {props.lang === "en" ? props.cfg("labels.lang_zh") : props.cfg("labels.lang_en")}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    setLang: _setLang
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
