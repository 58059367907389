import {css} from 'styled-components';
import isArray from 'lodash/isArray';
import result from 'lodash/result';

/**
 * Styled Components <code>if</code>
 *
 * If <code>test</code> is a function, the truthiness of the result of invoking
 * it with the component's props determines whether or not to include the
 * conditional CSS.
 *
 * If <code>test</code> is a string or array of strings, the truthiness of the
 * prop with that path determines whether or not to include the conditional
 * CSS.
 *
 * If <code>test</code> is neither a function, string nor array, its truthiness
 * determines whether or not to include the conditional CSS.
 *
 * @example <caption>Add visibility: hidden; if the isHidden prop is truthy</caption>
 *
 * ${cssIf('isHidden')`
 *     visibility: hidden;
 * `}
 *
 * @param {*} test - a function of props, prop name or any other value
 * @returns {Function} - a function of props to include in a styled component
 */
export function cssIf(test) {
    return (...ruleFragments) => (
        (props) => truthyPropTest(props, test) ? css(...ruleFragments) : ''
    );
}

/**
 * Styled Components inverse <code>if</code>
 *
 * If <code>test</code> is a function, the truthiness of the result of invoking
 * it with the component's props determines whether or not to include the
 * conditional CSS.
 *
 * If <code>test</code> is a string or array of strings, the truthiness of the
 * prop with that path determines whether or not to include the conditional
 * CSS.
 *
 * If <code>test</code> is neither a function, string nor array, its truthiness
 * determines whether or not to include the conditional CSS.
 *
 * @example <caption>Add opacity: 0; if the isVisible prop is falsy</caption>
 *
 * ${cssIfNot('isVisible')`
 *     opacity: 0;
 * `}
 *
 * @param {*} test - a function of props, prop name or any other value
 * @returns {Function} - a function of props to include in a styled component
 */
export function cssIfNot(test) {
    return (
        (...ruleFragments) => (
            (props) => truthyPropTest(props, test) ? '' : css(...ruleFragments)
        )
    );
}

/**
 * Styled Components <code>if-else</code>
 *
 * If <code>test</code> is a function, the truthiness of the result of invoking
 * it with the component's props determines which conditional CSS to include.
 *
 * If <code>test</code> is a string or array of strings, the truthiness of the
 * prop with that path determines which conditional CSS to include.
 *
 * If <code>test</code> is neither a function, string nor array, its truthiness
 * determines which conditional CSS to include.
 *
 * @example <caption>Add one set of styles or another one depending on the truthiness of the hasIcon prop</caption>
 *
 * ${cssIfElse('hasIcon')`
 *     position: relative;
 *     padding: 0 59px 0 31px;
 * ``
 *     padding: 0 31px;
 * `}
 *
 * @param {*} test - a function of props, prop name or any other value
 * @returns {Function} - a function of props to include in a styled component
 */
export function cssIfElse(test) {
    return (
        (...ruleFragmentsIf) => (
            (...ruleFragmentsElse) => (
                (props) => truthyPropTest(props, test) ? css(...ruleFragmentsIf) : css(...ruleFragmentsElse)
            )
        )
    );
}

function truthyPropTest(props, test) {
    if (typeof test === 'string' || isArray(test)) {
        return result(props, test);
    } else if (typeof test === 'function') {
        return test(props);
    } else {
        return !!test;
    }
}
