import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {selectFieldType, textAreaType} from '../../common/common-prop-types';
import useAutoComplete from '../../common/use-auto-complete';
import Button from '../buttons/button';
import Form from '../form-controls/common/form';
import RadioButtonsField from '../form-fields/radio-buttons-field';
import TextAreaField from '../form-fields/text-area-field';
import Paragraph from '../typography/paragraph';

const CenteredParagraph = styled(Paragraph)`
    text-align: center;
    color: ${property('theme.color.tertiary90')};
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 48px auto 0;
`;

export default function IncidentLocation({
    additionalDetails = {},
    className,
    damageArea = {},
    isSubmitting,
    onSubmit,
    shouldShowAdditionalDetails,
    submitButtonText,
}) {
    const {label: damageAreaLabel, ...restDamageArea} = damageArea;
    const {label: additionalDetailsLabel, ...restAdditionalDetails} = additionalDetails;

    return (
        <Form
            className={classnames('IncidentLocation', className)}
            onSubmit={onSubmit}
            autoComplete={useAutoComplete()}
            noValidate
        >
            <CenteredParagraph>
                {damageAreaLabel}
            </CenteredParagraph>

            <RadioButtonsField {...restDamageArea} />

            {!!shouldShowAdditionalDetails && (
                <>
                    <CenteredParagraph>
                        {additionalDetailsLabel}
                    </CenteredParagraph>
                    <TextAreaField
                        aria-label={additionalDetailsLabel}
                        {...restAdditionalDetails}
                    />
                </>
            )}

            <StyledButton
                type="submit"
                color="primary"
                isTall
                disabled={isSubmitting}
            >
                {submitButtonText}
            </StyledButton>
        </Form>
    );
}

IncidentLocation.propTypes = {
    additionalDetails: textAreaType.isRequired,
    className: propTypes.string,
    isSubmitting: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
    damageArea: selectFieldType.isRequired,
    shouldShowAdditionalDetails: propTypes.bool,
    submitButtonText: propTypes.string.isRequired,
};
