import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

/* TODO */
const Wrapper = styled.div`
  position: fixed;
  top: -88px;
  left: 0;
  padding: 0 160px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
  background-color: #003da5;
  font-family: "Inter-Medium";
  @media (max-width:767.95px){
    padding: 0 16px;
    height: 88px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

function TopNotice({ message, duration = 2, onClose = null }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const dom = messageContainer.querySelector("div");
    if (open) {
      dom.style.transition = "all 0.3s ease";
      dom.style.transform = "translateY(88px)";

      setTimeout(() => {
        if (duration === 0) return;
        setOpen(false);
        onClose && onClose();
      }, duration * 1000);
    } else {
      // close - unmount
      dom.style.transform = "translateY(0)";

      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(messageContainer);
      }, 350);
    }
  }, [open, duration, onClose]);

  return <Wrapper open={open}>{message}</Wrapper>;
}

let messageContainer = document.getElementById("message-container");

/**
 *  static
 * @param {message,duration,onClose} param0 duration:s秒
 */
TopNotice.show = function ({ message, duration = 2, onClose = null }) {
  messageContainer = document.getElementById("message-container");
  if (!messageContainer) {
    messageContainer = document.createElement("div");
    messageContainer.id = "message-container";
    document.body.appendChild(messageContainer);
  }

  //   const reactElement = ReactDOM.createPortal(
  //     <TopNotice message={message} duration={duration} onClose={onClose} />,
  //     document.body
  //     // messageContainer
  //   );
  //   ReactDOM.render(reactElement, messageContainer);
  ReactDOM.render(
    <TopNotice message={message} duration={duration} onClose={onClose} />,
    messageContainer
  );
  //   ReactDOM.render(reactElement, document.body);
};

TopNotice.propTypes = {
  message: PropTypes.node, // 消息内容
  duration: PropTypes.number, // 自动关闭的延时，单位秒。设为 0 时不自动关闭。
  onClose: PropTypes.func // 关闭时的回调
};

export default TopNotice;
