
import React from 'react';
import CancelAutoRenewal from "../components/auto-renewal/auto-renewal-cancel";

const AutoRenewalCancelPage = () => {
    return (
        <div>
            <CancelAutoRenewal/>
        </div>
    )
}

export default AutoRenewalCancelPage;
