import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import Actions from '../buttons/actions';
import Text from '../typography/text';

const StyledActions = styled(Actions)`
    padding-top: 32px;
`;

const ContentBody = styled.div`
    padding: 32px 0;
    text-align: center;
`;

export default function WelcomeContent({
    className,
    message,
    primaryActionLabel,
    primaryActionHref,
    onClickPrimaryAction,
    secondaryActionLabel,
    secondaryActionHref,
    onClickSecondaryAction,
    tertiaryActionLabel,
    tertiaryActionHref,
    onClickTertiaryAction,
}) {
    return (
        <div className={classnames('WelcomeContent', className)}>
            <ContentBody>
                <Text weight="semiBold" color="tertiary90">
                    {message}
                </Text>
            </ContentBody>

            <StyledActions
                primaryAction={{
                    label: primaryActionLabel,
                    href: primaryActionHref,
                    onClick: onClickPrimaryAction,
                }}
                secondaryAction={{
                    label: secondaryActionLabel,
                    href: secondaryActionHref,
                    onClick: onClickSecondaryAction,
                }}
                tertiaryAction={
                    tertiaryActionLabel
                        ? {
                            label: tertiaryActionLabel,
                            href: tertiaryActionHref,
                            onClick: onClickTertiaryAction,
                        }
                        : undefined
                }
            />
        </div>
    );
}

WelcomeContent.propTypes = {
    className: propTypes.string,
    message: propTypes.node,
    primaryActionLabel: propTypes.string,
    primaryActionHref: propTypes.string,
    onClickPrimaryAction: propTypes.func,
    secondaryActionLabel: propTypes.string,
    secondaryActionHref: propTypes.string,
    onClickSecondaryAction: propTypes.func,
    tertiaryActionLabel: propTypes.string,
    tertiaryActionHref: propTypes.string,
    onClickTertiaryAction: propTypes.func,
};
