import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import {ReactComponent as Download} from "../../assets/policyDetails/download_white.svg";
import {ReactComponent as Mail} from "../../assets/policyDetails/Mail_white.svg";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import IconSpeed from "../custom/policy-detail/icon-speed";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {_getCountry, _getPolicyToken, _hideSpinner, _setDocumentToken, _showSpinner} from "../../store/system/service";
import {
    _loadDocument,
    _loadPolicyDocuments,
    _sendDocumentEmail,
    _downloadZip, _getPolicy
} from "../../store/policy/service";
import {saveAs} from 'file-saver';
import EmailDocDialog from "../custom/policy-detail/email-doc-dialog";
import {customReplaceAll, getDocName} from "../../utils/utility";
import PolicyDocumentEmailDialog from "./policy-document-email-dialog";
import * as TagManager from "react-gtm-module";

const MWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.06);
`;

const MItem = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #e1e4e8;
  position: relative;

  &:last-child {
    border: none;
  }
`;

const MRow = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const MKey = styled.div`
  margin-bottom: 4px;
  color: #8a94a6;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

const MValue = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const MDocValue = styled(MValue)`
  margin-bottom: 4px;
  color: #003da5;
`;

const IconSpeedWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
`;

/* ============================ */

const TypeWrapper = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const DocumentTypeWrapper = styled.div`
  color: #003da5;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const DocumentTypeItem = styled.div`
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: #1d4fbb;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:nth-child(2) {
    margin-left: 40px;
  }
`;

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

const StyledTableContainer = withStyles({
    root: {
        padding: "0 20px",
        borderRadius: "20px",
        boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)"
    }
})(TableContainer);

const StyledTableCell = withStyles({
    root: {
        padding: "20px"
    },
    head: {
        width: "25%",
        color: "#8A94A6",
        fontFamily: "Inter",
        fontSize: "14px",
        letterSpacing: 0,
        lineHeight: "20px"
    }
})(TableCell);

const StyledTableBody = withStyles({
    root: {
        "& .MuiTableRow-root:last-child .MuiTableCell-root": {
            border: "none"
        }
    }
})(TableBody);

const StyledTooltip = withStyles({
    popper: {
        opacity: "0.9",
        borderRadius: "2px"
    },
    tooltip: {
        padding: "4px 12px",
        margin: "4px 0",
        fontFamily: "Inter",
        fontSize: "14px",
        letterSpacing: 0,
        lineHeight: "20px",
        color: "#FFFFFF",
        backgroundColor: "#4E5D78"
    }
})(Tooltip);

const Empty = styled.div`
  padding: 40px 0;
  height: 100px;
  width: 100%;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

function createData(type, date, documentType, actions) {
    return {type, date, documentType, actions};
}

const columns = ["policy.heading.type", "policy.heading.date", "policy.heading.documentType", "policy.heading.actions"];

const rows = [
    createData("Cancellation", "22/08/2020", ["Cancellation Note"]),
    createData("Endorsement", "08/07/2020", ["Policy Schedule", "Endorsement Note", "Receipt"]),
];

const PolicyDocuments = ({className, policy, country, policyToken, loadPolicyDocuments, loadDocument, sendDocumentEmail,
                             setDocumentToken, cfg, policyEmail, downloadZip, updateP400Email, ...props}) => {
    const [documents, setDocuments] = useState();
    const [currentDocuments, setCurrentDocuments] = useState([]);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [showEmailSent, setShowEmailSent] = useState(false);
    const [emailTo, setEmailTo] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if (policy.policyNumber) {
            setDocumentLoaded(false);
            loadPolicyDocuments().then((resp) => {
                setDocuments(resp.transactionDocs);
                filterCurrentDocuments(resp.transactionDocs);
                setDocumentToken(resp.policyDocumentToken);
                setDocumentLoaded(true);
            });
        }
    }, [policy]);

    const filterCurrentDocuments = (documents) => {
        let currDocs = [];
        if (documents) {
            let invalidDocumentType = cfg('invalidDocuments.' + country);
            documents.map((item) => {
                item.documentInfoBeans = item.documentInfoBeans.filter(docBeans => {
                    let documentType = docBeans?.dDocType.replace('ASIA-', '');
                    return !invalidDocumentType.includes(documentType);
                })

                if (item.documentInfoBeans.length > 0) {
                    currDocs.push(item);
                }
            })
        }
        setCurrentDocuments(currDocs);
    }

    const base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const data = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            data[i] = binaryString.charCodeAt(i);
        }
        return data;
    }

    const saveAsPdf = (fileName, byteArray) => {
        if (byteArray) {
            const data = base64ToArrayBuffer(byteArray);
            const blob = new Blob([data], {type: 'application/pdf'});
            saveAs(blob, fileName + '.pdf');
        }
    }

    const saveAsDoc = (fileName, byteArray) => {
        if (byteArray) {
            const data = base64ToArrayBuffer(byteArray);
            const blob = new Blob([data], {type: 'application/msword'});
            saveAs(blob, fileName + '.doc');
        }
    }

    const saveAsFile = (rowIndex, docIndex) => {
        if (currentDocuments) {
            const tran = currentDocuments[rowIndex];
            const document = tran.documentInfoBeans[docIndex];

            const fileNameForGA = getDocName(document, cfg, country);
            const eventCategoryValue = tran.txnTypeDescription + " " + fileNameForGA;
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Document-Download',
                    eventCategory: eventCategoryValue.replaceAll(/\s/g,'-')
                }
            });
            saveDoc(document);
        }
    }

    const saveAsFileAll = (rowIndex) => {
        if (currentDocuments) {
            const tran = currentDocuments[rowIndex];
            const docs = tran.documentInfoBeans;

            const eventCategoryValue = tran.txnTypeDescription + ' Download';
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Icon-Click',
                    eventCategory: eventCategoryValue.replaceAll(/\s/g,'-')
                }
            });

            console.log(docs);
            let docNames = [];
            docs.map((doc) => (
                docNames.push({
                    dDocName: doc.ddocName,
                    dDocTitle: getDocName(doc, cfg, country)
                })
            ));
            props.showSpinner();
            downloadZip(docNames).then((resp) => {
                if (resp !== 'FAILED'){
                    const data = base64ToArrayBuffer(resp.zipFile);
                    const blob = new Blob([data], {type: 'application/zip'});
                    saveAs(blob, 'download.zip');
                }
                props.hideSpinner();
            });
        }
    }

    const saveDoc = (document) => {
        const docId = document.did;
        const ddocName = document.ddocName;
        const fileName = getDocName(document, cfg, country);

        if (!docId) {
            // static document
            saveAsPdf(fileName, document.documentBytes);
        } else {
            props.showSpinner();
            loadDocument(ddocName).then((doc) => {
                if (doc) {
                    let stringArr = doc.idcFileName.split(".");
                    let fileExt = stringArr[stringArr.length - 1];

                    if (fileExt === 'pdf') {
                        saveAsPdf(fileName, doc.documentBytes);
                    } else if (fileExt === 'doc') {
                        saveAsDoc(fileName, doc.documentBytes);
                    }
                }
                props.hideSpinner();
            });
        }
    }

    const onClickEmail = (rowIndex) => {
        if (currentDocuments) {
            const tran = currentDocuments[rowIndex];
            setSelectedTransaction(tran)
            setShowEmailDialog(true);

            const eventCategoryValue = tran.txnTypeDescription + ' Email';
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Icon-Click',
                    eventCategory: eventCategoryValue.replaceAll(/\s/g,'-')
                }
            });

            TagManager.dataLayer({
                dataLayer: {
                    event: 'popup',
                    popupPath: '/policy',
                    popupTitle: 'Email Documents'
                }
            });
        }

    }

    const closeEmailDialog = () => {
        setShowEmailDialog(false);
    }

    const sendEmail = (emailObj, updateFlag) => {
        if (emailObj) {
            setShowEmailDialog(false);
            if (selectedTransaction) {
                let selectedEmailDoc = [];
                selectedTransaction.documentInfoBeans.forEach((doc) => {
                    selectedEmailDoc.push(
                        {
                            did: doc.did,
                            ddocName: doc.ddocName,
                            name: getDocName(doc, cfg, country),
                            ext: 'pdf'
                        }
                    )
                });

                let agentPhoneNumberVal = "";
                let agentEmailVal = "";

                if (policy.agentDetails.agentPhoneNumber) {
                    agentPhoneNumberVal = policy.agentDetails.agentPhoneNumber;
                } else {
                    if (country === 'SGP') {
                        agentPhoneNumberVal = "6562246633";
                    } else if (country === 'MYS'){
                        agentPhoneNumberVal = "60378618480";
                    } else {
                        agentPhoneNumberVal = "85228283138";
                    }
                }
                if (policy.agentDetails.agentEmailAddress) {
                    agentEmailVal = policy.agentDetails.agentEmailAddress;
                } else {
                    if (country === 'SGP') {
                        agentEmailVal = "info.sing@qbe.com";
                    } else if(country === 'MYS'){
                        agentEmailVal = "info.mal@qbe.com";
                    }else {
                        agentEmailVal = "info.gihk@qbe.com";
                    }
                }

                const email = {
                    toAddress: emailObj.to,
                    subject: emailObj.subject,
                    message: emailObj.content,
                    updateP400EmailFlag: updateFlag,
                    attachments: selectedEmailDoc,
                    country: country,
                    productCode: policy.productCode,
                    catalogueCode: policy.catalogueCode,
                    policyNumber: policy.policyNumber,
                    customerName: policy.name,
                    agentName: policy.agentDetails.agentName,
                    agentMobileNumber: agentPhoneNumberVal,
                    agentEmailAddress: agentEmailVal
                };

                sendDocumentEmail(email);
            }
        }
    }

    const getTypeDescription = (typeDescription) => {
        if (typeDescription === 'New Business Issue') {
            return cfg('policyTrxTypeDescription.NB');
        } else if (typeDescription === 'Endorsement Issue'){
            return cfg('policyTrxTypeDescription.ENDT');
        } else {
            // no available chinese translation, return original value
            return typeDescription;
        }
    }

    const maskEmail = (email) => {
        if (email) {
            let val = email.split('@')[0];
            let regexToUse = val.length > 4 ? /^.*(?=....@)/g : /^.*(?=@)/g;
            return customReplaceAll(regexToUse, email, '•');
        }

        return null;
    }

    return (
        <>
            <Hidden mdUp>
                <MWrapper>
                    {documentLoaded && currentDocuments?.length > 0 &&
                    currentDocuments?.map((row, rowIndex) => (
                        <MItem key={row.txnTypeDescription + rowIndex}>
                            <MRow>
                                <MKey>{columns[0]?.title}</MKey>
                                <MValue>{getTypeDescription(row.txnTypeDescription)}</MValue>
                            </MRow>
                            <MRow>
                                <MKey>{columns[1]?.title}</MKey>
                                <MValue>{row.effectiveDate}</MValue>
                            </MRow>
                            <MRow>
                                <MKey>{columns[2]?.title}</MKey>
                                {Array.isArray(row.documentInfoBeans) &&
                                row.documentInfoBeans.map((doc, docIndex) => (
                                    <MDocValue
                                        key={doc.did}
                                        onClick={() => saveAsFile(rowIndex, docIndex)}
                                    >
                                        {getDocName(doc, cfg, country)}
                                    </MDocValue>
                                ))}
                            </MRow>

                            <IconSpeedWrapper>
                                <IconSpeed
                                    // id={row?._id}
                                    onClickEmail={() => onClickEmail(rowIndex)}
                                    onClickDownload={() => saveAsFileAll(rowIndex)}
                                />
                            </IconSpeedWrapper>
                        </MItem>
                    ))}
                    {documentLoaded && currentDocuments && currentDocuments.length === 0 &&
                    <MItem key='noClaim'>
                        <MRow>
                            <Empty>{cfg("policy.noDocumentFound")}</Empty>
                        </MRow>
                    </MItem>
                    }
                    {!documentLoaded &&
                    <MItem key='noClaim'>
                        <MRow>
                            <Empty>{cfg("policy.loading")}</Empty>
                        </MRow>
                    </MItem>
                    }

                </MWrapper>
            </Hidden>
            <Hidden smDown>
                <StyledTableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        {documentLoaded && currentDocuments?.length > 0 &&
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <StyledTableCell key={index}>{cfg(item)}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        }
                        <StyledTableBody>
                            {documentLoaded && currentDocuments?.length > 0 && currentDocuments?.map((row, rowIndex) => (
                                <TableRow key={row.txnTypeDescription + rowIndex}>
                                    <StyledTableCell component="th" scope="row">
                                        <TypeWrapper>{getTypeDescription(row.txnTypeDescription)}</TypeWrapper>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TypeWrapper>{row.effectiveDate}</TypeWrapper>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <DocumentTypeWrapper>
                                            {row.documentInfoBeans.map((doc, docIndex) => (
                                                <DocumentTypeItem key={doc.did}
                                                                  onClick={() => saveAsFile(rowIndex, docIndex)}>
                                                    {getDocName(doc, cfg, country)}
                                                </DocumentTypeItem>
                                            ))}
                                        </DocumentTypeWrapper>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <ActionWrapper>
                                            { (!cfg('validPolicyStatusCodes.' + policy.country + '.inactive')
                                                .includes(policy.origStatus ? policy.origStatus : policy.statusCode)) &&
                                                <StyledTooltip title={cfg('labels.policyDocuments.emailTooltip')}>
                                                    <IconWrapper onClick={() => onClickEmail(rowIndex)}>
                                                        <Mail/>
                                                    </IconWrapper>
                                                </StyledTooltip>
                                            }

                                            <StyledTooltip title={cfg('labels.policyDocuments.downloadTooltip')}>
                                                <IconWrapper onClick={() => saveAsFileAll(rowIndex)}>
                                                    <Download/>
                                                </IconWrapper>
                                            </StyledTooltip>
                                        </ActionWrapper>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                            {documentLoaded && currentDocuments && currentDocuments.length === 0 &&
                            <TableRow>
                                <StyledTableCell>
                                    <Empty>{cfg("policy.noDocumentFound")}</Empty>
                                </StyledTableCell>
                            </TableRow>
                            }
                            {!documentLoaded &&
                            <TableRow>
                                <StyledTableCell>
                                    <Empty>{cfg("policy.loading")}</Empty>
                                </StyledTableCell>
                            </TableRow>
                            }
                        </StyledTableBody>
                    </Table>
                </StyledTableContainer>
            </Hidden>

            {/*<PolicyDocumentEmailDialog
                open={showEmailDialog}
                title={cfg("policy.emailLabel")}
                message={cfg("policy.emailMessage")}
                email={policyEmail}
                onClose={() => closeEmailDialog()}
                onSubmit={(eml) => {
                    sendEmail(eml);
                    setShowEmailSent(true); 
                }}
                onUpdate={(emailAddress) => {
                    updateEmail(emailAddress);
                }}
                tran={selectedTransaction}
                cfg={cfg}
            >
            </PolicyDocumentEmailDialog>*/}

            <EmailDocDialog
                title={cfg("policy.emailLabel")}
                message={cfg("policy.emailMessage")}
                email={policyEmail}
                chkMessage={country === 'MYS' ? cfg("policy.checkboxMessage_my") : cfg("policy.checkboxMessage")}
                open={showEmailDialog}
                onClose={() => {
                    closeEmailDialog()
                }}
                onSend={(eml, flag) => {
                    sendEmail(eml, flag);
                    setShowEmailSent(true);
                    setEmailTo(eml.to);
                }}
            />

            <EmailDocDialog
                title={cfg('policy.emailSentTitle')}
                message={
                    cfg('policy.emailSent') + maskEmail(emailTo) + cfg('policy.emailSent2')
                }
                open={showEmailSent}
                onClose={() => {
                    setShowEmailSent(false);
                }}
                isEmailSentDialog={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        policy: _getPolicy(state)
    }
}

const mapDispatchToProps = {
    loadPolicyDocuments: _loadPolicyDocuments,
    sendDocumentEmail: _sendDocumentEmail,
    loadDocument: _loadDocument,
    setDocumentToken: _setDocumentToken,
    downloadZip: _downloadZip,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PolicyDocuments));
