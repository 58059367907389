import {useRef} from 'react';
import noop from 'lodash/noop';

/**
 * Deterministic version of React's <code>useMemo</code>
 *
 * @param {Function}     compute     - function that computes the value
 * @param {Array}        inputs      - inputs that, when changed, cause the value to be re-computed
 * @returns {*} the last computed value
 */
export default function useDeterministicMemo(compute = noop, inputs) {
    const currentInputs = useRef(null);
    const currentValue = useRef(null);

    // Recompute the value if the inputs changed.
    if (!(
        Array.isArray(inputs)
        && Array.isArray(currentInputs.current)
        && inputs.length === currentInputs.current.length
        && inputs.every((input, index) => input === currentInputs.current[index])
    )) {
        currentValue.current = compute();
        currentInputs.current = inputs;
    }

    return currentValue.current;
}
