import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {colorType} from '../../common/common-prop-types';
import typographyStyles from '../../common/typography-styles';

const sizes = [
    'normal',
    'small',
];

const weights = [
    'medium',
    'bold',
];

const StyledSubheading = styled.h1`
    margin: 16px 0;

    ${typographyStyles({
        type: 'subheading',
        sizes,
        defaultSize: 'normal',
        weights,
        defaultWeight: 'bold',
        defaultColor: 'primary',
    })}
`;

export default function Subheading({
    className,
    size,
    weight,
    level,
    color,
    children,
    ...rest
}) {
    return (
        <StyledSubheading
            {...rest}
            className={classnames('Subheading', className)}
            as={`h${level || 1}`}
            scSize={size}
            scWeight={weight}
            scColor={color}
        >
            {children}
        </StyledSubheading>
    );
}

Subheading.propTypes = {
    className: propTypes.string,
    size: propTypes.oneOf(sizes),
    weight: propTypes.oneOf(weights),
    level: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
    color: colorType,
    children: propTypes.node,
};
