import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import {smDown, smUp} from '../../common/breakpoints';
import {colorType} from '../../common/common-prop-types';
import {cssIf, cssIfElse} from '../../common/conditional-styles';
import typographyStyles from '../../common/typography-styles';

const StyledTable = styled.table`
    ${smDown`
        display: block;
    `}

    ${smUp`
        position: relative;
        border-collapse: collapse;
        margin: 0 -16px;
        padding: 16px 0;
        width: calc(100% + 32px);
        border-collapse: collapse;
    `}
`;

const Header = styled.thead`
    ${smDown`
        display: block;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        pointer-events: none;
    `}

    ${smUp`
        border-bottom: 1px solid ${property('theme.color.grey')};
    `}
`;

const HeaderRow = styled.tr`
    ${smUp`
        border-bottom: 1px solid ${property('theme.color.grey40')};
    `}
`;

/* eslint-disable indent */
const HeaderCell = styled.th`
    ${smUp`
        border-bottom: 1px solid ${property('theme.color.grey40')};
        padding: 4px 8px;
        vertical-align: bottom;
        text-align: left;

        ${cssIf('scIsHeaderSticky')`
            position: sticky;
            top: 0;
            box-shadow: 0 1px 0 0 ${property('theme.color.grey40')};

            ${cssIf('scStickyHeaderBackgroundColor')`
                background: ${({theme, scStickyHeaderBackgroundColor}) => theme.color[scStickyHeaderBackgroundColor] || 'transparent'};
            `}
        `}

        ${typographyStyles({
            type: 'text',
            defaultSize: 'small',
            defaultWeight: 'regular',
            defaultColor: 'primary',
        })}

        &:first-of-type {
            padding-left: 16px;
            padding-right: 0;
        }

        &:last-of-type {
            padding-left: 0;
            padding-right: 16px;
        }
    `}
`;
/* eslint-enable indent */

const Body = styled.tbody`
    ${smDown`
        display: block;
    `}
`;

const Row = styled.tr`
    ${smDown`
        display: grid;
        display: -ms-grid;
        border: 1px solid ${property('theme.color.grey40')};
        margin: 0 -8px 16px;
        border-radius: 10px;
        padding: 4px 8px;

        &:last-of-type {
            margin-bottom: 0;
        }
    `}

    ${smUp`
        border-top: 1px solid ${property('theme.color.grey40')};
    `}
`;

const Cell = styled.td`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'regular',
        defaultColor: 'tertiary90',
    })}

    ${smDown`
        padding: 16px;

        ${cssIfElse('scIsHiddenOnMobile')`
            display: none;
        ``
            display: block;
        `}

        ${cssIf('scHasTopBorder')`
            border-top: 1px solid ${property('theme.color.grey40')};
        `}
    `}

    ${smUp`
        padding: 4px 8px;
        vertical-align: top;

        ${cssIf('scAlign')`
            text-align: ${property('scAlign')};
        `}

        &:first-of-type {
            padding-left: 16px;
            padding-right: 0;
        }

        &:last-of-type {
            padding-left: 0;
            padding-right: 16px;
        }
    `}
`;

/* eslint-disable indent */
const InlineHeading = styled.div.attrs({
    'aria-hidden': true,
})`
    ${cssIfElse('scIsHiddenOnMobile')`
        display: none;
    ``
        ${smDown`
            display: block;

            ${typographyStyles({
                type: 'text',
                defaultSize: 'small',
                defaultWeight: 'regular',
                defaultColor: 'primary',
            })}
        `}

        ${smUp`
            display: none;
        `}
    `}
`;
/* eslint-enable indent */

export default function Table({
    className,
    isHeaderSticky,
    stickyHeaderBackgroundColor,
    rows,
    columns,
}) {
    // IE11 needs us to be very specific
    const msGridColumns = useMemo(
        () => (
            Array
                .from(
                    {length: columns.reduce((maxGridColumn, {gridColumn}) => Math.max(maxGridColumn, gridColumn || 0), 0)},
                    () => '1fr'
                )
                .join(' ')
        ),
        [columns]
    );

    return (
        <StyledTable className={classnames('Table', className)}>
            <Header>
                <HeaderRow>
                    {columns.map(({heading, width, align}, index) => (
                        <HeaderCell
                            key={index}
                            scIsHeaderSticky={!!isHeaderSticky}
                            scStickyHeaderBackgroundColor={stickyHeaderBackgroundColor}
                            style={{
                                width,
                                textAlign: align,
                            }}
                        >
                            {heading}
                        </HeaderCell>
                    ))}
                </HeaderRow>
            </Header>
            <Body>
                {rows.map((cells, rowIndex) => (
                    <Row
                        key={rowIndex}
                        style={{msGridColumns}}
                    >
                        {columns.map((
                            {
                                heading,
                                isHiddenOnMobile,
                                hasTopBorder,
                                gridColumn,
                                gridColumnSpan,
                                gridRow,
                                align,
                            },
                            columnIndex
                        ) => (
                            <Cell
                                key={columnIndex}
                                scIsHiddenOnMobile={isHiddenOnMobile}
                                scHasTopBorder={hasTopBorder}
                                scAlign={align}
                                style={{
                                    gridRow,
                                    msGridRow: gridRow,
                                    gridColumn: gridColumnSpan
                                        ? `${gridColumn} / span ${gridColumnSpan}`
                                        : gridColumn,
                                    msGridColumn: gridColumn,
                                    msGridColumnSpan: gridColumnSpan,
                                }}
                            >
                                <InlineHeading scIsHiddenOnMobile={isHiddenOnMobile}>
                                    {heading}
                                </InlineHeading>
                                {cells[columnIndex]}
                            </Cell>
                        ))}
                    </Row>
                ))}
            </Body>
        </StyledTable>
    );
}

Table.propTypes = {
    className: propTypes.string,
    isHeaderSticky: propTypes.bool,
    stickyHeaderBackgroundColor: colorType,
    columns: propTypes.arrayOf(propTypes.shape({
        heading: propTypes.string, // has to be string, not node, because it's being used in CSS content

        // Mobile only
        isHiddenOnMobile: propTypes.bool,
        hasTopBorder: propTypes.bool,
        gridColumn: propTypes.number,
        gridColumnSpan: propTypes.number,
        gridRow: propTypes.number,

        // Desktop only
        width: propTypes.oneOfType([propTypes.string, propTypes.number]),    // width css
        align: propTypes.oneOf(['start', 'left', 'center', 'end', 'right']),
    })),
    rows: propTypes.arrayOf( // rows
        propTypes.arrayOf(   // cells
            propTypes.node   // cell content
        )
    ),
};
