import React from "react";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";
import QnectButton from "../../qnect-ui/qnect-button";
import useLoginStyles, {StyledModalDiv, ButtonContainer} from "../../login-style";
import "../../login-sgp-addon-style.css"


const NoNricDialog = ({
  open,
  onClose,
    cfg,
    ...props
}) => {
  const classes = useLoginStyles();

  return (
    <div>
      <Dialog id="TermsConditions"  className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title={
            <div className="title">
                {cfg("noNric.title")}
            </div>
          }
          onClose={onClose}
        />
        <StyledModalDiv id="UserError" className="UserError_Modal">
          <div className="content">{cfg('login.modalUserErrorMessage1')}<span><a href="https://www.qbe.com/sg/eservice/contact-us">{cfg('login.modalUserErrorMessage2')}</a></span></div>
          <ButtonContainer>
            <QnectButton
                field="ok"
                color="primary"
                className="okButton"
                onClick={onClose}
            >
            </QnectButton>
          </ButtonContainer>
        </StyledModalDiv>
      </Dialog>
    </div>
  );
}

NoNricDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default NoNricDialog;
