import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import '../../assets/styles/payment.css';
import Tick from '../../assets/Tick.svg';
import useConfig from "../qnect-ui/qnect-config";
import QbeLogo from "../../assets/QBE-logo-on-light.png";
import {_getLang, _getPolicyToken, _getPortfolioToken, _setLang} from "../../store/system/service";
import {_getPolicyPayment} from "../../store/policy-payment/service";
import * as TagManager from "react-gtm-module";
import {_getPolPaymentCurrency} from "../../utils/utility";

const OnlinePaymentFinish = (props) => {
    const cfg = useConfig(props.lang);
    const [lang, setLang] = useState( props.lang);
    const [productName] = useState (props.policyPayment.productName);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [country] = useState (props.policyPayment.country);
    const [amount] = useState(props.policyPayment.amount);
    const [receiptNumber] = useState( props.policyPayment.payment.receiptNumber);
    const [emailAddress] = useState (props.policyPayment.email ? props.policyPayment.email : '');

    useEffect(() => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Online Payment Finish – Payment - ' + productName,
                vPath: '/payment-finish',
                vProduct: productName,
                vPolicyNo: policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(country),
                vPaymentAmount: amount,
                vCountry: country,
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: policyNumber,
                            revenue: amount
                        },
                        products: [{
                            name: productName,
                            price: amount,
                            dimension1: 'credit-card',
                            dimension2: country,
                            category: 'Online Payment',
                            quantity: 1
                        }]
                    }
                }
            }
        });
    },[])

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
    }

    return (

        <div className="pageContainer online-payment-finish-page">
            <div className="container">
                  <div className="topRow">
                      <div className="left">
                          <a href="https://www.qbe.com/hk/en/about-qbe">
                              <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                          </a>
                      </div>
                      <div className="right">
                          <div className="languageSelect" onClick={() => switchLanguage()}
                               style={{ visibility: (props.policyPayment.country === 'HKG' ||
                                   props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                              {(lang === 'en') ?
                                  cfg("labels.lang_zh")
                                  :
                                  cfg("labels.lang_en")
                              }
                          </div>
                      </div>
                  </div>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        <div className="main-content-box no-topTitle">
                            <div className="thankyou-message">
                                <img src={Tick} width="80" height="80" alt="Info"/>
                                <div className="main-content-title alignCenter">{ cfg('labels.paymentSuccessTitle')}</div>
                                <div className="main-description-text alignCenter">{ cfg('labels.paymentSuccessMsg') } <b>{ emailAddress } </b></div>
                            </div>
                            <div className="table-details policy-details">
                                <table>
                                    <thead>
                                    <tr>
                                        <th colSpan="2">{ cfg('labels.policyDetail')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{ cfg('labels.policyNumber')}</td>
                                        <td>{ policyNumber }</td>
                                    </tr>
                                    <tr>
                                        <td>{ cfg('labels.receiptNumber')}</td>
                                        <td>{ receiptNumber }</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlinePaymentFinish));

