import React from "react";
import {useStyles} from "../common-style";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";

import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";

import {connect} from "react-redux";
import {_getCountry} from "../../../store/system/service";


const RenewalConfirmationDialog = ({open, onClose, onConfirm, onClickDocument, cfg, lang}) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                <DialogTitle title={cfg("renewal.popup.title")} onClose={onClose} lang={lang}/>
                <DialogContent>
                    <div className={classes.message}>
                        <span>{cfg("renewal.popup.message1")}</span>
                        <span className={[classes.hyperlinkColor, classes.cursorPointer].join(" ")}
                              onClick={onClickDocument}>
                            {cfg("renewal.popup.message2")}
                        </span>
                        <span>{cfg("renewal.popup.message3")}</span>
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <ButtonWrapper className={[classes.buttonStyle, classes.buttonStyleRenewal].join(" ")}>
                        <QnectButton
                            field="cancel"
                            onClick={onClose}
                        ></QnectButton>
                        <QnectButton
                            field="confirm"
                            onClick={onConfirm}
                        ></QnectButton>
                    </ButtonWrapper>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        country: _getCountry(state),
    };
};

export default connect(mapStateToProps, null)(RenewalConfirmationDialog);
