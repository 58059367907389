import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import typographyStyles from '../../../common/typography-styles';
import {Paragraph} from '../../typography';

const Message = styled.div`
    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultWeight: 'bold',
        defaultColor: 'tertiary90',
    })}
`;

const List = styled.ul`
    margin-top: 8px;
    padding-left: 8px;
    list-style-type: '-';
`;

const Item = styled.li`
    padding-left: 8px;
    ${typographyStyles({
        type: 'text',
        defaultSize: 'small',
        defaultColor: 'tertiary90',
        defaultWeight: 'regular',
    })}
`;

export default function SupplierDetails({
    message,
    contactDetails,
    combinationClaimInstructions,
    specialInstructions,
}) {
    return (
        <>
            {!!message && (
                <Message>
                    {message}
                </Message>
            )}

            {!!contactDetails && contactDetails.length && (
                <List>
                    {contactDetails.map((item, index) => (
                        <Item key={index}>
                            {item}
                        </Item>
                    ))}
                </List>
            )}

            {!!combinationClaimInstructions && (
                <Paragraph size="small" color="tertiary90" >
                    {combinationClaimInstructions}
                </Paragraph>
            )}

            {!!specialInstructions && (
                <Paragraph size="small" color="tertiary90" >
                    {specialInstructions}
                </Paragraph>
            )}
        </>
    );
}

SupplierDetails.propTypes = {
    message: propTypes.string,
    contactDetails: propTypes.arrayOf(propTypes.string),
    combinationClaimInstructions: propTypes.string,
    specialInstructions: propTypes.string,
};
