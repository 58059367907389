import React from "react";
import styled from "styled-components";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import ButtonWrapper from "../button-wrapper";
import DialogTitle from "../dialog-title";
import Hidden from "@material-ui/core/Hidden";
import { ReactComponent as Back } from "../../../assets/login/back_sharp.svg";
import WhatsAppMessage from "../whats-app-message";

const StyledBack = styled(Back)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;
  margin-left: -24px;
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    width: "720px",
    padding: "14px 70px 60px",
    [theme.breakpoints.between("sm", "md")]: {
      padding: " 14px 39px 60px"
    },
  },
  paperWidthSm: {
    maxWidth: "none",
  },
}))(Dialog);

const StyledActionText = styled.div`
  font-family: "Inter";
  line-height: 24px;
  color: #003da5;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 22px;
`;

const StyledIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > :first-child {
    padding-right: 4px;
  }
  a {
    text-decoration: none;
    color: black;
  }
`;

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    fontSize: "18px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  message: {
    margin: "20px auto 0",
    [theme.breakpoints.up('sm')]:{
      width: "453px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      whiteSpace: 'normal',
      marginLeft: '4px',
      marginRight: '4px',
    },
    "& > span": {
      color: "#003DA5",
      cursor: "pointer",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        display: 'block'
      },
    },
    whiteSpace: "pre-wrap"
  },
  iconWrapper: {
    display: "block",
    justifyContent: "center",
    marginTop: "44px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  actionDialog: {
    marginTop: "44px",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 24px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "60px auto 0",
    },
  },
  phoneMessage: {
    textAlign: "center",
    color: "#8A94A6",
    fontSize: "16px",
    fontFamily: "Inter-Regular",
    marginTop: "4px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",

    },
  }
}));

export default function AuthTipsDialogMore({ open, onClose, onOpenAnother, cfg, lang, country }) {
  const classes = useStyles();
  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle
          title={
            <div className={classes.title}>
              <Hidden smUp>
                <StyledBack onClick={onOpenAnother} />
              </Hidden>
              {cfg('labels.authenticationTips.needHelpContactUs')}
            </div>
          }
          onClose={onClose}
          lang={lang}
        />
        <DialogContent className={classes.content}>
          <div className={classes.message}>
          {country === 'MYS' ? cfg('labels.authenticationTips.youMayAlsoContact_mys') : cfg('labels.authenticationTips.youMayAlsoContact')}
            <br/>
            <WhatsAppMessage country={country}></WhatsAppMessage>
            {cfg('labels.authenticationTips.youMayAlsoContact2')}
            <span onClick={() => {
              if (lang === 'zh') {
                window.open(process.env.REACT_APP_ONLINE_ENQUIRY_ZH, '_blank');
              } else if (country === 'MYS') {
                window.open(process.env.REACT_APP_ONLINE_ENQUIRY_MY, '_blank');
              } else {
                window.open(process.env.REACT_APP_ONLINE_ENQUIRY, '_blank');
              }
            }}>
              {cfg("labels.authenticationTips.contactUsForm")}
            </span>
          </div>
          <br/>
          <div className={classes.iconWrapper}>
            <StyledIconRow onClick={()=>{window.open(country === 'MYS' ? 'tel:+60378618480' : 'tel:+85228283138')}}>
              <PhoneIcon /> {country === 'MYS' ? '+60 3 7861 8480' : '+852 2828 3138'}
            </StyledIconRow>
            <div className={classes.phoneMessage}>{country === 'MYS' ? cfg("forgotEmail.phoneMessage_my") : cfg("forgotEmail.phoneMessage")}</div>
            <StyledIconRow>
              <MailOutlineIcon />
              <a href={country === 'MYS' ? "mailTo:cs@qbe.com.my" : "mailTo:customerservice@qbe.com"}>{country === 'MYS' ? "cs@qbe.com.my" : "customerservice@qbe.com"}</a>
            </StyledIconRow>
          </div>
        </DialogContent>
        <DialogActions className={classes.actionDialog}>
          <Hidden smDown>
            <StyledActionText
              onClick={() => {
                onClose();
                onOpenAnother();
              }}
            >
              {cfg('labels.authenticationTips.backToTips')}
            </StyledActionText>
          </Hidden>
          <ButtonWrapper>
            <QnectButton onClick={onClose}>{cfg("labels.tryAgain")}</QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </StyledDialog>
    </>
  );
}
