import classnames from 'classnames';
import property from 'lodash/property';
import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {smUp} from '../../common/breakpoints';
import typographyStyles from '../../common/typography-styles';

const Heading = styled.div`
    margin: 0;
    background-color: ${property('theme.color.grey30')};
    padding: 16px;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultColor: 'tertiary90',
        defaultWeight: 'semiBold',
    })};

    ${smUp`
        padding: 16px 32px;
    `}
`;

export default function CardHeading({
    className,
    level,
    children,
}) {
    return (
        <Heading
            className={classnames('CardHeading', className)}
            as={`h${level || 2}`}
        >
            {children}
        </Heading>
    );
}

CardHeading.propTypes = {
    className: propTypes.string,
    level: propTypes.oneOf([1, 2, 3, 4, 5, 6]),
    children: propTypes.node,
};
