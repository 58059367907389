import React, {useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import noop from 'lodash/noop';

import useParentBlur from '../../common/use-parent-blur';
import RadioCard from '../form-controls/radio-card';
import Paragraph from '../typography/paragraph';

const StyledRadioCard = styled(RadioCard)`
    & + .RadioCard {
        margin-top: 16px;
    }
`;

const HelperText = styled(Paragraph)`
    margin: 0 14px;
    padding-top: 16px;
`;

const RadioButtonsField = React.forwardRef((
    {
        className,
        radioButtonsContainer: RadioButtonsContainer = 'div',
        radioButtonComponent: RadioButtonComponent = StyledRadioCard,
        name,
        helperText,
        value: currentValue,
        onChange = noop,
        onBlur = noop,
        errorMessage,
        options,
        disabled,
    },
    ref
) => {
    // Fallback ref so we can get onBlur events without an externally provided ref
    const internalRef = useRef(null);

    // Detect focus outside the group of radios
    useParentBlur(ref || internalRef, onBlur);

    return (
        <RadioButtonsContainer
            ref={ref || internalRef}
            className={classnames('RadioButtonsField', className)}
        >
            {(options || []).map(({value, text, ...rest}) => (
                <RadioButtonComponent
                    {...rest}
                    key={value}
                    disabled={disabled}
                    label={text}
                    name={name}
                    value={value}
                    checked={value === currentValue}
                    onChange={() => void onChange(value)}
                />
            ))}

            {!!(errorMessage || helperText) && (
                <HelperText
                    size="xSmall"
                    color={errorMessage ? 'error' : 'tertiary90'}
                >
                    {errorMessage || helperText}
                </HelperText>
            )}
        </RadioButtonsContainer>
    );
});

RadioButtonsField.propTypes = {
    className: propTypes.string,
    radioButtonsContainer: propTypes.elementType,
    radioButtonComponent: propTypes.elementType,
    name: propTypes.string,
    helperText: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    errorMessage: propTypes.string,
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string,
        text: propTypes.node,
    })),
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

RadioButtonsField.displayName = 'RadioButtonsField';

export default RadioButtonsField;
