import styled from "styled-components";
import Background from "../../assets/login/background.jpg";
import BackgroundSGP from "../../assets/login/background_sgp.jpg";
import {lgDown, smDown} from "../../ui/common";
import QnectButton from "../qnect-ui/qnect-button";
import SwipeableViews from "react-swipeable-views";

export const StyledTopBackground = styled.div`
  height: 460px;
  width: 100%;
  background-image: ${({country}) => country === 'SGP' ? 'url(' + BackgroundSGP +')' : 'url(' + Background +')'};
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${lgDown`
    height: 450px;
  `}
  ${smDown`
    height: fit-content;
  `}
`;

export const HeaderWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
  ${lgDown`
    height: 25%;
  `}
`;

export const StyledMiddleBackground = styled.div`
  background-color: #f6f8fa;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  ${lgDown`
    padding: 0 0 40px 24px;
  `}
  ${smDown`
    padding: 60px 0 40px 16px;
  `}
`;

export const StyledBottomBackground = styled(StyledMiddleBackground)`
  background-color: white;
  height: 803px;
  ${lgDown`
    height: 100%;
    min-height: 611px;
    padding-bottom: 40px;
  `}
  ${smDown`
    padding: 0 0 40px 16px;
    min-height: 520px;
  `}
`;

export const StyledInfoBox = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${lgDown`
    width: 646px;
    height: 120px;  
    display: block;
  `}
  ${smDown`
    margin: 16px 16px 30px;
    height: 100%;
    width: auto;
    display: block;
    padding: 16px;
  `}
`;

export const StyledInfoWrapper = styled.div`
  width: 80%;
  ${lgDown`
    width: 80%;
  `}
  ${smDown`
    width: 100%;
`}
`;

export const StyledInfoTitle = styled.div`
  color: #003da5;
  font-family: Stag;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  padding-top: 40px;
  padding-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${lgDown`
    font-size: 24px;
    padding-top: 24px;
    padding-left: 24px;
  `}
  ${smDown`
    font-size: 20px;
    line-height: 24px;
    padding: 0;
  `}
`;

export const StyledInfoSubTitle = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 18px;
  line-height: 28px;
  padding-left: 30px;
  ${lgDown`
    padding-left: 24px;
    min-height: 140px;
  `}
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    padding: 8px 0 16px;
    min-height: 184px;
  `}
`;

export const QnectButtonWraper = styled.div`
  padding-top: 54px;
  padding-right: 30px;
  flex-shrink:0;
  ${lgDown`
    padding-top: 25px;
    padding-right: 24px;
    padding-left: 24px;
  `}
  ${smDown`
    padding: 0;
  `}
`;

export const StyledQnectButton = styled(QnectButton)`
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  background-color: #1d4fbb;
  ${lgDown`
    box-shadow: none;
    height: 50px;
  `}
  ${smDown`
    line-height: 28px;
    font-size: 16px;
    height: 35px;
  `}
`;

export const StyledTitle = styled.div`
  color: #0a1f44;
  font-family: Stag;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 64px;
  ${lgDown`
    padding-top: 30px;
  `}
`;

export const StyledTitleRow = styled(StyledTitle)`
  display: flex;
  justify-content: space-between;
  ${lgDown`
    display: block;
  `}
`;

export const StyledItemWrapper = styled.div`
  display: flex;
`;

export const StyledPendingSwipeable = styled(SwipeableViews)`
  @media (max-width: 1279px) {
    padding: 0 800px 0 0;
  }
  @media (max-width: 1150px) {
    padding: 0 700px 0 0;
  }
  @media (max-width: 1080px) {
    padding: 0 600px 0 0;
  }
  @media (max-width: 959px) {
    padding: 0 500px 0 0;
  }
  @media (max-width: 849px) {
    padding: 0 400px 0 0;
  }
  @media (max-width: 759px) {
    padding: 0 300px 0 0;
  }
  @media (max-width: 650px) {
    padding: 0 200px 0 0;
  }
  @media (max-width: 550px) {
    padding: 0 100px 0 0;
  }
  @media (max-width: 450px) {
    padding: 0 100px 0 0;
  }
  @media (max-width: 450px) {
    padding: 0 20px 0 0;
  }
`;

export const StyledSwipeable = styled(StyledPendingSwipeable)`
  @media (max-width: 1279px) {
    padding: 0 700px 0 0;
  }
  @media (max-width: 1080px) {
    padding: 0 600px 0 0;
  }
  @media (max-width: 959px) {
    padding: 0 500px 0 0;
  }
  @media (max-width: 849px) {
    padding: 0 400px 0 0;
  }
  @media (max-width: 759px) {
    padding: 0 300px 0 0;
  }
  @media (max-width: 650px) {
    padding: 0 200px 0 0;
  }
  @media (max-width: 550px) {
    padding: 0 100px 0 0;
  }
  @media (max-width: 450px) {
    padding: 0 50px 0 0;
  }
  @media (max-width: 390px) {
    padding: 0 20px 0 0;
  }
`;

export const StyledNewsSwipeable = styled(SwipeableViews)`
  @media (max-width: 1279px) {
    padding: 0 700px 0 0;
  }
  @media (max-width: 1080px) {
    padding: 0 600px 0 0;
  }
  @media (max-width: 959px) {
    padding: 0 550px 0 0;
  }
  @media (max-width: 859px) {
    padding: 0 450px 0 0;
  }
  @media (max-width: 759px) {
    padding: 0 350px 0 0;
  }
  @media (max-width: 650px) {
    padding: 0 250px 0 0;
  }
  @media (max-width: 450px) {
    padding: 0 150px 0 0;
  }
`;

export const StyledLocatePolicyWrapper = styled(StyledItemWrapper)`
  cursor: pointer;
`;

export const StyledWhatsNewsTitle = styled(StyledTitle)`
  padding-top: 64px;
  ${lgDown`
    padding-top: 30px;
  `}
`;

export const StyledSectionWrapper = styled.div`
  width: 1110px;
  ${lgDown`
    width: 100%;
  `}
`;

export const StyledMessage = styled.div`
    margin-top: 38px;
    font-family: Inter-Regular;
    font-size: 16px;
    color: #0A1F44;
    @media (max-width:767.95px){
      margin-right: 16px;
    }
`;
