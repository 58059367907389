import MuiCircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import property from 'lodash/property';

const CircularProgress = styled(MuiCircularProgress)`
    &.MuiCircularProgress-colorPrimary {
        color: ${property('theme.color.primary')};
    }
`;

CircularProgress.displayName = 'CircularProgress';

export default CircularProgress;
