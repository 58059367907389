import {css} from 'styled-components';

/**
 * CSS rules specific to IE11 and Legacy Edge.
 *
 * @param {*} ruleFragments - styled components rules
 * @returns {*} MS-specific wrapper around rules
 */
export function ms(...ruleFragments) {
    return css`
        @supports (-ms-ime-align: auto) {
            ${css(...ruleFragments)}
        }
    `;
}

/**
 * CSS rules to be ignored by IE11 and Legacy Edge.
 *
 * @param {*} ruleFragments - styled components rules
 * @returns {*} MS-specific wrapper around rules
 */
export function notMs(...ruleFragments) {
    return css`
        @supports not (-ms-ime-align: auto) {
            ${css(...ruleFragments)}
        }
    `;
}
