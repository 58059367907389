import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import property from 'lodash/property';

import {smDown, smUp} from '../../common/breakpoints';
import Modal from '../modals/modal';
import Heading from '../typography/heading';
import Table from './table';

const StyledModal = styled(Modal)`
    ${smUp`
        border-radius: 10px;
        padding: 24px 32px;
        width: calc(100vw - 64px);
    `}
`;

const StyledHeading = styled(Heading)`
    ${smDown`
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        margin: 0;
        background: linear-gradient(to top, transparent 0px, ${property('theme.color.white')} 8px, ${property('theme.color.white')} 100%);
        padding: 16px 24px;
    `}

    ${smUp`
        margin: 0 0 32px;
    `}
`;

const TableContainer = styled.div`
    ${smDown`
        margin-top: 48px;
    `}

    ${smUp`
        margin: 0 -32px;
        min-height: 200px;
        max-height: 50vh;
        overflow-x: visible;
        overflow-y: auto;

        table {
            max-width: 100%;
        }
    `}
`;

const TableContainerInner = styled.div`
    ${smUp`
        padding: 0 0 0 32px;
        overflow-x: visible;
    `}
`;

export default function ModalTable({
    heading,
    columns,
    rows,
    isOpen,
    onClose,
    onReleaseFocus,
}) {
    return (
        <StyledModal
            {...{isOpen, onClose, onReleaseFocus}}
            isFullWidthOnMobile
        >
            {!!heading && (
                <StyledHeading
                    size="medium"
                    weight="medium"
                    color="primary"
                >
                    {heading}
                </StyledHeading>
            )}
            <TableContainer>
                <TableContainerInner>
                    <Table
                        isHeaderSticky
                        stickyHeaderBackgroundColor="white"
                        {...{columns, rows}}
                    />
                </TableContainerInner>
            </TableContainer>
        </StyledModal>
    );
}

ModalTable.propTypes = {
    heading: propTypes.string,
    isOpen: propTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    columns: propTypes.array, // defined in Table
    rows: propTypes.array,    // defined in Table
    /* eslint-enable react/forbid-prop-types */
    onClose: propTypes.func,
    onReleaseFocus: propTypes.func,
    children: propTypes.node,
};
