import React, {useEffect} from 'react';
import Policy from "../components/policy/policy";
import {connect} from "react-redux";
import {_getShowPageBroken, _hideSpinner, _setShowPageBroken} from "../store/system/service";


const PolicyPage = (props) => {

    useEffect(() => {
        if (props.showPageBroken) {
            props.history.push('/pageUnavailable');
            props.setShowPageBroken(false);
            props.hideSpinner();
        }
    }, [props.showPageBroken]);

    return (
        <div>
            <Policy/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        showPageBroken: _getShowPageBroken(state)
    }
}

const mapDispatchToProps = {
    setShowPageBroken: _setShowPageBroken,
    hideSpinner: _hideSpinner
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyPage);
