import React from 'react';
import styled from 'styled-components';
import {TextField as MuiTextField} from '@material-ui/core';
import property from 'lodash/property';

const StyledMuiTextField = styled(MuiTextField)`
    &.MuiFormControl-root {
        width: 100%;

        .MuiFormLabel-root {
            color: ${property('theme.color.tertiary90')};
            font-family: ${property('theme.typography.text.fontFamily')};
        }

        .Mui-focused {
            color: ${property('theme.color.primary')};
        }

    .MuiFormLabel-root.Mui-disabled {
        color: #c9ced6 !important;
    }      
        

    .MuiInputBase-root {
      border-radius: 12px;
      border: 1px solid #c9ced6;
      background-color: ${property("theme.color.white")};
      color: ${property("theme.color.black")};
      font-family: ${property("theme.typography.text.fontFamily")};
      font-weight: ${property("theme.typography.text.fontWeight.medium")};
    }

    .MuiInputBase-root.Mui-focused {
      border: 1px solid #1547b1;
    }

    .MuiInputBase-root.Mui-disabled {
      color: #000 !important;
      cursor: default;
      background-color: #c9ced6;
    }

    .MuiFilledInput-underline::before {
      border-bottom-color: transparent;
    }

    .MuiFilledInput-underline::after {
      border-bottom-color: transparent;
    }

    .MuiFormHelperText-root {
      margin-top: 0;
      padding: 0 0 15px;
      color: ${property('theme.color.error')};
      font-family: ${property("theme.typography.text.fontFamily")};
      font-family: Inter;
      font-size: 16px;
      line-height: 24px;
      margin-left: 0;
      margin-right: 0;
      display: contents !important;
      height: 0px;
    }

    .MuiFilledInput-input {
      padding: 0;
      height: 61px;
      padding-left: 20.5px;
      font-size: 18px;
      line-height: 24px;
      color: #0a1f44;
    }
  }

    // .MuiFormLabel-root.Mui-error,
    .MuiFormHelperText-root.Mui-error {
        color: ${property('theme.color.error')};
    }

    .MuiFilledInput-underline.Mui-error::after {
        border-bottom-color: transparent;
    }

    .MuiFormLabel-root.Mui-error {
      color: #0a1f44;
    }

    .MuiInputBase-root.Mui-focused.Mui-error,
    .MuiInputBase-root.Mui-error {
      border: 1px solid ${property('theme.color.error')};
    }
`;

const TextInput = React.forwardRef((props, ref) => (
    <StyledMuiTextField
        {...props}
        inputRef={ref}
        InputLabelProps={{
            shrink: true
        }}
    />
));

TextInput.displayName = 'TextInput';

export default TextInput;
