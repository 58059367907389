import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {_getLang} from "../../../store/system/service";
import {connect} from "react-redux";
import useConfig from "../../qnect-ui/qnect-config";
import {lgDown, mdDown} from "../../../ui/common";
import zxcvbn from "zxcvbn";
import {_getUser} from "../../../store/user/service";

const StyledDiv = styled.div`
    height: ${({lang}) => lang === 'zh' ? '247px' : '384px'};
    width: 270px;
    background-color: #4E5D78;
    opacity: 90%;
`;

const PointerDiv = styled.div`
    position: relative;
    border-top: 17px solid transparent;
    border-right: 29px solid #4E5D78;
    border-bottom: 15px solid transparent;
    width: 30px;
    bottom: ${({lang}) => lang === 'zh' ? '178px' : '264px'};
    right: 30px;
    opacity: 90%;
`;

const MessageDiv = styled.div`
    position: absolute;
`;

const MainDiv = styled.div`
    position: relative;
    top: ${({lang}) => lang === 'zh' ? '-119px' : '-168px'};
    left: 490px;
    color: white;
    font-family: Inter-Regular;
    font-size: 16px;
    display: ${({isFocused}) => !isFocused ? 'none' : 'block'}; 
    ${lgDown`
        left: 394px;
    `}
    ${mdDown`
        display: none;
    `}
`;

const RequirementsDiv = styled.div`
    padding-top:10px;
    padding-left: 10px;
    padding-right: 25px;
    
    ul {
        padding-left: 22px;
        margin-top: 10px;
    }
`;

const StyledList = styled.li`
    ${({passed}) => {
        if (passed) {
            return css`
                    list-style-type:  '✓'; 
                    padding-left: 7px;
                    margin-left: -7px; 
                `
        } else {
            return css`
                    list-style-type:  disc;   
                    opacity: 30%;
                `
        }
    }}
`;

const PasswordReq = (props) => {

    const [cond1, setCond1] = useState(false);
    const [cond2, setCond2] = useState(false);
    const [cond3, setCond3] = useState(false);
    const [cond4, setCond4] = useState(false);
    const [cond5, setCond5] = useState(false);
    const [cond6, setCond6] = useState(false);
    const [cond7, setCond7] = useState(false);
    const cfg = useConfig(props.lang);
    const passwordMinLength = props.passwordMinLength;

    const isPasswordCompliant = (isPasswordCompliant) => {
        props.isPasswordCompliant(isPasswordCompliant);
    }

    function isEmpty(val){
        return val === undefined || val == null || val.length <= 0;
    }

    const isCommonPassword = (password) => {
        var isCommonPass = false;
        var result=zxcvbn(password);
        console.log("Result: " + result.score);
        if(!isEmpty(result)) {
            if(result.score < 2){
                isCommonPass = true;
            }
        }

        return isCommonPass;
    }

    useEffect(() => {
        if (props.password.length >= passwordMinLength) {
            setCond1(true);
        } else {
            setCond1(false);
        }

        let includesUsername = false;
        if(!isEmpty(props.email)) {
            let usernameWithoutDomain = props.email.split("@") ? props.email.split("@")[0] : props.email;
            if(!isEmpty(usernameWithoutDomain)) {
                if(props.password.toLowerCase().includes(usernameWithoutDomain.toLowerCase())) {
                    includesUsername = true;
                    console.log("Includes Username");
                }
            }
        }

        let isCommonPass = false;
        if(isCommonPassword(props.password)) {
            isCommonPass = true;
            console.log("Common Password");
        }

        if(includesUsername || isCommonPass) {
            setCond7(false);
        } else {
            setCond7(true);
        }

        let cond2Checker = 0;

        if (props.password.match(/[a-z]/g)){
            cond2Checker++;
            setCond3(true);
        } else {
            setCond3(false);
        }

        if (props.password.match(/[A-Z]/g)){
            cond2Checker++;
            setCond4(true);
        } else {
            setCond4(false);
        }

        if (props.password.match(/\d/g)){
            cond2Checker++;
            setCond5(true);
        } else {
            setCond5(false);
        }

        if (props.password.match(/[^A-Za-z0-9]/g)){
            cond2Checker++;
            setCond6(true);
        } else {
            setCond6(false);
        }

        if (cond2Checker > 2) {
            setCond2(true);
        } else {
            setCond2(false);
        }
    }, [props.password]);

    useEffect(() => {
        if(cond1 && cond2 && cond7) {
            isPasswordCompliant(true);
        } else {
            isPasswordCompliant(false);
        }

    }, [cond1, cond2, cond7]);



    return (
        <>
            <MainDiv isFocused={props.isFocused} lang={props.lang}>
                <MessageDiv>
                    <StyledDiv lang={props.lang}>
                        <RequirementsDiv>
                            { cfg('labels.password.title') }
                            <ul id="passwordReqUl">
                                <StyledList passed={cond1}> { cfg('labels.password.line0')  + passwordMinLength + cfg('labels.password.line1') } </StyledList>
                                <StyledList passed={cond7}> { cfg('labels.password.line7') } </StyledList>
                                <StyledList passed={cond2}> { cfg('labels.password.line2') } </StyledList>
                                <ul>
                                    <StyledList passed={cond3}> { cfg('labels.password.line3') } </StyledList>
                                    <StyledList passed={cond4}> { cfg('labels.password.line4') } </StyledList>
                                    <StyledList passed={cond5}> { cfg('labels.password.line5') } </StyledList>
                                    <StyledList passed={cond6}> { cfg('labels.password.line6') } </StyledList>
                                </ul>

                            </ul>
                        </RequirementsDiv>


                    </StyledDiv>
                    <PointerDiv lang={props.lang}></PointerDiv>
                </MessageDiv>
            </MainDiv>
        </>
    )


}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        user: _getUser(state)
    }
}

export default connect(mapStateToProps)(PasswordReq);

