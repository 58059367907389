import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";

import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import StyledTextFieldWrapper from "../style-text-field-wrapper";
import QnectTextField from "../../qnect-ui/qnect-text-field";

import { connect } from "react-redux";
import { _getPolicy } from "../../../store/policy/service";
import { _isNotDecimalChar } from "../../../utils/utility";
import {_getCountry} from "../../../store/system/service";
import QnectSelectField from "../../qnect-ui/qnect-select-field";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 44px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  input_wrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "31px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "39px"
    }
  },
  country: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
      width: "112px",
      flexShrink: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "20px",
      width: "160px"
    }
  },
  phone_number: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      flex: 1
    }
  },
  error_message: {
    margin: "8px 0",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    color: "#d54040",
    wordBreak: "keep-all",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {}
  },
  help: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#003da5",
    cursor: "pointer",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "4px"
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "22px"
    }
  },
  actions: {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "44px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "60px",
      padding: "0 24px"
    }
  }
}));

const ForgetEmailDialog = ({
  open,
  onHelp,
  onClose,
  onSubmit,
  policy,
  errorMessage,
  setErrorMessage,
  cfg,
    ...props
}) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    setCountryCode(props.country === 'SGP' ? '65' : props.country === 'MYS'? '60':'852');
  }, [props.country]);

  useEffect(() => { if (open) {setPhoneNumber('')}}, [open])

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle title={cfg("forgotEmail.label")} onClose={onClose} lang={props.lang}/>
        <DialogContent>
          <div className={classes.message}>
            {cfg("forgotEmail.message")}
          </div>
          <div className={classes.input_wrapper}>
            <div className={classes.country}>
              <StyledTextFieldWrapper>
                <QnectSelectField
                  field="registrationCountryCode"
                  value={countryCode}
                  onChange={(evt) => setCountryCode(evt.target.value)}
                  onBlur={(evt) => setCountryCode(evt.target.value)}
                />
              </StyledTextFieldWrapper>
            </div>
            <div className={classes.phone_number}>
              <StyledTextFieldWrapper>
                <QnectTextField
                  field="inputField"
                  label={cfg("forgotEmail.mobileNumber")}
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                    setErrorMessage("");
                  }}
                  onKeyPress={event => {
                    if (_isNotDecimalChar(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </StyledTextFieldWrapper>
            </div>
          </div>
          <div className={classes.error_message}>{errorMessage}</div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div className={classes.help} onClick={onHelp}>
            {cfg("forgotEmail.needHelp")}
          </div>
          <ButtonWrapper>
            <QnectButton onClick={() => onSubmit(phoneNumber, countryCode)}>
              {cfg("forgotEmail.submit")}
            </QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    policy: _getPolicy(state),
    country: _getCountry(state)
  };
};

export default connect(mapStateToProps)(ForgetEmailDialog);
