import React, {useEffect, useState, useRef} from "react";
import QbeLogo from "../assets/QBE-logo-on-light.png";
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Illustration } from '../assets/Illustration.svg';
import { useSelector } from "react-redux";
import { _getLang, _setLang, _getCountry} from "../store/system/service";
import useConfig from "../components/qnect-ui/qnect-config";

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        background: "rgb(246,248,250)",
        minHeight: "calc(100vh - 64px)",
        [theme.breakpoints.down("sm")]: {
            minHeight: "calc(100vh - 87px)",
        },
    },
    topBg: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "432px"
        }
    },
    topRow: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            height: "79px",
            padding: "0 24px",
            alignItems: "center",
            boxSizing: 'borderbox',
        },
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            padding: "0 12px",
            alignItems: "center",
            borderBottom: "1px solid #E1E4E8"
        },
        [theme.breakpoints.up("lg")]: {
            alignItems: "flex-end",
            height: "75px"
        }
    },
    logo: {
        [theme.breakpoints.down("sm")]: {
            width: "70px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "122px",
            height: "35px"
        },
        cursor: "pointer"
    },
    main: {
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)",
        [theme.breakpoints.down("md")]: {
            marginTop: "36px",
            marginLeft: '24px',
            marginRight: '24px',
        },
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            width: "92%"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "-220px auto 0",
            padding: "30px 37px 44px 39px",
            height: "607px",
            width: "630px"
        }
    },
    title: {
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            marginBottom: "0px",
            color: "#003DA5",
            fontSize: "24px",
            lineHeight: '36px',
            padding: '30px 0 40px 0',

        },
        [theme.breakpoints.down("sm")]: {
            padding: '20px 0 20px 0',
            lineHeight: '24px',
            color: "#003DA5",
            fontSize: "18px",
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "57px",
            color: "#003DA5"
        }
    },
    message: {
        color: "#0A1F44",
        fontFamily: "Inter",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "24px",
        [theme.breakpoints.down("md")]: {
            padding: '48px 84px 40px 82px',
            fontSize: '18px',
            lineHeight: '25px',
        },
        [theme.breakpoints.down("sm")]: {
            padding: '20px 20px 40px 20px',
            fontSize: "12px",
            lineHeight: "normal",
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "18px",
            "&:last-child": {
                marginTop: "37px"
            }
        }
    },
    image: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "179px",
                height: '155px',
            }
        },

    },
    Mlanguage: {
        color: "#1D4FBB",
        fontWeight: "500"
    },
}));

const BrokenPage = (props) => {
    const classes = useStyles();
    const [lang, setLang] = useState(props.lang);
    const cfg = useConfig(lang)
    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        console.log('setting language as ' + newLang);
    }

    const onLogoClick = () => {
        if (props.country === 'SGP') {
            window.open('https://www.qbe.com/sg/about-qbe', '_blank');
        } else if(props.country === 'MYS') {
            window.open('https://www.qbe.com/my/about-qbe', '_self');
        }else{
            window.open('https://www.qbe.com/hk/en/about-qbe', '_blank');
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <div className={classes.topBg}>
                    <div className={classes.topRow}>
                        <img src={QbeLogo} className={classes.logo} alt="QBE" onClick={onLogoClick} />
                        {(props.country !== 'SGP' && props.country !== 'MYS') &&
                            <span
                                className={classes.Mlanguage}
                                onClick={() => switchLanguage()}
                            >
                                {lang === "en"
                                    ? cfg("labels.lang_zh")
                                    : cfg("labels.lang_en")}
                            </span>
                        }
                    </div>
                </div>
                <div className={classes.main}>
                    <div className={classes.title}>
                        {cfg('pageUnavailable.title')}
                    </div>
                    <div className={classes.image}>
                        <Illustration />
                    </div>
                    <div className={classes.message}>
                        {props.country === 'SGP' ?
                            <>
                                {cfg('pageUnavailable.message_sgp')}
                                <a href={'mailto:myaccount.sg@qbe.com'}>myaccount.sg@qbe.com</a>
                                {cfg('pageUnavailable.message_sgp2')}
                            </>
                            :
                            props.country === 'MYS'?
                            cfg('pageUnavailable.message_my') :cfg('pageUnavailable.message')
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state),
    }
}

const mapDispatchToProps = {
    setLang: _setLang,
}

export default withRouter(connect(mapStateToProps, null)(BrokenPage));