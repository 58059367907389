// Dark theme to test/demonstrate theme switching. Not intended for production.

const primaryColors = [
    '#002185', // Primary10
    '#003399',
    '#003da5',
    '#1547b1',
    '#1d4fbb',
    '#4a69c5',
    '#6d84cf', // Primary
    '#98a6dc',
    '#003DA5', // Primary90
];

const DEFAULT_PRIMARY_COLOR = 6;

const secondaryColors = [
    '#00569a', // Secondary10
    '#0076bc',
    '#0087d0',
    '#009ae4',
    '#00a8f3',
    '#28b5f5', // Secondary
    '#4ec2f6',
    '#81d3f9',
    '#b3e5fc', // Secondary90
];

const DEFAULT_SECONDARY_COLOR = 5;

const tertiaryColors = [
    '#0a1f44', // Tertiary10
    '#14284b',
    '#283a5b',
    '#364766',
    '#455571',
    '#4e5d78',
    '#596780',
    '#627088', // Tertiary
    '#717d92', // Tertiary90
];

const DEFAULT_TERTIARY_COLOR = 7;

const greyColors = [
    '#8a94a6', // Grey10
    '#98a1b1',
    '#a7aebb',
    '#b0b7c3',
    '#c9ced6',
    '#e1e4e8',
    '#f1f2f4', // Grey
    '#f7f8f9',
    '#fafbfb', // Grey90
];

const DEFAULT_GREY_COLOR = 6;

// 3 - 9 are placeholders
const accentColors = [
    '#702f8a', // Accent1
    '#00c457',
    '#00c1cc', // Accent3

    // Not used (yet)
    '#ffffff', // Accent4
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff', // Accent10
];

const primary = primaryColors[DEFAULT_PRIMARY_COLOR];
const secondary = secondaryColors[DEFAULT_SECONDARY_COLOR];
const tertiary = tertiaryColors[DEFAULT_TERTIARY_COLOR];
const black = '#ffffff';
const white = '#000000';
const grey = greyColors[DEFAULT_GREY_COLOR];
const success = '#00865a';
const warning = '#e0792e';
const error = '#a50000';
const backdrop = '#000000';

const color = {
    primary,
    secondary,
    tertiary,
    black,
    white,
    grey,
    success,
    warning,
    error,
    backdrop,
    ...numberedColors(primaryColors, 'primary'),
    ...numberedColors(secondaryColors, 'secondary'),
    ...numberedColors(tertiaryColors, 'tertiary'),
    ...numberedColors(greyColors, 'grey'),
    ...numberedColors(accentColors, 'accent'),
};

export default color;

/**
 * Convert an array of colors into an object whose properties follow the styled guide's color naming convention.
 *
 * @param {string[]}     colors     - array of colors (typically 9)
 * @param {string}       prefix     - color name prefix
 * @returns {object.<string>} an object that maps style guide color names to their values
 */
function numberedColors(colors, prefix) {
    return colors.reduce((acc, color, index) => Object.assign(acc, {[`${prefix}${10 * (index + 1)}`]: color}), {});
}
