import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 48px 32px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 32px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "28px"
    }
  }
}));

export default function SuccessDialog({
  open = false,
  onClose,
  onConfirm = null
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle
          title="Updated Successfully"
          closable={false}
          onClose={onClose}
        />
        <DialogContent>
          <div className={classes.message}>
            The mobile number has been updated and verified successfully. Please
            use the new mobile number to login to your account next time.
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper>
            <QnectButton onClick={onConfirm}>Confirm</QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SuccessDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
