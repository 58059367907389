import React, {useEffect, useState} from 'react';
import QrCodeDisplay from './qr-code-display';
import Timer from './timer';
import ResetQrCode from './reset-qr-code';
import {
    _hideSpinner,
    _showError,
    _showSpinner,
} from '../../store/system/service';
import {_adhocGenerateQrCode, _getPolicyPayment} from '../../store/policy-payment/service';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import QrCodeSimulatePayment from './qr-code-simulate-payment';
import CheckPaymentComplete from './payment-complete/check-payment-complete';

import styles from './style/qr-code.module.css';

const initQrCodeMetadata = {
    qrCode: "",
    txnRef: ""
};

const QrCode = (props) => {
    const [qrCodeMetadata, setQrCodeMetadata] = useState(initQrCodeMetadata);
    const [isRegenerateQrCode, setIsRegenerateQrCode] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        generateQrCode();
    }, []);

    const handleCompleteCountdown = (reset) => {
        setIsExpired(reset);
        setIsRegenerateQrCode(reset);
    }

    const onResetTime = () => {
        setQrCodeMetadata(initQrCodeMetadata); // clean existing QR Code
        handleCompleteCountdown(false);
        generateQrCode();
    }

    const generateQrCode = () => {
        props.generateQrCode(props.policyPayment).then(resp => {
            setQrCodeMetadata(resp.response && resp.response.response);
        }, (error) => {
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
        });
    }

    const isQrCodeGenerated = () => {
        if (qrCodeMetadata.qrCode) {
            props.hideSpinner();
            return true;
        }
        props.showSpinner();
        return false;
    }

    return <div className={styles.container}>
        <QrCodeDisplay
            qrCodeValue={qrCodeMetadata.qrCode}
            isExpired={isExpired}
        />
        {isQrCodeGenerated() && <QrCodeSimulatePayment
            txnRef={qrCodeMetadata.txnRef}
        />}
        {!isRegenerateQrCode? isQrCodeGenerated() && <Timer
            countdownMillis={600000} // 10 minutes
            onCompleteTime={handleCompleteCountdown}
        />: isQrCodeGenerated() && <ResetQrCode onResetTime={onResetTime} />}
        { isQrCodeGenerated() && <CheckPaymentComplete txnRef={qrCodeMetadata.txnRef} /> }
    </div>
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
    }
};

const mapDispatchToProps = {
    generateQrCode: _adhocGenerateQrCode,
    showError: _showError,
    hideSpinner: _hideSpinner,
    showSpinner: _showSpinner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QrCode));