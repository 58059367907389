import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {lgUp, mdDown, mdUp} from '../../common/breakpoints';
import {grid, gridCell} from '../../common/grid';
import typographyStyles from '../../common/typography-styles';
import ActionIconExitToApp from '../icons/functional/action/exit-to-app';
import NavigationIconClose from '../icons/functional/navigation/close';
import QnectLogo from '../logos/qbe-logo';
import Stepper from '../progress/stepper';

const PageHeaderContainer = styled.div`
    overflow-x: hidden;

    ${mdDown`
        ${grid({columns: 2, rows: 2, rowGap: '8px', columnTemplate: ['1fr', '1fr']})};
    `}

    ${mdUp`
        ${grid({columns: 3, rows: 1, columnTemplate: ['1fr', '600px', '1fr']})}
    `}
`;

const StyledStepper = styled(Stepper)`
    ${mdDown`
        ${gridCell({column: 1, row: 2, colSpan: 2})}
    `}

    ${mdUp`
         ${gridCell({row: 1, column: 2})}
    `};
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0 0 16px;

    ${gridCell({column: 1, row: 1})}

    ${mdUp`
        padding: 0 0 0 16px;
    `}

    ${lgUp`
        padding: 0 0 0 48px;
    `}
`;

const StyledQnectLogo = styled(QnectLogo)`
    width: 100%;
    max-width: 77px;

    ${mdUp`
        max-width: 98px;
    `}
`;

const ExitLinkContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 9px 0 0;

    ${mdDown`
        ${gridCell({column: 2, row: 1})}
    `}

    ${mdUp`
        padding: 0 9px 0 0;

        ${gridCell({column: 3, row: 1})}
    `};

    ${lgUp`
        padding: 0 48px 0 0;
    `}
`;

const ExitLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    cursor: pointer;
    min-width: 36px;
    height: 36px;
    text-decoration: none;

    ${typographyStyles({
        defaultColor: 'white',
        type: 'text',
    })};

    ${mdUp`
        ${gridCell({row: 1, column: 1, colSpan: 1})}
    `};
`;

const ExitText = styled.span.attrs({
    'aria-hidden': true,
})`
    display: none;

    ${lgUp`
        display: inline;
        align-items: center;
    `}
`;

const MobileExitIcon = styled(NavigationIconClose).attrs({
    display: null,
    size: '36px',
})`
    display: inline;

    ${lgUp`
        display: none;
    `}
`;

const DesktopExitIcon = styled(ActionIconExitToApp).attrs({
    display: null,
    size: null,
})`
    display: none;
    padding-left: 10px;
    width: 19px;
    height: 19px;

    ${lgUp`
        display: inline;
    `}
`;

export default function PageHeader({
    activeStep = 0,
    exitLabel,
    onExit,
    steps = [],
}) {
    return (
        <PageHeaderContainer>
            <LogoContainer>
                <StyledQnectLogo width={null} />
            </LogoContainer>
            <StyledStepper steps={steps} activeStep={activeStep} />
            <ExitLinkContainer>
                <ExitLink onClick={onExit}>
                    <ExitText>
                        {exitLabel}
                    </ExitText>
                    <MobileExitIcon aria-label={exitLabel} />
                    <DesktopExitIcon aria-label={exitLabel} />
                </ExitLink>
            </ExitLinkContainer>
        </PageHeaderContainer>
    );
}

PageHeader.propTypes = {
    activeStep: propTypes.number,
    exitLabel: propTypes.string.isRequired,
    onExit: propTypes.func.isRequired,
    steps: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string,
        name: propTypes.string,
    })),
};
