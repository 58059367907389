import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import QnectButton from "../../qnect-ui/qnect-button";
import QnectCheckBox from "../../qnect-ui/qnect-check-box";
import IllustrationOne from "../../../assets/registration/icon_illustration_one.svg";
import IllustrationTwo from "../../../assets/registration/icon_illustration_two.svg";
import IllustrationThree from "../../../assets/registration/icon_illustration_three.svg";
import {ReactComponent as ArrowLeft} from "../../../assets/registration/icon_arrow_left.svg";
import Dialog from "../dialog";
import ButtonWrapper from "../button-wrapper";
import {ReactComponent as Logo} from "../../../assets/login/icon_logo.svg";
import Hidden from "@material-ui/core/Hidden";
import {useSelector} from "react-redux";
import {_getLang} from "../../../store/system/service";
import * as TagManager from "react-gtm-module";

const StyledCheckBoxContent = styled.div`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  position: absolute;
  top: 42%;
  left: 5%;
  cursor: pointer;
`;

const CheckBoxWrapper = styled.div`
  & label {
    margin: 32px auto 0;
  }
`

const useStyles = makeStyles((theme) => ({
    logo: {
        width: "70px",
        height: "20px",
    },
    dialogTitle: {
        [theme.breakpoints.down("md")]: {
            padding: 0,
        },
        [theme.breakpoints.down("sm")]: {
            padding: "12px",
            "& .MuiTypography-root": {
                display: "flex",
                justifyContent: "space-between",
            },
        },
    },
    title: {
        height: "25px",
        width: "41px",
        color: "#003da5",
        textAlign: "center",
        position: "absolute",
        right: "60px",
        top: "30px",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            right: "25px",
        },
        [theme.breakpoints.down("sm")]: {
            lineHeight: "23px",
            position: "static",
            fontSize: "16px",
            right: "60px",
            top: "20px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            width: "920px",
            padding: "14px 76px 52px",
            fontSize: "18px",
            lineHeight: "25px",
            fontFamily: "Inter",
            [theme.breakpoints.down("md")]: {
                width: "596px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "100%",
                padding: 0,
                margin: 0,
                borderRadius: 0,
                fontSize: "16px",
                lineHeight: "20px",
            },
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "none",
        },
        [theme.breakpoints.down("sm")]: {
            "& .MuiDialog-paperScrollPaper": {
                maxHeight: "none",
            },
        },
    },
    illustration: {
        margin: "52px auto 0",
        display: "block",
        [theme.breakpoints.down("md")]: {
            height: "244px",
            width: "356px",
            margin: "38px auto 34px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "172px",
            width: "251px",
            margin: "60px auto 34px",
        },
    },
    stepper: {
        backgroundColor: "transparent",
        width: "100% !important",
        margin: "90px auto 26px",
        "& .MuiMobileStepper-dot": {
            width: "6px",
            height: "6px",
            borderRadius: "6px",
            opacity: "0.3",
            backgroundColor: "#003DA5",
        },
        "& .MuiMobileStepper-dotActive": {
            opacity: "1",
            color: "#003DA5",
            width: "20px",
            height: "6px",
            borderRadius: "6px",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "52px",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "64px auto 30px",
        },
    },
    contentTitle: {
        margin: "0 auto",
        fontSize: "24px",
        lineHeight: "36px",
        fontWeight: 500,
        color: "#003da5",
        letterSpacing: "1px",
        textAlign: "center",
        fontFamily: "Stag",
        height: "36px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
            lineHeight: "24px",
        },
    },
    contentMessage: {
        marginTop: "8px",
        textAlign: "center",
        color: "#0a1f44",
        [theme.breakpoints.down("sm")]: {
            height: "25px",
            width: "303px",
            padding: 0,
            margin: "8px auto 0",
            display: "block",
        },
    },
    actionDialog: {
        display: "block",
        justifyContent: "center",
        padding: "8px 24px",
        margin: "24px auto 0",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            marginBottom: 0,
        },
        [theme.breakpoints.down("sm")]: {
            margin: "40px auto 51px",
        },
        "& button": {
            marginBottom: "10px",
            width: "180px",
            lineHeight: "20px",
            fontSize: "20px",
            fontWeight: 500,
            fontFamily: "Inter",
            boxShadow: "none",
            [theme.breakpoints.down("md")]: {
                marginBottom: "5px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "160px",
                display: "block",
                margin: "0 auto 0",
                fontSize: "18px",
            },
        },
    },
    contentDialog: {
        margin: "0 auto !important",
        width: "80%",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            width: "auto"
        },
    }
}));

export default function AfterRegDialog({open, onClose, skipFlashUpdate, cfg, country}) {
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);
    const [doNotShowAgainValue, setDoNotShowAgainValue] = React.useState(false);

    const lang = useSelector(state => _getLang(state));

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Left-Arrow'
            }
        })
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const skipFlash = (value) => {
        setDoNotShowAgainValue(value)

        skipFlashUpdate(value ? "Y" : "N");
    }

    return (
        <>
            <Dialog className={classes.dialog} open={open} onClose={onClose}>
                <DialogTitle className={classes.dialogTitle} onClick={() => onClose(doNotShowAgainValue)}>
                    <Hidden mdUp>
                        <Logo className={classes.logo}/>
                    </Hidden>
                    <div className={classes.title}>{activeStep !== 2 && cfg('labels.portfolioSplashScreen.skip')}</div>
                </DialogTitle>
                <DialogContent className={classes.contentDialog}>
                    {activeStep !== 0 ? <StyledArrowLeft onClick={handleBack}/> : null}
                    {activeStep === 0 ? (
                        <img src={IllustrationOne} className={classes.illustration}/>
                    ) : activeStep === 1 ? (
                        <img src={IllustrationTwo} className={classes.illustration}/>
                    ) : (
                        <img src={IllustrationThree} className={classes.illustration}/>
                    )}
                    <MobileStepper
                        className={classes.stepper}
                        style={{width: "100%"}}
                        variant="dots"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                        nextButton={<Button disabled/>}
                        backButton={<Button disabled/>}
                    />
                    <div className={classes.contentTitle}>
                        {activeStep === 0 ? cfg('labels.portfolioSplashScreen.contentTitle1') : activeStep === 1 ? cfg('labels.portfolioSplashScreen.contentTitle2') : cfg('labels.portfolioSplashScreen.contentTitle3')}
                    </div>
                    <div className={classes.contentMessage}>
                        {activeStep === 0
                            ? cfg('labels.portfolioSplashScreen.contentMessage1')
                            : activeStep === 1
                                ? cfg('labels.portfolioSplashScreen.contentMessage2')
                                : (lang === 'en' ? "to let QBE " + (country === "SGP" ? "Singapore" : "Hong Kong")  + " better contact or service" : cfg('labels.portfolioSplashScreen.contentMessage3_zh'))}
                    </div>
                </DialogContent>
                <DialogActions className={classes.actionDialog}>
                    <ButtonWrapper>
                        <QnectButton onClick={activeStep > 0 ? () => onClose(doNotShowAgainValue) : handleNext}>
                            {activeStep > 0 ? cfg("labels.getStarted") : cfg("labels.continue")}
                        </QnectButton>
                    </ButtonWrapper>
                    <CheckBoxWrapper>
                        <QnectCheckBox
                           checked={doNotShowAgainValue}
                           field="doNotShowAgain"
                           onChange={(value) => {
                               skipFlash(value)
                           }}>
                            <StyledCheckBoxContent>{ cfg('labels.portfolioSplashScreen.doNotShowAgain') }</StyledCheckBoxContent>
                        </QnectCheckBox>
                    </CheckBoxWrapper>
                </DialogActions>
            </Dialog>
        </>
    );
}
