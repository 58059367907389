import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import {smDown} from '../../common/breakpoints';
import Actions from '../buttons/actions';
import Modal from './modal';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    ${smDown`
        padding-top: 24px;
    `}
`;

const StyledActions = styled(Actions)`
    padding-top: 48px;
`;

export default function ActionModal({
    className,
    isFullWidthOnMobile,
    isOpen,
    isLoading,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    onClose,
    onReleaseFocus,
    children,
}) {
    return (
        <Modal
            className={classnames('ActionModal', className)}
            {...{isFullWidthOnMobile, isOpen, isLoading, onClose, onReleaseFocus}}
        >
            <StyledContainer>
                {children}

                <StyledActions
                    {...{
                        primaryAction,
                        secondaryAction,
                        tertiaryAction,
                    }}
                />
            </StyledContainer>
        </Modal>
    );
}

ActionModal.propTypes = {
    className: propTypes.string,
    isFullWidthOnMobile: propTypes.bool,
    isOpen: propTypes.bool,
    isLoading: propTypes.bool,
    primaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    secondaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    tertiaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    onClose: propTypes.func,
    onReleaseFocus: propTypes.func,
    children: propTypes.node,
};
