import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import styled, {css} from 'styled-components';

import useAutoComplete from '../../common/use-auto-complete';

const Form = styled.form`
    margin: 0 auto;
    max-width: 346px;
`;

// Tuck in the kids
const tuckInMargins = css`
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
`;

const DescriptionSlot = styled.div`
    margin-bottom: 36px;

    ${tuckInMargins}
`;

const FieldsSlot = styled.div`
    ${tuckInMargins}
`;

const SubmissionSlot = styled.div`
    margin-top: 72px;

    ${tuckInMargins}
`;

export default function FormLayout({
    className,
    description,
    fields,
    submission,
    onSubmit,
}) {
    return (
        <Form
            className={classnames('FormLayout', className)}
            onSubmit={onSubmit}
            autoComplete={useAutoComplete()}
            noValidate
        >
            {!!description && (
                <DescriptionSlot>
                    {description}
                </DescriptionSlot>
            )}

            {!!fields && (
                <FieldsSlot>
                    {fields}
                </FieldsSlot>
            )}

            <SubmissionSlot>
                {submission}
            </SubmissionSlot>
        </Form>
    );
}

FormLayout.propTypes = {
    className: propTypes.string,
    description: propTypes.node,
    fields: propTypes.node,
    submission: propTypes.node.isRequired,
    onSubmit: propTypes.func,
};
