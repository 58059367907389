import React, {Component} from 'react';
import PayLaterDetail from "../components/pay-later-detail/pay-later-detail";

const PayLaterDetailPage = () => {
    return (
        <div>
            <PayLaterDetail/>
        </div>
    )
}

export default PayLaterDetailPage;
