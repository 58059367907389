import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import SelectCard from './common/select-card';
import SelectCardLabel from './common/select-card-label';
import RadioButton from './radio-button';

const StyledRadioButton = styled(RadioButton).attrs({
    className: 'StyledRadioButton',
})`
    align-self: start;
    margin-top: 2px;
`;

const Label = styled.label.attrs({
    className: 'Label',
})`
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin: 0;
    cursor: inherit;
    padding: 16px;
    // padding: 24px;
`;
const StyledSpan = styled.span.attrs({
    className: 'StyledSpan',
})`
& .lineBreak{
display: inline-block;
content: ' ';
margin-right: 5px;
}
@media(max-width: 1024px){
    & .lineBreak{
    display: block;
    margin: 0;
    }
}
`;

const StyledIcon = styled.img.attrs({
    className: 'StyledIcon',
})`
  position: absolute;
  bottom: 5px;
  margin-left: 26px;
`;

const mapBr = (str)=>str.split('\n').map((item, i) => {
    return <><StyledSpan>{item}<div className={"lineBreak"}/></StyledSpan></>;
});

const RadioCard = React.forwardRef((
    {
        className,
        disabled,
        label,
        name,
        value,
        icon,
        checked, // must be provided externally for the card to be styled correctly
        onChange,
        ...rest
    },
    ref
) => (
    <SelectCard
        isDisabled={disabled}
        isSelected={checked}
        className={classnames('RadioCard', className)}
    >
        <Label>
            <StyledRadioButton
                {...rest}
                {...{ref, disabled, name, value, checked, onChange}}
            />
            <SelectCardLabel>
                {label}
            </SelectCardLabel>
            {
                icon &&
                <StyledIcon src={icon} />
            }
        </Label>
    </SelectCard>
));

RadioCard.propTypes = {
    className: propTypes.string,
    disabled: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    label: propTypes.string,
    name: propTypes.string,
    icon: propTypes.any,
    value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func,
};

RadioCard.displayName = 'RadioCard';

export default RadioCard;
