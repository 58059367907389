import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 52px 52px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 52px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  row: {
    color: "#0a1f44",
    textAlign: "center",
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        marginTop: "40px"
      },
      margin: "20px auto 0",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      "&:first-child": {
        marginTop: "20px"
      },
      margin: "30px auto 0",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  icon: {
    width: "24px",
    height: "24px",
    marginRight: "4px"
  }
}));

export default function TooManyFailedAttemptsDialog({
  open = false,
  onClose,
  cfg
}) {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose}>
      <DialogTitle title={cfg("labels.tooManyFail")} onClose={onClose} />
      <DialogContent>
        <div className={classes.message}>
          Are you having trouble logging into your account? Please contact QBE's
          Customer Service.
        </div>
        <div>
          <div className={classes.row}>
            <Phone className={classes.icon} />
            +852 2123-1234
          </div>

          <div className={classes.row}>
            <Mail className={classes.icon} />
            customerservice@qbe.com
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

TooManyFailedAttemptsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
