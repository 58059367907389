import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

const Buttons = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 352px;

    > .ReporterButton:not(:first-of-type) {
        margin-top: 32px;
    }
`;

export default function SelectReporter({
    className,
    children,
}) {
    return (
        <div className={classnames('SelectReporter', className)}>
            {React.Children.count(children) > 0 && (
                <Buttons>
                    {children}
                </Buttons>
            )}
        </div>
    );
}

SelectReporter.propTypes = {
    className: propTypes.string,
    children: propTypes.node,
};
