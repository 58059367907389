import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import useConfig from "../qnect-ui/qnect-config";
import {_getLang, _hideModal, _hideSpinner, _setLang, _showModal, _showSpinner} from "../../store/system/service";
import '../../assets/styles/payment.css';
import {_formatPaymentAmount, _getPolPaymentCurrency, _validateForm} from "../../utils/utility";
import {_getPolicyPayment, _storePolicyPayment} from "../../store/policy-payment/service";
import * as TagManager from "react-gtm-module";
import {OPTOUT} from "../auto-renewal/auto-renewal-constants";
import AutoRenewalSectionRenewalStatus from "../auto-renewal/auto-renewal-section-status";
import QnectCheckBox from "../qnect-ui/qnect-check-box";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const OnlinePaymentDetail = (props) => {
    const formId = 'onlinePaymentDetail';
    const cfg = useConfig(props.lang);
    const [lang, setLang] = useState( props.lang);
    const [productName] = useState (props.policyPayment.productName);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [customerName] = useState (props.policyPayment.customerName);
    const [country] = useState (props.policyPayment.country);
    const [paymentAmount] = useState(props.policyPayment.amount);
    const [emailAddress, setEmailAddress] = useState (props.policyPayment.email);
    const [eligibleForAutoRenewal] = useState (props.policyPayment.eligibleForAutoRenewal);
    const [autoRenewalStatus, setAutoRenewalStatus] = useState (props.policyPayment.autoRenewalStatus);
    const [confirmed, setConfirmed] = useState (false);
    const [callFunction, setCallFunction] = useState("");
    const [isCheckedDisclaimer, setIsCheckedDisclaimer] = useState(false);

    useEffect(() => {
        props.setLang('en');

        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Online Payment Detail - Payment - ' + productName,
                vPath: '/online-payment-detail',
                vProduct: productName,
                vPolicyNo: policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(country),
                vPaymentAmount: paymentAmount,
                vCountry: country
            }
        });
        setConfirmed(false);
        setIsCheckedDisclaimer(props.policyPayment.country !== 'HGI');
    }, []);

    const continueNextPage = () => {
        if (_validateForm(formId)) {
            props.policyPayment.email = emailAddress;
            props.storePolicyPayment(props.policyPayment);
            if(eligibleForAutoRenewal && !confirmed && props.policyPayment.autoRenewalStatus === OPTOUT) {
                autoRenewalConfirmModal();
            } else {
                props.history.push('/online-payment-process');
            }
        }
    }

    const updateAutoRenewalStatus = (autoRenewalStatus) => {
        setAutoRenewalStatus(autoRenewalStatus);
        props.policyPayment.autoRenewalStatus = autoRenewalStatus;
        props.storePolicyPayment(props.policyPayment);
        if(autoRenewalStatus === OPTOUT) {
            setConfirmed(false);
            setCallFunction("");
        }
    }

    const autoRenewalConfirmModal = () => {

        props.showModal({
            disableBackDropClick: true,
            flat: true,
            content:
                <div className="auto-renewal-modal_turn-on">
                    <div className="modal-block">
                        <div className="modal-top">
                            <span className="modal-title">{ cfg('labels.autoRenewal.areYouSure') }</span>
                        </div>
                        <div className="modal-body">
                            <div className="note-top">{ cfg('labels.autoRenewal.confirmationAutoRenewalMsg') }</div>
                        </div>
                        <div className="modal-bottom">
                            <div className="button-container">
                                <QnectButton color="primary" className="btn-big" field="turnOffAutoRenewal" onClick={() => {
                                    updateAutoRenewalStatus(OPTOUT);
                                    setConfirmed(true);
                                    props.hideModal();
                                    props.history.push('/online-payment-process');
                                }}/>
                                <QnectButton color="primary" className="btn-big" field="turnOnAutoRenewal" onClick={() => {
                                    setCallFunction("showAutoRenewalTurnOnModal")
                                    setConfirmed(true);
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
        });
    }

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
    }

    return (

        <div className="pageContainer online-payment-detail-page">
            <div className="container">
                  <div className="topRow">
                      <div className="left">
                          <a href="https://www.qbe.com/hk/en/about-qbe">
                              <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                          </a>
                      </div>
                      <div className="right">
                          <div className="languageSelect" onClick={() => switchLanguage()}
                               style={{ visibility: (props.policyPayment.country === 'HKG' ||
                                   props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                              {(lang === 'en') ?
                                  cfg("labels.lang_zh")
                                  :
                                  cfg("labels.lang_en")
                              }
                          </div>
                      </div>
                  </div>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        <div className="main-content-title">{ cfg('labels.payment') }</div>
                        <div className="main-content-box">

                            <div className="form-container">
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField field="policyNumber" value={policyNumber} disabled={true}/>
                                    </InputGroupStyle>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField field="productName" value={productName} disabled={true}/>
                                    </InputGroupStyle>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField field="customerName" value={customerName} disabled={true}/>
                                    </InputGroupStyle>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField field="paymentAmount" value={_formatPaymentAmount(props.policyPayment.country, paymentAmount)} disabled={true}/>
                                    </InputGroupStyle>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField formId={formId} field="emailAddress" value={emailAddress}
                                                        onChange={(event) => setEmailAddress(event.target.value)}/>
                                    </InputGroupStyle>
                                    <div className="form-caption">
                                        <span>{ cfg('labels.confirmWording') }</span>
                                    </div>
                                </div>
                                {
                                    eligibleForAutoRenewal &&
                                    <AutoRenewalSectionRenewalStatus
                                        updateAutoRenewalStatusCallBack={updateAutoRenewalStatus}
                                        autoRenewalStatus={autoRenewalStatus}
                                        callFunction={callFunction}
                                    />
                                }
                                { props.policyPayment.country === 'HGI' &&
                                    <div className="form-field-container">
                                        <InputGroupStyle>
                                            <QnectCheckBox field="ccDetailsDisclaimer"
                                                           checked={isCheckedDisclaimer}
                                                           onChange={(value) => setIsCheckedDisclaimer(value)}
                                                           classNames="CCDetailsDisclaimerCheckBox">
                                            </QnectCheckBox>
                                        </InputGroupStyle>
                                    </div>
                                }
                                <div className="form-field-container button-container button-size1">
                                    <InputGroupStyle>
                                        <QnectButton field="confirm" onClick={continueNextPage} disabled={!(policyNumber && emailAddress && isCheckedDisclaimer)}/>
                                    </InputGroupStyle>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state)
    }
};

const mapDispatchToProps = {
    storePolicyPayment: _storePolicyPayment,
    setLang: _setLang,
    hideSpinner: _hideSpinner,
    showSpinner: _showSpinner,
    showModal: _showModal,
    hideModal: _hideModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlinePaymentDetail));

