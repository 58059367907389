import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {withRouter} from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import QnectButton from "../../components/qnect-ui/qnect-button";
import AfterRegDialog from "../../components/custom/registration/after-reg-dialog";
import Registration from "./registration";
import {_isNotDecimalChar, _scrollToTop, _validateForm, customReplaceAll} from "../../utils/utility";
import {
    _getCountry, _getLang,
    _hideModal,
    _hideSpinner, _isLocalhost, _setCpToken,
    _setLang,
    _showModal,
    _showSpinner, _verifyOtp
} from "../../store/system/service";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import {LanguageSelectDiv} from "../login-style";
import Hidden from "@material-ui/core/Hidden";
import MobileHeader from "../custom/registration/mobile-header";
import ButtonWrapper from "../../components/custom/button-wrapper";
import {smDown, lgDown} from '../../ui/common/breakpoints';
import LabelledCheckbox from "../../ui/components/form-controls/labelled-checkbox";
import {_getUser, _registerEmailAndMobile, _sendOTPToEmailOrMobile, _storeUser} from "../../store/user/service";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as ShowIcon} from "../../assets/registration/icon_show.svg";
import {ReactComponent as HideIcon} from "../../assets/registration/icon_hide.svg";
import PasswordReq from "../custom/registration/password-req";
import BalloonTip from "../ui/balloon-tip/balloon-tip";
import {ReactComponent as SMSIcon} from "../../assets/registration/sms_icon_button.svg";
import {ReactComponent as GreenDone} from "../../assets/GreenDone2.svg";
import AuthStepEmailMobileOtpDialog from "../custom/registration/auth-step-email-mobile-otp-dialog";
import PopupDialogue from "../custom/popup-dialogue";
import * as TagManager from "react-gtm-module";
import QnectSelectField from "../qnect-ui/qnect-select-field";


const Title = styled.div`
  font-family: "Stag";
  font-size: 40px;
  line-height: 48px;
  color: #003da5;
  text-align: left;
  margin-left: -70px;
  margin-bottom: 60px;
  display: flex;
  ${lgDown`
    font-size: 32px;
    line-height: 40px;
  `}
`;

const SubTitle = styled.div`
  font-family: "Inter";
  font-size: 18px;
  line-height: 24px;
  color: #0A1F44;
  margin-bottom: 86px;
  ${lgDown`
    margin-top: 8px;
    margin-bottom: 0;
  `}
`;

const SubText = styled.div`
  color: #8a94a6;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 0 0 4px;
  margin-bottom: 52px;
  ${lgDown`
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0 0 0;
    width: 100%;
  `}
  white-space: pre-wrap;
  @media (max-width:767.95px) {
    white-space:  normal;
  }
`;

const StyledSecondRowWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  ${lgDown`
    padding-top: 24px;
  `}
`;

const StyledArrowBackIcon = styled(ArrowBackIosIcon)`
  font-size: 18px;
  margin-top: 12px;
  cursor: pointer;
  margin-right: 32px;
  margin-left: 14px;
  ${lgDown`
    margin: 8px 12px 0 36px;
  `}
`;

const StyledCheckBoxContent = styled.div`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  & > span {
    color: #003da5;
    cursor: pointer;
  }
  ${lgDown`
    line-height: 20px;
  `}
`;

const AddictionalMargin = styled.div`
  margin-bottom: 20px;
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
`;

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  & .MuiInputLabel-formControl{
    ${lgDown`
      font-size: 16px;
      line-height: 24px;
      top: -30px;
    `}
  }      
`;

const StyledEmailInputWrapper = styled.div`
    position: relative;
    & > .BalloonTip {
            position: absolute;
            top: -35px;
            left: 125px;
            cursor: pointer;
    }
    & > .BalloonTip .contentDiv {
            bottom: 0px;
            top: auto;
            left: 0px;
            min-width: 330px;
            background: #4e5d78;
    }
    ${lgDown`
        & > .BalloonTip {
            position: absolute;
            top: -30px;
            left: 110px;
            cursor: pointer;
        }
         & > .BalloonTip .contentDiv {
            bottom: 0px;
            top: auto;
            left: 0px;
            min-width: 300px;
            background: #4e5d78;
        }
    `}
    ${smDown`
      padding: 20px 16px 23px;
      font-size: 16px;
      line-height: 20px;
      font-family: Inter;
      & > .BalloonTip {
            position: absolute;
            top: 30px;
            left: 127px;
            cursor: pointer;
       }
       & > .BalloonTip .contentDiv {
            bottom: -25px;
            top: auto;
            left: 20px;
            min-width: 210px;
            font-size: xx-small;
            background: #4e5d78;
       }
    `}
`;

const EmailInputWrapper = styled(StyledTextFieldWrapper)`
  ${lgDown`
    margin-top: 98px;
  `}
  ${smDown`
    padding-top: 0;
    margin-top: 40px;
  `}  
`;

const LongTextInputWrapper = styled(StyledTextFieldWrapper)`
  margin-left: 20px;
  flex: 1;
  ${smDown`
    width: auto;
    margin-left: 16px;
  `}
    .Mui-disabled {
        opacity: 0.5;
    }
`;

const ShortTextInputWrapper = styled(StyledTextFieldWrapper)`
  width: 161px;
  ${lgDown`
    width: 126px;
  `}
    .Mui-disabled {
        opacity: 0.5;
    }
`;

const SMSIconDiv = styled.div`
    position: relative;
    & > svg {
        position: relative;
        left: 8px;
        top: 44px;
        cursor: pointer;
    }
`;

const GreenDoneDiv = styled.div`
    position: relative;
    padding-left: 10px;
     & > svg {
        position: relative;
        left: 8px;
        top: 44px;
        width: 44px;
        height: 44px;
        cursor: pointer;
    }
`;

const StyledPopper = styled(Popper)`
  z-index: 999 !important;
`;

const StyledPopperContainer = styled.div`
  margin-top: 25px;
  ${smDown`
    margin-top: 83px;
  `}
`;

const PopperButtonText = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

const StyledPaper = styled(Paper)`
  width: 570px !important;
  height: 220px !important;
  background-color: #4a69c5 !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  line-height: 36px !important;
  ${lgDown`
    width: 364px !important;
    height: 220px !important;
    margin-top: 45px !important;
  `}
  ${smDown`
    width: 343px !important;
    height: 220px !important;
    margin: 0 auto !important;
  `}
`;

const StyledDownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 16px solid #4a69c5;
  margin-left: 255px;
  ${lgDown`
    margin-left: 169px;
  `}
  ${smDown`
    border-top: 0;
    border-bottom: 16px solid #4a69c5;
    margin: -207px auto;
  `}
`;

const PaperContent = styled.div`
  padding: 20px 16px 20px;
  text-align: center;
  ${lgDown`
    padding-top: 16px;
  `}
  ${smDown`
    padding-top: 20px;
  `}
  & > :first-child {
    font-family: "Stag";
    font-weight: 500;
    line-height: 36px;
    align-text: center;
    font-size: 24px;
    ${lgDown`
      font-size: 18px;
      line-height: 24px;
    `}
  }
  & > :nth-child(2) {
    width: auto;
    font-size: 18px;
    font-family: "Inter";
    line-height: 25px;
    padding-top: 12px;
    ${lgDown`
      padding-top: 12px;
      font-size: 14px;
      line-height: 21px;
    `}
    ${smDown`
      padding-top: 10px;
    `}
  }
  & > :nth-child(3) {
    padding-top: 20px;
    text-align: right;
    & > .Button {
      width: 120px !important;
      height: 50px !important;
      border-radius: 35px !important;
      background-color: #14284b !important;
    }
    ${smDown`
      text-align: center;
      & button {
        width: 160px !important;
      }
    `}
  }
`;

const StyledModalDiv = styled.div`
    width: 504px;
    text-align: center;
    
    div.title {
        font-family: Stag-Medium;
        font-size: 24px;
        color: #003DA5;
        margin-top: 40px;
    }
    
    div.content {
        margin-top: 41px;
        font-family: Inter-Regular;
        font-size: 18px;
    }
    
    div.buttonDiv {
        margin-top: 40px;
        margin-left: 161px;
        margin-right: 163px;
    }

`;

const StyledLanguageDiv = styled(LanguageSelectDiv)`
    font-weight: normal;
    top: 5%;
    right: 15%;
`;

const StyledWrapper = styled.div`
    font-size: 18px;
    line-height: 24px;
    color: #0a1f44;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
    float: right;
    margin-top: 41px;
    margin-bottom: 60px;
    ${lgDown`
      margin-top: 40px;
    `}
    ${smDown`
      margin-top: 20px;
      & button {
        width: 160px !important;
      }
    `}
`;

const NewPasswordInputWrapper = styled(StyledTextFieldWrapper)`
    margin-top: 53px;
`;

const ConfirmNewPasswordInputWrapper = styled(StyledTextFieldWrapper)`
    margin-top: 53px;
    margin-bottom: 32px;
`;

const StyledModalDiv2 = styled(StyledModalDiv)`
    width: 590px;
    white-space: pre-wrap;
    
    div.title {
        margin-top: 30px;
        ${({lang}) => {
    if (lang === 'zh') {
        return css` font-weight: bold`
    }
}}
    }
    div.content {
        margin: 41px auto 40px;
        text-align: left;
        
        ol {
            padding: 0;
            margin-left: 20px;
            margin-top: 0;
            list-style-type: lower-alpha;
            
            li {
                margin-bottom: 10px;
                margin-left: 12px;
            }
            
            ol {
                margin-top: 10px;
                list-style-type: lower-roman;
            }
        }
       
    }
`;

const StyledMessageDiv = styled.div`
    font-size: 16px;
    font-family: Inter-Regular;
`;

const PICSDiv = styled.div`
    li {
        margin-bottom: 5px;
    }
    
    ol {
        margin-top: 5px;
    }
    
    .content {
        white-space: pre-wrap;
    }
`;

const PersonalDetailsSection = (props) => {
    const cfg = useConfig(props.lang);
    const formId = "registration-personal-details";

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPopper, setOpenPopper] = React.useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [email, setEmail] = useState(props.user.email);
    const [confirmEmail, setConfirmEmail] = useState('');
    const [countryCode, setCountryCode] = useState(props.country === 'SGP' ? '65' : (props.country === 'MYS'? '60':'852'));
    const [phone, setPhone] = useState(props.user.phoneNumber);
    const [isCheckedOne, setIsCheckedOne] = useState(false);
    const [isCheckedTwo, setIsCheckedTwo] = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState();
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState();
    const [passwordFieldFocused, setPasswordFieldFocused] = useState();

    const [mobileModified, setMobileModified] = useState('N');
    const [otpDone, setOtpDone] = useState(false);
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
    const [authStepEmailMobileOtpDialog, setAuthStepEmailMobileOtpDialog]  = useState(false);
    const [maskedPhoneNumber, setMaskedPhoneNumber] = useState();
    const [resendTimer, setResendTimer] = useState(0);
    const [resendTimerIntervalId, setResendTimerIntervalId] = useState(null);
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState();
    const [openPicsDialog, setOpenPicsDialog] = useState(false);

    const [isPasswordCompliant, setIsPasswordCompliant] = useState(false);
    const passwordMinLength = props.history.location.state.passwordMinLength;


    useEffect(() => {
        _scrollToTop();
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Personal Information',
                vPath: '/registration/personalDetail'
            }
        });
    }, []);

    const handleClickTitle = () => {
        setOpenPopper(false);
    };

    const continueToNextPage = () => {
        if (validateAll()) {
            props.showSpinner();
            props.registerEmailAndMobile(email, countryCode, phone, password, mobileModified).then((response) => {
                if (response === 'SUCCESS') {
                    props.hideSpinner();
                    props.user.email = email.toLowerCase();
                    props.user.phoneNumber = phone;
                    props.user.country = props.country;
                    props.user.countryCode = countryCode;
                    props.storeUser(props.user);
                    props.history.push('/registration/verify');
                } else if (response === 'USER_EXISTS') {

                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'popup',
                            popupPath: '/registration/personalDetail',
                            popupTitle: 'Welcome Back'
                        }
                    });

                    props.showModal({
                        flat: true,
                        content: <StyledModalDiv>
                            <div className="title">Welcome Back!</div>
                            <div className="content">It has come to our attention that you have registered before.
                                Please login to your account with your registered email address
                            </div>
                            <div className="buttonDiv">
                                <StyledQnectButton onClick={() => {
                                    props.history.push('/login');
                                    props.hideModal();
                                }}>Login</StyledQnectButton>
                            </div>
                        </StyledModalDiv>
                    });
                }
                props.hideSpinner();
            }, error => {
                props.hideSpinner();
            });
        }
    }

    const passwordCompliantValue = (value) => {
        setIsPasswordCompliant(value);
    }

    const validateAll = () => {
        let validatePasswords = validatePasswordFormat();
        let validateFormFields = _validateForm(formId);
        let validatePhoneNumber = checkIfPhoneNumberVerified();
        let validateEmailFields = checkIfEmailAddressesMatch();

        if (!validatePasswords || !validateFormFields || !validatePhoneNumber || !validateEmailFields) {
            return false;
        } else {
            return true;
        }
    }

    const checkIfEmailAddressesMatch = () => {
        if (email.toLowerCase() != confirmEmail.toLowerCase()){
            setEmailAddressErrorMessage(cfg('registration.errorMessages.emailAddressesNotMatch'));
            return false;
        } else {
            setEmailAddressErrorMessage('');
            return true;
        }
    }

    const checkIfPhoneNumberVerified = () => {
        if (mobileModified === 'Y' && !otpDone) {
            setPhoneNumberErrorMessage(cfg('registration.errorMessages.verifyPhoneNumber'));
            return false;
        } else {
            return true;
        }
    }

    const validatePasswordFormat = () => {

        if (password != confirmPassword) {
            setPasswordErrorMessage(cfg('registration.errorMessages.passwordNotMatch'));
            setConfirmPasswordErrorMessage(cfg('registration.errorMessages.passwordNotMatch'));
            return false;
        }


        if (isPasswordCompliant) {
            return true;
        }

        setPasswordErrorMessage(cfg('registration.errorMessages.invalidPassword'));
        setConfirmPasswordErrorMessage(cfg('registration.errorMessages.invalidPassword'));
        return false;
    }

    const switchLanguage = () => {
        let newLang = props.lang === 'en' ? 'zh' : 'en';
        props.setLang(newLang);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Change-Language'
            }
        });
    }

    const openPICSPage = () => {
        setOpenPicsDialog(true);
        TagManager.dataLayer({
            dataLayer: {
                event: 'popup',
                popupPath: '/registration/personalDetail',
                popupTitle: 'Personal Information Collection Statement ("PICS")'
            }
        });
    }

    const validatePhoneNumberFormat = (number) => {
        if (props.country === 'HKG') {
            let regex = /[5679](\d{7})$/g;
            if (number.match(regex)){
                setPhoneNumberErrorMessage('');
            } else {
                setPhoneNumberErrorMessage(cfg('registration.errorMessages.invalidPhoneNumberFormat'));
            }
        } else {
            setPhoneNumberErrorMessage('');
        }
    }

    const handleSendOtp = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'popup',
                popupPath: '/registration/personalDetail',
                popupTitle: 'Complete Authentication'
            }
        });

        let proceed = false;

        setMaskedPhoneNumber(customReplaceAll(/^\d*(?=\d{4}$)/g, phone, '•'));
        let regex = /[5679](\d{7})$/g;

        if (props.country === 'HKG') {
            // bypass phone number for dev
            if (process.env.REACT_APP_ENV  !== 'prod' && phone === '12345678') {
                setPhoneNumberErrorMessage('');
                proceed = true;
            }
            else if (phone.match(regex)) {
                setPhoneNumberErrorMessage('');
                proceed = true;
            } else {
                setPhoneNumberErrorMessage(cfg('registration.errorMessages.invalidPhoneNumberFormat'));
                proceed = false;
            }
        } else {
            proceed = true;
        }

        if (proceed && countryCode) {
            props.sendOTPToEmailOrMobile(null, countryCode, phone, mobileModified)
                .then((response) => {
                    props.setCpToken(response.token);
                    resetResendTimer();
                    setAuthStepEmailMobileOtpDialog(true);
                    props.hideSpinner();
                });
        }
    }

    const handleResend = () => {
        props.showSpinner();
        if (resendTimer === 0) {
            props.sendOTPToEmailOrMobile(null, countryCode, phone, mobileModified)
                .then((response) => {
                    props.setCpToken(response.token);
                    props.hideSpinner();
                }, () => props.hideSpinner());
            resetResendTimer();
        }
    }

    const resetResendTimer = () => {
        if (resendTimerIntervalId) {
            clearInterval(resendTimerIntervalId);
        }
        setResendTimer(60);
        let setTimerInterval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(setTimerInterval);
                    return 0;
                }
            });
        }, 1000);
        setResendTimerIntervalId(setTimerInterval);
    }

    const handleConfirm = (otpValue) => {
        props.showSpinner();
        props.verifyOtp(otpValue).then((response) => {
            if (response.success) {
                props.setCpToken(response.token);
                setAuthStepEmailMobileOtpDialog(false);
                setOtpDone(true);
                props.hideSpinner();
            } else {
                setOtpErrorMessage(cfg("otp.errorMessages.invalidOTP"));
                props.hideSpinner();
            }
        }, (error) => {
            setOtpErrorMessage(error.response.data);
            props.hideSpinner();
        })
    }

    return (
        <Registration currentStep={1}>
            <Hidden xsDown>
                <Title>
                    <StyledArrowBackIcon
                        onClick={() => {
                            props.history.push("/registration/authentication");
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'Icon-Click',
                                    eventCategory: 'Back-Arrow'
                                }
                            })
                        }}
                    />
                    <div style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>{cfg('labels.personalDetails.title')}</div>
                    <StyledLanguageDiv onClick={switchLanguage} style={{ visibility: (props.country === 'SGP' || props.country === "MYS" ? 'hidden' : 'visible')}}>
                        {(props.lang === 'en') ?
                            cfg("labels.lang_zh")
                            :
                            cfg("labels.lang_en")
                        }
                    </StyledLanguageDiv>
                </Title>
            </Hidden>
            <Hidden smUp>
                <MobileHeader
                    title={cfg('labels.personalDetails.title')}
                    lang={(props.lang === 'en') ? cfg("labels.lang_zh") : cfg("labels.lang_en")}
                    onBack={() => props.history.go(-1)}
                    onToggleLang={() => switchLanguage()}
                    onClickTitle={() => {
                    }}
                    isHidden={props.country === 'SGP' || props.country === "MYS" }
                />
            </Hidden>

            <StyledWrapper>
                <StyledEmailInputWrapper>
                    <EmailInputWrapper ref={setAnchorEl}>
                        <QnectTextField field="registrationEmailAddress"
                                        value={email}
                                        onBlur={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setEmailAddressErrorMessage('');
                                        }}
                                        formId={formId}
                                        maxLength={60}
                                        errorMessage={emailAddressErrorMessage}
                        />
                    </EmailInputWrapper>
                    <BalloonTip>{cfg("tooltip.personalDetailsEmail")}</BalloonTip>
                    <SubText>{cfg('labels.personalDetails.message1')}</SubText>

                    <StyledTextFieldWrapper>
                        <QnectTextField field="registrationConfirmEmailAddress"
                                        value={confirmEmail}
                                        onBlur={(e) => {
                                            setConfirmEmail(e.target.value);
                                        }}
                                        onChange={(e) => {
                                            setConfirmEmail(e.target.value);
                                            setEmailAddressErrorMessage('');
                                        }}
                                        formId={formId}
                                        maxLength={60}
                                        errorMessage={emailAddressErrorMessage}
                        />
                    </StyledTextFieldWrapper>

                    <StyledSecondRowWrapper isError={phone === "1"}>
                        <ShortTextInputWrapper>
                            <QnectSelectField
                                field="registrationCountryCode"
                                value={countryCode}
                                onChange={(evt) => setCountryCode(evt.target.value)}
                                onBlur={(evt) => setCountryCode(evt.target.value)}
                                disabled={otpDone}
                                formId={formId}
                            />
                        </ShortTextInputWrapper>
                        <LongTextInputWrapper>
                            <QnectTextField
                                field="registrationPhoneNumber"
                                value={phone}
                                onChange={(evt) => {
                                    if (props.user.phoneNumber !== evt.target.value) {
                                        setMobileModified('Y');
                                    } else {
                                        setMobileModified('N')
                                    }
                                    //validatePhoneNumberFormat(evt.target.value);
                                    setPhone(evt.target.value);
                                }}
                                onKeyPress={(event) => {
                                    if (_isNotDecimalChar(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                maxLength={ 12 }
                                errorMessage={phoneNumberErrorMessage}
                                formId={formId}
                                disabled={otpDone}
                            />
                        </LongTextInputWrapper>
                        {mobileModified === 'Y' ?
                            !otpDone ?
                                <SMSIconDiv>
                                    <SMSIcon
                                        title={"Send Verification Code"}
                                        onClick={() => {
                                            handleSendOtp();
                                        }}
                                    />
                                </SMSIconDiv> :
                                <GreenDoneDiv>
                                    <GreenDone/>
                                </GreenDoneDiv>
                        : null}
                    </StyledSecondRowWrapper>

                    <SubText>{cfg('labels.personalDetails.message2')}</SubText>

                    <NewPasswordInputWrapper>
                        <QnectTextField field="registrationPassword"
                                        value={password}
                                        onFocus={() => setPasswordFieldFocused(true)}
                                        onChange={(evt) => {
                                            setPasswordErrorMessage('');
                                            setPassword(evt.target.value);
                                        }}
                                        onBlur={(evt) => {
                                            setPasswordErrorMessage('');
                                            setPassword(evt.target.value);
                                            setPasswordFieldFocused(false);
                                            if (evt.target.value && evt.target.value.length < passwordMinLength) {
                                                setPasswordErrorMessage(cfg('labels.password.line0')  + passwordMinLength + cfg('labels.password.line1'));
                                            }
                                        }}
                                        type={showPassword ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <HideIcon/> : <ShowIcon/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        errorMessage={passwordErrorMessage}
                                        formId={formId}/>

                        <PasswordReq password={password} isFocused={passwordFieldFocused} isPasswordCompliant={passwordCompliantValue} passwordMinLength={passwordMinLength} email={email}/>
                    </NewPasswordInputWrapper>
                    <ConfirmNewPasswordInputWrapper>
                        <QnectTextField field="registrationConfirmPassword"
                                        value={confirmPassword}
                                        onChange={(evt) => {
                                            setConfirmPasswordErrorMessage('');
                                            setConfirmPassword(evt.target.value);
                                        }}
                                        onBlur={(evt) => {
                                            setConfirmPasswordErrorMessage('');
                                            setConfirmPassword(evt.target.value);
                                            if (evt.target.value && evt.target.value.length < passwordMinLength) {
                                                setConfirmPasswordErrorMessage(cfg('labels.password.line0')  + passwordMinLength + cfg('labels.password.line1'));
                                            }
                                        }}
                                        type={showConfirmPassword ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                    {showConfirmPassword ? <HideIcon/> : <ShowIcon/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        errorMessage={confirmPasswordErrorMessage}
                                        formId={formId}/>
                    </ConfirmNewPasswordInputWrapper>

                    <LabelledCheckbox checked={isCheckedOne} onChange={(event) => setIsCheckedOne(event.target.checked)}>
                        <StyledCheckBoxContent>
                            {
                                props.lang !== 'zh' ?
                                    <>
                                        {cfg('labels.personalDetails.checkboxMessage2')}
                                        {props.country === 'MYS' ?
                                                <>
                                                    <span onClick={() => {
                                                        window.open("https://www.qbe.com/my/privacy-policy","_blank")
                                                    }}>Privacy Policy
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span onClick={openPICSPage}>
                                                        {cfg('labels.personalDetails.checkboxMessage2PICS')}
                                                    </span>.(Optional)
                                                </>
                                        }
                                    </> :
                                    <>
                                        {cfg('labels.personalDetails.checkboxMessage2_zh.part1')}
                                        <span onClick={openPICSPage}>
                                            {cfg('labels.personalDetails.checkboxMessage2_zh.part2')}
                                        </span>
                                        {cfg('labels.personalDetails.checkboxMessage2_zh.part3')}
                                    </>
                            }
                        </StyledCheckBoxContent>
                    </LabelledCheckbox>
                    <AddictionalMargin></AddictionalMargin>
                    <StyledMessageDiv>{cfg('labels.personalDetails.checkboxMessage1'+props.country)}</StyledMessageDiv>
                    <AddictionalMargin></AddictionalMargin>

                    <StyledButtonWrapper>
                        <StyledQnectButton
                            onClick={() => continueToNextPage()}
                        >
                            {cfg("labels.continue")}
                        </StyledQnectButton>
                    </StyledButtonWrapper>
                </StyledEmailInputWrapper>
            </StyledWrapper>

            <AfterRegDialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                    props.parentHistory.replace("/login");
                }}
                cfg={cfg}
            />

            <AuthStepEmailMobileOtpDialog open={authStepEmailMobileOtpDialog}
                                          onClose={() => {
                                              setAuthStepEmailMobileOtpDialog(false);
                                              TagManager.dataLayer({
                                                  dataLayer: {
                                                      event: 'Icon-Click',
                                                      eventCategory: 'Close'
                                                  }
                                              })
                                          }}
                                          maskedPhone={maskedPhoneNumber}
                                          isForEmail={false}
                                          handleResend={handleResend}
                                          onConfirm={handleConfirm}
                                          errorMessage={otpErrorMessage}
                                          setErrorMessage={setOtpErrorMessage}
                                          resendTimer={resendTimer}
                                          cfg={cfg}/>

            <StyledPopper
                open={anchorEl != null && openPopper}
                anchorEl={anchorEl}
                placement={"top"}
                transition
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                    arrow: {
                        enabled: true,
                    },
                }}
            >
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <StyledPopperContainer>
                            <StyledPaper>
                                <PaperContent>
                                    <div>{cfg('registration.reminder')}</div>
                                    <div>
                                        {cfg('registration.personalDetailsPopperMessage'+props.country)}
                                    </div>
                                    <div>
                                        <QnectButton onClick={handleClickTitle}>
                                            <PopperButtonText> { cfg('ok.label') }</PopperButtonText>
                                        </QnectButton>
                                    </div>
                                </PaperContent>
                            </StyledPaper>
                            <StyledDownArrow/>
                        </StyledPopperContainer>
                    </Fade>
                )}
            </StyledPopper>


            <PopupDialogue open={openPicsDialog}
                           onClose={() => setOpenPicsDialog(false)}
                           title='labels.PICS.title'
                           lang={props.lang}
                           cfg={cfg}>
                {props.lang === 'zh' ?
                    <PICSDiv>
                        <div className="content">
                            {cfg('labels.PICS.message')}
                            <ol type="a">
                                <li>
                                    {cfg('labels.PICS.message2')}
                                </li>
                                <li>
                                    {cfg('labels.PICS.message3')}
                                    <a style={{color: "#003DA5"}} href="https://hk-customer.qbe.com/portal" target="_blank">hk-customer.qbe.com/portal</a>
                                    {cfg('labels.PICS.message3-part2')}
                                </li>
                                <li>
                                    {cfg('labels.PICS.message4.title')}
                                    <ol type="i">
                                        <li>{cfg('labels.PICS.message4.msg-sub1')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub2')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub3')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub4')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub5')}</li>
                                    </ol>
                                </li>
                                <li>{cfg('labels.PICS.message5')}</li>
                                <li>{cfg('labels.PICS.message6')}</li>
                                <li>{cfg('labels.PICS.message7')}</li>
                            </ol>
                        </div>
                    </PICSDiv>
                    :
                    <PICSDiv>
                        <div className="content">
                            {cfg('labels.PICS.message')}
                            <ol type="a">
                                <li>{cfg('labels.PICS.message2')}</li>
                                <li>
                                    {cfg('labels.PICS.message3')}
                                    <a style={{color: "#003DA5"}} href="https://hk-customer.qbe.com/portal" target="_blank">hk-customer.qbe.com/portal</a>
                                    {cfg('labels.PICS.message3-part2')}
                                </li>
                                <li>
                                    {cfg('labels.PICS.message4.title')}
                                    <ol type="i">
                                        <li>{cfg('labels.PICS.message4.msg-sub1')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub2')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub3')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub4')}</li>
                                        <li>{cfg('labels.PICS.message4.msg-sub5')}</li>
                                    </ol>
                                </li>
                                <li>{cfg('labels.PICS.message5')}</li>
                                <li>{cfg('labels.PICS.message6')}</li>
                                <li>{cfg('labels.PICS.message7')}</li>
                            </ol>
                        </div>
                    </PICSDiv>
                }

            </PopupDialogue>

        </Registration>
    );
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        country: _getCountry(state),
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storeUser: _storeUser,
    registerEmailAndMobile: _registerEmailAndMobile,
    showModal: _showModal,
    hideModal: _hideModal,
    setLang: _setLang,
    sendOTPToEmailOrMobile: _sendOTPToEmailOrMobile,
    verifyOtp: _verifyOtp,
    setCpToken: _setCpToken
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsSection));
