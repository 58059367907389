import React, {memo, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import QnectButton from "../../qnect-ui/qnect-button";
import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import Dialog from "../dialog";
import PropTypes from "prop-types";
import useConfig from "../../qnect-ui/qnect-config";
import {connect} from "react-redux";
import {_getLang} from "../../../store/system/service";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 76px 32px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 76px 32px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      justifyContent: "space-between"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "28px"
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px"
    }
  }
}));

const LogoutDialog = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const cfg = useConfig(props.lang);

  return (
    <Dialog className={classes.dialog} open={props.open} onClose={props.onClose}>
      <DialogTitle title={cfg("profilePage.signOut")} closable={false} onClose={props.onClose} />
      <DialogContent>
        <div className={classes.message}>{cfg("profilePage.logOutMessage")}</div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ButtonWrapper color="secondary">
          <QnectButton color="secondary" onClick={() => props.onClose()}>
            {cfg("profilePage.cancel")}
          </QnectButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <QnectButton className={classes.button} onClick={() => props.onLogout()}>
            {cfg("profilePage.logOut")}
          </QnectButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
}

LogoutDialog.propTypes = {
  cfg: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onLogout: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    lang: _getLang(state)
  }
}

export default memo(connect(mapStateToProps)(LogoutDialog));
