import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogTitle from "../dialog-title";
import { ReactComponent as Phone } from "../../../assets/login/Phone_sharp.svg";
import { ReactComponent as Mail } from "../../../assets/login/Mail_sharp.svg";
import { smDown, lgDown } from "../../../ui/common/breakpoints";

const StyledPhone = styled(Phone)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  vertical-align: bottom;
`;

const StyledMail = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  vertical-align: bottom;
`;

const StyledMessage = styled.div`
  margin-top: 30px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 470px;
  ${lgDown`
    margin-top: 20px;
  `}
  ${smDown`
    margin-top: 4px;
    font-size: 16px;
    line-height: 20px;
    width: auto;
  `}
`;

const Wrapper = styled.div`
  margin: 40px auto 0;
  font-size: 18px;
  line-height: 25px;
  color: #0a1f44;
  text-align: center;
  font-family: Inter;
  ${smDown`
    font-size: 16px;
    line-height: 20px;
  `}
`;

const SecondWrapper = styled(Wrapper)`
  ${smDown`
    margin: 20px auto 0;
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "14px 76px 52px",
    [theme.breakpoints.down("md")]: {
      padding: "14px 39px 52px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "4px 20px 60px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

export default function ClaimRegisteredDialog({ open, onClose }) {
  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle title={"Claim Registered"} onClose={onClose} />
        <DialogContent>
          <StyledMessage>
            You registered claim for domestic helper insurance is being reviewed. Please contact QBE Hong Kong's Customer Service if you
            need any help.
          </StyledMessage>
          <Wrapper>
            <StyledPhone />
            +852 2123-1234
          </Wrapper>
          <SecondWrapper>
            <StyledMail />
            customerservice@qbe.com
          </SecondWrapper>
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
