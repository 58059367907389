import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import styled from "styled-components";
import {withStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import useConfig from "../qnect-ui/qnect-config";
import QnectButton from "../qnect-ui/qnect-button";
import Divider from "../../components/custom/renewal-notice/divider";
import ButtonWrapper from "../../components/custom/button-wrapper";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import {ReactComponent as Phone} from "../../assets/login/Phone_sharp.svg";
import {ReactComponent as Mail} from "../../assets/login/Mail_sharp.svg";
import BlankImage from "../../assets/profile/avatar_image_placeholder.svg";
import {smDown} from "../../ui/common/breakpoints";
import {_getPolicyPayment, _issuePolicyWithCreditTerms, _storePolicyPayment} from "../../store/policy-payment/service";
import {
  _getLang,
  _getPolicyToken,
  _getPortfolioToken,
  _hideModal,
  _hideSpinner,
  _setCpToken,
  _showError,
  _showModal,
  _showSpinner
} from "../../store/system/service";
import {_loadDocument, _retrieveRenewalNoticeAndAgentDetails} from "../../store/policy/service";
import {connect} from "react-redux";
import Header from "../custom/header";
import * as TagManager from "react-gtm-module";
import moment from "moment";
import {getPremiumFormat, showGstDisclaimer} from "../../utils/utility";
import RenewalConfirmationDialog from "../custom/renewal-notice/renewal-confirmation-dialog";
import AutoRenewalSectionRenewalStatus from "../auto-renewal/auto-renewal-section-status";
import {OPTOUT} from "../auto-renewal/auto-renewal-constants";

const Container = styled.div`
  background: #f6f8fa;
  height: 100%;
  min-height: 1761px;
  margin-bottom: 5px;
  padding-bottom: 20px;
  ${smDown`
    min-height: 1397px;
  `}
`;

const HeaderSection = styled.div`
  width: 100%;
  background: #fff;
  ${smDown`
    height: 250px;
  `}
`;

const HeaderInfoBox = styled.div`
  width: 600px;
  border-radius: 20px;
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 23px 30px 24px 30px;
  margin: 30px auto;
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  ${smDown`
    width: 343px;
    height: auto;
    padding: 16px;
  `}
`;

const ContentSection = styled.div`
  margin: 0 auto 0;
  width: 100%;
  max-width: 1110px;
  padding-bottom: 20px;
  ${smDown`
    margin: 290px 0 0;
  `}
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  height: 100%;
  position: relative;
`;

const ContentWrapper = styled(Wrapper)`
  width: 660px;
  margin: 40px auto;
  ${smDown`
    width: 343px;
    margin: 40px auto 0;
    padding-bottom: 40px;
  `}
`;

const RenewalTitle = styled.div`
  width: 600px;
  margin: 0px auto 0px 54px;
  color: #0a1f44;
  font-family: "Stag";
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  ${smDown`
    width: 343px;
    margin: 0px auto 0px;
  `}
`;

const RenewalInfoBox = styled.div`
  width: 600px;
  border-radius: 20px;
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 14px 30px 50px 30px;
  margin: 30px auto 40px;
  color: #0a1f44;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  ${smDown`
    width: 343px;
    margin: 24px auto 30px;
    padding: 8px 4px 40px;
  `}
`;

const BackIcon = styled(Back)`
  margin-right: 8px;
`;

const BackWrapper = styled.div`
  margin-top: 40px;
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  & button {
    height: 70px !important;
    width: 350px !important;
    margin: 40px auto 0 !important;
    display: block !important;
    font-size: 20px !important;
    text-align: center !important;
    ${smDown`
      height: 56px !important;
      width: 214px !important;
      margin: 22px auto 0 !important;
    `}
  }
`;

const Title = styled.div`
  margin-top: 40px;
  font-size: 40px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
`;

const Avatar = styled.img`
  width: 67.43px;
  height: 67.43px;
  border-radius: 50%;
  position: relative;
  left: 1.5px;
  top: 1px;
`;

const AgentTitle = styled.div`
  color: #003da5;
  font-family: Stag;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 32px;
`;

const AgentMobileTitle = styled(AgentTitle)`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 100px;
  margin-bottom: 5px;
  height: 48px;
`;

const AgentName = styled.div`
  color: #0a1f44;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 8px;
`;

const AgentContactPhone = styled.div`
  display: flex;
  align-items: center;
  & > a {
    color: black;
    text-decoration: none !important;
  }
`;

const AgentContactEmail = styled(AgentContactPhone)`
  margin-left: 24px;
  cursor: pointer;
  & > a {
    color: black;
    text-decoration: none !important;
  }
`;

const PhoneIcon = styled(Phone)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const MailIcon = styled(Mail)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const AgentBox = styled.div`
  width: 600px;
  border-radius: 20px;
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 30px;
  margin: 40px auto;
  color: #0a14ff;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  ${smDown`
    width: 343px;
    padding: 12px 20px 20px;
    display: block;
    margin-top: 20px;
  `}
`;

const AgentBoxRow = styled.div`
  position: relative;
  ${smDown`
    display: flex;
  `}
`;

const AgentContent = styled.div`
  padding-left: 30px;
  ${smDown`
    padding-left: 0;
  `}
`;

const AgentContact = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-family: Inter;
  color: #0a1f44;
  padding-top: 20px;
  ${smDown`
    display: block;
    margin-top: 0;
    & > :nth-child(2) {
      margin-left: 0;
      margin-top: 12px;
    }
  `}
`;

const AvatarWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  ${smDown`
    position: absolute;
    top: -35px;
  `}
`;

const AgentDescription = styled.div`
  color: #8a94a6;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  padding-top: 8px;
  ${smDown`
    color: #0a1f44;
  `}
`;

const HelperText = styled.div`
  color: #0a1f44;
  font-family: "InterSemiBold", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 40px auto;
  padding: 0 30px 0 30px;
  ${smDown`
    padding: 20px 0 20px;
  `}
`;

const StyledListItemIcon = withStyles({
  root: {
    width: "136px",
    color: "#0A1F44",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "20px",
  },
})(ListItemIcon);

const StyledListItemText = withStyles({
  root: {
    color: "#0A1F44",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "20px",
    marginLeft: "109px",
  },
})(ListItemText);


const ModifiedStyledListItemText = styled(StyledListItemText)`
  & span.MuiTypography-root {
    cursor: pointer;
    color: #003DA5;
  }
`;


const StyledListItemTextBold = styled(StyledListItemText)`
  .MuiTypography-body1 {
    font-weight: 600 !important;
  }
`;

const StyledMobileListText = withStyles({
  root: {
    fontFamily: "Inter",
    lineHeight: "20px",
    padding: 0,
    margin: 0,
  },
  primary: {
    color: "#8a94a6",
    fontSize: "14px",
    paddingBottom: "4px",
  },
  secondary: {
    color: "#0a1f44",
    fontSize: "16px",
    "& .hightlight": {
      color: "#003da5",
    },
    "& .bold": {
      fontWeight: 600,
    },
  },
})(ListItemText);

const RenewalNotice = (props) => {
  const cfg = useConfig(props.lang);
  const history = useHistory();
  const [productName] = useState (props.policyPayment.catalogCode ?
      cfg("productType." + props.policyPayment.country + "." + props.policyPayment.catalogCode) : cfg("productType.GENPRD"));
  const [policyNumber] = useState (props.policyPayment.policyNumber);
  const [customerName] = useState (props.policyPayment.applicant?.fullName);
  const [paymentAmount] = useState(props.policyPayment.amount);
  const renewalPeriod = props.policyPayment.effectiveStart + ' - ' + props.policyPayment.effectiveEnd;
  const [agentDetails, setAgentDetails] = useState(null);
  const [renewalNoticeDoc, setRenewalNoticeDoc] = useState(null);
  const [renewalConfirmationDialogOpen, setRenewalConfirmationDialogOpen] = useState(false);
  const [autoRenewalStatus, setAutoRenewalStatus] = useState (props.policyPayment.autoRenewalStatus);
  const [eligibleForAutoRenewal] = useState (props.policyPayment.eligibleForAutoRenewal);
  const [confirmed, setConfirmed] = useState (false);
  const [callFunction, setCallFunction] = useState("");

  useEffect(() => {
    let currency = 'HKD';
    if (props.policyPayment.country === 'SGP') {
      currency = 'SGD';
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'vpageview',
        pageStep: 'Policy Renewal - Renewal Notice - Renewal Details - ' + productName,
        vPath: '/policy',
        vProduct: '/' + productName,
        vPolicyNo: policyNumber,
        vPaymentCurrency: currency,
        vPaymentAmount: paymentAmount
      }
    });

    props.showSpinner();
    props.retrieveRenewalNoticeAndAgentDetails(props.policyPayment.policyNumber, props.policyPayment.country,
        moment(props.policyPayment.effectiveStart, 'DD/MM/YYYY').toDate()).then(
        response => {
          setAgentDetails(response.agentDetails);
          setRenewalNoticeDoc(response?.document);
          props.setCpToken(response.token);
          props.hideSpinner();
        }, err => {
          console.error(err);
          props.hideSpinner();
        });
    setConfirmed(false);
  }, []);

  const handleBack = () => {
    if (props.policyToken) {
      props.history.push('/policy');
    }
    else {
      props.history.push('/portfolio');
    }
  }

  const canGoBack = () => {
    if (props.portfolioToken || props.policyToken) {
      return true;
    }
    return false;
  }

  const showRenewalDocument = () => {
    props.showSpinner();
    props.loadDocument(renewalNoticeDoc.ddocName).then((doc) => {
      if (doc) {
        const data = base64ToArrayBuffer(doc.documentBytes);
        const blob = new Blob([data], {type: 'application/pdf'});
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      }
      props.hideSpinner();
    });
  }

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const data = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      data[i] = binaryString.charCodeAt(i);
    }
    return data;
  }

  const getAgentName = () => {
    if (props.policyPayment.country === 'HKG' || props.policyPayment.country === 'HGI') {
      if (agentDetails?.agentType === 'H') {
        return cfg('policy.heading.hangSengName');
      } else if (agentDetails?.agentType === 'HD') {
        return cfg('policy.heading.hangSengDirect');
      } else if (agentDetails?.agentType === 'A' || agentDetails?.agentType === 'M') {
        return agentDetails?.agentName;
      } else if (agentDetails?.agentType === 'C') {
        return cfg('policy.heading.ccba');
      } else if (agentDetails?.agentType === 'MQ') {
        return cfg('policy.heading.manulifeQbeAgent');
      } else {
        return cfg('policy.heading.qbeDefaultIntermediary');
      }
    } else if (props.policyPayment.country === 'SGP') {
      return agentDetails?.agentName ? agentDetails?.agentName : "QBE";
    } else if (props.policyPayment.country === 'MYS') {
      return agentDetails?.agentName ? agentDetails?.agentName : "QBE";
    }
  }
  const getContactDetails = () => {
    if (props.policyPayment.country === 'SGP') {
      if (agentDetails?.agentPhoneNumber === '') {
        return <>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + (agentDetails?.agentEmailAddress
                ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}>
              {(agentDetails?.agentEmailAddress
                  ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}</a>
          </AgentContactEmail>
        </>
      } else {
        return <>
          <AgentContactPhone>
            <PhoneIcon/>
            <a href={'tel:' + ('+65' + agentDetails?.agentPhoneNumber)}>
              {('+65' + agentDetails?.agentPhoneNumber)}</a>
          </AgentContactPhone>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + (agentDetails?.agentEmailAddress
                ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}>
              {(agentDetails?.agentEmailAddress
                  ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.country))}</a>
          </AgentContactEmail>
        </>
      }
    } else if (props.policyPayment.country === 'HKG' || props.policyPayment.country === 'HGI') {
      if (agentDetails?.agentType === 'H' || agentDetails?.agentType === 'HD') {
        return <>
          <AgentContactPhone>
            <PhoneIcon/>
            <a href={'tel:' + cfg('genericLabels.phoneHref.' + props.policyPayment.country)}>
              {cfg('genericLabels.phone.' + props.policyPayment.country)}</a>
          </AgentContactPhone>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:cs.gihk@qbe.com'}>cs.gihk@qbe.com</a>
          </AgentContactEmail>
        </>
      } else if (agentDetails?.agentType === 'A') {
        return null;
      } else if (agentDetails?.agentType === 'M') {
        return <>
          <AgentContactPhone>
            <PhoneIcon/>
            <a href={'tel:' + (agentDetails?.agentPhoneNumber
                ? '+852' + agentDetails?.agentPhoneNumber : cfg('genericLabels.phoneHref.MANU'))}>
              {(agentDetails?.agentPhoneNumber
                  ? ('+852' + agentDetails?.agentPhoneNumber) : cfg('genericLabels.phone.MANU'))}</a>
          </AgentContactPhone>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + (agentDetails?.agentEmailAddress
                ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.HKG-CS'))}>
              {(agentDetails?.agentEmailAddress
                  ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.HKG-CS'))}</a>
          </AgentContactEmail>
        </>
      } else if (agentDetails?.agentType === 'MQ') {
        return <>
          <AgentContactPhone>
            <PhoneIcon/>
            <a href={'tel:' + cfg('genericLabels.phoneHref.MANU')}>
              {cfg('genericLabels.phone.MANU')}</a>
          </AgentContactPhone>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + cfg('genericLabels.email.HKG-CS')}>{cfg('genericLabels.email.HKG-CS')}</a>
          </AgentContactEmail>
        </>
      } else {
        return <>
          <AgentContactPhone>
            <PhoneIcon/>
            <a href={'tel:' + cfg('genericLabels.phoneHref.HKG-CS')}>
              {cfg('genericLabels.phone.HKG-CS')}</a>
          </AgentContactPhone>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + cfg('genericLabels.email.HKG-CS')}>
              {cfg('genericLabels.email.HKG-CS')}</a>
          </AgentContactEmail>
        </>
      }
    } else if (props.policyPayment.country === 'MYS') {
      if (agentDetails?.agentPhoneNumber === '') {
        return <>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + (agentDetails?.agentEmailAddress
                ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.policyPayment.country))}>
              {(agentDetails?.agentEmailAddress
                  ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.policyPayment.country))}</a>
          </AgentContactEmail>
        </>
      } else {
        return <>
          <AgentContactPhone>
            <PhoneIcon/>
            <a href={'tel:' + ('+60' + agentDetails?.agentPhoneNumber)}>
              {('+60' + agentDetails?.agentPhoneNumber)}</a>
          </AgentContactPhone>
          <AgentContactEmail>
            <MailIcon/>
            <a href={'mailto:' + (agentDetails?.agentEmailAddress
                ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.policyPayment.country))}>
              {(agentDetails?.agentEmailAddress
                  ? agentDetails?.agentEmailAddress : cfg('genericLabels.email.' + props.policyPayment.country))}</a>
          </AgentContactEmail>
        </>
      }
    }
  }

  const proceedToRenewPage = () => {
    props.storePolicyPayment(props.policyPayment);
    if (eligibleForAutoRenewal && !confirmed && props.policyPayment.autoRenewalStatus === OPTOUT) {
      autoRenewalConfirmModal();
    } else {
      if (props.policyPayment.country === 'SGP') {
        history.push("/renew/renewal-detail")
      } else {
        setRenewalConfirmationDialogOpen(true);
      }
    }
  }

  const proceedToRenewPayLaterPage = () => {
    if (props.policyPayment.country === 'HGI') {
      setRenewalConfirmationDialogOpen(false);
      process();
    } else {
      history.push("/renew/renewal-detail");
    }
  }

  const process = () => {
    props.showSpinner();
    props.policyPayment.isCustomerPortal = true;
    props.issuePolicyWithCreditTerms(props.policyPayment).then(resp => {
      props.hideSpinner();
      props.storePolicyPayment(resp);
      props.history.push('/renew/pay-later-finish');
    }, (error) => {
      props.hideSpinner();
      props.showError([error]);
      });
  }


  const getAgentContactCardHeader = () => {
    if (props.policyPayment.country === 'HKG') {
      return cfg('renewal.licensedIntermediaryAgentHeaderHKG');
    } else if (props.policyPayment.country === 'HGI'){
      return cfg('renewal.licensedIntermediaryAgentHeaderHGI');
    } else {
      return cfg('policy.heading.youAgentsContact');
    }
  }

  const updateAutoRenewalStatus = (autoRenewalStatus) => {
    setAutoRenewalStatus(autoRenewalStatus);
    props.policyPayment.autoRenewalStatus = autoRenewalStatus;
    props.storePolicyPayment(props.policyPayment);
    if(autoRenewalStatus === OPTOUT) {
      setConfirmed(false);
      setCallFunction("");
    }
  }

  const autoRenewalConfirmModal = () => {

    props.showModal({
      disableBackDropClick: true,
      flat: true,
      content:
          <div className="auto-renewal-modal_turn-on">
            <div className="modal-block">
              <div className="modal-top">
                <span className="modal-title">{ cfg('labels.autoRenewal.areYouSure') }</span>
              </div>
              <div className="modal-body">
                <div className="note-top">{ cfg('labels.autoRenewal.confirmationAutoRenewalMsg') }</div>
              </div>
              <div className="modal-bottom">
                <div className="button-container">
                  <QnectButton color="primary" className="btn-big" field="turnOffAutoRenewal" onClick={() => {
                    updateAutoRenewalStatus(OPTOUT);
                    setConfirmed(true);
                    props.hideModal();
                    props.history.push('/renew/renewal-detail')
                  }}/>
                  <QnectButton color="primary" className="btn-big" field="turnOnAutoRenewal" onClick={() => {
                    setCallFunction("showAutoRenewalTurnOnModal")
                    setConfirmed(true);
                  }}/>
                </div>
              </div>
            </div>
          </div>
    });
  }

  return (
    <Container className="renewal-notice-page">
      <HeaderSection>
        <Wrapper>
          <Header pathname={props.history.location.pathname}/>

          <Hidden smDown>
            <BackWrapper className="back-button-container" onClick={() => handleBack()}>
              <BackIcon />
              Back
            </BackWrapper>
            <Title className="desktop-header-title"><span style={props.lang === 'zh' ? {fontWeight: 'bold'} : {}} >{ cfg("renewal.policyRenewalTitle") }</span></Title>
          </Hidden>

          <HeaderInfoBox>
            <div>
            { cfg("renewal.policyRenewalHeading1")}
            </div>
            <div style={ props.policyPayment.country === 'SGP' ? {} : {marginTop: '20px'} }>
            { cfg("renewal.policyRenewalHeading2." + props.policyPayment.country).startsWith('renewal.policyRenewalHeading2') ? null : cfg("renewal.policyRenewalHeading2." + props.policyPayment.country)}
            </div>
            <div style={ props.policyPayment.country === 'SGP' ? {} : {marginTop: '20px'} }>
            { cfg("renewal.policyRenewalHeading3." + props.policyPayment.country).startsWith('renewal.policyRenewalHeading3') ? null : cfg("renewal.policyRenewalHeading3." + props.policyPayment.country) }
            </div>
            <div style={ props.policyPayment.country === 'HGI' ? {} : {marginTop: '20px'} }>
              { cfg("renewal.policyRenewalHeading4." + props.policyPayment.country).startsWith('renewal.policyRenewalHeading4') ? null : cfg("renewal.policyRenewalHeading4." + props.policyPayment.country)}
            </div>
            <div style={ props.policyPayment.country === 'HGI' ? {} : {marginTop: '20px'} }>
              { cfg("renewal.policyRenewalHeading4." + props.policyPayment.country + "1").startsWith('renewal.policyRenewalHeading4') ? null : cfg("renewal.policyRenewalHeading4." + props.policyPayment.country + "1") }
            </div>
          </HeaderInfoBox>
        </Wrapper>
      </HeaderSection>
      <ContentSection className="renewal-details-section">
        <ContentWrapper className="renewal-notice">
          <RenewalTitle>{ cfg("renewal.policyRenewalDetailsTitle")}</RenewalTitle>
          <RenewalInfoBox className="renewal-notice-page-block">
            <Hidden smDown>
              <List>
                <ListItem>
                  <StyledListItemIcon>{ cfg("renewal.policyNumber")}</StyledListItemIcon>
                  <StyledListItemText primary={ policyNumber } />
                </ListItem>
                <ListItem>
                  <StyledListItemIcon>{ cfg("renewal.productName")}</StyledListItemIcon>
                  <StyledListItemText primary={ productName } />
                </ListItem>
                <ListItem>
                  <StyledListItemIcon>{ cfg("renewal.insured")}</StyledListItemIcon>
                  <StyledListItemText primary={ customerName } />
                </ListItem>
                <ListItem>
                  <StyledListItemIcon>{ cfg("renewal.policyPeriod")}</StyledListItemIcon>
                  <StyledListItemText primary={ renewalPeriod } />
                </ListItem>
                { renewalNoticeDoc?.ddocName &&
                  <ListItem>
                    <StyledListItemIcon>{ cfg("renewal.document")}</StyledListItemIcon>
                    <ModifiedStyledListItemText primary={ 'Renewal Notice' } onClick={showRenewalDocument}/>
                  </ListItem>
                }
                <ListItem>
                  <StyledListItemIcon>{ cfg("renewal.renewalPremium")}</StyledListItemIcon>
                  <StyledListItemTextBold primary={ getPremiumFormat(props.policyPayment.country,paymentAmount, props.policyPayment.hasGST) } />
                </ListItem>
                { showGstDisclaimer(props.policyPayment.country) &&
                  <ListItem>
                    {cfg("renewal.renewalGstDisclaimer")}
                  </ListItem>
                }
              </List>
            </Hidden>
            <Hidden mdUp>
              <List>
                <ListItem>
                  <StyledMobileListText primary={ cfg("renewal.policyNumber")} secondary={policyNumber} />
                </ListItem>
                <ListItem>
                  <StyledMobileListText primary={ cfg("renewal.productName")} secondary={productName} />
                </ListItem>
                <ListItem>
                  <StyledMobileListText primary={ cfg("renewal.insured")} secondary={customerName} />
                </ListItem>
                <ListItem>
                  <StyledMobileListText primary={ cfg("renewal.policyPeriod")} secondary={renewalPeriod} />
                </ListItem>
                {renewalNoticeDoc?.ddocName &&
                  <ListItem>
                    <StyledMobileListText primary={cfg("renewal.document")}
                                          secondary={<div style={{color: '#003DA5'}}
                                                          onClick={showRenewalDocument}>
                                            {'Renewal Notice'}
                                          </div>}/>
                  </ListItem>
                }
                <ListItem>
                  <StyledMobileListText primary={ cfg("renewal.renewalPremium")} secondary={<div className={"bold"}>{ getPremiumFormat(props.policyPayment.country,paymentAmount, props.policyPayment.hasGST) }</div>} />
                </ListItem>
              </List>
            </Hidden>

            {
              eligibleForAutoRenewal &&
              <AutoRenewalSectionRenewalStatus
                  updateAutoRenewalStatusCallBack={updateAutoRenewalStatus}
                  autoRenewalStatus={autoRenewalStatus}
                  callFunction={callFunction}
              />
            }

            <StyledButtonWrapper>
              <QnectButton field={"renewNow"} onClick={() => proceedToRenewPage()}
              />
            </StyledButtonWrapper>
          </RenewalInfoBox>
          <HelperText>
            <Divider>OR</Divider>
          </HelperText>
          <HelperText>
            { cfg("renewal.agentHeading." + props.policyPayment.country)}
          </HelperText>
          <AgentBox>
            <AgentBoxRow>
              <AvatarWrapper>
                <Avatar src={BlankImage} />
              </AvatarWrapper>
              <Hidden mdUp>
                <AgentMobileTitle>{getAgentContactCardHeader()}</AgentMobileTitle>
              </Hidden>
            </AgentBoxRow>
            <AgentContent>
              <Hidden smDown>
                <AgentTitle>
                  <span style={props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                    {getAgentContactCardHeader()}
                  </span>
                </AgentTitle>
              </Hidden>
              {
                props.policyPayment.country === 'SGP' &&
                <AgentName>{agentDetails?.agentIntermediary}</AgentName>
              }
              <AgentName>{getAgentName()}</AgentName>
              <AgentContact>
                {getContactDetails()}
              </AgentContact>
            </AgentContent>
          </AgentBox>
        </ContentWrapper>
      </ContentSection>

      <RenewalConfirmationDialog open={renewalConfirmationDialogOpen}
                                 cfg={cfg}
                                 lang={props.lang}
                                 onClose={() => setRenewalConfirmationDialogOpen(false)}
                                 onConfirm={() => {proceedToRenewPayLaterPage()}}
                                 onClickDocument={showRenewalDocument}
      />
    </Container>
  );
};


const mapStateToProps = (state) => {
  return {
    lang: _getLang(state),
    policyPayment: _getPolicyPayment(state),
    portfolioToken: _getPortfolioToken(state),
    policyToken: _getPolicyToken(state)
  }
};

const mapDispatchToProps = {
  retrieveRenewalNoticeAndAgentDetails: _retrieveRenewalNoticeAndAgentDetails,
  showSpinner: _showSpinner,
  showError: _showError,
  showModal: _showModal,
  hideSpinner: _hideSpinner,
  hideModal: _hideModal,
  loadDocument: _loadDocument,
  setCpToken: _setCpToken,
  storePolicyPayment: _storePolicyPayment,
  issuePolicyWithCreditTerms: _issuePolicyWithCreditTerms,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalNotice));
