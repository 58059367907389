import React, {useEffect} from "react";
import AuthenticationSection from "../components/find-my-policy/authentication";
import AuthenticationCompanySection from "../components/find-my-policy/authentication-company"
import {_getPolicy} from "../store/policy/service";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {_getShowPageBroken, _hideSpinner, _isNoLogin, _setShowPageBroken} from "../store/system/service";

const FindMyPolicyPage = (props) => {

    useEffect(() => {
        if (props.showPageBroken) {
            props.history.push('/pageUnavailable');
            props.setShowPageBroken(false);
            props.hideSpinner();
        }
    }, [props.showPageBroken]);

    return (
        <React.Fragment>
            {
                (!props.policy.policyNumber ?
                        (
                            props.noLogin ? <Redirect to='/'/> : <Redirect to='/login'/>
                        )
                        :
                        (
                            (props.match.params.role === 'individual' && <AuthenticationSection/>)
                            ||
                            (props.match.params.role === 'company' && <AuthenticationCompanySection/>)
                        )
                )
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        noLogin: _isNoLogin(state),
        policy: _getPolicy(state),
        showPageBroken: _getShowPageBroken(state)
    }
}

const mapDispatchToProps = {
    setShowPageBroken: _setShowPageBroken,
    hideSpinner: _hideSpinner
}

export default connect(mapStateToProps, mapDispatchToProps)(FindMyPolicyPage);
