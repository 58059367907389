import React from 'react';
import {Day} from '@material-ui/pickers';
import moment from 'moment';

/**
 * Custom day renderer for the date picker.
 *
 * This makes the date picker render dates in the previous and next months (if
 * there's space) in a way that works well with min/max dates.
 *
 * @param {string|Date|object}     currentDate     - the currently selected date
 * @param {string|Date|object}     [minDate]       - the minimum selectable date
 * @param {string|Date|object}     [maxDate]       - the maximum selectable date
 * @returns {Function} the custom day renderer
 */
export default function dayRenderer(currentDate, minDate, maxDate) {
    return (date, selectedDate, dayInCurrentMonth) => {
        const isDateBeforeMinDate = minDate ? date.isBefore(minDate, 'day') : false;
        const isDateAfterMaxDate = maxDate ? date.isAfter(maxDate, 'day') : false;

        return (
            <Day
                disabled={isDateBeforeMinDate || isDateAfterMaxDate || !dayInCurrentMonth}
                current={date.isSame(moment(), 'day')}
                selected={!!currentDate && date.isSame(currentDate, 'day')}
                hidden={!dayInCurrentMonth && (isDateBeforeMinDate || isDateAfterMaxDate)}
            >
                {date.date()}
            </Day>
        );
    };
}
