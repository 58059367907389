import React, {useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';

import {smUp} from '../../common/breakpoints';
import {cssIf} from '../../common/conditional-styles';
import useScrollThreshold from '../../common/use-scroll-threshold';
import useModalAwareness from '../../common/use-modal-awareness';

const Page = styled.div`
    background: ${property('theme.color.grey30')};
    min-height: 100vh;
`;

// z-index prioritises this modal over content and floating input labels.
const HeaderSlot = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transition: box-shadow 300ms;
    z-index: 2; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    background: ${property('theme.color.tertiary')};

    ${cssIf('scHasShadow')`
        box-shadow: 0 2px 4px 2px ${({theme}) => rgba(theme.color.tertiary, 0.2)}
    `}
`;

const ContentSlot = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 15px;

    ${smUp`
        padding: 44px;
    `}
`;

// Spacing under fixed header
const HeaderSpacer = styled.div.attrs({
    'aria-hidden': true,
})`
    display: flex;
    visibility: hidden;
`;

export default function StepPageLayout({
    className,
    header,
    children,
}) {
    const hasHeaderShadow = useScrollThreshold();
    const headerSlotRef = useRef();

    // Sync the HeaderSlot's padding:right to that of the body so that
    // it doesn't change size when modals are opened and closed.
    useModalAwareness(headerSlotRef);

    return (
        <Page className={classnames('StepPageLayout', className)}>
            {!!header && (
                <>
                    <HeaderSlot
                        ref={headerSlotRef}
                        scHasShadow={hasHeaderShadow}
                    >
                        {header}
                    </HeaderSlot>
                    <HeaderSpacer>
                        {header}
                    </HeaderSpacer>
                </>
            )}

            <ContentSlot
                key="content"
                role="main"
            >
                {children}
            </ContentSlot>
        </Page>
    );
}

StepPageLayout.propTypes = {
    className: propTypes.string,
    header: propTypes.element,
    children: propTypes.node,
};
