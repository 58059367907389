import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import Link from '../typography/link';
import Button from './button';

const Container = styled.div`
    display: grid;
    grid-row-gap: 18px;
    text-align: center;
`;

const ButtonStrip = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin: -12px;
`;

const StyledButton = styled(Button)`
    margin: 12px;
`;

// TODO: Move styling to Link itself or remove altogether (waiting on design decision)
const StyledLink = styled(Link)`
    text-decoration: underline;
`;

export default function Actions({
    className,
    primaryAction,
    secondaryAction,
    tertiaryAction,
}) {
    const primaryElement = !!primaryAction && (
        <StyledButton
            type={primaryAction.canSubmit ? 'submit' : 'button'}
            isTall
            color="primary"
            href={primaryAction.href}
            onClick={primaryAction.onClick}
            disabled={primaryAction.isDisabled}
        >
            {primaryAction.label}
        </StyledButton>
    );

    const secondaryElement = !!secondaryAction && (
        <StyledButton
            isTall
            color="secondary"
            href={secondaryAction.href}
            onClick={secondaryAction.onClick}
            disabled={secondaryAction.isDisabled}
        >
            {secondaryAction.label}
        </StyledButton>
    );

    const tertiaryElement = !!tertiaryAction && (
        <StyledLink
            href={tertiaryAction.href}
            onClick={tertiaryAction.onClick}
            disabled={tertiaryAction.isDisabled}
        >
            {tertiaryAction.label}
        </StyledLink>
    );

    return (
        <Container className={classnames('Actions', className)}>
            <ButtonStrip>
                {secondaryElement}
                {primaryElement}
            </ButtonStrip>

            {tertiaryElement}
        </Container>
    );
}

Actions.propTypes = {
    className: propTypes.string,
    primaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
        canSubmit: propTypes.bool,
    }),
    secondaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
    tertiaryAction: propTypes.shape({
        isDisabled: propTypes.bool,
        label: propTypes.string.isRequired,
        href: propTypes.string,
        onClick: propTypes.func,
    }),
};
