import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../dialog-title";
import ButtonWrapper from "../button-wrapper";
import QnectButton from "../../qnect-ui/qnect-button";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px 25px 32px"
      },
      [theme.breakpoints.up("lg")]: {
        padding: "18px 42px 32px",
        width: "630px"
      }
    }
  },
  message: {
    textAlign: "center",
    color: "#0a1f44",
    fontFamily: "Inter",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
      fontSize: "16px",
      lineHeight: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "25px"
    }
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "32px"
    }
  }
}));

export default function EmailSentDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog className={classes.dialog} open={open} onClose={onClose}>
        <DialogTitle title="Email Sent" closable={false} />
        <DialogContent>
          <div className={classes.message}>
            The attachment has been sent. <br />
            If you do not receive the attachment within a few minutes, please
            check your Junk E-mail folder just in case the email got delivered
            there instead of your inbox.
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <ButtonWrapper>
            <QnectButton onClick={onClose}>Close</QnectButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    </div>
  );
}
